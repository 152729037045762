import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'

import { INTERVALS } from '../../shared/intervals'
import { GRAPH_TYPES } from '../../shared/sharedConstants'
import { getEnergyGraphOptions } from './energy-graph-options'
import { getPowerGraphOptions } from './power-graph-options'

export const INTERVAL_SETTINGS = {
  [INTERVALS.DAY]: {
    xAxisFormat: 'ha',
    energyTooltipFormat: 'MMM D, YYYY [at] ha',
    powerTooltipFormat: 'MMM D, YYYY [at] h:mma',
    endDateOffset: 1,
  },
  [INTERVALS.WEEK]: {
    xAxisFormat: 'ddd<br/>MM/DD',
    energyTooltipFormat: 'MMM D, YYYY',
    xLabelsOffset: -8,
    endDateOffset: -24 * 3600,
  },
  [INTERVALS.MONTH]: {
    xAxisFormat: 'MM/DD',
    energyTooltipFormat: 'MMM D, YYYY',
    xLabelsOffset: -2,
    endDateOffset: -24 * 3600,
  },
  [INTERVALS.CUSTOM]: {
    xAxisFormat: 'MM/DD',
    energyTooltipFormat: 'MMM D, YYYY',
    xLabelsOffset: -2,
    endDateOffset: -24 * 3600,
    disableMinX: false,
    disableMaxX: false,
  },
  [INTERVALS.YEAR]: {
    xAxisFormat: 'MMM',
    energyTooltipFormat: 'MMM YYYY',
    endDateOffset: -31 * 24 * 3600,
  },
  [INTERVALS.LIFETIME]: {
    xAxisFormat: 'YYYY',
    energyTooltipFormat: 'YYYY',
    disableMinX: false,
    disableMaxX: false,
    endDateOffset: -12 * 31 * 24 * 3600,
  },
}

export enum TAB_INDEXES {
  ENERGY = 0,
  POWER = 1,
}

type DataPoint = (string | number)[]

type TooltipsType = {
  tooltipConsumptionTotals: object[]
  tooltipGridTotals: object[]
  tooltipProductionTotals: object[]
  tooltipStorageTotals: object[]
}

type EnergyGraphProps = {
  energyData: {
    production: DataPoint[]
    consumption: DataPoint[]
    storage: DataPoint[]
    tooltips?: TooltipsType
  }
  powerData?: {
    production: DataPoint[]
    consumption: DataPoint[]
    storage: DataPoint[]
  }
  interval: INTERVALS
  hasConsumption: boolean
  startDate: number | null
  endDate: number | null
  graphType: GRAPH_TYPES
  onTabSelection?: (index: number) => any
  windowWidth?: number | null
  tooltips?: TooltipsType | null
}

const formatData = (energyValues) =>
  energyValues.map(([date, value, _]) => [
    moment(date).valueOf(),
    Math.round((value as number) * 100) / 100,
  ])

const calcMax = (seriesA, seriesB) =>
  ([] as any[])
    .concat(seriesA, seriesB)
    .reduce((acc, curr) => (acc < curr[1] ? curr[1] : acc), 0)

export const ProductionConsumptionGraph = ({
  energyData,
  powerData = {
    production: [],
    consumption: [],
    storage: [],
  },
  startDate,
  endDate,
  interval,
  hasConsumption,
  graphType,
  windowWidth,
}: EnergyGraphProps) => {
  let options
  const formattedProducedData = formatData(energyData.production)
  const formattedConsumedData = formatData(energyData.consumption)
  const maxY = calcMax(formattedProducedData, formattedConsumedData)
  const energyGraphOptions = useMemo(
    () =>
      getEnergyGraphOptions(
        formattedProducedData,
        formattedConsumedData,
        maxY,
        INTERVAL_SETTINGS[interval],
        hasConsumption,
        startDate,
        endDate,
        interval,
      ),
    [
      formattedConsumedData,
      formattedProducedData,
      interval,
      maxY,
      hasConsumption,
      startDate,
      endDate,
    ],
  )

  if (interval === INTERVALS.DAY) {
    const formattedProducedPowerData = formatData(powerData.production)
    const formattedConsumedPowerData = formatData(powerData.consumption)
    const maxPowerY = calcMax(
      formattedProducedPowerData,
      formattedConsumedPowerData,
    )
    const powerGraphOptions = getPowerGraphOptions(
      formattedProducedPowerData,
      formattedConsumedPowerData,
      maxPowerY,
      INTERVAL_SETTINGS[interval],
      hasConsumption,
      startDate,
      endDate,
    )

    options = graphType === 'energy' ? energyGraphOptions : powerGraphOptions
  } else {
    options = energyGraphOptions
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      key={`${windowWidth}`}
    />
  )
}
