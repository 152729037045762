import './style.scss'

import clsx from 'clsx'
import React, { ReactElement, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useHistory } from 'react-router-dom'

import closeIcon from './assets/close-icon.svg'

export const Modal = ({
  title,
  fromPath,
  modalProps = {},
  hideTopBar = false,
  children,
  noStylesModal = false,
}) => {
  const history = useHistory()
  const onClose = useCallback(() => history.push(fromPath), [fromPath, history])
  const hideBorderBar = clsx('modal-card-head', {
    'modal-hide-border': hideTopBar,
  })
  const overridePaddingForImage = clsx({
    'modal-card-body': !hideTopBar || !noStylesModal,
  })

  const contentStyles = clsx({
    'modal-content px-5': !noStylesModal,
    'modal-content': noStylesModal,
  })

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement<{ fromPath: string }>, {
        fromPath: fromPath,
        ...modalProps,
      })
    }
    return child
  })

  return createPortal(
    <div className="modal is-active">
      <div className="modal-background" />
      <div className={contentStyles}>
        {!noStylesModal && (
          <header className={hideBorderBar}>
            <h1 className="modal-card-title is-size-3">{title}</h1>
            <img
              className="close"
              src={closeIcon}
              alt="close"
              onClick={onClose}
            />
          </header>
        )}
        <section className={overridePaddingForImage}>
          {childrenWithProps}
        </section>
      </div>
    </div>,
    document.getElementById('modal-root') as any,
  )
}
