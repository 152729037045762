import React from 'react'

import { SeasonType } from './flat-timeline'

interface TimelineLegendsProps {
  seasons: SeasonType[]
  width: number
}

export const TimelineLegends = ({ seasons, width }: TimelineLegendsProps) => {
  const legends = seasons.map((season, index) => {
    const { name, color } = season
    if (!name) return <React.Fragment key={`${index}-${color}`} />
    return (
      <div className="single-legend" key={`${index}-${name}`}>
        <div className="legend-square" style={{ backgroundColor: color }} />
        {name}
      </div>
    )
  })

  return (
    <div className="legends-container" style={{ width: width }}>
      {legends}
    </div>
  )
}
