import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'

import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import costSavingsDiagram from './assets/cost-savings-modes-charts.svg'

export const CostsSavings = ({
  onCancel,
  onSetCost,
}: {
  onSetCost: () => void
  onCancel: () => void
}) => {
  const flags = useFlags() as LaunchDarklyFlags
  const COST_SAVINGS_MODE_FLAG = flags.costSavingsModeFlag ?? false

  const costSavingsAvailable = (
    <h5 className="mt-5">
      Will reduce your peak time use of Grid
      <br /> Energy between 5-8pm.
    </h5>
  )
  const costSavingsNonAvailable = (
    <h5 className="mt-5">Not available in your state.</h5>
  )

  const costSavingsSubTitleAvailable = (
    <h6 className="mt-5">
      You can still reserve up to 80% of the battery
      <br /> to prepare for an unexpected grid outage.
    </h6>
  )
  const costSavingsSubTitleNonAvailable = (
    <h6 className="mt-5">
      We are working to enable this
      <br /> option within your state.
    </h6>
  )
  const costSavingsActionsAvailable = (
    <>
      <button className="cancel button mr-5 mb-5" onClick={onCancel}>
        cancel
      </button>
      <button className="go-to-slider button" onClick={onSetCost}>
        Set up cost savings
      </button>
    </>
  )
  const costSavingsActionsNonAvailable = (
    <>
      <button className="back button mr-5" onClick={onCancel}>
        Back
      </button>
    </>
  )

  const locationStateCheck = COST_SAVINGS_MODE_FLAG

  const costSavingsSubTitle = locationStateCheck
    ? costSavingsAvailable
    : costSavingsNonAvailable

  const costSavingsSubSubTitle = locationStateCheck
    ? costSavingsSubTitleAvailable
    : costSavingsSubTitleNonAvailable

  const actionsMarkup = locationStateCheck
    ? costSavingsActionsAvailable
    : costSavingsActionsNonAvailable

  return (
    <section className="section cost-savings-mode-container columns is-mobile is-vcentered">
      <img
        className="column"
        src={costSavingsDiagram}
        alt="cost savings over 24 hours"
      />
      <article className="column setup-mode-details">
        <h4 className="is-bold">Cost Savings</h4>
        {costSavingsSubTitle}
        {costSavingsSubSubTitle}
        <section className="actions-container mt-5">{actionsMarkup}</section>
      </article>
    </section>
  )
}
