import React from 'react'

import { InfoWidget } from '../info-widget'
import myAccountIcon from './assets/my-account-icon.svg'

export const MyAccount = ({ name = '', email = '', phone = '' }) => {
  return (
    <InfoWidget
      className="my-account"
      list={[
        {
          title: 'Name',
          value: name,
        },
        { title: 'Email', value: email },
        { title: 'Phone', value: phone },
      ]}
      icon={myAccountIcon}
    />
  )
}
