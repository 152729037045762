import './style.scss'

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

import { Loader } from '../../components/loader'
import { NetworksList } from '../../components/networks-list'
import errorIcon from '../../components/system-connection/assets/error.svg'
import { USER_SELECTED_SITE_KEY } from '../../config/cookies'
import { FETCH_NETWORK_INFO } from '../Profile/query'
import {
  CONNECT_WIFI,
  FETCH_ASSIGNMENTS,
  FETCH_CONNECT_WIFI_RESULT,
  FETCH_SCAN_WIFI_RESULT,
  SCAN_WIFI,
} from './query'

const validate = (values) => {
  const errors: any = {}

  if (!values.ssid) {
    errors.ssid = 'SSID required'
  }

  return errors
}

export const WifiSettings = () => {
  const [cookies] = useCookies([USER_SELECTED_SITE_KEY])
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]

  const { data: assignmentsData } = useQuery(FETCH_ASSIGNMENTS, {
    variables: { siteKey: selectedSiteKey },
  })
  const [
    fetchNetworkInfo,
    { data: networkInfoData },
  ] = useLazyQuery(FETCH_NETWORK_INFO, { fetchPolicy: 'cache-and-network' })
  const [scanWifi, { data: scanWifiCommandData }] = useMutation(SCAN_WIFI)
  const [
    fetchScanWifiResult,
    { data: scanWifiData, stopPolling: stopPollingScanWifiResult },
  ] = useLazyQuery(FETCH_SCAN_WIFI_RESULT, {
    pollInterval: 1000,
    fetchPolicy: 'network-only',
  })
  const [connectWifi, { data: connectWifiCommandData }] = useMutation(
    CONNECT_WIFI,
  )
  const [
    fetchConnectWifiResult,
    { data: connectWifiData, stopPolling: stopPollingConnectWifiResult },
  ] = useLazyQuery(FETCH_CONNECT_WIFI_RESULT, {
    pollInterval: 1000,
    fetchPolicy: 'network-only',
  })

  const [isNetworkListLoading, setIsNetworkListLoading] = useState(true)
  const [isWifiConnecting, setIsWifiConnecting] = useState(false)
  const [serialNumber, setSerialNumber] = useState<any>(null)
  const [scanNetworkError, setScanNetworkError] = useState(false)
  const [connectWifiError, setConnectWifiError] = useState(false)

  const currentNetwork = useMemo(
    () => networkInfoData?.deviceStatus?.netIntfRptCtnt?.sta0Ssid,
    [networkInfoData],
  )

  // Current network logic ---
  useEffect(() => {
    if (serialNumber) {
      fetchNetworkInfo({
        variables: { serialNumber },
      })
    }
  }, [serialNumber, fetchNetworkInfo])

  // Scan network logic ----
  useEffect(() => {
    onScanNetwork()
  }, [serialNumber])

  useEffect(() => {
    if (assignmentsData?.site?.assignments?.length) {
      const [assignment] = assignmentsData?.site?.assignments
      setSerialNumber(assignment?.deviceSerialNumber)
    }
  }, [assignmentsData])

  useEffect(() => {
    if (
      serialNumber &&
      scanWifiCommandData?.scanWifi &&
      scanWifiCommandData?.scanWifi?.commandToken
    ) {
      setScanNetworkError(false)
      setIsNetworkListLoading(true)
      fetchScanWifiResult({
        variables: {
          serialNumber,
          commandToken: scanWifiCommandData?.scanWifi?.commandToken,
        },
      })
    }
  }, [serialNumber, scanWifiCommandData, fetchScanWifiResult])
  const isLoading = isNetworkListLoading || isWifiConnecting

  useEffect(() => {
    if (
      scanWifiData?.commandResult?.commandProcessed &&
      stopPollingScanWifiResult
    ) {
      stopPollingScanWifiResult()
      setIsNetworkListLoading(false)

      if (!scanWifiData?.commandResult?.commandSucceed) {
        setScanNetworkError(true)
      }
    }
  }, [scanWifiData, stopPollingScanWifiResult])

  // Connect wifi logic ---
  useEffect(() => {
    if (
      serialNumber &&
      connectWifiCommandData?.connectWifi &&
      connectWifiCommandData?.connectWifi?.commandToken
    ) {
      fetchConnectWifiResult({
        variables: {
          serialNumber,
          commandToken: connectWifiCommandData?.connectWifi?.commandToken,
        },
      })
    }
  }, [serialNumber, connectWifiCommandData, fetchConnectWifiResult])

  useEffect(() => {
    if (
      connectWifiData?.commandResult?.commandProcessed &&
      stopPollingConnectWifiResult
    ) {
      stopPollingConnectWifiResult()

      if (!connectWifiData?.commandResult?.commandSucceed) {
        setIsWifiConnecting(false)
        setConnectWifiError(true)
      } else {
        setTimeout(() => {
          setIsWifiConnecting(false)
          fetchNetworkInfo({
            variables: { serialNumber },
          })
        }, 2000)
      }
    }
  }, [
    fetchNetworkInfo,
    serialNumber,
    connectWifiData,
    stopPollingConnectWifiResult,
  ])

  useEffect(() => {
    if (isLoading) {
      setConnectWifiError(false)
      setScanNetworkError(false)
    }
  }, [isLoading])

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ssid: '',
      password: '',
    },
    validate,
    onSubmit: (values) => {
      setConnectWifiError(false)
      setIsWifiConnecting(true)
      connectWifi({
        variables: {
          serialNumber,
          ssid: values.ssid.trim(),
          password: values.password.trim(),
        },
      })
    },
  })

  // Callbacks ---
  const onScanNetwork = useCallback(() => {
    if (serialNumber) {
      setScanNetworkError(false)
      scanWifi({ variables: { serialNumber } })
    }
  }, [serialNumber, scanWifi])

  const onNetworkSelect = useCallback(
    (network) => {
      setFieldValue('ssid', network.ssid)
      setFieldValue('password', '')
    },
    [setFieldValue],
  )

  return (
    <div className="wifi-settings">
      <div className="level mb-0">
        <div className="level-left"></div>
        <div className="level-right">
          <button
            disabled={isLoading}
            onClick={onScanNetwork}
            className="scan-networks button is-primary is-uppercase"
          >
            Scan networks
          </button>
        </div>
      </div>
      {scanNetworkError && !isLoading && (
        <div className="wifi-error has-text-centered">
          <img className="mb-4" alt="scan wifi error" src={errorIcon} />
          <p className="is-size-5">
            An error occurred when trying to scan for wifi networks. Please try
            again or contact us at{' '}
            <a href="mailto:mysunpower@sunpower.com">mysunpower@sunpower.com</a>
          </p>
        </div>
      )}
      {connectWifiError && !isLoading && (
        <div className="wifi-error has-text-centered">
          <img className="mb-4" alt="scan wifi error" src={errorIcon} />
          <p className="is-size-5">
            An error occurred when trying to connect to the wifi network. Is the
            password correct? Please try again or contact us at{' '}
            <a href="mailto:mysunpower@sunpower.com">mysunpower@sunpower.com</a>
          </p>
        </div>
      )}
      {!scanNetworkError && !connectWifiError && (
        <NetworksList
          networks={scanWifiData?.commandResult?.wifiAps ?? []}
          currentNetwork={currentNetwork}
          isLoading={isLoading}
          onSelect={onNetworkSelect}
        />
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            className={clsx('input is-medium', {
              'is-danger': errors.ssid && touched.ssid,
            })}
            disabled={isLoading}
            name="ssid"
            type="text"
            placeholder="SSID (Network name)"
            autoComplete="off"
            onChange={handleChange}
            value={values.ssid}
          />
        </div>
        <div className="mb-3">
          <input
            className={clsx('input is-medium', {
              'is-danger': errors.password && touched.password,
            })}
            disabled={isLoading}
            name="password"
            type="password"
            placeholder="Password"
            autoComplete="off"
            onChange={handleChange}
            value={values.password}
          />
        </div>
        <div className="level">
          <div className="level-item level-right">
            <button
              type="submit"
              disabled={!serialNumber || isLoading}
              className="connect-wifi button is-primary is-uppercase"
            >
              Connect
              {isWifiConnecting ? <Loader className="ml-3" /> : null}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
