import React from 'react'

import paths from '../../pages/Router/paths'
import { formatCommaNumber } from '../../shared/valueFormatters'
import { STATUS_LABEL_SIZES, StatusLabel } from '../status-label'
import c02emissions from './assets/c02emissions.svg'

export const EnvironmentalSavings = ({
  carbondioxide = { value: 0, unit: '' },
}) => {
  const { value, unit } = carbondioxide
  return (
    <div>
      <StatusLabel
        iconEnabled={c02emissions}
        statusText="CO2 Emissions Avoided"
        label={`${formatCommaNumber(value)} ${unit}`}
        statusTextBold={false}
        size={STATUS_LABEL_SIZES.BIG}
        centered={true}
        infoLink={paths.INFO_ENVIRONMENTAL_SAVINGS}
      />
    </div>
  )
}
