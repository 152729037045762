import './style.scss'

import { useLazyQuery } from '@apollo/client'
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { Modal } from '../../components/modal'
import { SelectableItem } from '../../components/selectable-item'
import { Tabs } from '../../components/tabs'
import {
  alertSeenTimestampVar,
  wifiAlertSeenTimestampVar,
} from '../../config/cache'
import { LOGIN_OVERRIDE, USER_SELECTED_SITE_KEY } from '../../config/cookies'
import { filterAvailableUsers } from '../../shared/filterAvailableUsers'
import paths from '../Router/paths'
import { FETCH_PARTY_SITES, FETCH_SITE_PARTIES } from './query'

const validate = (values) => {
  const errors: any = {}

  if (!values.userID) {
    errors.userID = 'User ID required'
  } else if (!values.siteID) {
    errors.siteID = 'Site ID required'
  }

  return errors
}

export const AdminPage = ({ modal }: { modal?: JSX.Element }) => {
  const [, setCookie] = useCookies([LOGIN_OVERRIDE])
  const history = useHistory()
  const [
    fetchPartySites,
    { data: partySites, loading: loadingSites },
  ] = useLazyQuery(FETCH_PARTY_SITES)
  const [
    fetchSiteParties,
    { data: sitePartiesData, loading: loadingParties },
  ] = useLazyQuery(FETCH_SITE_PARTIES)

  const [adminUserOrSiteIDState, setAdminUserOrSiteIDState] = useState({
    userID: '',
    siteID: '',
  })
  const [selectedUserId, setSelectedUserId] = useState('')
  const { userID, siteID } = adminUserOrSiteIDState
  const errors = useMemo(() => validate(adminUserOrSiteIDState), [
    adminUserOrSiteIDState,
  ])

  const handleChange = (e: any) => {
    setAdminUserOrSiteIDState({
      ...adminUserOrSiteIDState,
      [e.target.name]: e.target.value,
    })
  }

  const searchForSitesWithUserID = React.useCallback(
    (userId) => {
      fetchPartySites({ variables: { partyId: userId } })
    },
    [fetchPartySites],
  )
  const searchForUsersWithSiteID = React.useCallback(
    (siteId) => {
      fetchSiteParties({ variables: { siteId } })
    },
    [fetchSiteParties],
  )
  const onGoToParty = React.useCallback(
    (partyId: string, siteId: string) => {
      alertSeenTimestampVar(null)
      wifiAlertSeenTimestampVar(null)
      setCookie(LOGIN_OVERRIDE, partyId)
      setCookie(USER_SELECTED_SITE_KEY, siteId)
      history.push(paths.SITES_LIST)
    },
    [history, setCookie],
  )

  useEffect(() => {
    if (partySites) {
      const sites = partySites?.party?.sites || []
      if (sites.length > 0) {
        alertSeenTimestampVar(null)
        wifiAlertSeenTimestampVar(null)
        setCookie(LOGIN_OVERRIDE, userID)
        history.push(paths.SITES_LIST)
      }
    }
  }, [partySites, history, userID, setCookie])

  const userIdTab = {
    label: 'User ID',
    className: 'user-id-container',
    component: () => (
      <section className="rows">
        <div className="row is-full my-5">
          <div className="columns px-5">
            <input
              className={clsx('input column is-6 is-medium', {
                'is-danger': errors.userID && false,
              })}
              name="userID"
              type="text"
              placeholder="Enter User ID"
              autoComplete="off"
              onChange={handleChange}
              value={userID}
            />
            <button
              type="submit"
              className={clsx(
                'column auto submit-referral button is-primary is-uppercase',
                {
                  'is-loading': loadingSites,
                },
              )}
              onClick={() => searchForSitesWithUserID(userID)}
            >
              GO
            </button>
          </div>
        </div>
      </section>
    ),
  }

  const siteIdTab = {
    label: 'Site ID',
    className: 'site-id-container',
    component: () => (
      <section className="rows">
        <div className="row is-full mt-5">
          <div className="columns px-5">
            <input
              className={clsx('column is-6 input is-medium', {
                'is-danger': errors.siteID && false,
              })}
              name="siteID"
              type="text"
              placeholder="Enter Site ID"
              autoComplete="on"
              onChange={handleChange}
              value={siteID}
            />
            <button
              type="submit"
              className={clsx(
                'column auto submit-referral button is-primary is-uppercase',
                {
                  'is-loading': loadingParties,
                },
              )}
              onClick={() => searchForUsersWithSiteID(siteID)}
            >
              Check Users
            </button>
          </div>
        </div>
        {sitePartiesData?.site?.parties.length > 0 && (
          <React.Fragment>
            <div className="row is-full has-top-border mt-2 mb-4" />
            <div className="row is-full py-5">
              <h3 className="title">Select a User ID</h3>
            </div>
            <div className="row is-full">
              <div className="selection-list">
                {filterAvailableUsers(sitePartiesData?.site?.parties)?.map(
                  ({ partyId, displayName }) => (
                    <SelectableItem
                      key={`site_${partyId}`}
                      displayText={`${partyId} - ${displayName}`}
                      selected={selectedUserId === partyId}
                      onClick={setSelectedUserId}
                      value={partyId}
                    />
                  ),
                )}
              </div>
            </div>
            <div className="row is-full my-5 pt-3">
              <div className="columns">
                <div className="column auto" />
                <button
                  type="submit"
                  className="column button is-primary is-uppercase"
                  onClick={() => onGoToParty(selectedUserId, siteID)}
                  disabled={selectedUserId === ''}
                >
                  GO
                </button>
                <div className="column auto" />
              </div>
            </div>
          </React.Fragment>
        )}
      </section>
    ),
  }

  return (
    <Layout title={'Admin View'}>
      <>
        <div className="section admin-page is-mobile columns is-centered is-vcentered">
          <article className="column is-hidden-mobile is-one-fifth" />
          <section className="selection-container column auto is-primary">
            <Tabs
              className="user-site-id-tabs"
              showSeparator={true}
              tabs={[userIdTab, siteIdTab]}
            />
          </section>
          <article className="column is-hidden-mobile is-one-fifth" />
        </div>
        {modal && (
          <Modal
            hideTopBar={true}
            title={''}
            fromPath={paths.MONITORING_ADMIN_LOGIN}
          >
            {modal}
          </Modal>
        )}
      </>
    </Layout>
  )
}
