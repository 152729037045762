import { gql } from '@apollo/client'

export const UPDATE_USER_CONFIGS_MUTATION = gql`
  mutation UpdateUserConfigs($data: UserConfigInput!) {
    updateUserConfigs(data: $data) {
      panelDataLicenseAccepted
      siteKey
    }
  }
`
