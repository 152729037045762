import './style.scss'

import { useQuery } from '@apollo/client'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { Loader } from '../../components/loader'
import { SiteSelection } from '../../components/site-selection'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { getLaunchDarklyContext } from '../../shared/compileSessionInformation'
import { setSiteData } from '../../shared/firebaseAnalytics'
import { FETCH_PARTY_DATA } from '../../shared/hooks/query'
import { DataLayerEventName } from '../../shared/typings/dataLayer'
import paths from '../Router/paths'

export const SitesList = () => {
  const ldClient = useLDClient()

  const [cookies, setCookie] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])
  const session = cookies[USER_SESSION_INFO] ?? {}
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const history = useHistory()
  const [selectedSite, setSelectedSite] = useState()

  const { data, loading } = useQuery(FETCH_PARTY_DATA, {
    variables: { partyId: loginOverride ?? session.partyId },
  })

  useEffect(() => {
    if (data?.party?.sites.length === 1) {
      const [site] = data?.party?.sites
      handleSelectSite(site.siteKey)
    }
  }, [data])

  const handleSelectSite = (selectedSiteKey) => {
    const selectedSite = data?.party?.sites.filter(
      (currentSite) => currentSite.siteKey === selectedSiteKey,
    )
    setSelectedSite(selectedSiteKey)
    setSiteData(session, selectedSite ? selectedSite[0] : {})
    const launchDarklyContext = getLaunchDarklyContext(selectedSite[0], session)
    ldClient?.identify(launchDarklyContext)
  }

  useEffect(() => {
    if (selectedSite) {
      const selectedSiteData = (data?.party?.sites || []).find(
        (s) => s.siteKey === selectedSite,
      )
      window.dataLayer.push({
        event: DataLayerEventName.SITE_SELECT,
        userData: {
          siteId: selectedSite,
          hasLivedata: selectedSiteData?.hasLivedata || false,
        },
      })
      setCookie(USER_SELECTED_SITE_KEY, selectedSite)
      history.push(paths.ROOT)
    }
  }, [history, setCookie, data, selectedSite])

  return (
    <Layout>
      <section className="section sites-list">
        {loading || !data?.party?.sites || data?.party?.sites.length <= 1 ? (
          <div className="sites-list-loader-wrapper">
            <div className="sites-list-loader-content">
              <Loader />
              <p>Loading house data...</p>
            </div>
          </div>
        ) : (
          <SiteSelection
            onSelectSite={handleSelectSite}
            sites={data?.party?.sites}
          />
        )}
      </section>
    </Layout>
  )
}
