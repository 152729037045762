import './style.scss'

import clsx from 'clsx'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useLocation } from 'react-router-dom'

import { ENROLLMENT_STATUS, PENDING_STATUS } from './../../shared/typings/site'
import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
  MY_SUNPOWER_MONITORING_ID_TOKEN,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  MY_SUNPOWER_PENDING_SYSTEM,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import paths from '../../pages/Router/paths'
import { usePartyData } from '../../shared/hooks'
import { useSession, useWindowSize } from '../../shared/hooks'
import { useDynamicVpp } from '../../shared/hooks/useDynamicVpp'
import { SITE_TYPES } from '../../shared/sharedConstants'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import iconClose from '../menu/assets/icon-close.svg'
import { UserContext } from '../../context/userContext'

interface MenuInterface {
  handleClose: () => void
  panelDataLicenseAccepted: boolean
  showPanels: boolean
}

type PartialMenuItemProps = {
  linkTo: string
  onClick: () => any
  label: string
  currentPath: any
  href: string
}
type MenuItemProps = RequireOnlyOne<PartialMenuItemProps, 'linkTo' | 'onClick'>

const HIDE_MENU_ITEMS = [paths.SITES_LIST, paths.MONITORING_ADMIN_LOGIN]

const pagesShowAllMenuItems = [
  paths.CONTACT_US,
  paths.FEEDBACK,
  paths.F_A_Q,
  paths.PAYMENTS,
  paths.LIFE_EVENTS,
]

export const MenuItem = ({
  linkTo,
  onClick,
  label,
  currentPath,
  href
}: MenuItemProps) => {
  const selected = linkTo === (currentPath as Location).pathname

  const Wrapper = ({ children }: { children: any }) =>
    linkTo ? (
      <Link to={linkTo}>{children}</Link>
    ) : href ? (
      <a href={href || ""} onClick={onClick}>
        {children}
      </a>
    ) : (
      <a onClick={onClick}>
        {children}
      </a>
    )

  return (
    <li
      className={clsx(
        {
          selected,
        },
        'is-size-5',
      )}
    >
      <Wrapper>{label}</Wrapper>
    </li>
  )
}

export const Menu = ({
  handleClose = () => {},
  panelDataLicenseAccepted,
  showPanels,
}: MenuInterface) => {
  const location = useLocation()
  const { logout } = useSession()
  const [cookies] = useCookies([
    MY_SUNPOWER_MONITORING_ID_TOKEN,
    MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
    MY_SUNPOWER_PENDING_SYSTEM,
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])
  const userContext = useContext(UserContext)

  const { pathname } = location
  const flags = useFlags() as LaunchDarklyFlags

  const session = cookies[USER_SESSION_INFO] ?? {}
  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )
  const currentSiteKey = useMemo(
    () => (isKiosk ? selectedSiteKey : null),
    [isKiosk, selectedSiteKey],
  )

  const { data: partyData } = usePartyData(currentPartyId, currentSiteKey)

  let siteType = ''

  if (partyData?.party) {
    const { party } = partyData
    const { sites } = party
    const selectedSite = sites?.find((site) => site.siteKey === selectedSiteKey)
    siteType = selectedSite?.siteType ?? ''
  }

  const { fetchEnrollments, selectedEnrollment } = useDynamicVpp()

  useEffect(() => {
    fetchEnrollments()
  }, [])

  const showMenuItemsBasedOnPage = pagesShowAllMenuItems.includes(pathname)
  const [overrideMobileFlag, setOverrideMobileFlag] = useState(false)
  const currentWindowSize = useWindowSize()

  useEffect(() => {
    if (currentWindowSize && currentWindowSize[0] < 1024) {
      setOverrideMobileFlag(true)
    } else {
      setOverrideMobileFlag(false)
    }
  }, [currentWindowSize])
  const { isAdmin = false } = session

  const onLogout = () => logout(isKiosk)

  const panelsWithMobileOverrideCheck = showPanels || overrideMobileFlag
  const combineLogicWithPageTitle =
    panelsWithMobileOverrideCheck || showMenuItemsBasedOnPage

  const hasPendingSystem = cookies[MY_SUNPOWER_PENDING_SYSTEM] === 'true'

  const DYNAMIC_VPP_FLAG = flags?.dynamicVppFlag ?? false
  const HELP_CENTER_CHAT_FLAG = flags?.helpCenterChatFlag ?? false

  const showDynamicVpp = useMemo(() => {
    return DYNAMIC_VPP_FLAG && siteType === SITE_TYPES.STORAGE
  }, [DYNAMIC_VPP_FLAG, siteType])

  const dynamicEnrollmentStatus = useMemo(
    () => selectedEnrollment?.enrollmentStatus || ENROLLMENT_STATUS.UNE,
    [selectedEnrollment],
  )

  const dynamicVppPath =
    dynamicEnrollmentStatus === ENROLLMENT_STATUS.ACT
      ? paths.VPP_DASHBOARD
      : PENDING_STATUS.includes(dynamicEnrollmentStatus)
      ? paths.VIRTUAL_PROGRAM_ENROLL_STATUS
      : paths.MARKETING_VPP_DYNAMIC

  return (
    <React.Fragment>
      <aside className="menu">
        <button
          className="closeSideNav"
          aria-label="close"
          onClick={handleClose}
        >
          <img src={iconClose} alt="Close sidebar" />
        </button>
        <ul className="menu-list">
          {!HIDE_MENU_ITEMS.includes(location.pathname) &&
            !hasPendingSystem && (
              <React.Fragment>
                <MenuItem
                  linkTo={paths.ROOT}
                  label="Home"
                  currentPath={location}
                />
                <MenuItem
                  linkTo={paths.PROFILE}
                  label="Profile"
                  currentPath={location}
                />
                {combineLogicWithPageTitle && showPanels && (
                  <MenuItem
                    linkTo={
                      panelDataLicenseAccepted
                        ? paths.PANELS
                        : paths.ALERT_LEGAL_DOCUMENT_MODAL
                    }
                    label="Panels"
                    currentPath={location}
                  />
                )}

                <MenuItem
                  linkTo={paths.F_A_Q}
                  label="FAQ &amp; Solar Docs"
                  currentPath={location}
                />
                <MenuItem
                  linkTo={paths.PAYMENTS}
                  label="Payments"
                  currentPath={location}
                />
                <MenuItem
                  linkTo={paths.LIFE_EVENTS}
                  label="Life Events"
                  currentPath={location}
                />
                {showDynamicVpp && (
                  <MenuItem
                    linkTo={dynamicVppPath}
                    label="SunPower VPP"
                    currentPath={location}
                  />
                )}
                {HELP_CENTER_CHAT_FLAG ? (
                  <MenuItem
                    linkTo={paths.HELP_CENTER_CHAT}
                    label="Help Center"
                    currentPath={location}
                  />
                ) : (
                  <MenuItem
                    linkTo={paths.CONTACT_US}
                    label="Contact Us"
                    currentPath={location}
                  />
                )}
                <MenuItem
                  linkTo={paths.FEEDBACK}
                  label="Feedback"
                  currentPath={location}
                />
              </React.Fragment>
            )}
          {hasPendingSystem && (
            <>
              <MenuItem
                linkTo={paths.CONTACT_US}
                label="Contact Us"
                currentPath={location}
              />
            </>
          )}
          {isAdmin && (
            <MenuItem
              linkTo={paths.MONITORING_ADMIN_LOGIN}
              label="Admin View"
              currentPath={location}
            />
          )}
          <MenuItem
            onClick={onLogout}
            label="Logout"
            currentPath={location}
          />
        </ul>
      </aside>
      <div onClick={handleClose} className="backdrop" />
    </React.Fragment>
  )
}
