import './styles/referrals.scss'

import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import paths from '../../pages/Router/paths'
import { DataLayerEventName } from '../../shared/typings/dataLayer'
import referralsImg from './assets/referrals.gif'

export const Referrals = () => {
  const handleGAClick = useCallback(
    (eventName) => () => {
      window.dataLayer.push({
        event: eventName,
      })
    },
    [],
  )

  return (
    <div className="columns is-centered my-0 referral-container">
      <section className="section container columns referral-row-reverse">
        <div className="column has-text-centered referral-image-container">
          <img src={referralsImg} />
        </div>
        <div className="column is-flex referral-info-column">
          <h3 className="referral-title">Referral Program</h3>
          <div className="referral-text">
            Refer your friends and family to SunPower.
            <br />
            <b>
              You'll earn a $500 VISA Reward Card and your referral will receive
              a $500 VISA Reward Card when their SunPower installation is
              complete.
            </b>
          </div>
          <div>
            <a
              href="http://sharesunpower.com"
              target="_blank"
              className="button is-primary"
              onClick={handleGAClick(
                DataLayerEventName.REFERRALS_REFER_SOMEONE,
              )}
            >
              REFER SOMEONE
            </a>
          </div>
        </div>
      </section>
    </div>
  )
}
