import './style.scss'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Layout } from '../../../components/layout'
import { Modal } from '../../../components/modal'
import { Slider } from '../../../components/slider'
import { useDynamicVpp, usePartyData } from '../../../shared/hooks'
import paths from '../../Router/paths'

const DEFAULT_SELF_SUPPLY = '20'
const DEFAULT_LIMIT = 80

export const DynamicReserveLevel = ({ modal }: { modal?: JSX.Element }) => {
  const history = useHistory()
  const location = useLocation<any>()

  const {
    selectedEnrollment,
    selectedSiteKey,
    updateReserveLevel,
  } = useDynamicVpp()

  const { data: partyData } = usePartyData('', selectedSiteKey)
  const selectedSite = useMemo(
    () =>
      (partyData?.party?.sites ?? []).find(
        (site) => site.siteKey === selectedSiteKey,
      ),
    [partyData, selectedSiteKey],
  )

  const { isDefaultReserveLevel = false } = location?.state || {}

  const chargePercentage = useMemo(() => {
    const battery = selectedSite?.battery

    const stateOfChargePercentage =
      battery?.socAndChargeCapacity.find(
        (bs) => bs.stateOfChargePercentage != null,
      )?.stateOfChargePercentage ?? 1

    const siteLevelCustomerStateOfChargePercentage =
      battery?.siteLevelCustomerStateOfChargePercentage ?? 0.0

    return siteLevelCustomerStateOfChargePercentage ?? stateOfChargePercentage
  }, [selectedSite])

  const [reserveLevelVal, setReserveLevelVal] = useState(DEFAULT_SELF_SUPPLY)

  const handleCancel = useCallback(() => {
    history.goBack()
  }, [history])

  const updateSliderValue = useCallback(
    (e) => {
      setReserveLevelVal(e.target.value)
    },
    [setReserveLevelVal],
  )

  const vPPEnrollmentDevice = useMemo(
    () => selectedEnrollment?.enrollmentDevice,
    [selectedEnrollment],
  )

  const currentEventReserveLevel = useMemo(
    () =>
      vPPEnrollmentDevice
        ? vPPEnrollmentDevice.currentEvent.reserveLevel
        : null,
    [vPPEnrollmentDevice],
  )

  const defaultReserveLevel = useMemo(
    () => vPPEnrollmentDevice?.defaultReserveLevel || null,
    [vPPEnrollmentDevice],
  )

  useEffect(() => {
    if (defaultReserveLevel && isDefaultReserveLevel) {
      setReserveLevelVal(Math.round(defaultReserveLevel * 100).toString())
    } else if (currentEventReserveLevel && !isDefaultReserveLevel) {
      setReserveLevelVal(Math.round(currentEventReserveLevel * 100).toString())
    }
  }, [currentEventReserveLevel, defaultReserveLevel, isDefaultReserveLevel])

  const handleOnClickSave = useCallback(() => {
    updateReserveLevel(reserveLevelVal, isDefaultReserveLevel)
    history.goBack()
  }, [updateReserveLevel, reserveLevelVal, isDefaultReserveLevel, history])

  const disableButton = useMemo(() => {
    const roundedValue = chargePercentage * 100
    const higherValue =
      roundedValue > DEFAULT_LIMIT ? DEFAULT_LIMIT : roundedValue

    return !isDefaultReserveLevel && Number(reserveLevelVal) > higherValue
  }, [chargePercentage, isDefaultReserveLevel, reserveLevelVal])

  return (
    <Layout title={''}>
      <>
        <div className="main-container">
          {isDefaultReserveLevel ? (
            <>
              <div className="title">Set your default reserve level</div>
              <div className="sub-title">
                SunVault will not discharge below this level during discharge
                events:
              </div>
            </>
          ) : (
            <>
              <div className="title">Set your reserve level for this event</div>
              <div className="sub-title">
                SunVault will not discharge below this level during this event:
              </div>
            </>
          )}
          <div className="setup-mode-value">
            {reserveLevelVal}
            <span className="percentage ml-5">%</span>
          </div>
          <h5 className="is-uppercase setup-mode-saved-reserves">
            saved reserves
          </h5>
          <section className="column setup-mode-slider-container">
            <Slider
              min={'1'}
              max={'80'}
              onSliderChange={updateSliderValue}
              sliderValue={reserveLevelVal}
              leftDescriptiveLabel={
                <div className="slider-description">
                  Make more
                  <div className="dollar-sign">$$$$</div>
                </div>
              }
              rightDescriptiveLabel={
                <div className="slider-description">
                  Make less
                  <div className="dollar-sign">$$</div>
                </div>
              }
            />
          </section>
          <section className="buttons-container">
            <button className="cancel" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="save"
              onClick={handleOnClickSave}
              disabled={disableButton}
            >
              Save
            </button>
          </section>
          <div className="note">
            Note: The more energy your SunVault discharges, the more money you
            will make. Keep this in mind when selecting the reserve level you
            want to keep in your battery during this discharge event.
          </div>
        </div>
        {modal && (
          <Modal
            hideTopBar={true}
            title={''}
            fromPath={paths.RESERVE_DYNAMIC_VPP}
          >
            {modal}
          </Modal>
        )}
      </>
    </Layout>
  )
}
