import '../style.scss'

import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

interface EndChatAlertProps {
  callback?: any
}

export const AlertEndChat = ({ callback }: EndChatAlertProps) => {
  const history = useHistory()
  const handleOnClose = useCallback(() => history.goBack(), [history])
  const handleOnYes = useCallback(() => callback(), [callback])

  return (
    <div className='alert-modal-opted-out'>
      <div className='opt-out-title'>End chat?</div>
      <div className='opt-out-content'>
        If needed, you will need to start a new chat conversation.
      </div>
      <div className='buttons-container'>
        <button
          className='cancel end-chat-cancel-button'
          onClick={handleOnClose}
        >
          Cancel
        </button>
        <button className='agree' onClick={handleOnYes}>
          Yes, End Chat
        </button>
      </div>
    </div>
  )
}
