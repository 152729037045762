import './style.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import paths from '../../pages/Router/paths'
import { CurrentLevels } from '../../shared/hooks/useCurrentPowerLevels'
import { SITE_TYPES } from '../../shared/sharedConstants'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import { EVENT_STATUS, VPPDeviceCurrentEvent } from '../../shared/typings/site'
import { eventsDateFormat } from '../../shared/valueFormatters'
import { Battery } from '../battery'
import editImg from './assets/edit.svg'
import infiniteImg from './assets/infinite.svg'

const BATTERY_MODES = {
  RESERVE: 'BACKUP_ONLY',
  BACKUP_ONLY: 'Reserve',
  SELF_CONSUMPTION: 'Self Supply',
  ENERGY_ARBITRAGE: 'Cost Savings',
  UNAVAILABLE: 'Unavailable',
  MANUAL_CHARGE: 'Manual Charge',
}

export enum BATTERY_STATUS {
  READY = 'READY',
  POWERING_HOME = 'POWERING HOME',
  CHARGING = 'CHARGING',
  EMPTY = 'EMPTY',
}
export const DEFAULT_BACKUP_DAYS = {
  days: 0,
  hours: 0,
  minutes: 0,
}

const EVENTS_TO_SHOW = [
  EVENT_STATUS.EVENT_IN_PROGRESS,
  EVENT_STATUS.EVENT_SCHEDULED,
]

type StorageProps = {
  batteryMode: string
  batteryStatus: BATTERY_STATUS
  stateOfCharge: number
  backupDaysLeft: {
    days: number
    hours: number
    minutes: number
  }
  backUpReserveSocLevel: number
  dynamicVppEvent?: VPPDeviceCurrentEvent
  dynamicVppEventStatus: EVENT_STATUS
  onOptedOut: () => void
  currentLevel: CurrentLevels
  siteType?: string
  loading?: boolean
}

export const Storage = ({
  batteryMode = 'UNAVAILABLE',
  batteryStatus = BATTERY_STATUS.READY,
  stateOfCharge,
  backupDaysLeft = DEFAULT_BACKUP_DAYS,
  backUpReserveSocLevel = 0.0,
  dynamicVppEvent,
  dynamicVppEventStatus,
  onOptedOut,
  currentLevel,
  siteType,
  loading,
}: StorageProps) => {
  const flags = useFlags() as LaunchDarklyFlags
  const history = useHistory()

  const showEventComponent = useMemo(() => {
    return EVENTS_TO_SHOW.includes(dynamicVppEventStatus)
  }, [dynamicVppEventStatus])

  const { days, hours } = backupDaysLeft
  const percentageReserveLevel = Math.round(100 * backUpReserveSocLevel)
  const onEditClick = useCallback(() => {
    const notStorageSite = siteType !== SITE_TYPES.STORAGE

    if (!loading && siteType && notStorageSite) {
      history.push(paths.ROOT)
    }

    const COST_SAVINGS_MODE_FLAG = flags.costSavingsModeFlag ?? false

    if (COST_SAVINGS_MODE_FLAG) {
      history.push({
        pathname: paths.STORAGE_EDIT_MODAL_COSTS,
        state: {
          commandModal: true,
        },
      })
    } else {
      history.push({
        pathname: paths.STORAGE_EDIT_MODAL,
        state: {
          commandModal: true,
        },
      })
    }
  }, [flags.costSavingsModeFlag, history, loading, siteType])

  const handleEditEventReserveLevel = useCallback(() => {
    history.push(paths.RESERVE_DYNAMIC_VPP)
  }, [history])

  const batteryStatusContent = useMemo(() => {
    return BATTERY_STATUS[batteryStatus] ?? batteryStatus
  }, [batteryStatus])

  if (showEventComponent && !!dynamicVppEvent) {
    if (dynamicVppEventStatus === EVENT_STATUS.EVENT_IN_PROGRESS) {
      return (
        <OnGoingEvent
          stateOfCharge={stateOfCharge}
          event={dynamicVppEvent}
          onOptedOut={onOptedOut}
          handleEditEventReserveLevel={handleEditEventReserveLevel}
          currentLevel={currentLevel}
        />
      )
    } else {
      return (
        <IncomingEvent
          stateOfCharge={stateOfCharge}
          event={dynamicVppEvent}
          onOptedOut={onOptedOut}
          handleEditEventReserveLevel={handleEditEventReserveLevel}
        />
      )
    }
  }

  const chargingDetailsMarkup = (
    <article className="charging-mode-container">
      <h4 className="is-uppercase">Charge Reserved</h4>
      <section className="reserve-level is-centered">
        <h6>
          {percentageReserveLevel}
          <span className="percentage">%</span>
        </h6>
      </section>
      <p>SunVault will not</p>
      <p>discharge below this level</p>
    </article>
  )
  const reserveOperationalModeMarkup =
    backupDaysLeft === DEFAULT_BACKUP_DAYS ? (
      <article className="ml-5 infinite-mode-container">
        <h4>RESERVE MODE SET FOR:</h4>
        <div className="infinite-container">
          <img src={infiniteImg} alt="infite logo" />
          <div className="infinite-label">INFINITE DAYS</div>
        </div>
      </article>
    ) : (
      <article className="ml-5 reserve-mode-container">
        <h4>Time Remaining</h4>
        <h4>in reserve mode:</h4>
        <section className="time-remaining-container columns is-vcentered">
          <section className="column is-centered">
            <h5 className="divider">{days}</h5>
            <h6>Days</h6>
          </section>
          <section className="column is-centered">
            <h5>{hours}</h5>
            <h6>Hours</h6>
          </section>
        </section>
      </article>
    )

  const detailsMarkup =
    batteryMode === BATTERY_MODES.RESERVE
      ? reserveOperationalModeMarkup
      : chargingDetailsMarkup

  const updatedBatteryMode = BATTERY_MODES[batteryMode] ?? batteryMode
  const batterySummaryMarkup = (
    <article className="battery-summary-container">
      <section className="mt-5 mb-5">
        <h6>Status:</h6>
        <div className="is-uppercase is-centered is-size-4 has-text-weight-bold">
          {batteryStatusContent}
        </div>
      </section>
      <h6>Battery mode:</h6>
      <div className="is-uppercase is-centered is-size-4 has-text-weight-bold">
        {updatedBatteryMode}
      </div>
      <span onClick={onEditClick} className="edit-operational-mode-steps">
        Edit Mode
      </span>
    </article>
  )

  return (
    <div className="storage pb-6 columns is-vcentered is-centered ">
      <div className="is-uppercase column column-override is-size-7 storage-label ml-5">
        {batterySummaryMarkup}
      </div>
      <div className="is-uppercase battery-icon column is-centered mt-5">
        <Battery stateOfCharge={stateOfCharge} />
      </div>
      <div className="operational-mode-details storage-details-area is-uppercase column is-centered is-size-7 storage-label mr-4">
        {detailsMarkup}
      </div>
    </div>
  )
}

const IncomingEvent = ({
  stateOfCharge,
  event: { reserveLevel = 0, eventDatetime = '', eventLength = 0 },
  onOptedOut,
  handleEditEventReserveLevel,
}: {
  stateOfCharge: number
  event: VPPDeviceCurrentEvent
  onOptedOut: () => void
  handleEditEventReserveLevel: () => void
}) => {
  return (
    <div className="storage pb-6 columns is-vcentered is-centered incoming-event">
      <div className="column column-override is-size-7 storage-label event-wrapper">
        <h2 className="event-tittle">
          Battery is charging to prepare for event:
        </h2>
        <h2>BATTERY WILL DISCHARGE:</h2>
        <p className="event-date-info">
          {eventsDateFormat(eventDatetime, eventLength)}
        </p>
        <div className="event-opt-in-state">
          <div>You’re opted-in</div>
          <button className="opt-out-button" onClick={onOptedOut}>
            Opt-out?
          </button>
        </div>
        <h2>SAVED RESERVES:</h2>
        <div className="event-saved-reserves">
          {`${(reserveLevel * 100).toFixed(0)}`}
          <div className="event-saved-reserves-simbol">%</div>
        </div>
        <div className="event-edit-container">
          Edit
          <button
            className="event-edit-button"
            onClick={handleEditEventReserveLevel}
          >
            <img src={editImg} alt="edit button" />
          </button>
        </div>
      </div>
      <div className="is-uppercase battery-icon column is-centered mt-5">
        <Battery stateOfCharge={stateOfCharge} />
      </div>
      <div className="operational-mode-details storage-details-area is-uppercase column is-centered is-size-7 storage-label mr-4"></div>
    </div>
  )
}

const OnGoingEvent = ({
  stateOfCharge,
  event: { reserveLevel = 0, eventDatetime = '', eventLength = 0 },
  onOptedOut,
  handleEditEventReserveLevel,
  currentLevel,
}: {
  stateOfCharge: number
  event: VPPDeviceCurrentEvent
  onOptedOut: () => void
  handleEditEventReserveLevel: () => void
  currentLevel: CurrentLevels
}) => {
  const currentStorage = currentLevel?.storage ?? 0

  return (
    <div className="storage pb-6 columns is-vcentered is-centered ongoing-event">
      <div className="column column-override is-size-7 storage-label event-wrapper">
        <h2 className="event-tittle">Discharge event in progress:</h2>
        <h2 className="event-subtittle">BATTERY WILL DISCHARGE:</h2>
        <p className="event-date-info">
          {eventsDateFormat(eventDatetime, eventLength)}
        </p>
        <h2 className="event-subtittle">CURRENT POWER:</h2>
        <p className="event-date-info">{`${Math.abs(currentStorage)} kW`}</p>
        <div className="event-opt-in-state">
          <div>You’re opted-in</div>
          <button className="opt-out-button" onClick={onOptedOut}>
            Opt-out?
          </button>
        </div>
        <h2>SAVED RESERVES:</h2>
        <div className="event-saved-reserves">
          {`${(reserveLevel * 100).toFixed(0)}`}
          <div className="event-saved-reserves-simbol">%</div>
        </div>
        <div className="event-edit-container">
          Edit
          <button
            className="event-edit-button"
            onClick={handleEditEventReserveLevel}
          >
            <img src={editImg} alt="edit button" />
          </button>
        </div>
      </div>
      <div className="is-uppercase battery-icon column is-centered mt-5">
        <Battery stateOfCharge={stateOfCharge} />
      </div>
      <div className="operational-mode-details storage-details-area is-uppercase column is-centered is-size-7 storage-label mr-4"></div>
    </div>
  )
}
