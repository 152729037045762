import React from 'react'

import paths from '../../pages/Router/paths'
import { currencyFormat } from '../../shared/valueFormatters'
import { STATUS_LABEL_SIZES, StatusLabel } from '../status-label'
import moneyBagIcon from './assets/moneybag.svg'

export const BillSavings = ({ total = 0 }) => {
  return (
    <div className="bill-savings">
      <StatusLabel
        iconEnabled={moneyBagIcon}
        statusText="Estimated"
        statusTextBold={false}
        label={currencyFormat(total)}
        size={STATUS_LABEL_SIZES.BIG}
        centered={true}
        infoLink={paths.INFO_BILL_SAVINGS}
      />
    </div>
  )
}
