import { useApolloClient, useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  getSubscriptionParams,
  getWebsocketClient,
  setSubscriptionParams,
} from '../../config/apollo'
import { dateFormat } from '../constants'
import { findLatestValidDataPoint } from '../findLatestValidDataPoint'
import { INTERVALS } from '../intervals'
import { DataLayerEventName } from '../typings/dataLayer'
import { FETCH_POWER_DATA, SUBSCRIBE_POWER_DATA } from './query'

export const MAX_DATA_POINT_DELAY = 10 * 1000 // 10 seconds
export const POWER_DATA_POLLING_INTERVAL = 60 * 1000 * 5 // 5 minutes

export const useCurrentPower = (
  siteKey,
  deviceSerialNumber,
  startDate,
  endDate,
  interval,
  isLegacyPvs,
  timezone,
  hasLivedata = false,
) => {
  const [currentPower, setCurrentPower] = useState<number>(0)

  const [
    fetchPowerData,
    { called, data, loading, error, startPolling, stopPolling },
  ] = useLazyQuery(FETCH_POWER_DATA, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: POWER_DATA_POLLING_INTERVAL,
  })
  const isLiveDataActiveTimeout = useRef<any>(null)
  const [forceApi, setForceApi] = useState<number | null>(null)
  const forceApiRef = useRef<number | null>(null)
  const [liveDataLoading, setLiveDataLoading] = useState(false)
  const client = useApolloClient()
  const liveDataObserver = useRef<any>()
  const liveDataSubscription = useRef<any>()
  const liveDataErrorListener = useRef<any>()

  const shouldConnectLiveData = useMemo(
    () => !!(siteKey && hasLivedata && deviceSerialNumber),
    [deviceSerialNumber, hasLivedata, siteKey],
  )

  const connectToLiveData = useCallback(() => {
    stopPolling?.()
    liveDataSubscription.current?.unsubscribe()

    setLiveDataLoading(true)

    // Creates observer from Apollo client (this starts the websocket connection)
    liveDataObserver.current = client.subscribe({
      query: SUBSCRIBE_POWER_DATA,
    })

    // Subscribes to the observer results
    liveDataSubscription.current = liveDataObserver.current.subscribe(
      ({
        data: {
          receiveLiveData: { production = 0, timestamp },
        },
      }) => {
        if (forceApiRef.current && timestamp !== forceApiRef.current) {
          forceApiRef.current = null
          setForceApi(null)
        }

        if (isLiveDataActiveTimeout.current) {
          clearTimeout(isLiveDataActiveTimeout.current)
        }

        isLiveDataActiveTimeout.current = setTimeout(() => {
          // Force API polling if we don't receive live data in time
          forceApiRef.current = timestamp
          setForceApi(timestamp)
        }, MAX_DATA_POINT_DELAY)

        if (liveDataLoading) {
          setLiveDataLoading(false)
        }
        setCurrentPower(production ?? 0)
      },
      (error: object) => {
        console.log(error)
      },
    )

    isLiveDataActiveTimeout.current = setTimeout(() => {
      // Force API polling if we don't receive anything
      if (!forceApi) {
        forceApiRef.current = moment().valueOf()
        setForceApi(moment().valueOf())
      }
    }, MAX_DATA_POINT_DELAY)
  }, [client, forceApi, liveDataLoading, stopPolling])

  useEffect(() => {
    if (!forceApi) {
      // Switches back to API polling if there was a websocket error
      liveDataErrorListener.current = getWebsocketClient()?.onError(
        (liveDataError) => {
          if (liveDataLoading) {
            setLiveDataLoading(false)
          }
          forceApiRef.current = moment().valueOf()
          setForceApi(moment().valueOf())
          window.dataLayer.push({
            event: DataLayerEventName.LIVE_DATA_ERROR,
            eventData: { stack: liveDataError.stack },
          })
        },
      )
    }

    return () => liveDataErrorListener.current?.()
  }, [forceApi, liveDataLoading])

  useEffect(() => {
    return () => {
      // Close subscription and reset parameters when we navigate out of the current page
      liveDataSubscription.current?.unsubscribe()
      setSubscriptionParams(null, null)
    }
  }, [])

  useEffect(() => {
    const currentParams = getSubscriptionParams()

    if (shouldConnectLiveData && liveDataSubscription.current === undefined) {
      if (currentParams.siteKey !== siteKey) {
        getWebsocketClient()?.close(true)

        // Connect to websocket entry point
        setSubscriptionParams(siteKey, deviceSerialNumber)
        setCurrentPower(0)
      }
      connectToLiveData()
    }
  }, [
    connectToLiveData,
    deviceSerialNumber,
    forceApi,
    shouldConnectLiveData,
    siteKey,
  ])

  useEffect(() => {
    if (isLiveDataActiveTimeout.current && forceApi) {
      // Clear any existing live data check timeout if we are forcing API data
      clearTimeout(isLiveDataActiveTimeout.current)
    }
  }, [forceApi])

  useEffect(() => {
    if (error) {
      window.dataLayer.push({
        event: DataLayerEventName.POWER_DATA_ERROR,
        eventData: { stack: error.stack },
      })
    }
  }, [error])

  useEffect(() => {
    if (
      interval === INTERVALS.DAY &&
      siteKey &&
      startDate &&
      endDate &&
      timezone
    ) {
      if ((!hasLivedata || !!forceApi) && !loading) {
        if (liveDataLoading) {
          setLiveDataLoading(false)
        }
        // Fetch from API
        fetchPowerData({
          variables: {
            siteKey: siteKey,
            interval: isLegacyPvs ? 'quarter_hour' : 'five_minute',
            end: moment(endDate).tz(timezone).format(dateFormat),
            start: moment(startDate).tz(timezone).format(dateFormat),
          },
        })
        startPolling?.(POWER_DATA_POLLING_INTERVAL)
      }
    }
  }, [
    siteKey,
    startDate,
    endDate,
    interval,
    timezone,
    isLegacyPvs,
    hasLivedata,
    forceApi,
    startPolling,
  ])

  useEffect(() => {
    // Format API data
    if (called && data) {
      const { powerDataSeries } = data.power
      const production = powerDataSeries?.production ?? []
      const latestValidDataPoint = findLatestValidDataPoint(production)
      const currentProductionValue = parseFloat(latestValidDataPoint[1])
      setCurrentPower(currentProductionValue)
    }
  }, [called, data])

  const retValue = useMemo(
    () => ({
      power: currentPower,
      loading: !!(loading && liveDataLoading),
    }),
    [liveDataLoading, loading, currentPower],
  )

  return retValue
}
