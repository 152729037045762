export enum INTERVALS {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  LIFETIME = 'lifetime',
  CUSTOM = 'custom',
}

export const INTERVAL_OPTIONS = {
  [INTERVALS.DAY]: 'hour',
  [INTERVALS.WEEK]: 'day',
  [INTERVALS.MONTH]: 'day',
  [INTERVALS.CUSTOM]: 'day',
  [INTERVALS.YEAR]: 'month',
  [INTERVALS.LIFETIME]: 'year',
}

export enum INTERVALS_PERIODICITY {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}
