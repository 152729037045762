import { useFlags } from 'launchdarkly-react-client-sdk'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useContext } from 'react'
import { useCookies } from 'react-cookie'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom'

import {
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
  MY_SUNPOWER_USER_CONTEXT,
  MY_SUNPOWER_MONITORING_ID_TOKEN,
} from '../../config/cookies'
import { UserContext } from '../../context/userContext'
import { DataLayerEventName } from '../../shared/typings/dataLayer'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import { AdminPage } from '../AdminPage'
import { AlertCustomContent } from '../AlertModals/AlertCustomModal'
import { AlertEndChat } from '../AlertModals/AlertEndChat'
import { AlertICDModalContents } from '../AlertModals/AlertICD'
import { AlertICEModalContents } from '../AlertModals/AlertICE'
import { AlertIDModalContents } from '../AlertModals/AlertID'
import { AlertLegalDocumentModalContents } from '../AlertModals/AlertLegalDocument'
import { AlertOptOut } from '../AlertModals/AlertOptOut'
import { APIErrorModal } from '../APIErrorModal/'
import { BatteryOperationalModeEdit } from '../BatteryOperationalModeEdit'
import { BillSavingsInfo } from '../BillSavingsInfo'
import { Callback } from '../Callback'
import { ContactPage } from '../ContactPage'
import { CostSavingsModePage } from '../CostSavingsModePage'
import { CurrentPowerInfo } from '../CurrentPowerInfo'
import { Dashboard } from '../Dashboard'
import { EnergyGraphInfo } from '../EnergyGraphInfo'
import { EnvironmentalSavingsInfo } from '../EnvironmentalSavingsInfo'
import { FAQPage } from '../FAQPage'
import { FAQPanels } from '../FAQPanels'
import { FeedbackPage } from '../FeedbackPage'
import { HelpChat } from '../HelpCenter'
import { KioskCallback } from '../KioskCallback'
import { LifeEventsPage } from '../LifeEventsPage'
import { MyAccountInfo } from '../MyAccountInfo'
import { PanelsPage } from '../PanelsPage'
import { PaymentsPage } from '../PaymentsPage'
import { PendingSystem } from '../PendingSystem'
import { Profile } from '../Profile'
import { ProfileEditModal } from '../ProfileEditModal'
import { ReportsSetupModal } from '../ReportsSetupModal'
import { ReserveModePage } from '../ReserveModePage'
import { ReserveSetupPage } from '../ReserveSetupPage'
import { SelfSupplyModePage } from '../SelfSupplyModePage'
import { SelfSupplySetupPage } from '../SelfSupplySetupPage'
import { SitesList } from '../SitesList'
import { StorageInfo } from '../StorageInfo'
import { SystemInfo } from '../SystemInfo'
import { UtilityPlanDetail } from '../UtilityPlanDetail'
import { UtilityRateSchedules } from '../UtilityRateSchedules'
import { Login } from '../Login'
import {
  DynamicEnroll,
  DynamicFaqs,
  DynamicMarketing,
  DynamicProgramList,
  DynamicReserveLevel,
  DynamicStatus,
  EnrollmentDocuments,
  OhmCallback,
  VPPDashboard,
} from '../VPP'
import { WifiSettings } from '../WifiSettings'
import paths from './paths'
import { ProtectedRoute } from './protected-route'
import { PrivateRoute } from './PrivateRoute'

export const Router = () => {
  const userContext = useContext(UserContext)
  const [cookies] = useCookies([
    USER_SESSION_INFO,
    MY_SUNPOWER_MONITORING_ID_TOKEN,
  ])
  const allCookies = useCookies()
  const session = cookies[USER_SESSION_INFO] ?? {}
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY] ?? {}
  const mySunpowerUserContext = cookies[MY_SUNPOWER_USER_CONTEXT] ?? {}
  const mySunpowerMonitoringIdToken =
    cookies[MY_SUNPOWER_MONITORING_ID_TOKEN] ?? null

  const history = useHistory()
  const { search } = useLocation()
  const flags = useFlags() as LaunchDarklyFlags
  const isLoadingFlags = isEmpty(flags)

  const pathName = history?.location?.pathname ?? ''
  const previousPathName = useRef<string>()

  const { isAdmin = false } = session

  const REPORTS_SETUP_FLAG = flags?.reportsSetupFlag ?? false
  const PANELS_LAYOUT_FLAG = flags?.panelsLayoutFlag ?? false
  const CUSTOM_TARIFF_FLAG = flags?.customTariffFlag ?? false
  const DYNAMIC_VPP_FLAG = flags?.dynamicVppFlag ?? false
  const HELP_CENTER_CHAT_FLAG = flags?.helpCenterChatFlag ?? false

  const oktaId = userContext?.getoktaID(mySunpowerUserContext?.value)

  useEffect(() => {
    if (
      !mySunpowerMonitoringIdToken &&
      userContext?.user?.logoutIdToken &&
      userContext?.user?.loggingOut
    ) {
      const issuer = `${process.env.REACT_APP_LOGOUT_URL}oauth2/${process.env.REACT_APP_OKTA_API_ID}`
      const logoutUrl =
        `${issuer}/v1/logout?id_token_hint=${userContext?.user?.logoutIdToken}&post_logout_redirect_uri=${process.env.REACT_APP_LOGOUT_URL}` ||
        ''
      window.location.href = logoutUrl
    }
  }, [
    mySunpowerMonitoringIdToken,
    userContext?.user?.logoutIdToken,
    userContext?.user?.loggingOut,
  ])

  useEffect(() => {
    if (oktaId && !isAdmin) {
      userContext?.getQuickCustomerCheck(oktaId)
    }
  }, [oktaId, isAdmin])

  useEffect(() => {
    if (pathName !== previousPathName.current) {
      window.dataLayer.push({
        event: DataLayerEventName.PAGE_VIEW,
        userData: {
          isAdmin: session.isAdmin,
          partyId: session.partyId,
          ...(selectedSiteKey ? { siteId: selectedSiteKey } : {}),
        },
      })

      previousPathName.current = pathName
    }
  }, [session, selectedSiteKey, pathName])

  return (
    <Switch>
      {process.env.REACT_APP_ENV === 'qa' && (
        <Route exact path={paths.LOGIN} component={Login} />
      )}
      <Route exact path={paths.CALLBACK} component={Callback} />
      <Route exact path={paths.OHM_CALLBACK} component={OhmCallback} />
      <Route exact path={paths.KIOSK_CALLBACK} component={KioskCallback} />
      <PrivateRoute exact path={paths.ROOT} component={Dashboard} />
      <PrivateRoute exact path={paths.SITES_LIST} component={SitesList} />
      <Route exact path={paths.INFO_ENERGY_GRAPH}>
        <Dashboard
          modalTitle="About the Energy Graph Widget"
          modal={<EnergyGraphInfo />}
        />
      </Route>
      <Route exact path={paths.INFO_CURRENT_POWER}>
        <Dashboard
          modalTitle="About the Current Power Widget"
          modal={<CurrentPowerInfo />}
        />
      </Route>
      <Route exact path={paths.INFO_SYSTEM_INFO}>
        <Dashboard
          modalTitle="About the System Information Widget"
          modal={<SystemInfo />}
        />
      </Route>
      <Route exact path={paths.INFO_ENVIRONMENTAL_SAVINGS}>
        <Dashboard
          modalTitle="About the Environmental Savings Widget"
          modal={<EnvironmentalSavingsInfo />}
        />
      </Route>
      <Route exact path={paths.INFO_BILL_SAVINGS}>
        <Dashboard
          modalTitle="About the Bill Savings Widget"
          modal={<BillSavingsInfo />}
        />
      </Route>
      <Route exact path={paths.INFO_STORAGE}>
        <Dashboard
          modalTitle="About the Storage Widget"
          modal={<StorageInfo />}
        />
      </Route>
      <Route exact path={paths.INFO_STORAGE_EMPTY}>
        <Dashboard hideTopBar={true} modal={<StorageInfo isEmpty={true} />} />
      </Route>
      <Route exact path={paths.ALERT_OPTED_OUT}>
        <Dashboard
          hideTopBar
          modalTitle=""
          noStylesModal
          modal={<AlertOptOut />}
        />
      </Route>
      {isAdmin ? (
        <Route
          exact
          path={paths.MONITORING_ADMIN_LOGIN}
          component={AdminPage}
        />
      ) : null}
      {isAdmin && (
        <Route
          path={`${paths.MONITORING_ADMIN_LOGIN}-${paths.ALERT_CUSTOM_MODAL}`}
        >
          <AdminPage modal={<AlertCustomContent />} />
        </Route>
      )}
      <Route exact path={paths.F_A_Q} component={FAQPage} />
      <Route exact path={`${paths.F_A_Q}-${paths.ALERT_CUSTOM_MODAL}`}>
        <FAQPage modal={<AlertCustomContent />} />
      </Route>
      <Route exact path={paths.PROFILE} component={Profile} />
      <Route path={paths.PROFILE_INFO_MY_ACCOUNT}>
        <Profile
          modalTitle="About the User Profile"
          modal={<MyAccountInfo />}
        />
      </Route>
      {REPORTS_SETUP_FLAG && (
        <Route path={paths.PROFILE_REPORTS_SETUP}>
          <Profile
            modalTitle="Set Up Monthly Reports"
            modal={<ReportsSetupModal />}
          />
        </Route>
      )}
      <Route path={paths.PROFILE_SETTINGS_WIFI}>
        <Profile modalTitle="Wifi Settings" modal={<WifiSettings />} />
      </Route>
      <Route exact path={paths.ROOT_SETTINGS_WIFI}>
        <Dashboard modalTitle="Wifi Settings" modal={<WifiSettings />} />
      </Route>
      <Route exact path={paths.PROFILE_EDIT_MODAL}>
        <Profile modalTitle="Edit Profile" modal={<ProfileEditModal />} />
      </Route>

      <Route path={paths.STORAGE_EDIT_MODAL}>
        <Dashboard
          hideTopBar={false}
          modalTitle="Battery Modes"
          modal={<BatteryOperationalModeEdit />}
        />
      </Route>
      <Route path={paths.STORAGE_EDIT_MODAL_COSTS}>
        <Dashboard
          hideTopBar={false}
          modalTitle="Battery Modes"
          modal={<BatteryOperationalModeEdit isCostSavings={true} />}
        />
      </Route>
      <Route path={paths.ALERT_ICD_MODAL}>
        <Dashboard
          hideTopBar={true}
          modalTitle=""
          modal={<AlertICDModalContents />}
        />
      </Route>
      <Route path={paths.ALERT_ICE_MODAL}>
        <Dashboard
          hideTopBar={true}
          modalTitle=""
          modal={<AlertICEModalContents />}
        />
      </Route>
      <Route path={paths.ALERT_ID_MODAL}>
        <Dashboard
          hideTopBar={true}
          modalTitle=""
          modal={<AlertIDModalContents />}
        />
      </Route>
      <Route path={paths.ALERT_CUSTOM_MODAL}>
        <Dashboard
          hideTopBar={true}
          modalTitle=""
          modal={<AlertCustomContent />}
        />
      </Route>
      <Route path={`${paths.PROFILE}-${paths.ALERT_ICD_MODAL}`}>
        <Profile
          hideTopBar={true}
          modalTitle=""
          modal={<AlertICDModalContents />}
        />
      </Route>
      <Route path={`${paths.PROFILE}-${paths.ALERT_ICE_MODAL}`}>
        <Profile
          hideTopBar={true}
          modalTitle=""
          modal={<AlertICEModalContents />}
        />
      </Route>
      <Route path={`${paths.PROFILE}-${paths.ALERT_CUSTOM_MODAL}`}>
        <Profile
          hideTopBar={true}
          modalTitle=""
          modal={<AlertCustomContent />}
        />
      </Route>
      <Route path={paths.ALERT_LEGAL_DOCUMENT_MODAL}>
        <Dashboard
          hideTopBar
          modalTitle=""
          noStylesModal
          modal={<AlertLegalDocumentModalContents />}
        />
      </Route>
      <Route path={`${paths.PROFILE}${paths.ALERT_LEGAL_DOCUMENT_MODAL}`}>
        <Profile
          hideTopBar
          modalTitle=""
          noStylesModal
          modal={<AlertLegalDocumentModalContents />}
        />
      </Route>
      <Route path={`${paths.PROFILE}-${paths.ALERT_ID_MODAL}`}>
        <Profile
          hideTopBar={true}
          modalTitle=""
          modal={<AlertIDModalContents />}
        />
      </Route>
      <Route exact path={paths.CONTACT_US}>
        <ContactPage />
      </Route>
      <Route exact path={`${paths.CONTACT_US}-${paths.ALERT_CUSTOM_MODAL}`}>
        <ContactPage modal={<AlertCustomContent />} />
      </Route>
      <Route exact path={paths.LIFE_EVENTS}>
        <LifeEventsPage />
      </Route>
      <Route path={`${paths.LIFE_EVENTS}-${paths.ALERT_CUSTOM_MODAL}`}>
        <LifeEventsPage modal={<AlertCustomContent />} />
      </Route>
      <Route exact path={paths.FEEDBACK}>
        <FeedbackPage />
      </Route>
      <Route path={`${paths.FEEDBACK}-${paths.ALERT_CUSTOM_MODAL}`}>
        <FeedbackPage modal={<AlertCustomContent />} />
      </Route>
      <Route exact path={paths.PAYMENTS}>
        <PaymentsPage />
      </Route>
      <Route path={`${paths.PAYMENTS}-${paths.ALERT_CUSTOM_MODAL}`}>
        <PaymentsPage modal={<AlertCustomContent />} />
      </Route>
      <Route path={paths.COST_SAVINGS_MODE}>
        <CostSavingsModePage />
      </Route>
      <Route path={`${paths.API_ERROR}`}>
        <Dashboard
          modalTitle=""
          modal={<APIErrorModal toPath={paths.ROOT} />}
        />
      </Route>
      <Route path={`${paths.PROFILE_API_ERROR}`}>
        <Profile
          modalTitle=""
          modal={
            <APIErrorModal
              toPath={paths.PROFILE}
              errorMessage={
                'We were unable to edit your profile information at this time.'
              }
            />
          }
        />
      </Route>
      <Route path={`${paths.DOCUSIGN_EVENT}`}>
        <DynamicEnroll
          // user successfully signs the document
          isSuccessfullySignedDocRedirect={search === '?event=signing_complete'}
          //user already signed and is done viewing the doc
          isViewingSignedDocRedirect={search === '?event=viewing_complete'}
          // Timeout - Document has expired
          hasDocusignExpiredModal={search === '?event=ttl_expired'}
        />
      </Route>
      <Route path={`${paths.PROFILE}/${paths.PROFILE_REPORTS_API_ERROR}`} exact>
        <Profile
          modalTitle=""
          modal={
            <APIErrorModal
              toPath={paths.PROFILE}
              errorMessage={
                'We were unable to save your report information at this time.'
              }
            />
          }
        />
      </Route>
      <Route path={paths.SELF_SUPPLY_MODE}>
        <SelfSupplyModePage />
      </Route>
      <Route path={paths.SELF_SUPPLY_SETUP}>
        <SelfSupplySetupPage />
      </Route>
      <Route path={paths.RESERVE_MODE}>
        <ReserveModePage />
      </Route>
      <Route path={paths.RESERVE_SETUP_STEP_1}>
        <ReserveSetupPage step={1} />
      </Route>
      <Route path={paths.RESERVE_SETUP_STEP_2}>
        <ReserveSetupPage step={2} />
      </Route>
      <Route exact path={paths.PENDING_SYSTEM}>
        <PendingSystem />
      </Route>
      <Route path={`${paths.PENDING_SYSTEM}-${paths.ALERT_CUSTOM_MODAL}`}>
        <PendingSystem modal={<AlertCustomContent />} />
      </Route>
      <ProtectedRoute exact path={paths.PANELS} isActive={PANELS_LAYOUT_FLAG}>
        <PanelsPage />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${paths.PANELS}-${paths.ALERT_CUSTOM_MODAL}`}
        isActive={PANELS_LAYOUT_FLAG}
      >
        <PanelsPage modalTitle="" modal={<AlertCustomContent />} />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${paths.PANELS}-${paths.ALERT_ICD_MODAL}`}
        isActive={PANELS_LAYOUT_FLAG}
      >
        <PanelsPage modalTitle="" modal={<AlertICDModalContents />} />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${paths.PANELS}-${paths.ALERT_ICE_MODAL}`}
        isActive={PANELS_LAYOUT_FLAG}
      >
        <PanelsPage modalTitle="" modal={<AlertICEModalContents />} />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${paths.PANELS}-${paths.ALERT_ID_MODAL}`}
        isActive={PANELS_LAYOUT_FLAG}
      >
        <PanelsPage modalTitle="" modal={<AlertIDModalContents />} />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.F_A_Q_PANELS}
        isActive={PANELS_LAYOUT_FLAG}
      >
        <FAQPanels />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.UTILITY_RATE_PLAN_DETAIL}
        isActive={CUSTOM_TARIFF_FLAG}
      >
        <UtilityPlanDetail />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.UTILITY_RATE_PLAN_SCHEDULES}
        isActive={CUSTOM_TARIFF_FLAG}
      >
        <UtilityRateSchedules />
      </ProtectedRoute>
      {/* DYNAMIC VPP */}
      <ProtectedRoute
        exact
        path={paths.RESERVE_DYNAMIC_VPP}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <DynamicReserveLevel />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${paths.RESERVE_DYNAMIC_VPP}-${paths.ALERT_CUSTOM_MODAL}`}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <DynamicReserveLevel modal={<AlertCustomContent />} />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.MARKETING_VPP_DYNAMIC}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <DynamicMarketing />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.VIRTUAL_PROGRAMS_LIST}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <DynamicProgramList />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.VIRTUAL_PROGRAM_ENROLL}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <DynamicEnroll
          isSuccessfullySignedDocRedirect={false}
          isViewingSignedDocRedirect={false}
        />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.VIRTUAL_PROGRAM_ENROLL_STATUS}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <DynamicStatus />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.F_A_Q_DYNAMIC}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <DynamicFaqs />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={paths.VPP_DASHBOARD}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <VPPDashboard />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${paths.VPP_DASHBOARD}${paths.ALERT_OPTED_OUT}`}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <VPPDashboard modal={<AlertOptOut />} />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={paths.ENROLLMENT_DOCUMENTS}
        isActive={DYNAMIC_VPP_FLAG}
      >
        <EnrollmentDocuments />
      </ProtectedRoute>
      {/* HELP CENTER */}
      <ProtectedRoute
        exact
        path={paths.HELP_CENTER_CHAT}
        isActive={HELP_CENTER_CHAT_FLAG}
      >
        <HelpChat />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`${paths.HELP_CENTER_CHAT}-${paths.ALERT_END_CHAT}`}
        isActive={HELP_CENTER_CHAT_FLAG}
      >
        <HelpChat modal={<AlertEndChat />} />
      </ProtectedRoute>
      {process.env.REACT_APP_ENV === 'qa' && <Redirect to="/login" />}
    </Switch>
  )
}
