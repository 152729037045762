import moment from 'moment-timezone'

import { EVENT_STATUS, VPPDeviceCurrentEvent, VPPProgram } from './typings/site'

export const getEventDataFormated = (
  currentEventState: VPPDeviceCurrentEvent,
  dateFormat: string = 'ddd, l',
) => {
  const tz = moment.tz.guess()

  const eventDatetime = currentEventState?.eventDatetime ?? null
  const eventLength = currentEventState?.eventLength ?? 0

  const eventDate = eventDatetime
    ? moment(eventDatetime).tz(tz).format(dateFormat)
    : null

  const startTime = eventDatetime
    ? moment(eventDatetime).tz(tz).format('h:mma')
    : null

  const endTime = eventDatetime
    ? moment(eventDatetime).tz(tz).add(eventLength, 's').format('h:mma')
    : null

  return { eventDate, startTime, endTime }
}

export const getEventState = (currentEvent: VPPDeviceCurrentEvent) => {
  const optIn = currentEvent?.optIn ?? false
  const eventId = currentEvent?.eventId ?? null
  const eventLength = currentEvent?.eventLength ?? 0
  const eventDatetime = currentEvent?.eventDatetime ?? null

  if ((!eventId && !optIn) || !eventDatetime) {
    return EVENT_STATUS.EVENT_NOT_SCHEDULED
  }

  // Current event is canceled
  if (
    currentEvent?.optIn &&
    currentEvent?.eventStatus === EVENT_STATUS.CANCELED
  ) {
    return EVENT_STATUS.EVENT_NOT_SCHEDULED
  }

  const currentTimeStamp = moment().tz('UTC')
  const eventStartDate = moment(eventDatetime)
  const eventEndDate = moment(eventStartDate).add(eventLength, 's')

  const isStarted = currentTimeStamp.isAfter(eventStartDate)
  const isFinished = currentTimeStamp.isAfter(eventEndDate)

  if (isStarted) {
    if (!isFinished) return EVENT_STATUS.EVENT_IN_PROGRESS

    return EVENT_STATUS.EVENT_NOT_SCHEDULED
  }

  return EVENT_STATUS.EVENT_SCHEDULED
}

export const getSeasonfromProgram = (program: VPPProgram) => {
  const tz = moment.tz.guess()
  const startDate = program.seasonStart
    ? moment(program.seasonStart).tz(tz)
    : ''

  const endDate = program.seasonEnd ? moment(program.seasonEnd).tz(tz) : ''

  return [{ startDate, endDate, color: '#6AA442' }]
}
