import './style.scss'

import clsx from 'clsx'
import React from 'react'

import { useCurrentSiteData } from '../../shared/hooks'
import { OPERATIONAL_MODES } from '../../shared/setupModeConstants'
import { CALIFORNIA_CODE } from '../../shared/sharedConstants'
import checkedImg from './assets/check.svg'
import uncheckedImg from './assets/unchecked.svg'

export const OptionButton = ({ title, selected, onSelect }) => (
  <div className={clsx('setup-mode-btn-big', { selected })} onClick={onSelect}>
    {selected ? (
      <img src={checkedImg} className="setup-mode-check-img" />
    ) : (
      <img src={uncheckedImg} className="setup-mode-check-img" />
    )}
    {title}
  </div>
)

export const ReserveModeSetupStep2 = ({
  days,
  mode,
  handleMode,
  handleCancel,
  handleStart,
}: {
  days: number
  handleMode: (
    mode:
      | OPERATIONAL_MODES.ENERGY_ARBITRAGE
      | OPERATIONAL_MODES.SELF_CONSUMPTION,
  ) => void
  handleCancel: () => void
  handleStart: () => void
  mode: string
}) => {
  const { site } = useCurrentSiteData()
  const showSavingCost = site?.state === CALIFORNIA_CODE
  return (
    <React.Fragment>
      <article className="setup-mode-title column">
        Select mode to return to <br />
        after {days} days:
      </article>
      <section className="setup-mode-options-container">
        <OptionButton
          title="Self-Supply"
          selected={mode === OPERATIONAL_MODES.SELF_CONSUMPTION}
          onSelect={() => handleMode(OPERATIONAL_MODES.SELF_CONSUMPTION)}
        />
        {showSavingCost && (
          <OptionButton
            title="Cost Savings"
            selected={mode === OPERATIONAL_MODES.ENERGY_ARBITRAGE}
            onSelect={() => handleMode(OPERATIONAL_MODES.ENERGY_ARBITRAGE)}
          />
        )}
      </section>
      <section className="setup-mode-actions-container mt-5">
        <button className="btn-cancel button mr-1 mb-5" onClick={handleCancel}>
          cancel
        </button>
        <button className="btn-start button" onClick={handleStart}>
          start
        </button>
      </section>
    </React.Fragment>
  )
}
