import './style.scss'

import React from 'react'

import chevronDown from '../../../../components/header/assets/chevron-down.svg'
import { SeasonsTimeline } from '../../../../components/seasons-timeline/flat-timeline'
import { VPPEnrollment } from '../../../../shared/typings/site'
import settingsIcon from './assets/settings-icon.svg'
import { EventState } from './event-state'

type EventStatusProps = {
  enrollment: VPPEnrollment | null
  selectedSiteKey: string
  programSettingsTitle: string
  seasons: any
  onClickOptOut: () => void
  onClickEdit: () => void
  onClickOpenSettingsSlider: () => void
  handleOnPressViewDetails: () => void
}

export const EventStatus = ({
  enrollment,
  onClickOptOut,
  onClickEdit,
  selectedSiteKey,
  handleOnPressViewDetails,
  seasons,
  onClickOpenSettingsSlider,
  programSettingsTitle,
}: EventStatusProps) => {
  return (
    <>
      <EventState
        enrollment={enrollment}
        handleOptOut={onClickOptOut}
        onClickEdit={onClickEdit}
        siteKey={selectedSiteKey}
      />
      <div className="card-row">
        <div className="card" onClick={handleOnPressViewDetails}>
          <div className="card-title">Events only occur during the season</div>
          <SeasonsTimeline seasons={seasons} isVppProgram />
          <div className="card-hiperlink">View program details</div>
        </div>
        <div className="card settings-card" onClick={onClickOpenSettingsSlider}>
          <img
            className="settings-icon"
            alt="Settings Icon"
            src={settingsIcon}
          />
          <div className="settings-title">
            {programSettingsTitle}
            <div className="chevron-container">
              <img
                src={chevronDown}
                alt="Chevron Icon"
                className="question-chev"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
