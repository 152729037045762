import React from 'react'

export const CurrentPowerInfo = () => {
  return (
    <div className="content">
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          What can I learn from the Current Power widget?
        </h3>
        <p className="is-size-5-5">
          You'll see the current state of your solar system and the rate at
          which your solar system is currently converting sunlight into
          electricity—which is your current power in kW. It does not take into
          account the rate at which your home is using electricity.
        </p>
        <p className="is-size-5-5">
          Power from your system varies based on seasons and time of day (for
          example, more power in the summer and midday).
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          How do you calculate the maximum value for the gauge?
        </h3>
        <p className="is-size-5-5">
          To provide a realistic estimate of your expected maximum power, we
          multiply your system size by 85%. This accounts for inefficiencies
          common to all solar systems, notably power loss at the inverter (when
          electricity is converted from DC to AC). For example, if you have a 5
          kW system, the maximum value for your gauge would be 4.25 kW.
        </p>
      </div>
    </div>
  )
}
