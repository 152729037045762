import moment from 'moment-timezone'

export const calculateDaysDifference = (startDate, endDate, timezone) => {
  if (!timezone) {
    return Math.abs(moment(startDate).diff(moment(endDate), 'day'))
  }

  return moment
    .tz(endDate, timezone)
    .diff(moment.tz(startDate, timezone), 'day')
}
