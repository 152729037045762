import { gql } from '@apollo/client'

export const UPDATE_PERFORMANCE_REPORT = gql`
  mutation updatePerformanceReport($params: PerformanceReportInput!) {
    updatePerformanceReport(params: $params) {
      subscriptionId
      scheduleDay
      nextRun
      subscribers {
        email
        statusIndicator
      }
      nextPeriod {
        start
        end
      }
      siteKey
      reportTypeName
      reportDisplayName
      statusIndicator
      fileFormat
      expirationDate
    }
  }
`
