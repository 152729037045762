import React from 'react'

import { WeatherIconProps } from './cloudy'

export const Foggy = ({ x = 0, y = 0 }: WeatherIconProps) => (
  <svg
    width="27"
    height="27"
    viewBox={`${x} ${y} 27 27`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="27" height="27" />
    <path
      d="M8.43096 8.85503C6.56573 10.5514 6.32506 13.5043 7.94961 15.5147C8.63659 15.2255 10.8965 13.8112 13 15C13 9.33709 15.2737 10.6834 15.4706 9.85182C14.1471 7.74982 10.2962 7.1587 8.43096 8.85503Z"
      fill="#FFD463"
    />
    <path
      d="M6.04573 8.48369L4.7535 7.93652"
      stroke="#FFD463"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6153 6.19381L13.046 5"
      stroke="#FFD463"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.34607 12.2151L4 12.3644"
      stroke="#FFD463"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.79203 6.19381L8.2536 5"
      stroke="#FFD463"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5196 13.9412C12.5196 11.2874 14.912 9.20587 17.7255 9.20587C20.5358 9.20587 22.926 11.2827 22.9314 13.9322C23.8641 14.6461 24.5 15.7096 24.5 16.9412C24.5 19.1009 22.539 20.7941 20.2745 20.7941H10.8627C8.79432 20.7941 7.02941 19.2773 7.02941 17.2941C7.02941 15.3109 8.79432 13.7941 10.8627 13.7941C11.4651 13.7941 12.0273 13.9216 12.5247 14.1422C12.5213 14.0755 12.5196 14.0084 12.5196 13.9412ZM17.7255 10.2059C15.3625 10.2059 13.5196 11.9361 13.5196 13.9412C13.5196 14.3048 13.5873 14.6787 13.6942 15.0315C13.7593 15.2462 13.6733 15.4781 13.4839 15.5985C13.2944 15.7188 13.048 15.6982 12.8812 15.5481C12.3824 15.0992 11.6772 14.7941 10.8627 14.7941C9.24489 14.7941 8.02941 15.9597 8.02941 17.2941C8.02941 18.6285 9.24489 19.7941 10.8627 19.7941H20.2745C22.0884 19.7941 23.5 18.4521 23.5 16.9412C23.5 16.0086 22.9826 15.1635 22.1516 14.6026C22.0139 14.5097 21.9314 14.3544 21.9314 14.1882V13.9412C21.9314 11.9361 20.0884 10.2059 17.7255 10.2059Z"
      fill="#BFBFBF"
    />
  </svg>
)
