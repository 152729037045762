import { range } from '../../recharts-production-consumption-graphs/axisCalculations'

export const prepareDataForTimelapse = (maxDataPoint, hourlyData) => {
  const resp = {}
  const maxXIndex = maxDataPoint

  range(Math.max(maxDataPoint)).map((index) => {
    if (hourlyData === null) {
      resp[index] = {
        xIndex: index,
        power: 0,
      }
      return resp[index]
    }

    const { timestamp, value } = hourlyData[index] ?? [null, null]

    if (timestamp === null) {
      resp[index] = {
        xIndex: index,
        power: 0,
      }
      return resp[index]
    }

    resp[index] = {
      xIndex: index,
      power: value != null ? value : null,
      originalTimestamp: timestamp,
    }
    return resp[index]
  })

  return Object.keys(resp)
    .filter((xIndex) => xIndex <= maxXIndex)
    .map((xIndex) => resp[xIndex])
}

export const singleDayTicks = (tick) => {
  if (tick === 0) {
    return '12am'
  }
  if (tick === 12) {
    return '12pm'
  }
  if (tick === 24) {
    return '11:59'
  }
  if (tick < 12) {
    return `${tick}am`
  }
  return `${tick - 12}pm`
}
