import './style.scss'

import React from 'react'

import EmptyBatteryIcon from './assets/empty-battery.svg'

type StorageInfoProps = {
  isEmpty?: boolean
}

export const StorageInfo = ({ isEmpty = false }: StorageInfoProps) => {
  if (isEmpty) {
    return (
      <div className="content storage-modal-content">
        <div className="center-content">
          <img
            className="empty-battery-icon"
            src={EmptyBatteryIcon}
            alt="Empty battery"
          />
          <h3 className="has-text-weight-normal depleted-battery-text">
            Your SunVault's stored energy has been depleted. SunVault has
            entered a sleep state and will automatically attempt to recharge
            from solar periodically.
          </h3>
        </div>
      </div>
    )
  }

  return (
    <div className="content">
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          What can I learn from the Storage widget?
        </h3>
        <p className="is-size-5-5">
          You'll see the state of charge and battery status of your Equinox
          Storage system.
        </p>
      </div>
      <div className="mb-5">
        <div className="is-size-5-5">
          State of Charge tells you the percentage of charge of your storage
          system.
        </div>
      </div>
      <div className="mb-5">
        <p className="is-size-5-5">
          Battery Status tells you what your storage system is doing. Your
          battery is either powering your home or charging.
        </p>
      </div>
    </div>
  )
}
