import './styles/legend.scss'

import { SITE_TYPES } from '../../shared/sharedConstants'
import {
  consumptionLegendNew,
  productionLegendNew,
  storageLegendNew,
} from './site-legends-new'

export const GraphsLegendWithTotals = ({
  homeTotal = 0,
  solarTotal = 0,
  gridTotal = 0,
  storageTotal = 0,
  siteType = '',
  newEnergyMarkUpOptions = {
    homeConsumption: false,
    solarProduction: false,
    netGrid: false,
    netStorage: false,
  },
  setnewEnergyMarkUpOptions,
}) => {
  const newLegend =
    siteType === SITE_TYPES.CONSUMPTION
      ? consumptionLegendNew(
          siteType,
          homeTotal,
          solarTotal,
          gridTotal,
          newEnergyMarkUpOptions,
          setnewEnergyMarkUpOptions,
        )
      : siteType === SITE_TYPES.STORAGE
      ? storageLegendNew(
          siteType,
          homeTotal,
          solarTotal,
          gridTotal,
          storageTotal,
          newEnergyMarkUpOptions,
          setnewEnergyMarkUpOptions,
        )
      : productionLegendNew(siteType, solarTotal)

  return newLegend
}
