import React, { useMemo } from 'react'

import rightChevron from '../assets/right-arrow.svg'
import { getPanelStatusColor } from '../getPanelStatus'

export const PanelItem = ({
  panel,
  onClick = () => {},
  clickeable,
  loading,
  dceIceAlertFlag = false,
  alertDeviceDownFlag = false,
}) => {
  const panelValue = useMemo(() => {
    return loading
      ? '--'
      : !panel.dailyEnergyProduction || panel.dailyEnergyProduction === null
      ? 'N/A'
      : panel.dailyEnergyProduction?.toFixed(2)
  }, [panel, loading])
  const panelStatus = useMemo(() => {
    return getPanelStatusColor(panel, dceIceAlertFlag, alertDeviceDownFlag)
  }, [panel, dceIceAlertFlag, alertDeviceDownFlag])
  return (
    <article
      onClick={clickeable ? onClick : undefined}
      className="panel-list-item-container"
    >
      <div className="status" style={{ backgroundColor: panelStatus }}></div>
      <div className="serial-number">{panel.serialNumber}</div>
      <div className="panel-value">{panelValue}</div>
      <div className="panel-action">
        {clickeable && <img src={rightChevron} alt="panel action arrow" />}
      </div>
    </article>
  )
}
