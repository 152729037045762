import React from 'react'
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
} from 'recharts'

export const TimelapsePowerChart = ({
  data,
  tickFormat,
  weatherDecorator,
  onMouseMove,
  axisRender,
  activeIndex,
}) => {
  return (
    <ResponsiveContainer width="99%">
      <AreaChart
        data={data}
        margin={{ left: 10, right: 10 }}
        onMouseMove={onMouseMove}
      >
        <XAxis
          dataKey="xIndex"
          axisLine={false}
          tickLine={false}
          fontSize="10px"
          interval={0}
          scale="auto"
          ticks={[1, 6, 12, 18, 24]}
          tick={axisRender(tickFormat, weatherDecorator)}
        />
        <ReferenceLine x={activeIndex} stroke="#000" width={1} />
        <Area dataKey="power" fill="#EF8b22" stroke="#F9D7B7" opacity={0.8} />
      </AreaChart>
    </ResponsiveContainer>
  )
}
