import React from 'react'

import ChatContextProvider from './chatContext'
import CustomTariffContext from './customTariffContext'
import DynamicVppProvider from './dynamicVpp'
import PartyInfoContextProvider from './partyInfoContext'
import SupportCasesContext from './supportCasesContext'
import UserContext from './userContext'
import VppContext from './vppContext'

type ContextProviderPropsType = {
  children: JSX.Element
}

const ContextManager = ({ children }: ContextProviderPropsType) => {
  return (
    <ChatContextProvider>
      <VppContext>
        <CustomTariffContext>
          <DynamicVppProvider>
            <PartyInfoContextProvider>
              <SupportCasesContext>
                <UserContext>
                  {children}
                </UserContext>
              </SupportCasesContext>
            </PartyInfoContextProvider>
          </DynamicVppProvider>
        </CustomTariffContext>
      </VppContext>
    </ChatContextProvider>
  )
}

export default ContextManager
