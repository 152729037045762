import './style.scss'

import clsx from 'clsx'
import React from 'react'

export type InfoWidgetProps = {
  className?: string
  list: {
    title: string
    value: string
  }[]
  icon: string
}

export const InfoSection = ({
  title,
  value,
}: {
  title: string
  value: string
}) => (
  <div>
    <h4 className="has-text-weight-bold is-uppercase">{title}</h4>
    <p>{value}</p>
  </div>
)

export const InfoWidget = ({ className = '', list, icon }: InfoWidgetProps) => {
  const renderedList = React.useMemo(
    () =>
      list.slice(1).map((listItem) => (
        <div className="mt-5" key={listItem.title}>
          <InfoSection title={listItem.title} value={listItem.value} />
        </div>
      )),
    [list],
  )

  return (
    <div className={clsx('info-widget', className)}>
      <div className="level">
        <InfoSection title={list[0]?.title} value={list[0]?.value} />
        <div className="level-right">
          <img src={icon} alt={className} />
        </div>
      </div>
      {renderedList}
    </div>
  )
}
