import React from 'react'

export const SUNPOWER_POLICY_PRIVACY = 'https://us.sunpower.com/privacy'
export const SUNPOWER_TERMS_OF_USE = 'https://us.sunpower.com/terms'
export const SUNPOWER_VPP_SUPPORT_EMAIL = 'vpp@sunpower.com'
export const faqs = [
  {
    // 1
    question: 'Why should I join a SunPower VPP?',
    answer: (
      <div>
        Joining a SunPower VPP enables your SunVault to generate more value for
        your energy without compromising the security and resiliency of your
        solar and battery system. As part of the SunPower VPP, participants will
        be periodically asked to share some of their stored energy to reduce
        their home energy consumption or export that energy into the grid. By
        participating, you will help stabilize the grid, prevent rolling
        blackouts, and in some instances, may eliminate the need to build
        another gas or coal power plant in and around your community. Best of
        all, you can receive compensation for participating.
      </div>
    ),
  },
  {
    // 2
    question: 'How do I enroll in a SunPower VPP?',
    answer: (
      <div>
        Homeowners with SunVault systems can enroll directly through the
        mySunPower® mobile app.
        <ol type="1" style={{ marginTop: '15px' }}>
          <li>
            Launch the mySunPower app and click on the ‘Profile’ tab (in the
            bottom right corner). Look for ‘SunPower VPP’ and select it. You
            will be directed to a VPP information page with a big orange
            “Enroll” button that you can click to sign-up for a SunPower VPP in
            your area.
          </li>
          <li>
            If a SunPower VPP is available in your area, click “Enroll” and
            follow the enrollment instructions.
          </li>
          <li>
            You are all set! Please note that if you choose to proceed, SunPower
            will share certain data about you and your system – like how much
            energy your SunVault discharged – with the SunPower VPP’s grid
            clients. If necessary, please review our{' '}
            <a className="color-text-blue" href={SUNPOWER_POLICY_PRIVACY}>
              Privacy Policy
            </a>{' '}
            and our{' '}
            <a className="color-text-blue" href={SUNPOWER_TERMS_OF_USE}>
              Terms of Use
            </a>{' '}
            for more information about what information is collected and how it
            is used and disclosed.
          </li>
        </ol>
        <br />
        If you do not see these options, please contact us at{' '}
        <a
          className="color-text-blue"
          href={`mailto:${SUNPOWER_VPP_SUPPORT_EMAIL}`}
        >
          {SUNPOWER_VPP_SUPPORT_EMAIL}
        </a>
        . Likewise, if no VPPs are available, please leave us a note at{' '}
        <a
          className="color-text-blue"
          href={`mailto:${SUNPOWER_VPP_SUPPORT_EMAIL}`}
        >
          {SUNPOWER_VPP_SUPPORT_EMAIL}
        </a>{' '}
        to help us gauge interest. Your input helps the SunPower team advocate
        for local utilities to create more VPP opportunities
      </div>
    ),
  },
  {
    // 3
    question:
      'How does participating in a SunPower VPP work? What will happen?',
    answer: (
      <div>
        Once you sign up for a SunPower VPP, you will automatically begin taking
        part in grid “events” and earning rewards for participating.
        <ol style={{ marginTop: '15px' }}>
          <li>
            <b>Event Notification:</b> The grid will let us know that a period
            of high demand or high energy costs is coming up and will schedule
            an "event.” You will receive notification through the mySunPower app
            of event details when an event is scheduled. These notifications
            often happen 18-24 hours before the event, but sometimes can happen
            with shorter notice.
          </li>
          <li>
            <b>Event Participation:</b> Your SunVault battery will automatically
            participate in the event based on the settings you choose, which
            includes your battery reserve level and whether you wish to opt out
            of the event entirely. Your settings can be adjusted before and even
            during the event.
          </li>
          <li>
            <b>Get rewarded:</b> SunPower will calculate your SunVault’s
            performance and reward you for your participation. Compensation
            amount and timing varies by VPP opportunity. Before fully enrolling
            in a SunPower VPP, you should review all the participation details
            and confirm whether joining is right for you.
          </li>
        </ol>
      </div>
    ),
  },
  {
    // 4
    question: 'How much control do I have when participating in an Event?',
    answer: (
      <div>
        After you get notified of an upcoming event, your SunVault will switch
        into a VPP operations mode to prepare to discharge energy to the Grid.
        You retain full control of your SunVault and your decisions help guide
        SunPower’s operation of your SunVault’s participation. In the mySunPower
        mobile or Web app, you can adjust the reserve level of your SunVault for
        any VPP event. You can also set a default reserve level for future VPP
        events or edit the reserve level once the event is scheduled. Keep in
        mind that the less energy you reserve, the more rewards you can receive
        according to your selected program guidelines.
      </div>
    ),
  },
  {
    // 5
    question:
      'Do I have to be available or in touch with SunPower or my installer to participate in discharge events? ',
    answer: (
      <div>
        No. Once your SunVault system is installed and enrolled in a SunPower
        VPP, communication about events will come directly to you via the
        mySunPower app. If you have any follow-up questions, please email us at{' '}
        <a
          className="color-text-blue"
          href={`mailto:${SUNPOWER_VPP_SUPPORT_EMAIL}`}
        >
          {SUNPOWER_VPP_SUPPORT_EMAIL}
        </a>
        .
      </div>
    ),
  },
  {
    // 6
    question: 'I need additional help. Who can I reach out to?  ',
    answer: (
      <div>
        If you have any follow-up questions related to the SunPower VPP, please
        email us directly at{' '}
        <a
          className="color-text-blue"
          href={`mailto:${SUNPOWER_VPP_SUPPORT_EMAIL}`}
        >
          {SUNPOWER_VPP_SUPPORT_EMAIL}
        </a>
        .
      </div>
    ),
  },
]
