// ISO 8601  format
//`YYYY-MM-DDTHH:mm:ss.sss`

//getDateFromISOFormat - manually converts simple string dates whenever MomentJS is not capable

export const getDateFromISOFormat = (date) => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)

  return { year, month, day }
}
