import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'

import { PanelGraphData } from '../../../../components/panels-layout'
import { graphAxisData } from '../../../../components/recharts-production-consumption-graphs/axisCalculations'
import { Weather } from '../../../../components/weather'
import { GRAPH_TYPES } from '../../../../shared/sharedConstants'
import { combineTheYAxisSeriesData, prepareDataForGraphs } from './calcGraph'
import { EnergyGraph } from './energy-graph'
import { PowerGraph } from './power-graph'
import { getWeatherConditions, renderCustomAxisTick } from './weatherConditions'
import { WeatherConditions } from '..'

export const RechartGraph = ({
  graphType,
  setGraphType,
  hourlyData,
  datesData,
  weatherConditions,
  loading,
}: {
  graphType: GRAPH_TYPES
  setGraphType: Dispatch<SetStateAction<GRAPH_TYPES>>
  hourlyData: PanelGraphData[]
  datesData: {
    start: number
    end: number
  }
  weatherConditions: WeatherConditions
  loading: boolean
}) => {
  const xAxisTicks = useMemo(
    () =>
      graphAxisData({
        startDate: datesData.start,
        endDate: datesData.end,
        isPower: false,
      }),
    [datesData],
  )

  const { tickValues, tickFormat, maxValue } = xAxisTicks['TODAY']

  const handleOnClickEnergy = (type: GRAPH_TYPES) => () => {
    setGraphType(type)
  }

  const formattedData = useMemo(
    () => prepareDataForGraphs(maxValue, hourlyData, loading),
    [maxValue, hourlyData, loading],
  )

  const YTicksValue = useMemo(() => combineTheYAxisSeriesData(formattedData), [
    formattedData,
  ])

  const weatherDecorator = useCallback(
    (value) => (
      <Weather condition={getWeatherConditions(weatherConditions, value)} />
    ),
    [weatherConditions],
  )

  return (
    <div className="graph-container">
      {graphType === GRAPH_TYPES.ENERGY ? (
        <EnergyGraph
          data={formattedData}
          axisRender={renderCustomAxisTick}
          xTicks={tickValues}
          tickFormat={tickFormat}
          weatherDecorator={weatherDecorator}
          yTicks={YTicksValue.energy}
        />
      ) : (
        <PowerGraph
          data={formattedData}
          axisRender={renderCustomAxisTick}
          xTicks={tickValues}
          tickFormat={tickFormat}
          weatherDecorator={weatherDecorator}
          yTicks={YTicksValue.power}
        />
      )}
      <div className="graph-options-container">
        <div
          className={
            graphType === GRAPH_TYPES.ENERGY
              ? 'graph-option-selected'
              : 'graph-option'
          }
          onClick={handleOnClickEnergy(GRAPH_TYPES.ENERGY)}
        >
          ENERGY
        </div>
        <div
          className={
            graphType === GRAPH_TYPES.POWER
              ? 'graph-option-selected'
              : 'graph-option'
          }
          onClick={handleOnClickEnergy(GRAPH_TYPES.POWER)}
        >
          POWER
        </div>
      </div>
    </div>
  )
}
