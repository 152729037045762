import './style.scss'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'

import {
  DEFAULT_NON_ZERO,
  formatCommaNumber,
  formatNumber,
} from '../../shared/valueFormatters'
import fromSolarIcon from './assets/from-solar.svg'
import inactiveIcon from './assets/inactive.svg'

export const EnergyMix = ({
  energyMixPercentage = 0,
  energyFromSolar = 0,
  energyConsumed = 0,
  showData = false,
  windowWidth = null,
}: {
  energyMixPercentage?: number
  energyFromSolar?: number
  energyConsumed?: number
  showData?: boolean | null
  windowWidth?: number | null
}) => {
  const energyFromSolarInt = Math.round(energyFromSolar * 100)
  const energyConsumedInt = Math.round(energyConsumed * 100)

  const titleXPosition =
    energyMixPercentage > 99 ? (energyMixPercentage > 999 ? -15 : -5) : 0
  const subtitleXPosition =
    energyMixPercentage > 99
      ? energyMixPercentage > 999
        ? 35
        : 25
      : energyMixPercentage < 10
      ? 15
      : 25

  // TODO: This needs a refactor
  let energyFromSolarF = showData
    ? formatCommaNumber(energyFromSolar)
    : DEFAULT_NON_ZERO
  let energyConsumedF = showData
    ? formatCommaNumber(energyConsumed)
    : DEFAULT_NON_ZERO

  let formattedMixPercentage = showData
    ? `${formatNumber(energyMixPercentage, 0)}`
    : DEFAULT_NON_ZERO

  if (showData) {
    if (energyFromSolarF === DEFAULT_NON_ZERO && energyFromSolar < 100) {
      energyFromSolarF = '0'
    }

    if (energyConsumedF === DEFAULT_NON_ZERO && energyConsumed < 100) {
      energyConsumedF = '0'
    }

    if (
      formattedMixPercentage === DEFAULT_NON_ZERO &&
      energyMixPercentage < 100
    ) {
      formattedMixPercentage = '0'
    }
  }
  // to here
  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      reflow: true,
      height: 340,
      spacing: [0, 0, 20, 0],
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: formattedMixPercentage,
      style: {
        fontSize: '24px',
        fontWeight: '700',
      },
      align: 'center',
      verticalAlign: 'middle',
      x: titleXPosition,
    },
    subtitle: {
      text: formattedMixPercentage !== DEFAULT_NON_ZERO ? '%' : '',
      style: {
        fontSize: '12px',
        fontWeight: '700',
      },
      align: 'center',
      verticalAlign: 'middle',
      x: subtitleXPosition,
    },
    series: [
      {
        type: 'pie',
        data: showData
          ? [
              {
                y:
                  energyConsumedInt === 0
                    ? energyFromSolarInt === 0
                      ? 0
                      : 100
                    : energyMixPercentage,
                color: '#69b342',
              }, //green
              {
                y:
                  energyMixPercentage < 100
                    ? energyConsumedInt !== 0
                      ? 100 - energyMixPercentage
                      : energyFromSolarInt !== 0
                      ? 0
                      : 100
                    : 0,
                color: '#E6E6E6',
              }, //grey
            ]
          : [
              {
                y: 100,
                color: {
                  radialGradient: { cx: 0.5, cy: 0.5, r: 1 },
                  stops: [
                    [0, '#838B98'],
                    [0.4, '#E6E6E6'],
                    [1, '#838B98'],
                  ],
                },
              },
            ],
        size: '85%',
        innerSize: '70%',
        startAngle: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: false,
          },
        },
      },
    ],
  }

  const energyMixChart = React.useMemo(
    () => (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        key={`${windowWidth}`}
      />
    ),
    [options],
  )
  return (
    <div className="energy-mix">
      <div>{energyMixChart}</div>
      <div className="level summary is-mobile">
        <img
          className="mr-4"
          src={showData ? fromSolarIcon : inactiveIcon}
          alt="solar icon"
        />
        <div className="level-item level-left">
          <span>Total Energy Produced</span>
        </div>
        <div className="level-item level-right">{energyFromSolarF} kWh</div>
      </div>
      <div className="level summary-item is-mobile">
        <img className="mr-4" src={inactiveIcon} alt="grid icon" />
        <div className="level-item level-left">
          <span>Total Energy Used</span>
        </div>
        <div className="level-item level-right">{energyConsumedF} kWh</div>
      </div>
    </div>
  )
}
