import { isEqual } from 'lodash'
import React, { useMemo } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { calcYTickValues } from './calcGraph'
import { GraphTooltip } from './tooltip'

export const PowerGraph = ({
  data,
  axisRender,
  xTicks,
  tickFormat,
  weatherDecorator,
  yTicks,
}) => {
  const powerYTicks = useMemo(() => calcYTickValues(yTicks), [yTicks])
  const tooltipMarkup =
    data && data.length > 0 ? (
      <Tooltip cursor={{ fill: 'transparent' }} content={<GraphTooltip />} />
    ) : null
  return (
    <AreaChart
      width={370}
      height={215}
      data={data}
      margin={{
        top: 50,
        bottom: 10,
        right: 30,
      }}
    >
      <CartesianGrid end={0} dx={15} vertical={false} strokeDasharray="3 3" />
      <XAxis
        dataKey="xIndex"
        axisLine={false}
        tickLine={false}
        orientation={'top'}
        fontSize="10px"
        interval={0}
        scale="auto"
        ticks={xTicks}
        tick={axisRender(tickFormat, weatherDecorator)}
        padding={{ left: 5, right: 5 }}
      />
      <YAxis
        axisLine={false}
        allowDecimals={true}
        tickLine={false}
        ticks={powerYTicks}
        tickFormatter={(axisVal) => {
          const valindx = powerYTicks.indexOf(axisVal)
          if (valindx === powerYTicks.length - 1) {
            return `${axisVal} W`
          }
          return axisVal
        }}
        scale={isEqual(powerYTicks, [0]) ? 'auto' : 'linear'}
      />
      {tooltipMarkup}
      <Area dataKey="power" fill="#EF8b22" stroke="#F9D7B7" opacity={0.8} />
      <ReferenceLine y={0} stroke="#000000" />
    </AreaChart>
  )
}
