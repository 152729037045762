import './style.scss'

import React from 'react'

import { SiteCard } from '../site-card'

export const SiteSelection = ({ onSelectSite = (_) => {}, sites = [] }) => {
  const spacingClassName =
    sites.length > 2 ? 'space-between-justify' : 'space-around-justify'

  return (
    <React.Fragment>
      <h3 className="has-text-centered has-text-weight-bold is-size-4 mb-6">
        Select the site you want to view:
      </h3>
      <div className={`sites-selection ${spacingClassName}`}>
        {sites.map((site, idx) => (
          <SiteCard key={`site-${idx}`} site={site} onClick={onSelectSite} />
        ))}
      </div>
    </React.Fragment>
  )
}
