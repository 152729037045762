import './style.scss'

import clsx from 'clsx'
import React, { useMemo, useState } from 'react'

type TabsProps = {
  className?: string
  onTabSelection?: (index: number) => any
  tabs: {
    label: string
    className?: string
    component: () => JSX.Element
  }[]
  showSeparator?: boolean
  lazy?: boolean
}

export const Tabs = ({
  tabs,
  className,
  onTabSelection,
  showSeparator = false,
}: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const onTabClick = React.useCallback(
    (ix) => {
      setSelectedTab(ix)
      if (onTabSelection) {
        onTabSelection(ix)
      }
    },
    [onTabSelection],
  )

  const component = useMemo(
    () => tabs[selectedTab]?.component(),
    [tabs, selectedTab],
  )
  const contentClassName = useMemo(
    () => tabs[selectedTab]?.className,
    [tabs, selectedTab],
  )

  return (
    <div className="tabs-wrapper">
      <div role="tablist" className={clsx('tabs has-text-centered', className)}>
        {tabs.map((tab, ix) => {
          return (
            <article
              role="tab"
              key={tab.label}
              className={clsx({ 'is-active': selectedTab === ix })}
              onClick={() => onTabClick(ix)}
            >
              <span>{tab.label}</span>
            </article>
          )
        })}
      </div>
      <div role="tabpanel" className={clsx('tabs-content', contentClassName)}>
        {component}
      </div>
    </div>
  )
}
