import { useLazyQuery, useMutation } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { VppContext } from '../../context/dynamicVpp'
import paths from '../../pages/Router/paths'
import { LaunchDarklyFlags } from '../typings/launchDarklyFlags'
import {
  ALLOWED_STATUS,
  DocusignURL,
  ENROLLMENT_STATUS,
  PerformanceBySeason,
  PerformanceMetricBySeason,
  StepType,
  UTILITY_ACCOUNT_NUMBER_TEXT_FIELD,
  VPPEnrollment,
  VPP_OHM_CONNECT_PROGRAM_ID,
} from '../typings/site'
import {
  FETCH_DOCUSIGN_URL,
  FETCH_ENROLLMENT_PERFORMANCE_BY_SEASON,
  FETCH_ENROLLMENT_PERFORMANCE_METRIC,
  FETCH_PROGRAM_STEPS,
  FETCH_VPP_ENROLLMENTS,
  SUBMIT_PROGRAM_ENROLLMENT,
  UPDATE_DEFAULT_RESERVE_LEVEL_MUTATION,
  UPDATE_ENROLLMENT_SIGNATURE_STEP_COMPLETE,
  UPDATE_ENROLLMENT_UTILITY_ACCOUNT_NUMBER,
  UPDATE_RESERVE_LEVEL_MUTATION,
} from './query'

type useDynamicVppReturnType = {
  fetchEnrollments: () => void
  enrollments: any[]
  loading: boolean
  selectProgram: (program: VPPEnrollment) => void
  fetchProgramSteps: () => void
  programSteps: any[]
  loadingProgram: boolean
  currentStep: number
  setCurrentStep: (number) => void
  fetchDocusignURL: () => void
  docusignURL: DocusignURL
  loadingDocusignURL: boolean
  docusignURLFetched: boolean
  handleUpdateTextField: (
    textValue: string,
    textField: string,
    callback?: Function
  ) => void
  handleSubmitApplication: (callback?: Function) => void
  selectedEnrollment: VPPEnrollment | null
  selectedSiteKey: string
  handleSignAgreement: () => void
  fetchPerformanceMetric: () => void
  performanceMetricBySeason: PerformanceMetricBySeason[]
  loadingPerformanceMetricData: boolean
  fetchPerformanceBySeason: () => void
  performanceBySeason: PerformanceBySeason[]
  loadingPerformanceBySeasonData: boolean
  updateReserveLevel: (
    newReserveLevel: string,
    isDefaultReserveLevel: boolean
  ) => void
}

export const useDynamicVpp = (): useDynamicVppReturnType => {
  const flags = useFlags() as LaunchDarklyFlags
  const history = useHistory()

  const [cookies] = useCookies([
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
    USER_SELECTED_SITE_KEY,
  ])
  const session = cookies[USER_SESSION_INFO] ?? {}
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const DYNAMIC_VPP_FLAG = flags?.dynamicVppFlag

  const [updateUtilityAccountNumber] = useMutation(
    UPDATE_ENROLLMENT_UTILITY_ACCOUNT_NUMBER
  )
  const [updateEnrollmentSignatureStepComplete] = useMutation(
    UPDATE_ENROLLMENT_SIGNATURE_STEP_COMPLETE
  )

  const [submitEnrollmentProgram] = useMutation(SUBMIT_PROGRAM_ENROLLMENT)

  const [setDefaultReserveLevel] = useMutation(
    UPDATE_DEFAULT_RESERVE_LEVEL_MUTATION
  )

  const [setReserveLevel] = useMutation(UPDATE_RESERVE_LEVEL_MUTATION)

  const [fetchPending, setFetchPending] = useState(false)
  const vppContext = useContext(VppContext)

  const [
    fetchEnrollments,
    { data: enrollmentsData, loading: enrollmentsLoading },
  ] = useLazyQuery(FETCH_VPP_ENROLLMENTS, {
    fetchPolicy: 'network-only',
    variables: { siteKey: selectedSiteKey },
  })

  const [
    fetchProgramSteps,
    { data: programStepsData, loading: programStepsLoading },
  ] = useLazyQuery(FETCH_PROGRAM_STEPS, {
    fetchPolicy: 'network-only',
    variables: { enrollmentId: vppContext.selectedEnrollment?.enrollmentId },
    onError: (error) => {
      vppContext.setProgramSteps([])
      vppContext.setProgramStepsFetched(
        vppContext.selectedEnrollment?.enrollmentId
      )
      vppContext.setCurrentStep(-1)
    },
  })

  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies]
  )

  const partyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk]
  )

  const [
    fetchDocusignURL,
    { data: docusignURLData, loading: docusignURLLoading },
  ] = useLazyQuery(FETCH_DOCUSIGN_URL, {
    fetchPolicy: 'network-only',
    variables: {
      partyId: partyId,
      enrollmentId: vppContext.selectedEnrollment?.enrollmentId,
      returnParamPath: 'monitor/enroll-power-plants',
      //returnEnv: 'LOCAL', //Only for local testing purposes this should be left uncommented. Other envs don't use this
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const seasonId = vppContext.selectedEnrollment?.program?.seasonId || undefined

  const [
    fetchPerformanceMetric,
    { data: performanceMetricData, loading: loadingPerformanceMetricData },
  ] = useLazyQuery(FETCH_ENROLLMENT_PERFORMANCE_METRIC, {
    fetchPolicy: 'network-only',
    variables: {
      // @ts-ignore
      seasonId: parseFloat(seasonId),
      enrollmentId: vppContext?.selectedEnrollment?.enrollmentId,
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const [
    fetchPerformanceBySeason,
    { data: performanceBySeasonData, loading: loadingPerformanceBySeasonData },
  ] = useLazyQuery(FETCH_ENROLLMENT_PERFORMANCE_BY_SEASON, {
    fetchPolicy: 'network-only',
    variables: {
      // @ts-ignore
      seasonId: parseFloat(seasonId),
      enrollmentId: vppContext?.selectedEnrollment?.enrollmentId,
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const fetchEnrollmentsHandler = () => {
    const differentSiteSelected = vppContext.selectedSiteKey !== selectedSiteKey
    if (
      DYNAMIC_VPP_FLAG &&
      (!vppContext.enrollmentFetched || differentSiteSelected)
    ) {
      fetchEnrollments()
    } else if (
      DYNAMIC_VPP_FLAG === undefined &&
      (!vppContext.enrollmentFetched || differentSiteSelected)
    ) {
      setFetchPending(true)
    }
  }

  useEffect(() => {
    if (fetchPending) {
      fetchEnrollments()
      setFetchPending(false)
    }
  }, [DYNAMIC_VPP_FLAG])

  const fetchProgramStepsHandler = () => {
    if (
      !vppContext.programStepsFetched &&
      vppContext.selectedEnrollment?.enrollmentId
    ) {
      fetchProgramSteps({
        variables: {
          enrollmentId: vppContext.selectedEnrollment?.enrollmentId,
        },
      })
    }
  }

  const fetchDocusignURLHandler = () => {
    let needsSignature = false
    vppContext.programSteps.forEach((step) => {
      if (step.stepType === StepType.SIGNATURE) needsSignature = true
    })

    if (!vppContext.docusignURLFetched && needsSignature) {
      fetchDocusignURL()
    }
  }

  const fetchPerformanceMetricHandler = () => {
    if (!vppContext.performanceMetricBySeasonFetched && seasonId) {
      fetchPerformanceMetric()
    }
  }

  const fetchPerformanceBySeasonHandler = () => {
    if (!vppContext.performanceBySeasonFetched && seasonId) {
      fetchPerformanceBySeason()
    }
  }

  const selectProgram = (program: VPPEnrollment) => {
    const programData = {
      ...program,
      isOhmConnect: program?.program?.programId === VPP_OHM_CONNECT_PROGRAM_ID,
    }
    vppContext.setSelectedEnrollment(programData)
  }

  const handleUpdateTextField = (
    textValue: string,
    textField: string,
    callback?: Function
  ) => {
    const currentProgramId =
      vppContext.selectedEnrollment?.program?.programId ?? ''
    const isOhmConnect = currentProgramId === VPP_OHM_CONNECT_PROGRAM_ID

    updateUtilityAccountNumber({
      variables: {
        params: {
          siteId: selectedSiteKey,
          [textField]: textValue,
        },
      },
    }).then(() => {
      if (textField === UTILITY_ACCOUNT_NUMBER_TEXT_FIELD) {
        vppContext.setSelectedEnrollment({
          ...vppContext.selectedEnrollment,
          utilityAccountNumber: textValue,
        })
      }

      const programSteps = [...vppContext.programSteps]

      const stepIndex = programSteps.findIndex(
        (step) => step.fieldName === textField
      )

      const stepToComplete = programSteps[stepIndex]
      const completedStep = {
        ...stepToComplete,
        fieldInitialValue: textValue,
      }

      programSteps.splice(stepIndex, 1, completedStep)

      vppContext.setProgramSteps(programSteps)

      if (!isOhmConnect) {
        vppContext.setCurrentStep(vppContext.currentStep + 1)
      } else {
        handleSubmitApplication(callback)
      }
    })
  }

  const handleSignAgreement = () => {
    if (
      vppContext.selectedEnrollment &&
      vppContext.selectedEnrollment.enrollmentId
    ) {
      updateEnrollmentSignatureStepComplete({
        variables: {
          enrollmentId: parseFloat(vppContext.selectedEnrollment.enrollmentId),
        },
      }).then((response) => {
        if (
          response.data.updateEnrollmentSignatureStepComplete ===
          'Successfully signed'
        ) {
          console.log('Successfully signed')
        }
      })
    }
  }

  const handleSubmitApplication = (callback?: Function) => {
    submitEnrollmentProgram({
      variables: {
        params: {
          programId: vppContext.selectedEnrollment?.program?.programId,
          siteId: selectedSiteKey,
          utilityAccountNumber:
            vppContext.selectedEnrollment?.utilityAccountNumber,
        },
      },
    }).then((response) => {
      if (!vppContext.selectedEnrollment?.isOhmConnect) {
        vppContext.setSelectedEnrollment({
          ...vppContext.selectedEnrollment,
          enrollmentStatus: ENROLLMENT_STATUS.PND,
          enrollmentSubmission: moment.utc().format(),
        })
      }
      //checking if callback is undefined
      if (typeof callback == 'function') {
        callback()
      } else {
        history.push(paths.VIRTUAL_PROGRAM_ENROLL_STATUS)
      }
    })
  }

  const updateReserveLevel = (
    newReserveLevel: string,
    isDefaultReserveLevel: boolean
  ) => {
    let saveMutation = setDefaultReserveLevel
    const reserveLevel = parseFloat(newReserveLevel) / 100

    const params: {
      deviceUuid?: string | null
      reserveLevel?: number | null
      defaultReserveLevel?: number | null
      eventId?: string
      defaultOptIn?: boolean
    } = {
      deviceUuid:
        vppContext?.selectedEnrollment?.enrollmentDevice?.deviceUuid || '',
    }

    if (!isDefaultReserveLevel) {
      saveMutation = setReserveLevel
      params.eventId =
        vppContext?.selectedEnrollment?.enrollmentDevice?.currentEvent
          .eventId || ''
      params.reserveLevel = reserveLevel
    } else {
      params.defaultReserveLevel = reserveLevel
      params.defaultOptIn = true
    }

    saveMutation({
      variables: {
        params,
      },
    }).then(() => {
      if (isDefaultReserveLevel) {
        vppContext.setSelectedEnrollment({
          ...vppContext.selectedEnrollment,
          enrollmentDevice: {
            ...vppContext.selectedEnrollment?.enrollmentDevice,
            defaultReserveLevel: reserveLevel,
          },
        })
      } else {
        vppContext.setSelectedEnrollment({
          ...vppContext.selectedEnrollment,
          enrollmentDevice: {
            ...vppContext.selectedEnrollment?.enrollmentDevice,
            currentEvent: {
              ...vppContext.selectedEnrollment?.enrollmentDevice?.currentEvent,
              reserveLevel: reserveLevel,
            },
          },
        })
      }
    })
  }

  useEffect(() => {
    if (
      vppContext.selectedEnrollment?.enrollmentId &&
      vppContext.selectedEnrollment?.enrollmentId !==
        vppContext.programStepsFetched
    ) {
      fetchProgramSteps({
        variables: {
          enrollmentId: vppContext.selectedEnrollment?.enrollmentId,
        },
      })
    }
  }, [vppContext.selectedEnrollment?.enrollmentId])

  useEffect(() => {
    if (enrollmentsData) {
      const customEnrollments =
        enrollmentsData.vppEnrollments?.map((enrollment) => ({
          ...enrollment,
          isEnrolled: ALLOWED_STATUS.includes(enrollment.enrollmentStatus),
          isOhmConnect:
            enrollment?.program?.programId === VPP_OHM_CONNECT_PROGRAM_ID,
        })) ?? []

      vppContext.setEnrollments(customEnrollments)
      vppContext.setEnrollmentFetched(true)

      const activeEnrollment = customEnrollments.find((enrollment) =>
        ALLOWED_STATUS.includes(enrollment.enrollmentStatus)
      )
      if (activeEnrollment) {
        vppContext.setSelectedEnrollment(activeEnrollment)
      }
    }
  }, [enrollmentsData])

  useEffect(() => {
    if (vppContext.selectedSiteKey !== selectedSiteKey) {
      vppContext.setSelectedSiteKey(selectedSiteKey)
      vppContext.setEnrollments([])
      vppContext.setProgramSteps([])
      vppContext.setSelectedEnrollment(null)
      vppContext.setEnrollmentFetched(false)
      vppContext.setDocusignURL({})
      vppContext.setDocusignURLFetched(false)
      vppContext.setPerformanceMetricBySeason([])
      vppContext.setPerformanceMetricBySeasonFetched(false)
      vppContext.setPerformanceBySeason([])
      vppContext.setPerformanceBySeasonFetched(false)
    }
  }, [selectedSiteKey])

  useEffect(() => {
    if (programStepsData) {
      const { fetchProgramSignupStepsForEnrollment } = programStepsData
      vppContext.setProgramSteps(fetchProgramSignupStepsForEnrollment)
      vppContext.setProgramStepsFetched(
        vppContext.selectedEnrollment?.enrollmentId
      )
      vppContext.setCurrentStep(1)
    }
  }, [programStepsData])

  useEffect(() => {
    if (docusignURLData) {
      const { generateWebviewURL } = docusignURLData
      vppContext.setDocusignURL(generateWebviewURL)
      vppContext.setDocusignURLFetched(true)
    }
  }, [docusignURLData])

  useEffect(() => {
    if (performanceMetricData) {
      const { fetchEnrollmentPerformanceMetricBySeason } = performanceMetricData
      vppContext.setPerformanceMetricBySeason(
        fetchEnrollmentPerformanceMetricBySeason
      )
      vppContext.setPerformanceMetricBySeasonFetched(true)
    }
  }, [performanceMetricData])

  useEffect(() => {
    if (performanceBySeasonData) {
      const { fetchEnrollmentPerformanceBySeason } = performanceBySeasonData
      vppContext.setPerformanceBySeason(fetchEnrollmentPerformanceBySeason)
      vppContext.setPerformanceBySeasonFetched(true)
    }
  }, [performanceBySeasonData])

  return {
    fetchEnrollments: fetchEnrollmentsHandler,
    enrollments: vppContext.enrollments,
    loading: enrollmentsLoading,
    selectProgram,
    selectedEnrollment: vppContext.selectedEnrollment,
    fetchProgramSteps: fetchProgramStepsHandler,
    programSteps: vppContext.programSteps,
    loadingProgram: programStepsLoading,
    currentStep: vppContext.currentStep,
    setCurrentStep: vppContext.setCurrentStep,
    fetchDocusignURL: fetchDocusignURLHandler,
    docusignURL: vppContext.docusignURL,
    loadingDocusignURL: docusignURLLoading,
    docusignURLFetched: vppContext.docusignURLFetched,
    handleUpdateTextField,
    handleSignAgreement,
    handleSubmitApplication: handleSubmitApplication,
    selectedSiteKey: vppContext.selectedSiteKey,
    fetchPerformanceMetric: fetchPerformanceMetricHandler,
    performanceMetricBySeason: vppContext.performanceMetricBySeason,
    loadingPerformanceMetricData: loadingPerformanceMetricData,
    fetchPerformanceBySeason: fetchPerformanceBySeasonHandler,
    performanceBySeason: vppContext.performanceBySeason,
    loadingPerformanceBySeasonData: loadingPerformanceBySeasonData,
    updateReserveLevel,
  }
}
