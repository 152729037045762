import './style.scss'

import clsx from 'clsx'
import React from 'react'

export const SignalIndicator = ({ level = 0 }) => {
  const indicators = [...Array(5).keys()].map((k) => (
    <div
      key={`signal-${k + 1}`}
      className={clsx(`indicator signal-${k + 1}`, {
        active: level >= k + 1,
      })}
    ></div>
  ))
  return <div className="signal-indicator">{indicators}</div>
}
