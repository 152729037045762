import React from 'react'
import { NumericFormat } from 'react-number-format'

type NumericInputProps = {
  value?: string
  inputName: string
  placeholder?: string
  handler?: any
  mask?: string
  format?: string
}
export const NumericInput = ({
  value = '',
  placeholder = 'Placeholder',
  handler = () => {},
  inputName = 'label',
  mask,
  format,
}: NumericInputProps) => {
  return (
    <NumericFormat
      name={inputName}
      type="tel"
      className="input is-medium"
      value={value}
      placeholder={placeholder}
      onValueChange={handler}
      autoComplete="true"
      mask={mask}
      format={format}
    />
  )
}
