import './style.scss'

import React from 'react'
import { createPortal } from 'react-dom'

import iconClose from '../menu/assets/icon-close.svg'

export const OverlayModal = ({ children, onClose }) => {
  return createPortal(
    <div className="overlay-modal is-flex">
      <div className="container has-bar column">
        <img src={iconClose} alt="Close modal" onClick={onClose} />
        {children}
      </div>
    </div>,
    document.getElementById('modal-root') as any,
  )
}
