import { useLazyQuery } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'

import { dateFormat } from '../constants'
import { INTERVAL_OPTIONS } from '../intervals'
import { roundValue } from '../roundValue'
import { DataLayerEventName } from '../typings/dataLayer'
import { LaunchDarklyFlags } from '../typings/launchDarklyFlags'
import { FETCH_ENERGY_DATA, FETCH_ENERGY_RANGE } from './query'

export const useEnergy = (
  siteKey,
  startDate,
  endDate,
  interval: string,
  timezone,
) => {
  // @TODO - Remove CUSTOM_DATES_NV_FLAG flag and flag-related code from GQL and the current codebase ↓
  // if NightVision removes the following constrains for maximum time differences in a row:
  // For hour: up to 21 days
  // For month: up to 1095 days

  const flags = useFlags() as LaunchDarklyFlags

  const CUSTOM_DATES_NV_FLAG = flags.customDatesNvFlag ?? false

  const [energyData, setEnergyData] = useState({
    production: [],
    consumption: [],
    storage: [],
    grid: [],
    totalProduction: 0,
    totalConsumption: 0,
    totalGrid: 0,
    totalStorage: 0,
    energyMixPercentage: 0,
    tooltips: {
      tooltipConsumptionTotals: [],
      tooltipGridTotals: [],
      tooltipProductionTotals: [],
      tooltipStorageTotals: [],
    },
  })

  const [
    fetchEnergyData,
    { called, data: energyDataReport, loading, error },
  ] = useLazyQuery(FETCH_ENERGY_DATA, { fetchPolicy: 'cache-and-network' })

  const [
    fetchEnergyRangeData,
    {
      called: energyRangeCalled,
      data: energyRangeDataReport,
      loading: energyRangeLoading,
      error: energyRangeError,
    },
  ] = useLazyQuery(FETCH_ENERGY_RANGE, { fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    if (error || energyRangeError) {
      window.dataLayer.push({
        event: DataLayerEventName.ENERGY_DATA_ERROR,
        eventData: { stack: error ? error.stack : energyRangeError },
      })
    }
  }, [error, energyRangeError])

  useEffect(() => {
    if (siteKey && startDate && endDate && timezone) {
      // INTERVAL_OPTIONS[interval] = hour, day, month, year - *FOR ENERGY DATA*
      // 'hour' is set when INTERVALS.DAY is set either from the "day" tab, or from the "custom" tab, when only one day is selected
      // 'day' is set for: "day", "week", "month", "Custom" - multiple days
      // 'month' is set for: "year" tab
      // 'year' is set for: "lifetime" tab

      //Note: this interval also accepts the value 'five_minute'

      if (CUSTOM_DATES_NV_FLAG) {
        fetchEnergyRangeData({
          variables: {
            siteKey: siteKey,
            interval: INTERVAL_OPTIONS[interval],
            end: moment(endDate).tz(timezone).format(dateFormat),
            start: moment(startDate).tz(timezone).format(dateFormat),
          },
        })
      } else {
        fetchEnergyData({
          variables: {
            siteKey: siteKey,
            interval: INTERVAL_OPTIONS[interval],
            end: moment(endDate).tz(timezone).format(dateFormat),
            start: moment(startDate).tz(timezone).format(dateFormat),
          },
        })
      }
    }

  }, [siteKey, startDate, endDate, interval, timezone])

  useEffect(() => {
    if (
      (energyRangeCalled || called) &&
      (energyRangeDataReport || energyDataReport) &&
      !(loading || energyRangeLoading)
    ) {
      const {
        consumption = [],
        production = [],
        storage = [],
        grid = [],
      } = CUSTOM_DATES_NV_FLAG
        ? energyRangeDataReport?.energyRange.energyDataSeries
        : energyDataReport?.energy.energyDataSeries ?? {}

      const {
        totalProduction = 0,
        totalConsumption = 0,
        netStorageChargedDischarged: totalStorage = 0,
        netGridImportExport: totalGrid = 0,
        energyMixPercentage = 0,
      } = CUSTOM_DATES_NV_FLAG
        ? energyRangeDataReport?.energyRange
        : energyDataReport?.energy ?? {}

      const tooltipConsumptionTotals = CUSTOM_DATES_NV_FLAG
        ? energyRangeDataReport?.energyRange.tooltipConsumptionTotals
        : []
      const tooltipGridTotals = CUSTOM_DATES_NV_FLAG
        ? energyRangeDataReport?.energyRange.tooltipGridTotals
        : []
      const tooltipProductionTotals = CUSTOM_DATES_NV_FLAG
        ? energyRangeDataReport?.energyRange.tooltipProductionTotals
        : []
      const tooltipStorageTotals = CUSTOM_DATES_NV_FLAG
        ? energyRangeDataReport?.energyRange.tooltipStorageTotals
        : []

      setEnergyData({
        consumption: consumption,
        production: production,
        storage: storage,
        grid: grid,
        totalProduction: roundValue(totalProduction),
        totalConsumption: roundValue(totalConsumption),
        totalStorage: roundValue(totalStorage),
        totalGrid: roundValue(totalGrid),
        energyMixPercentage,
        tooltips: {
          tooltipConsumptionTotals,
          tooltipGridTotals,
          tooltipProductionTotals,
          tooltipStorageTotals,
        },
      })
    }
  }, [
    startDate,
    endDate,
    energyRangeDataReport,
    loading,
    called,
    energyDataReport,
    CUSTOM_DATES_NV_FLAG,
    energyRangeCalled,
    energyRangeLoading,
  ])

  const retValue = useMemo(
    () => ({ energy: energyData, loading: energyRangeLoading || loading }),
    [energyData, energyRangeLoading, loading],
  )

  return retValue
}
