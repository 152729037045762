import './style.scss'

import { useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Loader } from '../../../components/loader'
import { useDynamicVpp } from '../../../shared/hooks'
import SpLogo from './../assets/sunpower-logo.svg'
import { VPPLayout } from '../vpp-components/layout-vpp'
import { EXCHANGE_MUTATION } from './query'

const FINISH_SIGN_UP_SOURCE = 'https://app.ohmconnect.com/signup'

export const OhmCallback = () => {
  const location = useLocation()
  const history = useHistory()
  const [exchange] = useMutation(EXCHANGE_MUTATION)
  const { fetchEnrollments, enrollments } = useDynamicVpp()

  useEffect(() => {
    fetchEnrollments()
  }, [])

  useEffect(() => {
    const searchString = location.search ?? ''
    if (
      searchString &&
      searchString.search('code') !== -1 &&
      enrollments.length > 0
    ) {
      const code = searchString.split('=')[1]
      const redirectUrl = process.env.REACT_APP_OHM_CONFIG_REDIRECT_URI ?? ''
      const enrollment = enrollments.find(
        (enrollment) => enrollment.isOhmConnect,
      )
      exchange({
        variables: {
          authorizationCode: code,
          enrollmentId: enrollment?.enrollmentId,
          redirectUri: redirectUrl,
        },
      }).finally(() => {
        window.location.href = FINISH_SIGN_UP_SOURCE
      })
    }
  }, [location, history, enrollments])

  return (
    <VPPLayout title="Ohm Connect" className="ohm-callback-wrapper">
      <img src={SpLogo} alt="" />
      <div className="loader-container">
        <Loader />
      </div>
    </VPPLayout>
  )
}
