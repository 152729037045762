import './style.scss'

import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Loader } from '../loader'
import editIcon from './assets/edit-icon.svg'
import infoIcon from './assets/info-icon.svg'
import settingsIcon from './assets/settings-icon.svg'

type CustomButton = {
  icon: string
  alt: string
  onClick: () => any
}

type WidgetWrapperProps = {
  children: JSX.Element
  boldTitle?: string
  title?: string
  headerComponent?: React.ReactElement | null
  infoLink?: string | null
  infoData?: any
  settingsLink?: string | null
  settingsData?: any
  editLink?: string
  editData?: any
  isLoading?: boolean
  hasContentPadding?: boolean
  customButtonsBefore?: CustomButton[]
  customButtonsAfter?: CustomButton[]
  topBannerComponent?: React.ReactElement | null
}

const renderCustomButton = ({ icon, alt, onClick }) => (
  <img
    key={alt}
    className="widget-icon mr-3"
    onClick={onClick}
    src={icon}
    alt={alt}
  />
)

export const WidgetWrapper = ({
  children,
  boldTitle,
  title,
  headerComponent,
  infoLink,
  infoData = {},
  settingsLink,
  settingsData = {},
  editLink,
  editData = {},
  isLoading = false,
  hasContentPadding = true,
  customButtonsBefore = [],
  customButtonsAfter = [],
  topBannerComponent,
}: WidgetWrapperProps) => {
  const history = useHistory()

  const onSettingsClick = useCallback(() => {
    if (settingsLink) {
      history.push({
        pathname: settingsLink,
        state: settingsData,
      })
    }
  }, [history, settingsLink, settingsData])

  const onInfoClick = useCallback(() => {
    if (infoLink) {
      history.push({
        pathname: infoLink,
        state: infoData,
      })
    }
  }, [history, infoLink, infoData])

  const onEditClick = useCallback(() => {
    if (editLink) {
      history.push({
        pathname: editLink,
        state: editData,
      })
    }
  }, [history, editLink, editData])

  return (
    <section className="tile is-child box px-0 py-0 widget-wrapper">
      <header>
        {topBannerComponent ? topBannerComponent : null}
        <div className="level px-5 py-4 is-mobile">
          <div className="level-left">
            {headerComponent ? (
              headerComponent
            ) : (
              <h3 className="is-uppercase">
                {boldTitle && (
                  <span className="has-text-weight-bold">{boldTitle} </span>
                )}
                {title}
              </h3>
            )}
          </div>
          <div className="level-right">
            {customButtonsBefore.map(renderCustomButton)}
            {infoLink && (
              <img
                className={clsx('widget-icon', {
                  'mr-3': !!settingsLink || !!editLink,
                })}
                onClick={onInfoClick}
                src={infoIcon}
                alt="info"
              />
            )}
            {editLink && (
              <img
                className={clsx('widget-icon', {
                  'mr-3': !!infoLink,
                })}
                onClick={onEditClick}
                src={editIcon}
                alt="edit"
              />
            )}
            {settingsLink && (
              <img
                className="widget-icon"
                onClick={onSettingsClick}
                src={settingsIcon}
                alt="settings"
              />
            )}
            {customButtonsAfter.map(renderCustomButton)}
          </div>
        </div>
      </header>
      <div
        className={clsx('widget-content', {
          'px-5': hasContentPadding,
          'py-5': hasContentPadding,
        })}
      >
        {isLoading ? <Loader /> : children}
      </div>
    </section>
  )
}
