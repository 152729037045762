import './style.scss'

import clsx from 'clsx'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import React, { useEffect, useMemo, useState } from 'react'

import { DEFAULT_NON_ZERO, formatNumber } from '../../shared/valueFormatters'

highchartsMore(Highcharts)
highchartsAccessibility(Highcharts)
solidGauge(Highcharts)

const determineGaugeMaximum = (nowProducing: number, sysSize: number) => {
  // If _sysSize is not set start with 4.00 to handle the early morning case.
  if (!sysSize || sysSize <= 0) {
    sysSize = 4
  }

  // Update the _sysSize if the currentProduction is greater than the _sysSize.
  // Use a value that is 25% greater than the currentProductionkW
  if (nowProducing && nowProducing > sysSize) {
    sysSize = nowProducing * 1.25
  }

  // Make sure _sysSize is always rounded up to the next whole number
  return Math.ceil(sysSize)
}
type CurrentPowerGraphOptions = {
  hasICD?: boolean
  nowProducing?: number
  sysSize?: number
  isDaytime?: boolean
  isLoading?: boolean
  windowWidth?: number | null
}
export const CurrentPower = ({
  hasICD = false,
  nowProducing = 0,
  sysSize = 4,
  isDaytime = true,
  isLoading = false,
  windowWidth = 0,
}: CurrentPowerGraphOptions) => {
  const sysSizekW = useMemo(
    () => determineGaugeMaximum(nowProducing, sysSize),
    [nowProducing, sysSize],
  )
  const fontSize = windowWidth && windowWidth < 1200 ? '0.85rem' : '1rem'
  const distance = windowWidth && windowWidth > 1200 ? 30 : 25
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    chart: {
      type: 'solidgauge',
      backgroundColor: 'white',
      height: 170,
      reflow: true,
      spacing: [0, 0, -30, 0],
    },
    title: {
      style: {
        display: 'none',
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      center: ['50%', '75%'],
      size: '90%',
      background: [
        {
          backgroundColor: 'transparent',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
        },
      ],
    },
    yAxis: {
      min: 0,
      minPadding: 0,
      maxPadding: 0,
      max: sysSizekW,
      minorTickInterval: null,
      gridLineColor: '#9cbccd',
      lineColor: '#9cbccd',
      lineWidth: 0,
      tickPosition: 'outside',
      tickInterval: sysSizekW / 4,
      tickLength: 10,
      tickWidth: 1,
      labels: {
        distance,
        formatter: function () {
          return `<div>${!hasICD ? this.value : DEFAULT_NON_ZERO} kW</div>`
        },
        align: 'center',
        y: 1,
        x: 1,
        style: {
          color: '#5e6367',
          textAlign: 'center',
          fontSize,
        },
      },
      stops: [[1, '#69b342']],
    },
    plotOptions: {
      solidgauge: {
        innerRadius: '80%',
      },
    },
    series: [
      {
        type: 'solidgauge',
        data: [{ y: nowProducing }],
        dataLabels: {
          format: '<div class="production-label">{y} kW</div>',
          y: 10,
          x: 0,
          borderWidth: 0,
          useHTML: true,
          style: {
            fontSize: '18px',
            fontWeight: '600',
            color: 'transparent',
          },
        },
      },
    ],
  })

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      series: [
        {
          type: 'solidgauge',
          data: [{ y: nowProducing }],
          dataLabels: {
            format: '<div class="production-label">{y} kW</div>',
            y: 10,
            x: 0,
            borderWidth: 0,
            useHTML: true,
            style: {
              fontSize: '18px',
              fontWeight: '600',
              color: 'transparent',
            },
          },
        },
      ],
      yAxis: {
        max: sysSizekW,
        tickInterval: sysSizekW / 4,
        min: 0,
        minPadding: 0,
        maxPadding: 0,
        minorTickInterval: null,
        gridLineColor: '#9cbccd',
        lineColor: '#9cbccd',
        lineWidth: 0,
        tickPosition: 'outside',
        tickLength: 10,
        tickWidth: 1,
        labels: {
          distance,
          formatter: function () {
            return `<div>${!hasICD ? this.value : DEFAULT_NON_ZERO} kW</div>`
          },
          align: 'center',
          y: 1,
          x: 1,
          style: {
            color: '#5e6367',
            textAlign: 'center',
            fontSize,
          },
        },
        stops: [[1, '#69b342']],
      },
    })
  }, [nowProducing, sysSizekW, windowWidth])

  return (
    <div className="current-power">
      <div className="level now-producing is-mobile">
        <div className="level-item level-left">
          {isLoading
            ? 'Waiting for data'
            : isDaytime
            ? 'Now Producing'
            : 'Waiting for the sun'}
        </div>
        <div className="level-item level-right">
          {isDaytime ? formatNumber(nowProducing) : DEFAULT_NON_ZERO} kW
        </div>
      </div>
      <div
        className={clsx('mt-4', {
          disabled: hasICD,
        })}
      >
        <HighchartsReact
          key={`current-power-${windowWidth}`}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    </div>
  )
}
