export enum CasesStatus {
  OPEN = 'open',
  RESOLVED = 'resolved',
  UNDEFINED = 'undefined',
}

export type CaseComment = {
  createdBy: string
  commentBody: string
  createdDate: string
  user: { _id: number; name: string }
  isSent?: false
}

export type Case = {
  caseNumber: string
  statusColor?: string
  subject: string
  description: string
  category: string
  status: string
  createdDate: string
  lastModifiedDate: string
  caseComments?: CaseComment[]
  // non API fields
  loadingComments?: boolean
}
