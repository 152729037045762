import './contact.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'

import {
  HOME_ASSETS,
  LayoutWithBackground,
} from '../../components/layout-with-background'
import { Modal } from '../../components/modal'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import paths from '../Router/paths'
import callUsImg from './assets/call-us.svg'
import emailUsImg from './assets/email-us.svg'

export const ContactPage = ({ modal }: { modal?: JSX.Element }) => {
  const flags = useFlags() as LaunchDarklyFlags

  const TIME_BOUND_SUPPORT_FLAG = flags.timeBoundSupportFlag ?? false

  return (
    <LayoutWithBackground
      className="contact"
      title="Contact Us"
      asset={HOME_ASSETS.QUESTION}
    >
      <div className="columns contact-content">
        <div className="column contact-column-info">
          <h3>Email Us:</h3>
          <img
            src={emailUsImg}
            className="contact-emailus-img"
            alt="Email Us"
          />
          <p className="has-text-centered contact-text">
            <a
              href="mailto:customersupport@sunpower.com"
              className="contact-link is-block mt-3"
            >
              customersupport@sunpower.com
            </a>
          </p>
        </div>
        <div className="is-divider-vertical" data-content="OR" />
        <div className="column contact-column-info">
          <h3>Call Us:</h3>
          <img src={callUsImg} alt="Call Us" />
          <p className="has-text-centered contact-phone mb-5">1-800-786-7693</p>
          <p className="has-text-centered contact-text">Operating Hours:</p>
          {TIME_BOUND_SUPPORT_FLAG ? (
            <div
              className="has-text-centered contact-days-info-wrapper"
              id="info-hours"
            >
              <p className="days-subtitle">Pacific standard time</p>
              <div className="days-row">
                <div className="days-info">Mon - Fri</div>
                <div className="days-separator"></div>
                <b className="days-hour">6:00AM - 7:00PM</b>
              </div>
              <div className="days-row">
                <div className="days-info">Sat</div>
                <div className="days-separator"></div>
                <b className="days-hour">7:00AM - 4:00PM</b>
              </div>
            </div>
          ) : (
            <p
              className="has-text-centered contact-subtext mb-5 mt-2"
              id="info-everyday"
            >
              24 hours 7 days a week
            </p>
          )}
        </div>
      </div>
      <>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.CONTACT_US}>
            {modal}
          </Modal>
        )}
      </>
    </LayoutWithBackground>
  )
}
