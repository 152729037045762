import { gql } from '@apollo/client'

export const FETCH_SITE_INFO = gql`
  query FetchSiteInfo($siteKey: String!) {
    site(siteKey: $siteKey) {
      siteKey
      siteName
      siteType
      financeType
      financeStatus
    }
  }
`