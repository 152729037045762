import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { get } from 'lodash'
import moment from 'moment-timezone'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { SupportCasesContext } from '../../context/supportCasesContext'
import {
  ADD_CASE_COMMENT_MUTATION,
  FETCH_CASE_COMMENTS_QUERY,
  FETCH_OKTA_USER_QUERY,
  FETCH_SUPPORT_CASES_QUERY,
  GENERATE_TOKEN_MUTATION,
} from './query'
import { usePartyData } from './usePartyData'

const DEFAULT_OPEN_STATE = 'Open - Pending SunPower Action'

type useSupportCasesReturnType = {
  loading: boolean
  cases: any[]
  handleAddCaseComment: (
    caseNumber: string,
    comment: string,
    callback: () => void,
    isChangeStatusToOpenOnSuccess?: boolean,
  ) => void
  fetchCaseComments: (caseNumber: string) => void
  addCaseLoading: boolean
}

export const useSupportCases = (): useSupportCasesReturnType => {
  const [cookies] = useCookies([
    LOGIN_OVERRIDE,
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
    USER_SELECTED_SITE_KEY,
    USER_SESSION_INFO,
  ])
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const session = cookies[USER_SESSION_INFO] ?? {}
  const [loading, setLoading] = useState<boolean>(true)
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )
  const currentSiteKey = useMemo(() => (isKiosk ? selectedSiteKey : null), [
    isKiosk,
    selectedSiteKey,
  ])
  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )
  const { data: partyData } = usePartyData(currentPartyId, currentSiteKey)

  const [
    fetchSupportCases,
    { data: supportCasesData, loading: fetchLoading },
  ] = useLazyQuery(FETCH_SUPPORT_CASES_QUERY, {
    fetchPolicy: 'network-only',
  })
  const email = useMemo(() => partyData?.party?.email ?? '', [partyData])

  const {
    oktaToken,
    setOktaToken,
    oktaUser,
    setOktaUser,
    supportCases,
    setSupportCases,
    fetchedCases,
    setFetchedCases,
  } = useContext(SupportCasesContext)

  const [generateToken] = useMutation(GENERATE_TOKEN_MUTATION)
  const [fetchCommentId, setFetchCommentId] = useState<null | string>(null)
  const [refetchCases, setRefetchCases] = useState(false)

  const { data: commentsData, error: commentsError } = useQuery(
    FETCH_CASE_COMMENTS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        params: {
          caseNumber: fetchCommentId,
          jwt: oktaToken,
        },
      },
      skip: !fetchCommentId,
    },
  )
  const [fetchOktaUser, { data: oktaData }] = useLazyQuery(
    FETCH_OKTA_USER_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  )

  useEffect(() => {
    setLoading(fetchLoading)
  }, [fetchLoading])

  useEffect(() => {
    if (!oktaUser?.id && email !== '') {
      fetchOktaUser({
        variables: {
          email,
        },
      })
    }
    if (!oktaToken && email !== '') {
      generateToken().then((res) => {
        const token = get(res, 'data.generateToken.jwt', '')
        setOktaToken(token)
      })
    }
  }, [email])

  useEffect(() => {
    if (oktaData) {
      setOktaUser(oktaData.oktaUser)
    }
  }, [oktaData])

  useEffect(() => {
    if (
      oktaToken &&
      oktaUser &&
      oktaToken !== fetchedCases?.oktaToken &&
      oktaUser.id !== fetchedCases?.oktaUser?.id
    ) {
      const variables = {
        params: {
          emailId: oktaUser.email,
          jwt: oktaToken,
          oktaId: oktaUser.id,
          partyId: partyData?.party?.partyId ?? '',
          siteId: selectedSiteKey,
        },
      }

      fetchSupportCases({
        variables,
      })
    }
  }, [oktaToken, email, refetchCases])

  const [addCaseCommentMutation, { loading: addCaseLoading }] = useMutation(
    ADD_CASE_COMMENT_MUTATION,
  )

  const handleAddCaseComment = (
    caseNumber: string,
    comment: string,
    callback: () => void,
    isChangeStatusToOpenOnSuccess?: boolean,
  ) => {
    addCaseCommentMutation({
      variables: {
        params: {
          caseNumber: caseNumber,
          jwt: oktaToken,
          comment: comment,
        },
      },
    })
      .then(() => {
        const updatedCases = supportCases.map((supportCase) => {
          if (supportCase.caseNumber !== caseNumber) {
            return supportCase
          }
          const currentComments = supportCase.caseComments ?? []
          return {
            ...supportCase,
            status: isChangeStatusToOpenOnSuccess
              ? DEFAULT_OPEN_STATE
              : supportCase.status,
            caseComments: [
              ...currentComments,
              {
                commentBody: comment,
                createdDate: new Date(),
                createdBy: 'Customer',
                user: {
                  id: partyData?.party?.partyId ?? '',
                  name: partyData?.party?.displayName ?? '',
                },
              },
            ],
          }
        })

        setSupportCases([...updatedCases])
        callback()
      })
      .catch((error) => {
        console.log('Error: ', error)
      })
  }

  useEffect(() => {
    if (supportCasesData) {
      // response from fetch cases
      const { getCases } = supportCasesData
      const formattedCases = getCases
        .map((supportCase) => ({
          ...supportCase,
          caseComments: null, //non fetch comments
          loadingComments: false,
        }))
        .sort((a, b) => {
          //sort by created date
          return moment(b.createdDate).diff(moment(a.createdDate))
        })
      setSupportCases(formattedCases)
      setFetchedCases({ oktaToken, oktaUser })
    }
  }, [supportCasesData])

  useEffect(() => {
    if (commentsData) {
      // response from fetch comments
      const { getCase } = commentsData
      const comments = getCase?.caseComments ?? []

      const formattedComments = comments
        .map((comment) => ({
          ...comment,
          user: {
            id: partyData?.party?.partyId ?? '',
            name: partyData?.party?.displayName ?? '',
          },
        }))
        .sort((a, b) => {
          //sort by created date
          return moment(a.createdDate).diff(moment(b.createdDate))
        })

      const updatedCases = supportCases.map((supportCase) => {
        if (supportCase.caseNumber === fetchCommentId) {
          return {
            ...supportCase,
            caseComments: formattedComments,
            loadingComments: false,
          }
        }
        return supportCase
      })
      setSupportCases(updatedCases)
      setFetchCommentId(null)
    }
  }, [commentsData])

  const handleFetchCaseComments = async (caseNumber: string) => {
    setFetchCommentId(caseNumber)
    const updatedCases = supportCases.map((supportCase) => {
      if (supportCase.caseNumber === caseNumber) {
        return {
          ...supportCase,
          loadingComments: true,
        }
      }
      return supportCase
    })
    setSupportCases(updatedCases)
  }

  return {
    loading,
    cases: supportCases,
    fetchCaseComments: handleFetchCaseComments,
    handleAddCaseComment,
    addCaseLoading,
  }
}
