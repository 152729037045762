import React, { useMemo } from 'react'

import { AccordionFAQs } from '../../components/faq-question'
import documentsAvailable from '../../components/faq-question/document-text-and-links.json'
import chevronDown from '../../components/header/assets/chevron-down.svg'
import {
  HOME_ASSETS,
  LayoutWithBackground,
} from '../../components/layout-with-background'
import { Modal } from '../../components/modal'
import { Tabs } from '../../components/tabs'
import paths from '../Router/paths'

export const FAQPage = ({ modal }: { modal?: JSX.Element }) => {
  const faqTab = {
    label: 'FAQ',
    className: 'faqs-container',
    component: () => <AccordionFAQs />,
  }

  const documentsListMarkup = documentsAvailable['documentsAvailable'].map(
    (item, index) => {
      return (
        <div className="card" key={index}>
          <section className="card-header">
            <div className="card-header-title">
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                aria-label="more options"
              >
                <h3>{item.title}</h3>
              </a>
              <span className="subtitle">{item.subtitle}</span>
            </div>

            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className="card-header-icon"
              aria-label="more options"
            >
              <span className="icon">
                <img className="widget-icon" src={chevronDown} alt="settings" />
              </span>
            </a>
          </section>
        </div>
      )
    },
  )
  const documentsTab = {
    label: 'Documents',
    className: 'documents-container',
    component: () => (
      <section className="documents-container">{documentsListMarkup}</section>
    ),
  }
  const faqTabs = useMemo(() => [faqTab, documentsTab], [faqTab, documentsTab])
  return (
    <LayoutWithBackground
      title="FAQ AND SOLAR DOCUMENTS"
      asset={HOME_ASSETS.QUESTION}
      className="faq-page"
    >
      <Tabs className="faq-tabs title has-text-centered" tabs={faqTabs} />
      <>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.F_A_Q}>
            {modal}
          </Modal>
        )}
      </>
    </LayoutWithBackground>
  )
}
