import clsx from 'clsx'
import React from 'react'

import googlePlayBadge1x from './assets/play-store-badge1x.png'
import googlePlayBadge2x from './assets/play-store-badge2x.png'

export const PlayStoreBadge = ({
  imageHeight,
  className,
  href = 'https://play.google.com/store/apps/details?id=com.mysunpower',
}: {
  imageHeight: string
  className?: string
  href?: string
}) => {
  return (
    <a
      className={clsx('is-size-5', className)}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={googlePlayBadge1x}
        srcSet={googlePlayBadge1x + ' 1x,' + googlePlayBadge2x + ' 2x'}
        height={imageHeight}
        alt="Download on the Play Store"
      />
    </a>
  )
}
