import './styles.scss'

import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../../components/layout'
import { Loader } from '../../../components/loader'
import { useDynamicVpp } from '../../../shared/hooks/useDynamicVpp'
import { StepType } from '../../../shared/typings/site'
import paths from '../../Router/paths'
import enrollIcon from '../assets/enroll-icon.svg'
import dynamicGrayIcon from '../assets/info-grey-icon.svg'
import { DynamicStep } from '../vpp-components/enrollSteps'
import InfoModal from '../vpp-components/info-modal/info-modal'

type DynamicEnrollProps = {
  isSuccessfullySignedDocRedirect?: boolean
  isViewingSignedDocRedirect?: boolean
  hasDocusignExpiredModal?: boolean
}

export const DynamicEnroll = (props: DynamicEnrollProps) => {
  const history = useHistory()
  const {
    programSteps,
    loadingProgram,
    fetchDocusignURL,
    docusignURL,
    loadingDocusignURL,
    currentStep,
    setCurrentStep,
    handleSubmitApplication,
    handleSignAgreement,
    docusignURLFetched,
  } = useDynamicVpp()

  const [showModal, setShowModal] = useState(props.hasDocusignExpiredModal)

  const handleInfoClick = () => {
    history.push({
      pathname: paths.MARKETING_VPP_DYNAMIC,
      state: {
        onlyInfo: true,
      },
    })
  }

  const handleViewingSignedDocRedirect = () => {
    history.push({
      pathname: paths.VIRTUAL_PROGRAM_ENROLL,
    })
  }

  const { isSuccessfullySignedDocRedirect, isViewingSignedDocRedirect } = props

  const stepsList = useMemo(
    () =>
      programSteps?.map((item, index) => {
        if (
          item.stepType === StepType.SIGNATURE &&
          !item.documentStepIsCompleted
        ) {
          if (isSuccessfullySignedDocRedirect) {
            handleSignAgreement()
          } else {
            if (isViewingSignedDocRedirect) {
              handleViewingSignedDocRedirect()
            } else {
              fetchDocusignURL()
            }
          }
        }

        return (
          <DynamicStep
            key={`step-${index}`}
            {...item}
            docusignURL={docusignURL}
          />
        )
      }),
    [docusignURL, programSteps]
  )

  const loadingInfo = loadingProgram || loadingDocusignURL

  const finishedState =
    programSteps.length < currentStep &&
    docusignURLFetched &&
    (isSuccessfullySignedDocRedirect || isViewingSignedDocRedirect)

  const isReadyToSubmit = useMemo(() => {
    let pendingStep = false
    programSteps.forEach((step) => {
      if (
        (step.stepType === StepType.SIGNATURE &&
          !step.documentStepIsCompleted) ||
        (step.stepType !== StepType.TEXT_FIELD && step.fieldInitialValue === '')
      ) {
        pendingStep = true
      }
    })
    return !pendingStep
  }, [programSteps])

  const handleEdit = () => {
    setCurrentStep(1)
  }

  const handleGoToSubmit = () => {
    const stepsAmount = programSteps.length
    setCurrentStep(stepsAmount + 1)
  }

  return (
    <>
      <Layout
        title='SunPower VPP'
        sectionClassName='section-enroll'
        containerClassName='container-enroll'
      >
        <>
          {loadingInfo && <LoadingState />}
          <div className='enroll-top-section'>
            {!loadingInfo && (
              <div className='enroll-wrapper'>
                <div className='enroll-content-card'>
                  <div className='header-section'>
                    <img src={enrollIcon} alt='enroll icon' />
                    <img
                      src={dynamicGrayIcon}
                      alt='info button'
                      className='info-button'
                      onClick={handleInfoClick}
                    />
                    <b>
                      {finishedState
                        ? 'Application Complete'
                        : 'Application Status'}
                    </b>
                  </div>
                  {finishedState ? (
                    <FinishedState
                      handleEdit={handleEdit}
                      handleSubmit={handleSubmitApplication}
                    />
                  ) : (
                    stepsList
                  )}
                </div>
                {isReadyToSubmit && !finishedState && (
                  <button
                    className='redirect-submit-button'
                    onClick={handleGoToSubmit}
                  >
                    Go to submit
                  </button>
                )}
              </div>
            )}
          </div>
          <div className='enroll-bottom-section'></div>
        </>
      </Layout>
      {showModal && (
        <InfoModal
          title={'Session expired'}
          handleClose={() => setShowModal(false)}
          buttonText='Try again'
          buttonStyle={'cta-modal-button'}
        />
      )}
    </>
  )
}

const FinishedState = ({
  handleEdit,
  handleSubmit,
}: {
  handleEdit: () => void
  handleSubmit: () => void
}) => {
  return (
    <div className='vpp-enroll-finish'>
      <p className='vpp-enroll-finish-info'>
        You completed your inputs. Click below to submit your application for
        review
      </p>
      <div className='vpp-enroll-buttons-container'>
        <button className='vpp-enroll-finish-button edit' onClick={handleEdit}>
          Edit Application
        </button>
        <button className='vpp-enroll-finish-button' onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}

const LoadingState = () => {
  return (
    <div className='vpp-enroll-loader'>
      <p className='vpp-enroll-loader-info'>Loading program steps</p>
      <div>
        <Loader />
      </div>
    </div>
  )
}
