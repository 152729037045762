import './style.scss'

import React from 'react'

import { AppleStoreBadge } from '../apple-store-badge'
import { PlayStoreBadge } from '../play-store-badge'
import experience1x from './assets/experience1x.png'
import experience2x from './assets/experience2x.png'

export const MobileMonitoringExperience = () => {
  return (
    <div className="mobile-monitoring-experience">
      <div className="background">
        <div className="experience-text">
          <p className="is-size-5 is-uppercase">Introducing</p>
          <h2 className="is-size-1 has-text-weight-bold">
            The New
            <br />
            Mobile Monitoring
            <br />
            Experience.
          </h2>
          <div className="app-buttons">
            <AppleStoreBadge className="mr-4" imageHeight="40" />
            <PlayStoreBadge imageHeight="40" />
          </div>
        </div>
        <img
          className="monitoring-experience-image-mobile"
          src={experience1x}
          srcSet={experience1x + ' 1x,' + experience2x + ' 2x'}
          alt="The new Mobile Monitoring experience"
        />
      </div>
      <img
        className="monitoring-experience-image"
        src={experience1x}
        srcSet={experience1x + ' 1x,' + experience2x + ' 2x'}
        alt="The new Mobile Monitoring experience"
      />
    </div>
  )
}
