import './rate.scss'

import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import * as React from 'react'

import activeStarImg from './assets/star_active.svg'
import starImg from './assets/star.svg'

interface Props {
  totalStars: number
  selectedStars: number
  onStarPress: (value: number) => any
  className?: string
}
export const Rate = ({
  totalStars,
  selectedStars,
  onStarPress,
  className = '',
}: Props) => {
  const [overStars, setOverStars] = useState(0)
  const displayActiveStarsCount = Math.max(overStars, selectedStars)

  const stars = useMemo(() => [...Array(totalStars).keys()], [totalStars])

  // this returns a list of callbacks for every star, to avoid having each individual star to be re-rendered
  const handleMouseOverList = useMemo(
    () => stars.map((index) => () => setOverStars(index + 1)),
    [setOverStars],
  )
  const handleMouseLeave = useCallback(() => setOverStars(0), [setOverStars])
  const handleClickList = useMemo(
    () => stars.map((index) => () => onStarPress(index + 1)),
    [onStarPress],
  )

  return (
    <div className={clsx('rate is-flex', className)}>
      {stars.map((index) => (
        <span
          key={`rate-${index}`}
          className="rate-star"
          data-testid={`rate-star-${index + 1}`}
          onMouseOver={handleMouseOverList[index]}
          onMouseLeave={handleMouseLeave}
          onClick={handleClickList[index]}
        >
          <img
            src={index < displayActiveStarsCount ? activeStarImg : starImg}
          />
        </span>
      ))}
    </div>
  )
}
