import './lifeEvents.scss'

import React from 'react'

import {
  HOME_ASSETS,
  LayoutWithBackground,
} from '../../components/layout-with-background'
import { Modal } from '../../components/modal'
import paths from '../Router/paths'
import HomeSaleImg from './assets/home-sale.svg'
import LeaseChangesImg from './assets/lease-changes.svg'
import RefinanceMortageImg from './assets/refinance-mortgage.svg'

export const LifeEventsPage = ({ modal }: { modal?: JSX.Element }) => (
  <LayoutWithBackground
    className="life-events"
    title="Life Events"
    asset={HOME_ASSETS.QUESTION}
  >
    <div className="life-events-content">
      <div className="columns">
        <section className="tile box column">
          <div className="life-events-img-container life-events-color-1">
            <img alt="Refinance " src={RefinanceMortageImg} />
          </div>
          <div className="has-text-weight-bold mt-5">REFINANCE MORTGAGE</div>
          <div className="life-events-description">
            Fill out the request to refinance form if you are refinancing your
            mortgage.
          </div>
          <div className="life-events-mark life-events-mark-color-1" />
        </section>
        <section className="tile box column">
          <div className="life-events-img-container life-events-color-2">
            <img alt="Refinance " src={LeaseChangesImg} />
          </div>
          <div className="has-text-weight-bold mt-5">LEASE CHANGES</div>
          <div className="life-events-description">
            Changes to lease due to military relocation, divorce, one lessee
            passing away, or removing a co-borrower’s information.
          </div>
          <div className="life-events-mark life-events-mark-color-2" />
        </section>
        <section className="tile box column">
          <div className="life-events-img-container life-events-color-3">
            <img alt="Refinance " src={HomeSaleImg} />
          </div>
          <div className="has-text-weight-bold mt-5">HOME SALE</div>
          <div className="life-events-description">
            Changes to current lease due to sale of your home. Must be completed
            prior to final close of sales.
          </div>
          <div className="life-events-mark life-events-mark-color-3" />
        </section>
      </div>
      <div className="is-flex is-flex-centered mb-4 mt-4">
        <a
          href="https://us.sunpower.com/solar-resources/lease-services"
          target="_blank"
          className="button is-primary"
        >
          Go to SunPower.com
        </a>
      </div>
    </div>
    <>
      {modal && (
        <Modal hideTopBar={true} title={''} fromPath={paths.LIFE_EVENTS}>
          {modal}
        </Modal>
      )}
    </>
  </LayoutWithBackground>
)
