import { useCookies } from 'react-cookie'

import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
  MY_SUNPOWER_MONITORING_ID_TOKEN,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../config/cookies'

export const useRemoveCookies = (options = {}) => {
  const [, , removeCookie] = useCookies([
    MY_SUNPOWER_MONITORING_ID_TOKEN,
    MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])

  return () => {
    removeCookie(MY_SUNPOWER_MONITORING_ID_TOKEN, options)
    removeCookie(MY_SUNPOWER_MONITORING_ACCESS_TOKEN, options)
    removeCookie(MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN, options)
    removeCookie(USER_SESSION_INFO, options)
    removeCookie(USER_SELECTED_SITE_KEY, options)
    removeCookie(LOGIN_OVERRIDE, options)
  }
}
