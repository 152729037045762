import React from 'react'
import { Link } from 'react-router-dom'

import paths from '../../Router/paths'
import alertHouseModal from '../assets/alert-modal-house.png'

export const AlertICDModalContents = () => {
  return (
    <div className="alert-modal-contents icd">
      <div className="container has-text-centered">
        <img src={alertHouseModal} alt="Alert ICD" />
        <p className="alert-message is-size-4">
          We've lost communication
          <br />
          with your system
        </p>
        <p className="troubleshooting-guide">
          Check out the{' '}
          {
            <Link to={paths.F_A_Q} className="link_to_faq">
              FAQ
            </Link>
          }{' '}
          page for a troubleshooting guide
        </p>
        <p className="alert-email-message is-size-5 mb-5">
          <span>or email us at:</span>
          <a
            href="mailto:mysunpower@sunpower.com"
            className="support-email is-size-4"
          >
            mysunpower@sunpower.com
          </a>
        </p>
      </div>
    </div>
  )
}
