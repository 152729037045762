export default {
  nm: 'pigeons',
  ddd: 0,
  h: 179,
  w: 320,
  meta: { g: 'LottieFiles AE 0.1.20' },
  layers: [
    {
      ty: 4,
      nm: 'eye21 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [22.807, 11.529, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 39.37, 0],
              t: 0,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 39.37, 0],
              t: 6,
              ti: [0, -0.25, 0],
              to: [0, 1.583, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 48.87, 0],
              t: 9,
              ti: [0, 1.333, 0],
              to: [0, 0.25, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 40.87, 0],
              t: 12,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 40.87, 0],
              t: 40,
              ti: [0, -1.333, 0],
              to: [0, -0.25, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 39.37, 0],
              t: 42,
              ti: [0, -0.25, 0],
              to: [0, 1.333, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 48.87, 0],
              t: 45,
              ti: [0, 1.333, 0],
              to: [0, 0.25, 0],
            },
            { s: [50.764, 40.87, 0], t: 47.9999958454548 },
          ],
          ix: 2,
        },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -12.458],
                    [-9.312, -0.005],
                    [-5.532, -1.534],
                    [6.457, 6.457],
                    [6.229, 0],
                  ],
                  o: [
                    [5.546, -1.538],
                    [9.269, 0.005],
                    [0, -1.502],
                    [-4.082, -4.082],
                    [-12.458, 0],
                  ],
                  v: [
                    [-22.557, 11.278],
                    [0.045, 8.209],
                    [22.557, 11.278],
                    [15.951, -4.671],
                    [0, -11.278],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.5412, 0.6706, 0.9294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [23.178, 12.01], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 1,
      parent: 4,
    },
    {
      ty: 4,
      nm: 'eye22 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [22.807, 11.529, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 95.938, 0],
              t: 0,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 95.938, 0],
              t: 6,
              ti: [0, -0.25, 0],
              to: [0, -3.75, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 73.438, 0],
              t: 9,
              ti: [0, -4, 0],
              to: [0, 0.25, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 97.438, 0],
              t: 12,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 97.438, 0],
              t: 40,
              ti: [0, 4, 0],
              to: [0, -0.25, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 95.938, 0],
              t: 42,
              ti: [0, -0.25, 0],
              to: [0, -4, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [50.764, 73.438, 0],
              t: 45,
              ti: [0, -4, 0],
              to: [0, 0.25, 0],
            },
            { s: [50.764, 97.438, 0], t: 47.9999958454548 },
          ],
          ix: 2,
        },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 12.459],
                    [0, 0],
                    [-12.458, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 12.459],
                    [12.459, 0],
                  ],
                  v: [
                    [22.557, -11.279],
                    [-22.557, -11.279],
                    [-0.001, 11.279],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.5412, 0.6706, 0.9294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [23.178, 12.3], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 2,
      parent: 4,
    },
    {
      ty: 4,
      nm: 'tail2 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [20.635, 13.592, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [201.457, 20.318, 0],
              t: 0,
              ti: [0, 0, 0],
              to: [0.583, 1.583, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [204.957, 29.818, 0],
              t: 3,
              ti: [0.583, 1.583, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [201.457, 20.318, 0],
              t: 6,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [201.457, 20.318, 0],
              t: 24,
              ti: [0, 0, 0],
              to: [0.583, 1.583, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [204.957, 29.818, 0],
              t: 27,
              ti: [0.583, 1.583, 0],
              to: [0, 0, 0],
            },
            { s: [201.457, 20.318, 0], t: 29.9999974034093 },
          ],
          ix: 2,
        },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 0,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [40],
              t: 3,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 6,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 24,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [40],
              t: 27,
            },
            { s: [0], t: 29.9999974034093 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-11.458, 0.599],
                    [0, 0],
                    [-2.12, -1.182],
                    [0.677, -5.076],
                    [-0.013, 0.048],
                    [-3.151, -0.115],
                    [-1.281, -1.13],
                    [5.28, -2.667],
                    [0, 0],
                    [0.554, -0.321],
                    [0.013, 0.018],
                    [0.721, 1.045],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.794, 1],
                    [-0.007, 0.051],
                    [1.394, -5.001],
                    [1.306, 0.048],
                    [9.175, 8.096],
                    [-0.001, 0],
                    [-0.555, 0.321],
                    [-0.02, 0.011],
                    [-0.721, -1.045],
                    [-4.172, -6.435],
                  ],
                  v: [
                    [-8.927, -13.343],
                    [-8.927, -13.343],
                    [-4.258, -12.161],
                    [-1.182, -2.092],
                    [-1.106, -2.075],
                    [6.959, -10.128],
                    [11.211, -8.343],
                    [-2.799, 12.367],
                    [-2.802, 12.368],
                    [-4.464, 13.332],
                    [-4.52, 13.319],
                    [-6.682, 10.183],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.5412, 0.6706, 0.9294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [20.83, 14.337], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 3,
      parent: 5,
    },
    {
      ty: 4,
      nm: 'Слой 14 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [52.204, 68.328, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [47.245, -65.329, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -8.81],
                    [-8.808, 0],
                    [0, 8.809],
                    [8.81, 0],
                  ],
                  o: [
                    [0, 8.809],
                    [8.81, 0],
                    [0, -8.81],
                    [-8.808, 0],
                  ],
                  v: [
                    [-15.95, 0],
                    [-0.001, 15.951],
                    [15.95, 0],
                    [-0.001, -15.951],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.1137, 0.1137, 0.1059], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [51.178, 61.516], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 2',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -12.458],
                    [-12.458, 0],
                    [0, 12.458],
                    [12.459, 0],
                  ],
                  o: [
                    [0, 12.458],
                    [12.459, 0],
                    [0, -12.458],
                    [-12.458, 0],
                  ],
                  v: [
                    [-22.557, 0],
                    [0, 22.558],
                    [22.557, 0],
                    [0, -22.558],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [51.178, 61.516], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 3',
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.388, -1.022],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.319, 1.158],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [10.123, -8.464],
                    [-6.804, -2.567],
                    [-6.615, 3.415],
                    [10.123, 8.464],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [11.284, 83.947], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 4',
          ix: 4,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [22.828, 0],
                    [0, -22.828],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, -22.828],
                    [-22.828, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [41.334, -26.245],
                    [0, -67.579],
                    [-41.334, -26.245],
                    [-41.334, 67.579],
                    [41.334, 67.579],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.5412, 0.6706, 0.9294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [62.74, 68.689], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 4,
      parent: 5,
    },
    {
      ty: 4,
      nm: 'body2 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [101.358, 66.782, 0], ix: 1 },
        s: { a: 0, k: [51.56, 51.56, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [235.731, 118.191, 0],
              t: 0,
              ti: [0, -0.5, 0],
              to: [0, 1.333, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [235.731, 126.191, 0],
              t: 3,
              ti: [0, 0.833, 0],
              to: [0, 0.5, 0],
            },
            { s: [235.731, 121.191, 0], t: 5.99999948068185 },
          ],
          ix: 2,
        },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-7.48, -3.058],
                    [0.312, -3.913],
                    [33.048, 3.041],
                    [7.673, 6.631],
                    [-5.317, 12.696],
                    [-6.3, 2.498],
                  ],
                  o: [
                    [49.759, 18.709],
                    [-1.379, 17.315],
                    [-7.678, -0.707],
                    [-9.317, -8.054],
                    [4.175, -9.969],
                    [20.882, -8.281],
                  ],
                  v: [
                    [11.602, -26.342],
                    [62.524, 9.362],
                    [-21.961, 33.364],
                    [-46.284, 24.426],
                    [-57.519, -11.654],
                    [-36.951, -28.124],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6431, 0.7804, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [103.115, 75.122], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 2',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.063, 1.032],
                    [27.196, 5.665],
                    [5.384, 2.123],
                    [0.114, -1.347],
                    [-16.959, -0.259],
                    [-9.096, 3.596],
                  ],
                  o: [
                    [-13.823, 4.882],
                    [-6.724, -1.4],
                    [-0.113, 1.347],
                    [8.635, 3.291],
                    [19.52, 0.298],
                    [-0.063, -1.033],
                  ],
                  v: [
                    [41.395, -5.52],
                    [-23.067, -0.493],
                    [-41.243, -5.933],
                    [-41.584, -1.892],
                    [-2.439, 5.635],
                    [41.584, -2.422],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [57.614, 19.548], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 3',
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.31, 5.341],
                    [0.566, -0.328],
                    [0.729, 1.056],
                    [14.783, -3.896],
                    [10.069, -0.675],
                    [10.262, 10.792],
                    [1.447, 9.22],
                    [0.037, 0.271],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.025, -0.615],
                    [0.046, -0.571],
                    [3.984, -6.473],
                    [0, -10.49],
                    [-20.96, -7.658],
                    [-0.012, -0.004],
                    [-1.333, -0.374],
                    [-41.132, 18.897],
                    [-13.195, 30.815],
                  ],
                  o: [
                    [-0.565, 0.328],
                    [-0.729, -1.056],
                    [-4.096, -0.046],
                    [-4.589, 1.21],
                    [-12.671, 0.848],
                    [-2.237, -2.352],
                    [-0.041, -0.264],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.574],
                    [-0.026, 0.613],
                    [-0.598, 7.532],
                    [-5.124, 8.321],
                    [0, 23.612],
                    [0.011, 0.004],
                    [1.289, 0.47],
                    [20.082, 6.153],
                    [8.279, -3.803],
                    [5.135, -11.991],
                  ],
                  v: [
                    [97.299, -34.097],
                    [95.601, -33.113],
                    [93.415, -36.281],
                    [68.111, -28.34],
                    [44.926, -24.247],
                    [5.075, -33.058],
                    [-2.532, -49.674],
                    [-2.657, -50.465],
                    [-2.657, -66.533],
                    [-85.325, -66.533],
                    [-85.325, -54.885],
                    [-85.361, -53.1],
                    [-85.471, -51.324],
                    [-92.525, -30.049],
                    [-100.609, -1.444],
                    [-64.687, 49.943],
                    [-64.649, 49.954],
                    [-60.71, 51.208],
                    [42.48, 47.635],
                    [92.625, -2.229],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.5412, 0.6706, 0.9294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [101.731, 66.8], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 5,
    },
    {
      ty: 4,
      nm: 'wing2 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [64.627, 5.749, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [34.425, 41.348, 0],
              t: 0,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [34.425, 41.348, 0],
              t: 2,
              ti: [2, 0, 0],
              to: [-2, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [22.425, 41.348, 0],
              t: 5,
              ti: [2, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [22.425, 41.348, 0],
              t: 7,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [27.425, 41.348, 0],
              t: 8,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [22.425, 41.348, 0],
              t: 11,
              ti: [-2.25, 0.083, 0],
              to: [2.25, -0.083, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [35.925, 40.848, 0],
              t: 15,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [35.925, 40.848, 0],
              t: 18,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [35.925, 40.848, 0],
              t: 20,
              ti: [1.583, 0, 0],
              to: [-1.583, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [26.425, 40.848, 0],
              t: 21,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [26.425, 40.848, 0],
              t: 22,
              ti: [0.083, -1.083, 0],
              to: [-0.083, 1.083, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [25.925, 47.348, 0],
              t: 25,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [25.925, 47.348, 0],
              t: 27,
              ti: [-0.333, 1.833, 0],
              to: [0.333, -1.833, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [27.925, 36.348, 0],
              t: 30,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [27.925, 36.348, 0],
              t: 32,
              ti: [-4.333, -2.5, 0],
              to: [4.333, 2.5, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.925, 51.348, 0],
              t: 35,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.925, 51.348, 0],
              t: 37,
              ti: [2, 0, 0],
              to: [-3.5, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [32.925, 51.348, 0],
              t: 40,
              ti: [0, 0, 0],
              to: [-2, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [41.925, 51.348, 0],
              t: 42,
              ti: [1.5, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [32.925, 51.348, 0],
              t: 44,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [32.925, 51.348, 0],
              t: 46,
              ti: [0, 2.667, 0],
              to: [0, -2.667, 0],
            },
            { s: [32.925, 35.348, 0], t: 49.9999956723488 },
          ],
          ix: 2,
        },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 0,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [85],
              t: 2,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [85],
              t: 5,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [85],
              t: 7,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [85],
              t: 11,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 15,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 18,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [61],
              t: 21,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [61],
              t: 22,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [37],
              t: 25,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [37],
              t: 27,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [68],
              t: 30,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [68],
              t: 32,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [43],
              t: 35,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [43],
              t: 37,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [43],
              t: 46,
            },
            { s: [0], t: 49.9999956723488 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [4.833, -3.022],
                    [1.935, 1.789],
                    [-15.816, 17.664],
                    [-6.805, 1.661],
                    [-4.426, -8.27],
                    [1.388, -4.633],
                  ],
                  o: [
                    [-31.283, 20.779],
                    [-8.559, -7.917],
                    [3.675, -4.104],
                    [8.262, -2.018],
                    [3.475, 6.493],
                    [-4.601, 15.362],
                  ],
                  v: [
                    [11.949, 17.635],
                    [-28.818, 32.084],
                    [-5.182, -26.154],
                    [10, -36.396],
                    [33.902, -27.974],
                    [33.766, -9.423],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6431, 0.7804, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [38.298, 39.367], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 6,
      parent: 5,
    },
    {
      ty: 4,
      nm: 'legs2 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [30.949, 35.53, 0], ix: 1 },
        s: { a: 0, k: [51.56, 51.56, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [219.383, 154.97, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [1.071, 2.387],
                    [1.935, 1.136],
                  ],
                  o: [
                    [-1.072, -2.387],
                    [-1.934, -1.136],
                    [0, 0],
                  ],
                  v: [
                    [4.51, 5.285],
                    [1.295, -1.876],
                    [-4.51, -5.285],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [17.854, 48.17], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 2',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [1.015, 2.657],
                    [1.887, 1.119],
                  ],
                  o: [
                    [-1.016, -2.656],
                    [-1.887, -1.119],
                    [0, 0],
                  ],
                  v: [
                    [4.353, 5.663],
                    [1.307, -2.307],
                    [-4.353, -5.663],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [47.48, 55.447], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 3',
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.109, -20.509],
                    [4.109, 15.734],
                    [-4.109, 20.509],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [15.041, 30.56], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 4',
          ix: 4,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.456, -22.908],
                    [4.456, 18.504],
                    [-4.456, 22.908],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [44.333, 34.636], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 7,
    },
    {
      ty: 4,
      nm: 'eye1 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [23.303, 11.529, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 41.872, 0],
              t: 0,
              ti: [0, 0.083, 0],
              to: [0, 1, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 47.872, 0],
              t: 3,
              ti: [0, 1.083, 0],
              to: [0, -0.083, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 41.372, 0],
              t: 6,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 41.372, 0],
              t: 28,
              ti: [0, -1.083, 0],
              to: [0, 0.083, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 41.872, 0],
              t: 30,
              ti: [0, 0.083, 0],
              to: [0, 1.083, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 47.872, 0],
              t: 33,
              ti: [0, 1.083, 0],
              to: [0, -0.083, 0],
            },
            { s: [53.145, 41.372, 0], t: 35.9999968840911 },
          ],
          ix: 2,
        },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -12.458],
                    [10.304, 0.093],
                    [4.037, -2.01],
                    [-6.457, 6.457],
                    [-6.229, 0],
                  ],
                  o: [
                    [-4.002, -1.992],
                    [-10.807, -0.096],
                    [0, -1.503],
                    [4.082, -4.082],
                    [12.459, 0],
                  ],
                  v: [
                    [22.558, 11.279],
                    [0.454, 5.971],
                    [-22.558, 11.279],
                    [-15.951, -4.672],
                    [-0.001, -11.279],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6431, 0.7804, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [23.391, 11.583], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 8,
      parent: 11,
    },
    {
      ty: 4,
      nm: 'eye2 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [23.303, 11.529, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 95.441, 0],
              t: 0,
              ti: [0, -0.417, 0],
              to: [0, -3.5, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 74.441, 0],
              t: 3,
              ti: [0, -3.5, 0],
              to: [0, 0.417, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 97.941, 0],
              t: 6,
              ti: [0, 3.917, 0],
              to: [0, 3.5, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 95.441, 0],
              t: 30,
              ti: [0, -0.417, 0],
              to: [0, -3.917, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [53.145, 74.441, 0],
              t: 33,
              ti: [0, -3.917, 0],
              to: [0, 0.417, 0],
            },
            { s: [53.145, 97.941, 0], t: 35.9999968840911 },
          ],
          ix: 2,
        },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 12.458],
                    [0, 0],
                    [12.459, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 12.458],
                    [-12.458, 0],
                  ],
                  v: [
                    [-22.557, -11.279],
                    [22.557, -11.279],
                    [-0.001, 11.279],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6431, 0.7804, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [23.391, 11.906], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 9,
      parent: 11,
    },
    {
      ty: 4,
      nm: 'tail1 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [20.635, 13.592, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1.258, 15.839, 0],
              t: 0,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1.258, 15.839, 0],
              t: 8,
              ti: [0, 0, 0],
              to: [-0.583, 1.667, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-2.242, 25.839, 0],
              t: 11,
              ti: [-0.583, 1.667, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1.258, 15.839, 0],
              t: 14,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1.258, 15.839, 0],
              t: 36,
              ti: [0, 0, 0],
              to: [-0.583, 1.667, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-2.242, 25.839, 0],
              t: 39,
              ti: [-0.583, 1.667, 0],
              to: [0, 0, 0],
            },
            { s: [1.258, 15.839, 0], t: 41.999996364773 },
          ],
          ix: 2,
        },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 8,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-42],
              t: 11,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 14,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 36,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-42],
              t: 39,
            },
            { s: [0], t: 41.999996364773 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [11.458, 0.599],
                    [0, 0],
                    [2.12, -1.182],
                    [-0.677, -5.076],
                    [0.013, 0.048],
                    [3.151, -0.115],
                    [1.281, -1.13],
                    [-5.281, -2.667],
                    [0, 0],
                    [-0.554, -0.321],
                    [-0.013, 0.018],
                    [-0.721, 1.045],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-1.794, 1],
                    [0.007, 0.051],
                    [-1.395, -5.001],
                    [-1.307, 0.048],
                    [-9.175, 8.096],
                    [0.001, 0],
                    [0.554, 0.321],
                    [0.019, 0.011],
                    [0.721, -1.045],
                    [4.171, -6.435],
                  ],
                  v: [
                    [8.927, -13.343],
                    [8.927, -13.343],
                    [4.258, -12.161],
                    [1.182, -2.092],
                    [1.106, -2.075],
                    [-6.959, -10.128],
                    [-11.21, -8.343],
                    [2.8, 12.367],
                    [2.802, 12.368],
                    [4.464, 13.332],
                    [4.52, 13.319],
                    [6.683, 10.183],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6431, 0.7804, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [20.739, 14.337], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 10,
      parent: 12,
    },
    {
      ty: 4,
      nm: 'head1 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [51.706, 70.069, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [155.471, -65.964, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -8.809],
                    [8.81, 0],
                    [0, 8.81],
                    [-8.809, 0],
                  ],
                  o: [
                    [0, 8.81],
                    [-8.809, 0],
                    [0, -8.809],
                    [8.81, 0],
                  ],
                  v: [
                    [15.951, -0.001],
                    [-0.001, 15.951],
                    [-15.951, -0.001],
                    [-0.001, -15.951],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.1137, 0.1137, 0.1059], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [53.391, 61.121], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 2',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -12.458],
                    [12.459, 0],
                    [0, 12.459],
                    [-12.458, 0],
                  ],
                  o: [
                    [0, 12.459],
                    [-12.458, 0],
                    [0, -12.458],
                    [12.459, 0],
                  ],
                  v: [
                    [22.558, -0.001],
                    [-0.001, 22.557],
                    [-22.558, -0.001],
                    [-0.001, -22.557],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [53.391, 61.121], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 3',
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [3.388, -1.022],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [3.319, 1.157],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.122, -8.464],
                    [6.803, -2.567],
                    [6.615, 3.415],
                    [-10.122, 8.464],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [93.285, 83.553], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 4',
          ix: 4,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [22.827, 0],
                    [0, 0],
                    [0, -22.827],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, -22.827],
                    [0, 0],
                    [-22.828, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [41.334, -28.486],
                    [0.001, -69.819],
                    [-0.001, -69.819],
                    [-41.334, -28.486],
                    [-41.334, 69.819],
                    [41.334, 69.819],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6431, 0.7804, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [41.829, 70.535], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 11,
      parent: 12,
    },
    {
      ty: 4,
      nm: 'body1 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [101.358, 64.542, 0], ix: 1 },
        s: { a: 0, k: [51.56, 51.56, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [84.839, 120.431, 0],
              t: 6,
              ti: [0, 0.167, 0],
              to: [0, 1, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [84.839, 126.431, 0],
              t: 9,
              ti: [0, 1.167, 0],
              to: [0, -0.167, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [84.839, 119.431, 0],
              t: 12,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            { s: [84.839, 119.431, 0], t: 34.9999969706441 },
          ],
          ix: 2,
        },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 35,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-9],
              t: 38,
            },
            { s: [0], t: 40.999996451326 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.064, 1.032],
                    [-27.197, 5.665],
                    [-5.383, 2.123],
                    [-0.114, -1.347],
                    [16.96, -0.259],
                    [9.097, 3.596],
                  ],
                  o: [
                    [13.823, 4.882],
                    [6.723, -1.4],
                    [0.114, 1.347],
                    [-8.636, 3.291],
                    [-19.519, 0.298],
                    [0.064, -1.033],
                  ],
                  v: [
                    [-41.394, -5.52],
                    [23.068, -0.493],
                    [41.243, -5.933],
                    [41.585, -1.892],
                    [2.439, 5.635],
                    [-41.585, -2.422],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [145.954, 15.548], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 2',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [7.48, -3.058],
                    [-0.312, -3.913],
                    [-33.048, 3.041],
                    [-7.673, 6.631],
                    [5.317, 12.696],
                    [6.3, 2.498],
                  ],
                  o: [
                    [-49.759, 18.709],
                    [1.38, 17.315],
                    [7.678, -0.707],
                    [9.318, -8.054],
                    [-4.174, -9.969],
                    [-20.882, -8.281],
                  ],
                  v: [
                    [-11.602, -26.342],
                    [-62.524, 9.362],
                    [21.961, 33.364],
                    [46.284, 24.426],
                    [57.519, -11.654],
                    [36.951, -28.124],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.5412, 0.6706, 0.9294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [100.454, 71.122], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 3',
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 23.611],
                    [5.124, 8.321],
                    [0.598, 7.531],
                    [0.025, 0.613],
                    [0.001, 0.574],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.041, -0.264],
                    [2.237, -2.353],
                    [12.671, 0.849],
                    [4.59, 1.209],
                    [4.095, -0.047],
                    [0.729, -1.057],
                    [0, 0],
                    [-5.135, -11.991],
                    [-8.28, -3.804],
                    [-20.081, 6.154],
                    [-1.289, 0.471],
                    [-0.012, 0.004],
                  ],
                  o: [
                    [0, -10.49],
                    [-3.985, -6.473],
                    [-0.046, -0.571],
                    [-0.025, -0.615],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.037, 0.271],
                    [-1.446, 9.219],
                    [-10.261, 10.792],
                    [-10.07, -0.674],
                    [-14.782, -3.896],
                    [-0.729, 1.056],
                    [0, 0],
                    [-3.31, 5.341],
                    [13.195, 30.814],
                    [41.132, 18.897],
                    [1.333, -0.374],
                    [0.012, -0.003],
                    [20.96, -7.658],
                  ],
                  v: [
                    [100.609, -3.684],
                    [92.525, -32.289],
                    [85.469, -53.563],
                    [85.361, -55.339],
                    [85.324, -57.124],
                    [85.324, -64.292],
                    [2.656, -64.292],
                    [2.656, -52.704],
                    [2.53, -51.914],
                    [-5.076, -35.298],
                    [-44.926, -26.488],
                    [-68.113, -30.579],
                    [-93.417, -38.52],
                    [-95.603, -35.352],
                    [-97.299, -36.337],
                    [-92.626, -4.469],
                    [-42.48, 45.396],
                    [60.71, 48.967],
                    [64.65, 47.714],
                    [64.686, 47.702],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6431, 0.7804, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [101.839, 65.041], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 12,
    },
    {
      ty: 4,
      nm: 'wing1 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [14.628, 8.737, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [169.791, 37.368, 0],
              t: 0,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [169.791, 37.368, 0],
              t: 2,
              ti: [-1.667, 0, 0],
              to: [1.667, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [179.791, 37.368, 0],
              t: 5,
              ti: [-1.667, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [179.791, 37.368, 0],
              t: 7,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [174.791, 37.368, 0],
              t: 8,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [179.791, 37.368, 0],
              t: 11,
              ti: [1.917, -0.25, 0],
              to: [-1.917, 0.25, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [168.291, 38.868, 0],
              t: 15,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [168.291, 38.868, 0],
              t: 18,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [168.291, 38.868, 0],
              t: 20,
              ti: [-1, 0, 0],
              to: [1, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [174.291, 38.868, 0],
              t: 21,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [174.291, 38.868, 0],
              t: 22,
              ti: [0, 1.75, 0],
              to: [0, -1.75, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [174.291, 28.368, 0],
              t: 25,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [174.291, 28.368, 0],
              t: 27,
              ti: [0.667, -3.167, 0],
              to: [-0.667, 3.167, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [170.291, 47.368, 0],
              t: 30,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [170.291, 47.368, 0],
              t: 32,
              ti: [3.167, 0.167, 0],
              to: [2.975, -1.136, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [188.141, 40.55, 0],
              t: 35,
              ti: [2.808, -0.97, 0],
              to: [-3.167, -0.167, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [151.291, 46.368, 0],
              t: 37,
              ti: [-1.167, 0, 0],
              to: [-2.808, 0.97, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [171.291, 46.368, 0],
              t: 40,
              ti: [0, 0, 0],
              to: [1.167, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [158.291, 46.368, 0],
              t: 42,
              ti: [-2.167, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [171.291, 46.368, 0],
              t: 44,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [171.291, 46.368, 0],
              t: 46,
              ti: [0, 1.667, 0],
              to: [0, -1.667, 0],
            },
            { s: [171.291, 36.368, 0], t: 49.9999956723488 },
          ],
          ix: 2,
        },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 0,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-85],
              t: 2,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-85],
              t: 5,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-85],
              t: 7,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-85],
              t: 11,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 15,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 18,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-36],
              t: 21,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-36],
              t: 22,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-56],
              t: 25,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-56],
              t: 27,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-14],
              t: 30,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-14],
              t: 32,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-39],
              t: 35,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-39],
              t: 37,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-39],
              t: 46,
            },
            { s: [0], t: 49.9999956723488 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-4.832, -3.022],
                    [-1.934, 1.789],
                    [15.817, 17.664],
                    [6.804, 1.661],
                    [4.426, -8.27],
                    [-1.389, -4.633],
                  ],
                  o: [
                    [31.284, 20.779],
                    [8.56, -7.917],
                    [-3.674, -4.104],
                    [-8.263, -2.018],
                    [-3.474, 6.493],
                    [4.601, 15.362],
                  ],
                  v: [
                    [-11.949, 17.635],
                    [28.818, 32.084],
                    [5.182, -26.154],
                    [-10.001, -36.396],
                    [-33.904, -27.974],
                    [-33.766, -9.423],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.5412, 0.6706, 0.9294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [38.272, 39.367], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 13,
      parent: 12,
    },
    {
      ty: 4,
      nm: 'legs1 Outlines',
      sr: 1,
      st: 0,
      op: 719.999937681822,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [30.949, 35.53, 0], ix: 1 },
        s: { a: 0, k: [51.56, 51.56, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [103.186, 153.97, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-1.071, 2.387],
                    [-1.935, 1.136],
                  ],
                  o: [
                    [1.072, -2.387],
                    [1.935, -1.136],
                    [0, 0],
                  ],
                  v: [
                    [-4.51, 5.285],
                    [-1.296, -1.876],
                    [4.51, -5.285],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [44.715, 48.17], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 2',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-1.014, 2.657],
                    [-1.886, 1.119],
                  ],
                  o: [
                    [1.015, -2.656],
                    [1.887, -1.119],
                    [0, 0],
                  ],
                  v: [
                    [-4.352, 5.663],
                    [-1.308, -2.307],
                    [4.352, -5.663],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [15.088, 55.447], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 3',
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.109, -20.509],
                    [-4.109, 15.734],
                    [4.109, 20.509],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [47.528, 30.56], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 4',
          ix: 4,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.456, -22.908],
                    [-4.456, 18.504],
                    [4.456, 22.908],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              c: { a: 0, k: [0.9882, 0.4, 0], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [18.236, 34.636], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 14,
    },
  ],
  v: '5.5.7',
  fr: 23.9759979248047,
  op: 50.9999955857958,
  ip: 0,
  assets: [],
}
