import './calendar.scss'

import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar'
import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'

const defaultOpts = {
  showHeader: false,
  displayMode: 'inline',
  showTodayButton: false,
  showClearButton: false,
  color: 'black',
}

export const Calendar = ({
  onChange = (v) => {},
  options = {},
  right = false,
  className = '',
}) => {
  const input = useRef(null)

  const onSelect = React.useCallback(
    (datepicker) => {
      onChange({
        startDate: datepicker.data.date.start,
        endDate: datepicker.data.date.end,
      })
    },
    [onChange],
  )

  useEffect(() => {
    const customize: any = {
      ...defaultOpts,
      ...options,
    }
    // Dates used in the calendar are in the local timezone (to keep bulma-calendar compatible)
    // Dates used outside the calendar are in the site's timezone
    customize.startDate = new Date(customize.startDate).valueOf()
    customize.endDate = new Date(customize.endDate).valueOf()

    if (customize.startDate > customize.endDate) {
      const tmpStartDate = customize.startDate
      customize.startDate = customize.endDate
      customize.endDate = tmpStartDate
    }

    // Start and end Date should be on date format on new version
    customize.startDate = new Date(customize.startDate)
    customize.endDate = new Date(customize.endDate)

    const calendar = bulmaCalendar.attach(input.current, customize)[0]
    calendar.on.bind(calendar.datePicker)('select', onSelect)
  }, [input, onSelect, options, right])

  const classes = clsx('calendar', className, {
    right,
  })

  return (
    <div className={classes}>
      <input
        data-testid="date-input"
        className="is-invisible"
        type="date"
        ref={input}
      />
    </div>
  )
}
