import './styles.scss'

import React from 'react'

import CloseIcon from '../../assets/modal-close-icon.svg'
import ModalIcon from '../../assets/modal-icon.svg'

type InfoModalProps = {
  title: string
  body?: string
  handleClose: () => void
  buttonText: string
  buttonStyle?: string
}

const InfoModal = ({
  title,
  body,
  handleClose,
  buttonText,
  buttonStyle,
}: InfoModalProps) => {
  return (
    <div className="modal-wrapper">
      <div className="modal-content">
        <img
          src={CloseIcon}
          alt="icon close"
          className="close-button"
          onClick={handleClose}
        />
        <img src={ModalIcon} alt="sunpower info" className="house-icon" />
        <h2 className="modal-title">{title}</h2>
        {body && <p className="modal-body">{body}</p>}
        <button
          className={buttonStyle ? buttonStyle : 'main-button'}
          onClick={handleClose}
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}

export default InfoModal
