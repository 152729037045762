import './style.scss'

import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import OutsideClickHandler from 'react-outside-click-handler'
import { useHistory } from 'react-router-dom'

import calendarIcon from '../../components/filter-bar/assets/calendar.svg'
import { Loader } from '../../components/loader'
import closeIcon from '../../components/modal/assets/close-icon-grey.svg'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { validateEmail } from '../../shared/validations'
import { FETCH_PARTY_INFO } from '../Profile/query'
import paths from '../Router/paths'
import emailIcon from './assets/emailIcon.svg'
import reportIcon from './assets/report-icon.svg'
import reportSaved from './assets/report-saved.svg'
import reportLoading from './assets/reports-loading.gif'
import { DayPicker } from './dayPicker'
import { UPDATE_PERFORMANCE_REPORT } from './query'

export const DATE_STRING_FORMAT = 'Do'
export const ReportsSetupModal = ({}) => {
  const [toggleReports, setToggleReports] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [showFailedSaved, setShowFailedSaved] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showSuccessSaved, setShowSuccessSaved] = useState(false)
  const [enableSaveReport, setEnableSaveReport] = useState(false)
  const [dates, setDates] = useState({
    start: 1,
  })
  const [recipientEmail, setRecipientEmail] = useState('')
  const [reportEmails, setReportEmails] = useState([] as string[])
  const [cookies] = useCookies([
    LOGIN_OVERRIDE,
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
  ])
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const session = cookies[USER_SESSION_INFO] ?? {}
  const loginOverride = cookies[LOGIN_OVERRIDE]

  const history = useHistory()
  const { data, loading } = useQuery(FETCH_PARTY_INFO, {
    fetchPolicy: 'cache-first',
    variables: {
      partyId: loginOverride ?? session.partyId,
      siteKey: cookies[USER_SELECTED_SITE_KEY],
    },
  })
  const { party = {} } = data ?? {}
  const { email = '' } = party
  const selectedSite = useMemo(
    () => (party?.sites ?? []).find((site) => site.siteKey === selectedSiteKey),
    [party, selectedSiteKey],
  )
  const [
    updatePerformanceReport,
    { data: performanceReportData, loading: performanceReportLoading, error },
  ] = useMutation(UPDATE_PERFORMANCE_REPORT, {
    refetchQueries: [
      {
        query: FETCH_PARTY_INFO,
        variables: { partyId: loginOverride ?? session.partyId },
      },
    ],
  })
  useEffect(() => {
    if (error) {
      setShowFailedSaved(true)
    }
  }, [performanceReportData, performanceReportLoading, error, history])

  useEffect(() => {
    if (performanceReportData && !error) {
      setShowSuccessSaved(true)
      setTimeout(() => {
        setShowSuccessSaved(false)
        history.push(paths.PROFILE)
      }, 1500)
    }
  }, [performanceReportData, performanceReportLoading, error, history])

  useEffect(() => {
    if (selectedSite && selectedSite.performanceReport) {
      setToggleReports(true)
      setShowFailedSaved(false)
      const {
        nextRun = moment().valueOf(),
        subscribers = [],
      } = selectedSite.performanceReport
      const subscriberEmailList = subscribers
        .filter((item) => item.statusIndicator !== 'INACTIVE')
        .map((item) => item.email)
      setDates({
        start: parseInt(moment(nextRun.split('T').shift()).format('D')),
      })
      setReportEmails(subscriberEmailList)
    }
  }, [selectedSite])
  const toggleReportsHandler = () => {
    setEnableSaveReport(true)
    setToggleReports(!toggleReports)
  }
  const showCalendarHandler = () => {
    setShowCalendar(true)
  }
  const handleReciptientEmail = (e) => {
    setRecipientEmail(e.target.value)
    setEmailError('')
  }
  const handleAddEmailToReport = () => {
    if (validateEmail(recipientEmail) && recipientEmail !== email) {
      const updateEmails = [...reportEmails, recipientEmail]
      setReportEmails(updateEmails)
      setRecipientEmail('')
      setEmailError('')
      setEnableSaveReport(true)
    } else {
      setEmailError('Please enter a valid email address')
    }
  }
  const handleDeleteRecipientEmail = (filterOutIdx) => {
    const updatedEmails = reportEmails.filter((email, index) => {
      return filterOutIdx !== index
    })
    setReportEmails(updatedEmails)
    setEnableSaveReport(true)
  }

  const onDaySelect = useCallback(
    (daySelected) => {
      setDates({
        start: daySelected,
      })
      setEnableSaveReport(true)
      setShowCalendar(false)
    },
    [setDates, setShowCalendar],
  )
  const handleSaveReport = useCallback(() => {
    // this is using data structures to remove duplicates
    const updatedReportEmails = Array.from(new Set([email, ...reportEmails]))
    updatePerformanceReport({
      variables: {
        params: {
          siteKey: selectedSiteKey,
          fileFormat: 'PDF',
          isActive: toggleReports,
          subscriberList: updatedReportEmails,
          scheduleDay: dates.start,
        },
      },
    })
    setEnableSaveReport(false)
  }, [
    reportEmails,
    toggleReports,
    dates,
    updatePerformanceReport,
    setEnableSaveReport,
  ])
  const displayedReportsDeliveryDate = `${moment(dates.start, 'D').format(
    'Do',
  )}`
  const datePickerContainerMarkup = (
    <div className="date-picker-container pb-6 pt-6">
      <h5 className="is-size-5 has-text-weight-bold mb-3">
        Which day of the month do you want to receive your monthly report?
      </h5>
      <label className="reports-delivery-display" onClick={showCalendarHandler}>
        <img className="calendar-icon" src={calendarIcon} alt="calendar icon" />
        <input
          className="input"
          type="text"
          value={displayedReportsDeliveryDate}
          readOnly
        />
      </label>

      {showCalendar && (
        <OutsideClickHandler onOutsideClick={() => setShowCalendar(false)}>
          <DayPicker onChange={onDaySelect} daySelected={dates.start} />
        </OutsideClickHandler>
      )}
    </div>
  )
  const recipientEmailListMarkup = reportEmails.map((recipientEmail, index) => {
    return (
      recipientEmail !== email && (
        <li key={`recipient-email-${index}`} className="recipient-email mt-2">
          <h6 title={recipientEmail} className="ml-3">
            {recipientEmail}
          </h6>
          <img
            onClick={(e) => handleDeleteRecipientEmail(index)}
            src={closeIcon}
            alt="delete recipient email"
          />
        </li>
      )
    )
  })
  const emailErrorMessageMarkup =
    emailError.length > 0 ? (
      <h4 className="is-4 mt-1 mb-3 email-error-message">{emailError}</h4>
    ) : null
  const emailListContainerMarkup = (
    <section className="email-list-container mt-6">
      <h4 className="is-size-5 has-text-weight-light mb-2">
        Your monthly report will be sent to:
      </h4>
      <h5 className="is-size-5 has-text-weight-bold mb-6">
        <img className="mr-2 email-icon" src={emailIcon} alt="email-icon" />
        {email}
      </h5>
      <h5 className="is-size-6 has-text-weight-bold">
        Add anyone else to your monthly report?
      </h5>
      <section className="add-email-container">
        <input
          className="input"
          onChange={handleReciptientEmail}
          placeholder="Enter Recipient Email"
          value={recipientEmail}
        />
        <button
          onClick={handleAddEmailToReport}
          className={`button ml-2 ${
            emailError.length !== 0 ? 'ready-to-add' : ''
          }`}
        >
          Add
        </button>
      </section>
      {emailErrorMessageMarkup}
      <ul className="recipient-email-list-container">
        {recipientEmailListMarkup}
      </ul>
    </section>
  )
  const navigateToContactUs = () => {
    history.push(paths.CONTACT_US)
  }
  const savingReportFailedMarkup = (
    <div className="report-fail has-text-centered mb-5">
      <img className="mt-5" src={reportIcon} alt="report-failed" />
      <h3 className="has-text-weight-bold is-size-4">Report Not Saved</h3>
      <h5>
        We were unable to save your report
        <br /> subscription at this time. Please try again later.
      </h5>
      <h6>
        If you need help, please{' '}
        <span
          className="contact has-font-weight-bold"
          onClick={navigateToContactUs}
        >
          Contact Us
        </span>
      </h6>
    </div>
  )
  const savingReportLoader = (
    <div className="report-loading has-text-centered">
      <img src={reportLoading} alt="report-icon" />
      <h3 className="has-text-weight-bold is-size-4 is-uppercase">
        Saving Report...
      </h3>
    </div>
  )
  const savingReportSuccessMarkup = (
    <div className="report-success has-text-centered">
      <img src={reportSaved} alt="report-saved" />
      <h3 className="has-text-weight-bold is-size-4 is-uppercase">
        Report Saved
      </h3>
    </div>
  )
  const reportsOnClassname = `reports-on-container ${
    toggleReports ? '' : 'collapsed'
  }`
  const reportsOnMarkup = toggleReports && (
    <section className={reportsOnClassname}>
      {datePickerContainerMarkup}
      {emailListContainerMarkup}
    </section>
  )
  const reportsContainerMarkup = (
    <div
      className={`reports-setup has-text-centered is-size-5 ${
        toggleReports ? '' : 'collapsed'
      }`}
    >
      <h4 className="is-size-4 has-text-weight-bold mb-3">
        To configure your monthly report:
      </h4>
      <h5 className="has-text-weight-medium reports-subtitle">
        Click the Turn Reports On checkbox and then specify
        <br /> the delivery date. We’ll email you a PDF of the report.
      </h5>
      <label className="checkbox turn-on-reports mt-4 mb-6 is-size-4 has-text-weight-bold">
        Turn Reports On
        <input
          onChange={toggleReportsHandler}
          checked={toggleReports}
          type="checkbox"
        />
        <span className="geekmark"></span>
      </label>
      {reportsOnMarkup}
      <section className="actions-container mt-6 mb-6">
        <button
          disabled={!enableSaveReport}
          onClick={handleSaveReport}
          className="button save-report"
        >
          save
        </button>
      </section>
    </div>
  )
  const theLoader = performanceReportLoading ? savingReportLoader : <Loader />

  return loading || performanceReportLoading
    ? theLoader
    : showFailedSaved
    ? savingReportFailedMarkup
    : showSuccessSaved
    ? savingReportSuccessMarkup
    : reportsContainerMarkup
}
