import { gql } from '@apollo/client'

export const SELF_SUPPLY_COMMAND = gql`
  mutation ChangeBatteryMode($params: NewBatteryModeInput!) {
    changeBatteryMode(params: $params) {
      siteKey
      label
      value
    }
  }
`
