import './style.scss'

import React from 'react'

import { rangeToDates } from '../../utilityPlanUtils'

const SeasonCard = ({
  startDate,
  endDate,
  name,
  isSelected,
  onClickCard,
}: {
  startDate: string
  endDate: string
  name: string
  isSelected: boolean
  onClickCard: () => void
}) => (
  <div
    className={`floating-card season-card ${isSelected ? 'is-selected' : ''}`}
    onClick={onClickCard}
  >
    <h3 className="season-title">{name}</h3>
    <h2 className="season-subtitle">{rangeToDates(startDate, endDate)}</h2>
  </div>
)

export default SeasonCard
