import './style.scss'

import cn from 'classnames'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Redirect, useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { Modal } from '../../components/modal'
import { SeasonsTimeline } from '../../components/seasons-timeline/flat-timeline'
import { USER_SELECTED_SITE_KEY } from '../../config/cookies'
import { useUtilityRatePlans } from '../../shared/hooks'
import { useWindowSize } from '../../shared/hooks'
import paths from '../Router/paths'
import { DetailCard } from './components/DetailCard/DetailCard'
import SeasonCard from './components/SeasonCard/SeasonCard'
import {
  SeasonParsed,
  TimelineParsed,
  parseUtilityPlanToSeasons,
  parseUtilityPlanToSeasonsTimeline,
} from './utilityPlanUtils'

const CUSTOM_BREAKPOINT = 890

type LayoutsProps = {
  utilityPlan: TimelineParsed
  seasons: SeasonParsed[]
  handleOnSelectSeason: (arg0: SeasonParsed) => void
  selectedSeason: SeasonParsed | null
  handleBack: () => void
  handleSelectPlan: () => void
}

export const UtilityPlanDetail = ({ modal }: { modal?: JSX.Element }) => {
  const history = useHistory()
  const [cookies] = useCookies([USER_SELECTED_SITE_KEY])

  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const { utilityPlanDetail, setUtilityPlanDetail } = useUtilityRatePlans({
    siteKey: selectedSiteKey,
  })

  const windowSize = useWindowSize()

  const utilityPlan = useMemo(
    () => parseUtilityPlanToSeasonsTimeline(utilityPlanDetail),
    [utilityPlanDetail]
  )

  const seasons = useMemo(
    () => parseUtilityPlanToSeasons(utilityPlanDetail),
    [utilityPlanDetail]
  )

  const isMobileView = useMemo(
    () => windowSize[0] <= CUSTOM_BREAKPOINT,
    [windowSize]
  )

  const [selectedSeason, setSelectedSeason] = useState<SeasonParsed | null>(
    seasons[0]
  )

  const handleOnSelectSeason = (season: SeasonParsed) => {
    if (season.seasonId === selectedSeason?.seasonId) {
      setSelectedSeason(null)
    } else {
      setSelectedSeason(season)
    }
  }

  const handleBack = useCallback(() => {
    setUtilityPlanDetail(null)
    history.goBack()
  }, [history, setUtilityPlanDetail])

  const handleSelectPlan = useCallback(() => {
    history.goBack()
  }, [history])

  if (!utilityPlanDetail) {
    return <Redirect to={paths.UTILITY_RATE_PLAN_SCHEDULES} />
  }
  return (
    <Layout
      title={'Select your utility rate plan'}
      headerOptions={{ hiddenLogo: true }}
    >
      <>
        {isMobileView ? (
          <MobileLayout
            utilityPlan={utilityPlan}
            seasons={seasons}
            handleOnSelectSeason={handleOnSelectSeason}
            selectedSeason={selectedSeason}
            handleBack={handleBack}
            handleSelectPlan={handleSelectPlan}
          />
        ) : (
          <WebLayout
            utilityPlan={utilityPlan}
            seasons={seasons}
            handleOnSelectSeason={handleOnSelectSeason}
            selectedSeason={selectedSeason}
            handleBack={handleBack}
            handleSelectPlan={handleSelectPlan}
          />
        )}
        {modal && (
          <Modal
            hideTopBar={true}
            title={''}
            fromPath={paths.UTILITY_RATE_PLAN_DETAIL}
          >
            {modal}
          </Modal>
        )}
      </>
    </Layout>
  )
}

const WebLayout = ({
  utilityPlan,
  seasons,
  handleOnSelectSeason,
  selectedSeason,
  handleBack,
  handleSelectPlan,
}: LayoutsProps) => {
  const hasInfo = seasons.length > 0

  return (
    <div className='columns details-container'>
      <div className='seasons-column'>
        <h1 className='seasons-code'>{utilityPlan.tariffCode}</h1>
        {hasInfo ? (
          <>
            <SeasonsTimeline seasons={utilityPlan.seasons} width={350} />
            <div className='seasons-cards'>
              {seasons.map((season, index) => {
                const {
                  seasonName,
                  seasonFromMonth,
                  seasonToMonth,
                  seasonFromDay,
                  seasonToDay,
                } = season
                return (
                  <SeasonCard
                    key={`${seasonName}-${index}`}
                    name={seasonName}
                    endDate={moment({
                      month: seasonToMonth - 1,
                      day: seasonToDay,
                    }).format()}
                    startDate={moment({
                      month: seasonFromMonth - 1,
                      day: seasonFromDay,
                    }).format()}
                    onClickCard={() => handleOnSelectSeason(season)}
                    isSelected={selectedSeason?.seasonName === seasonName}
                  />
                )
              })}
            </div>
          </>
        ) : (
          <NoInfoSeasson />
        )}
        <div className='seasons-button-container'>
          <button className='cancel  mr-5 mb-5' onClick={handleBack}>
            Back
          </button>
          <button
            className={cn('go-to-slider', { disabled: !selectedSeason })}
            onClick={handleSelectPlan}
            disabled={!selectedSeason}
          >
            Select Plan
          </button>
        </div>
      </div>
      <DetailCard selectedSeason={selectedSeason} />
    </div>
  )
}

const MobileLayout = ({
  utilityPlan,
  seasons,
  handleOnSelectSeason,
  selectedSeason,
  handleBack,
  handleSelectPlan,
}: LayoutsProps) => {
  const hasInfo = seasons.length > 0
  return (
    <div className='details-container mobile'>
      {hasInfo ? (
        <SeasonsTimeline seasons={utilityPlan.seasons} width={350} />
      ) : (
        <NoInfoSeasson />
      )}
      <div className='mobile-seasons-list'>
        {seasons.map((season, index) => {
          return (
            <DetailCard
              key={`${season.seasonName}-${index}`}
              selectedSeason={season}
              isSelected={selectedSeason?.seasonName === season.seasonName}
              onSelect={() => handleOnSelectSeason(season)}
              isMobile
            />
          )
        })}
      </div>
      <div className='seasons-button-container'>
        <button className='cancel  mr-5 mb-5' onClick={handleBack}>
          Back
        </button>
        <button
          className={cn('go-to-slider', { disabled: !selectedSeason })}
          onClick={handleSelectPlan}
          disabled={!selectedSeason}
        >
          Select Plan
        </button>
      </div>
    </div>
  )
}

const NoInfoSeasson = () => {
  const history = useHistory()
  const handleRedirectContact = () => {
    history.push(paths.CONTACT_US)
  }
  return (
    <div className='floating-card pending-info-card'>
      <p>
        We unfortunately couldn't get the details of your rate plan. You can
        find the details in your utility's website.
      </p>
      <br />
      <p>
        If you have any questions,
        <button className='contact-button' onClick={handleRedirectContact}>
          please contact us.
        </button>
      </p>
    </div>
  )
}
