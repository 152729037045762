import { gql } from '@apollo/client'

export const FETCH_PARTY_DATA = gql`
  query FetchPartyData($partyId: String!) {
    party(partyId: $partyId) {
      partyId
      displayName
      email
      phone

      sites {
        siteKey
        hasWifi
        financeType
        hasLivedata
        hasMI
        isEnabledInNightvision
        hasPanelLayout
        siteName
        siteType
        address1
        city
        state
        postalCode
        systemSize
        firstCommissionDate
        timezone
        currentWeather {
          sunrise
          sunset
          dateTime
        }
        battery {
          siteLevelCustomerStateOfChargePercentage
          operationMode
          backUpReserveSocLevel
          backupTimeLeft {
            formatted {
              days
              hours
              minutes
            }
          }
          socAndChargeCapacity {
            customerStateOfCharge
            stateOfChargePercentage
          }
        }
        assignments(assignmentType: COMMISSION) {
          deviceSerialNumber
          deviceType
          deviceKey
          assignmentEffectiveTimestamp

          devices(deviceType: "logger", deviceStatus: true) {
            dvcKey
            comProto
            fwVerNo
            sfwVerNo
            deviceStatus {
              netIntfRptCtnt {
                currNetIntfEnum
              }
            }
          }
        }
      }
      userConfigs {
        panelDataLicenseAccepted
        siteKey
      }
    }
  }
`

export const FETCH_SITE_DATA = gql`
  query FetchSiteData($siteKey: String!) {
    site(siteKey: $siteKey) {
      siteKey
      hasWifi
      financeType
      hasLivedata
      hasPanelLayout
      hasMI
      isEnabledInNightvision
      siteName
      siteType
      address1
      city
      state
      postalCode
      systemSize
      firstCommissionDate
      timezone
      currentWeather {
        sunrise
        sunset
        dateTime
      }
      battery {
        operationMode
        backUpReserveSocLevel
        siteLevelCustomerStateOfChargePercentage
        backupTimeLeft {
          formatted {
            days
            hours
            minutes
          }
        }
        socAndChargeCapacity {
          customerStateOfCharge
          stateOfChargePercentage
        }
      }
      assignments(assignmentType: COMMISSION) {
        deviceSerialNumber
        deviceType
        deviceKey
        assignmentEffectiveTimestamp

        devices(deviceType: "logger", deviceStatus: true) {
          dvcKey
          comProto
        }
      }
    }
  }
`

export const FETCH_ENERGY_DATA = gql`
  query FetchEnergyData(
    $interval: String!
    $end: String!
    $start: String!
    $siteKey: String!
  ) {
    energy(interval: $interval, end: $end, start: $start, siteKey: $siteKey) {
      energyDataSeries {
        production
        consumption
        storage
        grid
      }
      totalProduction
      totalConsumption
      energyMixPercentage
      totalGridImport
      totalGridExport
      netGridImportExport
      totalStorageCharged
      totalStorageDischarged
      netStorageChargedDischarged
    }
  }
`

export const FETCH_POWER_DATA = gql`
  query FetchPowerData(
    $interval: String!
    $end: String!
    $start: String!
    $siteKey: String!
  ) {
    power(interval: $interval, end: $end, start: $start, siteKey: $siteKey) {
      powerDataSeries {
        production
        consumption
        storage
        grid
      }
    }
  }
`

export const FETCH_ENERGY_RANGE = gql`
  query EnergyRange(
    $interval: String!
    $end: String!
    $start: String!
    $siteKey: String!
  ) {
    energyRange(
      interval: $interval
      end: $end
      start: $start
      siteKey: $siteKey
    ) {
      energyDataSeries {
        consumption
        grid
        production
        storage
      }
      totalProduction
      totalConsumption
      energyMixPercentage
      totalGridImport
      totalGridExport
      netGridImportExport
      totalStorageCharged
      totalStorageDischarged
      netStorageChargedDischarged
      tooltipConsumptionTotals {
        total
        dateString
      }
      tooltipProductionTotals {
        total
        dateString
      }
      tooltipStorageTotals {
        total
        dateString
      }
      tooltipGridTotals {
        total
        dateString
      }
    }
  }
`

export const FETCH_POWER_RANGE = gql`
  query PowerRange(
    $interval: String!
    $end: String!
    $start: String!
    $siteKey: String!
  ) {
    powerRange(
      interval: $interval
      end: $end
      start: $start
      siteKey: $siteKey
    ) {
      powerDataSeries {
        consumption
        grid
        production
        storage
      }
    }
  }
`

export const SUBSCRIBE_POWER_DATA = gql`
  subscription LiveData {
    receiveLiveData {
      production
      consumption
      storage
      net
      timestamp
    }
  }
`

export const FETCH_ENV_SAVINGS_QUERY = gql`
  query FetchEnvironmentalSavings(
    $siteKey: String!
    $start: String!
    $end: String!
    $interval: String!
  ) {
    environmentalSavings(
      siteKey: $siteKey
      start: $start
      end: $end
      interval: $interval
    ) {
      costSaving {
        value
        unit
      }
      co2 {
        value
        unit
      }
      tree {
        value
        unit
      }
      mile {
        value
        unit
      }
      gas {
        value
        unit
      }
    }
  }
`

export const FETCH_VPP_DATA = gql`
  query FetchVPPData($siteKey: String!) {
    vppEnrollment(siteKey: $siteKey) {
      enrollmentStatus
      program {
        programId
        programDescription
        programName
        seasonStart
        seasonEnd
      }
      enrollmentDevice {
        defaultReserveLevel
        deviceStatus
        deviceUuid
        currentEvent {
          eventId
          optIn
          reserveLevel
          eventStatus
          eventDatetime
          eventLength
          programId
        }
      }
      utilityAccountNumber
      hasSubmittedEnrollment
      enrollmentStateReason
      enrollmentStateCd
    }
  }
`

export const FETCH_PROGRAMS_AVAILABILITY = gql`
  query CheckSiteAvailability($siteKey: String!) {
    checkSiteAvailability(siteKey: $siteKey) {
      programId
      programName
      programDescription
      programDetails
      seasonStart
      seasonEnd
      seasonId
    }
  }
`

export const FETCH_CURRENT_POWER_LEVEL = gql`
  query FetchCurrentPower($siteKey: String!) {
    currentPower(siteKey: $siteKey) {
      production
      consumption
      storage
      grid
      timestamp
    }
  }
`

export const FETCH_CURRENT_UTILITY_RATE_PLAN_DATA_QUERY = gql`
  query CurrentSiteTariffDetails($siteKey: String!) {
    currentSiteTariffDetails(siteKey: $siteKey) {
      siteKey
      value
      valueId
      tariffCode
    }
  }
`
export const FETCH_UTILITY_RATE_PLANS_DATA_QUERY = gql`
  query FetchTariffRatePlansBySiteKey($siteKey: String!) {
    fetchTariffRatePlansBySiteKey(siteKey: $siteKey) {
      tariffId
      tariffCode
      tariffName
      seasons {
        summer {
          seasonId
          seasonName
          seasonFromMonth
          seasonFromDay
          seasonToMonth
          seasonToDay
          peaks {
            ON_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            OFF_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            PARTIAL_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            CRITICAL_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
          }
        }
        winter {
          seasonId
          seasonName
          seasonFromMonth
          seasonFromDay
          seasonToMonth
          seasonToDay
          peaks {
            ON_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            OFF_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            PARTIAL_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            CRITICAL_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
          }
        }
        spring {
          seasonId
          seasonName
          seasonFromMonth
          seasonFromDay
          seasonToMonth
          seasonToDay
          peaks {
            ON_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            OFF_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            PARTIAL_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            CRITICAL_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
          }
        }
        fall {
          seasonId
          seasonName
          seasonFromMonth
          seasonFromDay
          seasonToMonth
          seasonToDay
          peaks {
            ON_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            OFF_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            PARTIAL_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
            CRITICAL_PEAK {
              tariffRateId
              rateGroupName
              fromDateTime
              toDateTime
              timeOfUse {
                touId
                touName
                touType
                touPeriods {
                  touPeriodId
                  touId
                  fromDayOfWeek
                  fromHour
                  fromMinute
                  start
                  end
                  toDayOfWeek
                  toHour
                  toMinute
                  calendarId
                }
              }
            }
          }
        }
      }
    }
  }
`
export const FETCH_VPP_ENROLLMENTS = gql`
  query VppEnrollments($siteKey: String!) {
    vppEnrollments(siteKey: $siteKey) {
      enrollmentId
      enrollmentStatus
      enrollmentStateCd
      enrollmentStateReason
      enrollmentSubmission
      enrollmentDevice {
        deviceUuid
        deviceStatus
        defaultReserveLevel
        currentEvent {
          eventId
          optIn
          programId
          eventDatetime
          eventLength
          reserveLevel
          eventStatus
        }
      }
      program {
        programId
        programName
        programDescription
        programDetails
        seasonStart
        seasonEnd
        seasonId
        expectedEventsPerSeason
        estimatedPotentialEarningsPerSeason
        colorCode
        webviewLink
      }
      hasSubmittedEnrollment
      utilityAccountNumber
      isActive
    }
  }
`
export const FETCH_PROGRAM_STEPS = gql`
  query FetchProgramSignupStepsForEnrollment($enrollmentId: String!) {
    fetchProgramSignupStepsForEnrollment(enrollmentId: $enrollmentId) {
      buttonText
      documentStepIsCompleted
      documentTemplateId
      fieldInitialValue
      fieldName
      fieldTitle
      pageBody
      pageSubtitle
      pageTitle
      sequenceOrder
      stepType
      tooltipBody
      tooltipTitle
    }
  }
`

export const FETCH_DOCUSIGN_URL = gql`
  query GenerateWebviewURL(
    $partyId: String!
    $enrollmentId: String!
    $returnParamPath: String
    $returnEnv: String
  ) {
    generateWebviewURL(
      partyId: $partyId
      enrollmentId: $enrollmentId
      returnParamPath: $returnParamPath
      returnEnv: $returnEnv
    ) {
      webviewUrl
      envelopeId
    }
  }
`

export const UPDATE_ENROLLMENT_UTILITY_ACCOUNT_NUMBER = gql`
  mutation UpdateEnrollmentUtilityAccountNumber(
    $params: UpdateEnrollmentInput!
  ) {
    updateEnrollmentUtilityAccountNumber(params: $params)
  }
`
export const SUBMIT_PROGRAM_ENROLLMENT = gql`
  mutation NewEnrollment($params: EnrollmentInput!) {
    newEnrollment(params: $params) {
      enrollmentStatus
    }
  }
`

export const UPDATE_ENROLLMENT_SIGNATURE_STEP_COMPLETE = gql`
  mutation UpdateEnrollmentSignatureStepComplete($enrollmentId: Float!) {
    updateEnrollmentSignatureStepComplete(enrollmentId: $enrollmentId)
  }
`

export const FETCH_ENROLLMENT_PERFORMANCE_METRIC = gql`
  query FetchEnrollmentPerformanceMetricBySeason(
    $seasonId: Float!
    $enrollmentId: String!
  ) {
    fetchEnrollmentPerformanceMetricBySeason(
      seasonId: $seasonId
      enrollmentId: $enrollmentId
    ) {
      metric_title
      metric_value_formatted
      metric_type
    }
  }
`

export const FETCH_ENROLLMENT_PERFORMANCE_BY_SEASON = gql`
  query FetchEnrollmentPerformanceBySeason(
    $seasonId: Float!
    $enrollmentId: String!
  ) {
    fetchEnrollmentPerformanceBySeason(
      seasonId: $seasonId
      enrollmentId: $enrollmentId
    ) {
      avgPerformanceKw
      estimatedPayment
      eventsParticipated
      totalEvents
      eventPerformanceList {
        deviceUuid
        events {
          eventStart
          eventEnd
          powerDischarged
          energyDischarged
        }
      }
    }
  }
`
export const UPDATE_DEFAULT_RESERVE_LEVEL_MUTATION = gql`
  mutation patchDefaultReserveLevel($params: UpdateDefaultReserveLevelInput!) {
    patchDefaultReserveLevel(params: $params)
  }
`

export const UPDATE_RESERVE_LEVEL_MUTATION = gql`
  mutation patchReserveLevel($params: UpdateReserveLevelInput!) {
    patchReserveLevel(params: $params)
  }
`
// Support cases

export const FETCH_SUPPORT_CASES_QUERY = gql`
  query FetchSupportCases($params: getCustomerCasesInput!) {
    getCases(params: $params) {
      caseNumber
      category
      createdDate
      description
      lastModifiedDate
      status
      statusColor
      subject
    }
  }
`
export const FETCH_CASE_COMMENTS_QUERY = gql`
  query CaseComments($params: getCustomerCaseInput!) {
    getCase(params: $params) {
      caseComments {
        commentBody
        createdBy
        createdDate
      }
    }
  }
`

export const GENERATE_TOKEN_MUTATION = gql`
  mutation GenerateToken {
    generateToken {
      jwt
    }
  }
`
export const FETCH_OKTA_USER_QUERY = gql`
  query OktaUser($email: String!) {
    oktaUser(email: $email) {
      id
      status
      created
      firstName
      lastName
      email
      login
    }
  }
`

export const ADD_CASE_COMMENT_MUTATION = gql`
  mutation AddCaseComment($params: addCaseCommentInput!) {
    addCaseComment(params: $params) {
      message
      status
    }
  }
`
