import './style.scss'

import React from 'react'

const ToggleSwitch = ({
  id,
  name,
  checked,
  onChange,
  disabled,
}: {
  id: string
  name: string
  checked: boolean
  onChange: (e: boolean) => void
  disabled?: boolean
}) => {
  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <label
          className="toggle-switch-label"
          tabIndex={disabled ? -1 : 1}
          htmlFor={id}
        >
          <span
            className={
              disabled
                ? 'toggle-switch-inner toggle-switch-disabled'
                : 'toggle-switch-inner'
            }
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? 'toggle-switch-switch toggle-switch-disabled'
                : 'toggle-switch-switch'
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  )
}

export default ToggleSwitch
