import './style.scss'

import clsx from 'clsx'
import React from 'react'
import { animated, useSpring } from '@react-spring/web'

const ICON_PROPERTIES = {
  FULL_WIDTH: 186.5,
  SHORT_WIDTH: 36,
  FULL_HEIGHT: 365,
  INITIAL_X: 30,
}

function getLinePosition(stateOfCharge: number) {
  const topPosition = 370
  return (topPosition * (100 - stateOfCharge)) / 100
}

export const Battery = ({ stateOfCharge = 10, className = '' }) => {
  const percentage = Math.min(Math.max(stateOfCharge, 0), 100)
  const isHundredPercent = percentage === 100
  const classes = clsx('battery-icon', className)

  const hasLine = percentage > 0 && !isHundredPercent

  const { lineHeight, count, initialY } = useSpring({
    lineHeight: getLinePosition(percentage),
    initialY: getLinePosition(percentage) + (hasLine ? 1.5 : 3),
    count: percentage,
    from: { lineHeight: 370, count: 0, initialY: 370 },
  })
  const topLeftCorner = React.useCallback(
    (n: number) => `C ${ICON_PROPERTIES.INITIAL_X} ${n} 31.23858 ${n - 2} 34 ${n - 2}`,
    [],
  )
  const topRightCorner = React.useCallback(
    (n: number) =>
      `C 182 ${n - 2} ${ICON_PROPERTIES.FULL_WIDTH - 1} ${n - 2} ${
        ICON_PROPERTIES.FULL_WIDTH
      } ${n}`,
    [],
  )
  const rightCorner = `C ${ICON_PROPERTIES.FULL_WIDTH} 367.866 182.866 371 179 371`
  const leftCorner = `C 32.134 371 ${ICON_PROPERTIES.INITIAL_X} 367.866 ${ICON_PROPERTIES.INITIAL_X} 364`
  const moveRight = isHundredPercent ? '182' : ICON_PROPERTIES.FULL_WIDTH
  const fillColor = percentage !== 0 ? 'url(#battery_back)' : 'none'
  const animatedPath = initialY.to(
    (n) =>
      `${
        !isHundredPercent
          ? `M ${ICON_PROPERTIES.INITIAL_X} ${n}`
          : `M ${ICON_PROPERTIES.INITIAL_X} ${n} ${topLeftCorner(n)}`
      } H ${moveRight} ${isHundredPercent ? topRightCorner(n) : ''} V ${
        ICON_PROPERTIES.FULL_HEIGHT
      } ${rightCorner} H ${ICON_PROPERTIES.SHORT_WIDTH} ${leftCorner} V ${n} Z`,
  )
  const animatedColor = count.to({
    range: [60, 70],
    output: ['#cdcdcd', '#ffffff'],
  })

  return (
    <div className={classes}>
      <div className="background-circle" />
      <animated.svg
        className="battery-svg"
        width="220"
        height="372"
        viewBox="0 0 220 372"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <linearGradient id="battery_back" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#48A500' }} />
          <stop offset="70%" style={{ stopColor: '#1D730F' }} />
          <stop offset="100%" style={{ stopColor: '#0A5C16' }} />
        </linearGradient>
        <path
          d="M174.315 371.521H42.479C35.9799 371.521 32.9492 369.929 31.5348 368.592C30.0195 367.16 30.0195 365.727 30.0195 365.696V4.6461C29.9858 4.51877 29.9185 3.85027 30.592 3.08628C31.6022 1.90846 34.3635 0.507812 41.7045 0.507812H175.157C182.498 0.507812 185.259 1.90846 186.269 3.08628C186.943 3.85027 186.842 4.5506 186.842 4.6461V365.664C186.842 365.727 186.842 367.128 185.326 368.561C183.878 369.898 180.814 371.521 174.315 371.521ZM41.6709Z"
          fill="#FFFFFF"
        />
        <animated.path
          className="battery-display"
          opacity="1"
          d={animatedPath}
          fill={fillColor}
        />
        <path
          d="M174.317 372H42.4811C37.1269 372 33.3217 370.95 31.1665 368.912C29.5165 367.321 29.4828 365.729 29.4828 365.665V4.67945C29.4491 4.42478 29.4154 3.62896 30.1563 2.76947C31.739 0.923156 35.6116 0 41.673 0H175.125C181.186 0 185.059 0.923156 186.642 2.76947C187.382 3.62896 187.349 4.42478 187.315 4.67945V365.665C187.315 365.729 187.315 367.321 185.631 368.912C183.476 370.95 179.671 372 174.317 372ZM41.673 1.27332C34.3993 1.27332 32.0421 2.67397 31.2675 3.50163C30.7624 4.04279 30.8298 4.48845 30.8298 4.52028V4.58395V4.61578V365.665C30.8298 365.856 31.0655 370.727 42.4811 370.727H174.35C185.766 370.727 185.968 365.856 186.002 365.665V4.55211V4.48845C186.002 4.48845 186.069 4.04279 185.564 3.50163C184.79 2.67397 182.432 1.27332 175.159 1.27332H41.673Z"
          fill="#D3D7DB"
        />
        <g opacity="0.4">
          <path
            d="M118.151 104.702H99.5963C97.037 104.702 94.9492 102.728 94.9492 100.309V63.0961C94.9492 60.6768 97.037 58.7031 99.5963 58.7031H118.151C120.71 58.7031 122.798 60.6768 122.798 63.0961V100.309C122.798 102.728 120.71 104.702 118.151 104.702ZM99.5963 59.308C97.4075 59.308 95.6227 60.9951 95.6227 63.0642V100.277C95.6227 102.346 97.4075 104.033 99.5963 104.033H118.151C120.34 104.033 122.125 102.346 122.125 100.277V63.0961C122.125 61.0269 120.34 59.3398 118.151 59.3398H99.5963V59.308Z"
            fill="#D3D7DB"
          />
        </g>
        {hasLine && (
          <animated.line
            className="battery-display"
            opacity="0.66"
            x1="17.5"
            y1={lineHeight}
            x2="200.098"
            y2={lineHeight}
            stroke="#000000"
            strokeWidth="3"
            strokeLinecap="round"
          />
        )}
        <g opacity="0.5">
          <path
            d="M86.6398 47.3861C86.4278 46.9915 86.1554 46.779 85.671 46.779C85.1866 46.779 84.7325 47.1433 84.7325 47.6593C84.7325 48.145 85.2169 48.3575 85.6104 48.5397L85.9737 48.6914C86.7003 49.0254 87.3058 49.3593 87.3058 50.27C87.3058 51.2414 86.5187 52.0003 85.5499 52.0003C84.6416 52.0003 83.9756 51.4235 83.8242 50.5432L84.4297 50.361C84.5205 50.9378 84.9444 51.3931 85.5499 51.3931C86.1554 51.3931 86.7003 50.9378 86.7003 50.3003C86.7003 49.6325 86.1856 49.42 85.671 49.1771L85.3379 49.0254C84.6719 48.7218 84.127 48.3879 84.127 47.5986C84.127 46.7183 84.8536 46.1719 85.7012 46.1719C86.337 46.1719 86.8517 46.4754 87.1544 47.0522L86.6398 47.3861Z"
            fill="black"
          />
          <path
            d="M90.0615 49.6291C90.0615 50.0541 90.0616 50.5095 90.3037 50.8737C90.5459 51.2077 91.0303 51.4202 91.4239 51.4202C91.8175 51.4202 92.2716 51.238 92.5138 50.9345C92.8165 50.5702 92.7863 50.0845 92.7863 49.6595V46.3203H93.422V49.8113C93.422 50.4184 93.3918 50.9041 92.9376 51.3594C92.5441 51.7844 91.9991 51.9969 91.4239 51.9969C90.9092 51.9969 90.3643 51.7844 89.9707 51.4202C89.4863 50.9648 89.4258 50.4488 89.4258 49.8113V46.3203H90.0615V49.6291Z"
            fill="black"
          />
          <path
            d="M95.5703 46.0781L99.9298 50.6619V46.321H100.566V52.1797L96.2061 47.6263V51.9369H95.5703V46.0781Z"
            fill="black"
          />
          <path
            d="M103.504 46.9001H103.746C104.473 46.9001 105.199 46.9608 105.199 47.8715C105.199 48.6607 104.624 48.8732 103.928 48.8732H103.504V46.9001ZM103.504 49.45H103.958C104.412 49.45 104.897 49.3893 105.29 49.0857C105.623 48.7822 105.835 48.3268 105.835 47.8715C105.835 47.3858 105.623 46.9001 105.199 46.5965C104.806 46.3233 104.291 46.293 103.807 46.293H102.898V51.8785H103.534V49.45H103.504Z"
            fill="black"
          />
          <path
            d="M115.856 50.5122L117.672 46.0195L119.489 50.5122L120.942 46.3231H121.608L119.458 52.2122L117.642 47.7195L115.825 52.2122L113.676 46.3231H114.342L115.856 50.5122Z"
            fill="black"
          />
          <path
            d="M122.969 46.3203H125.875V46.8971H123.605V48.5667H125.815V49.1434H123.605V51.3291H125.875V51.9059H122.969V46.3203Z"
            fill="black"
          />
          <path
            d="M128.597 46.9001H128.778C129.535 46.9001 130.201 46.9912 130.201 47.9018C130.201 48.7822 129.505 48.9036 128.778 48.9036H128.597V46.9001ZM128.597 49.45H128.748L130.443 51.9089H131.2L129.414 49.3893C130.262 49.3286 130.807 48.7215 130.807 47.8411C130.807 46.5662 129.838 46.293 128.778 46.293H127.961V51.8785H128.597V49.45Z"
            fill="black"
          />
          <path
            d="M86.6398 47.3861C86.4278 46.9915 86.1554 46.779 85.671 46.779C85.1866 46.779 84.7325 47.1433 84.7325 47.6593C84.7325 48.145 85.2169 48.3575 85.6104 48.5397L85.9737 48.6914C86.7003 49.0254 87.3058 49.3593 87.3058 50.27C87.3058 51.2414 86.5187 52.0003 85.5499 52.0003C84.6416 52.0003 83.9756 51.4235 83.8242 50.5432L84.4297 50.361C84.5205 50.9378 84.9444 51.3931 85.5499 51.3931C86.1554 51.3931 86.7003 50.9378 86.7003 50.3003C86.7003 49.6325 86.1856 49.42 85.671 49.1771L85.3379 49.0254C84.6719 48.7218 84.127 48.3879 84.127 47.5986C84.127 46.7183 84.8536 46.1719 85.7012 46.1719C86.337 46.1719 86.8517 46.4754 87.1544 47.0522L86.6398 47.3861Z"
            fill="black"
          />
          <path
            d="M90.0615 49.6291C90.0615 50.0541 90.0616 50.5095 90.3037 50.8737C90.5459 51.2077 91.0303 51.4202 91.4239 51.4202C91.8175 51.4202 92.2716 51.238 92.5138 50.9345C92.8165 50.5702 92.7863 50.0845 92.7863 49.6595V46.3203H93.422V49.8113C93.422 50.4184 93.3918 50.9041 92.9376 51.3594C92.5441 51.7844 91.9991 51.9969 91.4239 51.9969C90.9092 51.9969 90.3643 51.7844 89.9707 51.4202C89.4863 50.9648 89.4258 50.4488 89.4258 49.8113V46.3203H90.0615V49.6291Z"
            fill="black"
          />
          <path
            d="M103.504 46.9001H103.746C104.473 46.9001 105.199 46.9608 105.199 47.8715C105.199 48.6607 104.624 48.8732 103.928 48.8732H103.504V46.9001ZM103.504 49.45H103.958C104.412 49.45 104.897 49.3893 105.29 49.0857C105.623 48.7822 105.835 48.3268 105.835 47.8715C105.835 47.3858 105.623 46.9001 105.199 46.5965C104.806 46.3233 104.291 46.293 103.807 46.293H102.898V51.8785H103.534V49.45H103.504Z"
            fill="black"
          />
          <path
            d="M115.856 50.5122L117.672 46.0195L119.489 50.5122L120.942 46.3231H121.608L119.458 52.2122L117.642 47.7195L115.825 52.2122L113.676 46.3231H114.342L115.856 50.5122Z"
            fill="black"
          />
          <path
            d="M122.969 46.3203H125.875V46.8971H123.605V48.5667H125.815V49.1434H123.605V51.3291H125.875V51.9059H122.969V46.3203Z"
            fill="black"
          />
          <path
            d="M128.597 46.9001H128.778C129.535 46.9001 130.201 46.9912 130.201 47.9018C130.201 48.7822 129.505 48.9036 128.778 48.9036H128.597V46.9001ZM128.597 49.45H128.748L130.443 51.9089H131.2L129.414 49.3893C130.262 49.3286 130.807 48.7215 130.807 47.8411C130.807 46.5662 129.838 46.293 128.778 46.293H127.961V51.8785H128.597V49.45Z"
            fill="black"
          />
          <path
            d="M112.856 49.1118C112.856 50.7511 111.554 51.9957 109.919 51.9957C108.314 51.9957 107.043 50.6904 107.043 49.1422C107.043 47.5637 108.314 46.2584 109.919 46.2584C111.554 46.228 112.856 47.4726 112.856 49.1118ZM107.679 49.1118C107.679 50.2957 108.617 51.3582 109.889 51.3582C111.191 51.3582 112.19 50.3868 112.19 49.1118C112.19 47.8065 111.191 46.8351 109.889 46.8351C108.617 46.8655 107.679 47.8976 107.679 49.1118Z"
            fill="black"
          />
        </g>
      </animated.svg>
      <animated.div
        className="energy-info is-flex"
        style={{ color: animatedColor }}
      >
        <h2 className="title">
          <animated.span className="percentage is-size-1">
            {count.to((n) => `${n.toFixed(0)}`)}
          </animated.span>
          <span className="symbol">%</span>
        </h2>
        <span className="is-uppercase">Charged</span>
      </animated.div>
    </div>
  )
}
