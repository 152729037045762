import './style.scss'

import clsx from 'clsx'
import React from 'react'

import lighthingIcon from './assets/lighting.svg'

export const SwitchToggle = ({ value, onChange }) => {
  const classes = clsx('react-toggle', {
    'react-toggle--checked': value,
  })
  return (
    <div className={classes} onClick={onChange}>
      <div className="react-toggle-track" />
      <div className="react-toggle-thumb">
        <img alt="energy" src={lighthingIcon} className="react-toogle-icon" />
      </div>
      <input className="react-toggle-screenreader-only" type="checkbox" />
    </div>
  )
}
