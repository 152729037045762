import './style.scss'

import clsx from 'clsx'
import React from 'react'

import spinner from './assets/spinner.gif'

export const Loader = ({ className = '' }) => (
  <div className={clsx('loader-wrapper columns is-centered', className)}>
    <img src={spinner} alt="loading" />
  </div>
)
