import { useLazyQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'

import { PartyInfoContext } from '../../context/partyInfoContext'
import { FETCH_PARTY_DATA, FETCH_SITE_DATA } from './query'

export const usePartyData = (partyId?: string, siteKey?: string) => {
  const partyContext = useContext(PartyInfoContext)

  const [
    fetchPartyDataByPartyId,
    { data: partyDataByPartyId, loading: loadingPartyDataByPartyId },
  ] = useLazyQuery(FETCH_PARTY_DATA, {
    fetchPolicy: 'network-only',
    variables: { partyId },
  })
  const [
    fetchSiteDataBySiteKey,
    { data: siteDataBySiteKey, loading: loadingFetchSiteDataBySiteKey },
  ] = useLazyQuery(FETCH_SITE_DATA, {
    fetchPolicy: 'network-only',
    variables: { siteKey },
  })

  useEffect(() => {
    const currentPartyId = partyContext.partyInfo.data?.party?.partyId ?? null
    if (partyId && partyId !== currentPartyId) {
      fetchPartyDataByPartyId()
    }
    if (siteKey) {
      fetchSiteDataBySiteKey()
    }
  }, [partyId, siteKey, fetchPartyDataByPartyId, fetchSiteDataBySiteKey])

  useEffect(() => {
    if (partyDataByPartyId) {
      partyContext.setPartyInfo({
        data: partyDataByPartyId,
        loading: loadingPartyDataByPartyId,
      })
    }
    if (loadingPartyDataByPartyId) {
      partyContext.setPartyInfo({
        data: { ...partyContext.partyInfo.data },
        loading: loadingPartyDataByPartyId,
      })
    }
  }, [partyDataByPartyId, loadingPartyDataByPartyId])

  useEffect(() => {
    if (siteDataBySiteKey) {
      partyContext.setPartyInfo({
        data: {
          party: {
            partyId: '--kiosk--',
            displayName: 'kiosk user',
            email: 'kiosk@sunpower.com',
            phone: '000-000-000',
            sites: [siteDataBySiteKey?.site],
            userConfigs: [
              {
                panelDataLicenseAccepted: true,
                siteKey: true,
              },
            ],
          },
        },
        loading: loadingFetchSiteDataBySiteKey,
      })
    }
    if (loadingFetchSiteDataBySiteKey) {
      partyContext.setPartyInfo({
        data: { ...partyContext.partyInfo.data },
        loading: loadingFetchSiteDataBySiteKey,
      })
    }
  }, [siteDataBySiteKey, loadingFetchSiteDataBySiteKey])

  return partyContext.partyInfo
}
