import './style.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { Loader } from '../../components/loader'
import { Modal } from '../../components/modal'
import { USER_SELECTED_SITE_KEY } from '../../config/cookies'
import {
  useCurrentUtilityRatePlan,
  useUtilityRatePlans,
} from '../../shared/hooks'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import paths from '../Router/paths'
import { UtilityRateDetails } from './UtilityRateDetails'

export const UtilityRateSchedules = ({
  modalTitle,
  modal,
  hideTopBar,
}: {
  modalTitle?: string
  modal?: JSX.Element
  hideTopBar?: boolean
}) => {
  const flags = useFlags() as LaunchDarklyFlags
  const history = useHistory()
  const [cookies] = useCookies([USER_SELECTED_SITE_KEY])

  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const CUSTOM_TARIFF_FLAG = flags.customTariffFlag ?? false
  const utilityPlan = useCurrentUtilityRatePlan({
    siteKey: selectedSiteKey,
    skip: !CUSTOM_TARIFF_FLAG,
  })

  const utilityPlans = useUtilityRatePlans({
    siteKey: selectedSiteKey,
    skip: !CUSTOM_TARIFF_FLAG,
  })

  const { data: currentPlanData, loading: currentPlanLoading } =
    utilityPlan || {}

  const { currentSiteTariffDetails } = currentPlanData || {}

  const {
    data: utilityPlansData,
    loading: utilityPlansLoading,
    utilityPlanDetail,
    setUtilityPlanDetail,
  } = utilityPlans || {}
  const { fetchTariffRatePlansBySiteKey } = utilityPlansData || {}

  return (
    <Layout
      title='Select your utility & rate plan'
      headerOptions={{ hiddenLogo: true }}
    >
      <section className='cost-savings-mode-page setup-mode-page'>
        {currentPlanLoading || utilityPlansLoading ? (
          <Loader />
        ) : (
          <UtilityRateDetails
            siteKey={selectedSiteKey}
            utilityPlanDetail={utilityPlanDetail}
            setUtilityPlanDetail={setUtilityPlanDetail}
            history={history}
            utilityPlans={fetchTariffRatePlansBySiteKey || []}
            currentPlan={currentSiteTariffDetails}
          />
        )}
        {modal && (
          <Modal
            title={modalTitle}
            hideTopBar={hideTopBar}
            fromPath={paths.COST_SAVINGS_MODE}
          >
            {modal}
          </Modal>
        )}
      </section>
    </Layout>
  )
}
