import React, { createContext, useState } from 'react'

import { UsePartyDataResponseInterface } from '../shared/typings/party'

type propsType = {
  children: JSX.Element
}

type contextType = {
  partyInfo: UsePartyDataResponseInterface
  setPartyInfo: (arg0: UsePartyDataResponseInterface) => void
}

const defaultPartyInfo: UsePartyDataResponseInterface = {
  data: { party: null },
  loading: false,
}

const defaultContextValue = {
  partyInfo: defaultPartyInfo,
  setPartyInfo: () => {},
}

export const PartyInfoContext = createContext<contextType>(defaultContextValue)

const PartyInfoContextProvider = ({ children }: propsType) => {
  const [partyInfo, setPartyInfo] =
    useState<UsePartyDataResponseInterface>(defaultPartyInfo)

  return (
    <PartyInfoContext.Provider value={{ partyInfo, setPartyInfo }}>
      {children}
    </PartyInfoContext.Provider>
  )
}
export default PartyInfoContextProvider
