import cloudy from './assets/CLOUDY.svg'
import foggy from './assets/FOGGY.svg'
import nightTime from './assets/Night-Time.svg'
import partialCloudy from './assets/PARTLY_CLOUDY.svg'
import partialRainy from './assets/PARTLY_RAINY.svg'
import partialSnowy from './assets/PARTLY_SNOWY.svg'
import rainy from './assets/RAINY.svg'
import snowy from './assets/SNOWY.svg'
import sunnyWeather from './assets/SUNNY.svg'
import thunderStorm from './assets/THUNDER_STORM.svg'

export const weatherIconsMap = {
  CLOUDY: cloudy,
  FOGGY: foggy,
  SUNNY: sunnyWeather,
  PARTLY_RAINY: partialRainy,
  PARTLY_CLOUDY: partialCloudy,
  PARTLY_SNOWY: partialSnowy,
  RAINY: rainy,
  THUNDERSTORM: thunderStorm,
  SNOWY: snowy,
  CLEAR_NIGHT: nightTime,
  UNAVAILABLE: sunnyWeather,
}

export const getWeatherIconForCondition = (conditions) => {
  return weatherIconsMap[conditions] || sunnyWeather
}
