import '../style.scss'

import { useMutation } from '@apollo/client'
import React, { useCallback } from 'react'
import Lottie from 'react-lottie'
import { useHistory } from 'react-router-dom'

import { FETCH_PARTY_DATA } from '../../../shared/hooks/query'
import { FETCH_PARTY_INFO } from '../../Profile/query'
import paths from '../../Router/paths'
import panel_animation from '../assets/panel_animation/panel_animation'
import { UPDATE_USER_CONFIGS_MUTATION } from './queries'

const document =
  'You are being given advanced access to this feature as a BETA test user. Your use is provisional and subject to re-enrollment upon full feature launch. THE FEATURE, FUNCTIONALITY, AND INFORMATION ARE PROVIDED IS “AS IS” AND “AS AVAILABLE” AND (TO THE FULLEST EXTENT PERMITTED BY LAW) WITHOUT WARRANTY (EXPRESS OR IMPLIED) OF ANY KIND. By using the feature, you acknowledge and agree that this feature is still being developed, and any feedback or information you provide to SunPower in connection with your use or access of this feature or associated application may be used by SunPower to improve its products, including the application.'
const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: panel_animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}
export const AlertLegalDocumentModalContents = ({
  siteKey,
  partyId,
}: {
  siteKey?: string
  partyId?: string
}) => {
  const history = useHistory()

  const [updateUserConfigs] = useMutation(UPDATE_USER_CONFIGS_MUTATION)
  const handleGoBack = () => {
    history.goBack()
  }
  const handleAgree = useCallback(() => {
    updateUserConfigs({
      variables: {
        data: { siteKey, partyId, panelDataLicenseAccepted: true },
      },
      refetchQueries: [
        {
          query: FETCH_PARTY_DATA,
          variables: { partyId },
        },
        { query: FETCH_PARTY_INFO, variables: { partyId, siteKey } },
      ],
    })
    history.replace(paths.PANELS)
  }, [siteKey, partyId, history])

  return (
    <div className="alert-modal-legal-document-content">
      <div className="header">
        <div className="labels-container">
          <div className="title">Introducing Panel Level Data</div>
          <div className="subtitle">
            The power of individual microinverters in your hands!
          </div>
          <div className="review">REVIEW YOUR AGREEMENT BELOW:</div>
        </div>
        <div className="animation-container">
          <Lottie options={defaultOptions} height={196} width={314} />
        </div>
      </div>
      <div className="content">{document}</div>
      <div className="button-container">
        <button className="cancel" onClick={handleGoBack}>
          Cancel
        </button>
        <button className="agree" onClick={handleAgree}>
          Agree
        </button>
      </div>
    </div>
  )
}
