import { gql } from '@apollo/client'

export const FETCH_ASSIGNMENTS = gql`
  query FetchAssignments($siteKey: String!) {
    site(siteKey: $siteKey) {
      siteKey
      assignments(assignmentType: COMMISSION) {
        deviceSerialNumber
        deviceType
        deviceKey
        assignmentEffectiveTimestamp

        devices(deviceType: "logger", deviceStatus: true) {
          dvcKey
          comProto
        }
      }
    }
  }
`

export const SCAN_WIFI = gql`
  mutation ScanWifi($serialNumber: String!) {
    scanWifi(params: { serialNumber: $serialNumber, waitForResult: false }) {
      commandToken
      commandSucceed
      commandProcessed
      wifiAps {
        ssid
      }
    }
  }
`

export const FETCH_SCAN_WIFI_RESULT = gql`
  query FetchScanWifiResult($serialNumber: String!, $commandToken: String!) {
    commandResult(
      params: {
        commandName: WIFI_SCAN
        serialNumber: $serialNumber
        commandToken: $commandToken
      }
    ) {
      commandSucceed
      commandProcessed
      networkListAvailable
      wifiAps {
        bssid
        chnVal
        rssiDbm
        secCapblStr
        ssid
      }
    }
  }
`

export const CONNECT_WIFI = gql`
  mutation ConnectWifi(
    $serialNumber: String!
    $ssid: String!
    $password: String!
  ) {
    connectWifi(
      params: { serialNumber: $serialNumber, ssid: $ssid, password: $password }
    ) {
      commandToken
      commandSucceed
      commandProcessed
    }
  }
`

export const FETCH_CONNECT_WIFI_RESULT = gql`
  query FetchConnectWifiResult($serialNumber: String!, $commandToken: String!) {
    commandResult(
      params: {
        commandName: WIFI_SET_CREDENTIALS
        serialNumber: $serialNumber
        commandToken: $commandToken
      }
    ) {
      commandSucceed
      commandProcessed
      commandSucceed
    }
  }
`
