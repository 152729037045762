import './style.scss'

import { useApolloClient } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'
// @ts-ignore
import moment from 'moment-timezone'
import React, {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { ConnectedSolutionsTopBanner } from '../../components/connected-solutions-top-banner'
import { CurrentPower } from '../../components/current-power'
import { EnergyMix } from '../../components/energy-mix'
import { FilterBar } from '../../components/filter-bar'
import { GraphsLegend } from '../../components/graphs-legend'
import { GraphsLegendWithTotals } from '../../components/graphs-legend/legend-with-totals'
import { Layout } from '../../components/layout'
import { Loader } from '../../components/loader'
import { MobileMonitoringExperience } from '../../components/mobile-monitoring-experience'
import { Modal } from '../../components/modal'
import OptionsMenuPopup from '../../components/options-menu-popup'
import {
  ProductionConsumptionGraph,
  TAB_INDEXES,
} from '../../components/production-consumption-graph'
import { RechartsEnergyPowerGraph } from '../../components/recharts-production-consumption-graphs'
import { Savings } from '../../components/savings'
import { BATTERY_STATUS, Storage } from '../../components/storage'
import { SystemInfo } from '../../components/system-info'
import { WidgetTabs } from '../../components/widget-tabs'
import { WidgetWrapper } from '../../components/widget-wrapper'
import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { ALERTS_ID, useAlerts, useDateRangeLabel } from '../../hooks'
import { dateFormat } from '../../shared/constants'
import { exportToExcel } from '../../shared/exportToExcel'
import { extractSiteDetails } from '../../shared/extractSiteDetails'
import { FirebaseEvents, fbTrackEvent } from '../../shared/firebaseAnalytics'
import {
  useCurrentPower,
  useCurrentPowerLevels,
  useDynamicVpp,
  useEnergy,
  usePartyData,
  usePower,
  useWindowSize,
} from '../../shared/hooks'
import { FETCH_ENERGY_DATA, FETCH_ENERGY_RANGE } from '../../shared/hooks/query'
import { INTERVALS, INTERVALS_PERIODICITY } from '../../shared/intervals'
import { GRAPH_TYPES, SITE_TYPES } from '../../shared/sharedConstants'
import { getEventState } from '../../shared/timeUtils'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import { ENROLLMENT_STATUS, EVENT_STATUS } from '../../shared/typings/site'
import { DEFAULT_NON_ZERO, formatNumber } from '../../shared/valueFormatters'
import paths from '../Router/paths'
import downloadIcon from './assets/download.svg'

export const DATALOGGER_DEVICE_TYPE = ['datalogger', 'logger']
export const NON_LEGACY_COMM_PROTOCOL = ['MQTT']
const DEFAULT_TIMEZONE = 'America/New_York'
export const DEFAULT_SHARED_STATE = {
  timezone: null,
  siteDetails: {},
  siteKey: null,
  isStorage: false,
  isProductionSite: true,
  isDaytime: false,
  systemSize: 0,
  stateOfCharge: 0,
  batteryMode: 'UNAVAILABLE',
  firstCommissionDate: '2010-01-01T00:00:00.000Z',
  isLegacyPvs: false,
  assignmentSerialNumber: null,
}

type SharedState = {
  timezone: string | null
  siteDetails: any
  siteKey: string | null
  isStorage: boolean
  isProductionSite: boolean
  isDaytime: boolean
  systemSize: number
  stateOfCharge: number
  batteryMode: string
  firstCommissionDate: string
  isLegacyPvs: boolean
  assignmentSerialNumber: string | null
}

export const Dashboard = ({
  modalTitle,
  modal,
  hideTopBar,
  noStylesModal = false,
}: {
  modalTitle?: string
  modal?: JSX.Element
  hideTopBar?: boolean
  noStylesModal?: boolean
}) => {
  const client = useApolloClient()
  const flags = useFlags() as LaunchDarklyFlags
  const STORAGE_GRAPH_FLAG = flags?.storageGraphFlag ?? false
  const CUSTOMER_SOC_FLAG = flags?.customerSocFlag ?? false
  const ENERGY_POWER_CHARTS_REFACTOR_FLAG =
    flags?.energyPowerChartsRefactorFlag ?? false

  const DYNAMIC_VPP_FLAG = flags?.dynamicVppFlag ?? false

  const DOWNLOAD_TIMEFRAME_FLAG = flags?.downloadTimeframeFlag ?? false

  const ENERGY_RANGE_EXCEL_DOWNLOAD_FLAG =
    flags?.energyRangeExcelDownloadFlag ?? false

  const ICD_ALERT_FLAG = flags?.icdAlertFlag ?? false

  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  ])

  const session = cookies[USER_SESSION_INFO] ?? {}
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const history = useHistory()
  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )

  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )
  const currentSiteKey = useMemo(
    () => (isKiosk ? selectedSiteKey : null),
    [isKiosk, selectedSiteKey],
  )
  const { data, loading: partyDataLoading } = usePartyData(
    currentPartyId,
    currentSiteKey,
  )
  const { fetchEnrollments, selectedEnrollment } = useDynamicVpp()

  useEffect(() => {
    fetchEnrollments()
  }, [])

  const selectedSite = useMemo(
    () =>
      (data?.party?.sites ?? []).find(
        (site) => site.siteKey === selectedSiteKey,
      ),
    [data, selectedSiteKey],
  )

  const [sharedState, setSharedState] =
    useState<SharedState>(DEFAULT_SHARED_STATE)

  const [dates, setDates] = useState({
    start: 0,
    end: 0,
  })

  const [nowDates, setNowDates] = useState({
    beginningToday: 0,
    endToday: 0,
  })
  const dynamicEnrollmentStatus = useMemo(
    () => selectedEnrollment?.enrollmentStatus || ENROLLMENT_STATUS.UNE,
    [selectedEnrollment],
  )

  const vPPEnrollmentDevice = useMemo(
    () => selectedEnrollment?.enrollmentDevice,
    [selectedEnrollment],
  )

  const vPPEnrollmentEvent = useMemo(
    () => vPPEnrollmentDevice?.currentEvent ?? { eventId: '' },
    [vPPEnrollmentDevice],
  )

  const programEventState = useMemo(
    () => getEventState(vPPEnrollmentEvent),
    [vPPEnrollmentEvent],
  )

  const onOptedOut = useCallback(() => {
    history.push(`${paths.ALERT_OPTED_OUT}`)
  }, [history])

  const [dataInterval, setDataInterval] = useState<INTERVALS>(INTERVALS.DAY)
  const [isGraphLoading, setIsGraphLoading] = useState(true)
  const [isEnergyMixLoading, setIsEnergyMixLoading] = useState(true)
  const [isPowerLoading, setIsPowerLoading] = useState(true)
  const [isShowingPowerGraph, setIsShowingPowerGraph] = useState(false)

  const { energy, loading: energyLoading } = useEnergy(
    selectedSite?.siteKey,
    dates.start,
    dates.end,
    dataInterval,
    sharedState.timezone,
  )
  const { power, loading: powerLoading } = usePower(
    selectedSite?.siteKey,
    selectedSite?.siteType,
    dates.start,
    dates.end,
    dataInterval,
    sharedState.isLegacyPvs,
    sharedState.timezone,
  )

  const [currentLevels] = useCurrentPowerLevels(
    selectedSite?.siteKey,
    selectedSite?.hasLivedata,
    sharedState.assignmentSerialNumber ?? '',
  )

  const { power: currentPower, loading: currentPowerLoading } = useCurrentPower(
    selectedSite?.siteKey,
    sharedState.assignmentSerialNumber,
    nowDates.beginningToday,
    nowDates.endToday,
    INTERVALS.DAY,
    sharedState.isLegacyPvs,
    sharedState.timezone,
    selectedSite?.hasLivedata,
  )

  const { timezone = DEFAULT_TIMEZONE } = selectedSite
    ? selectedSite
    : { timezone: DEFAULT_TIMEZONE } // forcing the timezone to have a default from the selected state

  useEffect(() => {
    window.dataLayer.push({
      userData: {
        hasLivedata: selectedSite?.hasLivedata,
        isKiosk,
      },
    })
  }, [selectedSite?.hasLivedata])

  useEffect(() => {
    setIsGraphLoading(
      partyDataLoading ||
        (!partyDataLoading && currentPowerLoading) ||
        energyLoading ||
        powerLoading ||
        !dates.start ||
        !dates.end ||
        !sharedState.timezone,
    )
  }, [
    partyDataLoading,
    currentPowerLoading,
    energyLoading,
    powerLoading,
    dates,
    sharedState.timezone,
  ])

  useEffect(() => {
    setIsEnergyMixLoading(
      partyDataLoading ||
        energyLoading ||
        !dates.start ||
        !dates.end ||
        !sharedState.timezone,
    )
  }, [partyDataLoading, energyLoading, dates, sharedState.timezone])

  useEffect(() => {
    setIsPowerLoading(
      partyDataLoading ||
        powerLoading ||
        !dates.start ||
        !dates.end ||
        !sharedState.timezone,
    )
  }, [partyDataLoading, powerLoading, dates, sharedState.timezone])

  useEffect(() => {
    if (!selectedSite && data?.party?.sites.length) {
      history.push(paths.SITES_LIST)
    }
  }, [selectedSite, history, data])

  useEffect(() => {
    if (selectedSite?.siteKey && sharedState.timezone) {
      const { timezone } = sharedState
      setDataInterval(INTERVALS.DAY)
      setNowDates({
        beginningToday: moment().tz(timezone).startOf('day').valueOf(),
        endToday: moment().tz(timezone).endOf('day').valueOf(),
      })
    }
  }, [selectedSite?.siteKey, sharedState.timezone])

  useEffect(() => {
    if (selectedSite) {
      const { timezone, assignments } = selectedSite
      const newSharedState = { ...sharedState }

      const siteProps = extractSiteDetails(selectedSite)

      const customStateOfCharge =
        selectedSite.battery?.siteLevelCustomerStateOfChargePercentage ??
        selectedSite.battery?.socAndChargeCapacity?.find(
          (soc) => soc.customerStateOfCharge != null,
        )?.customerStateOfCharge ??
        0

      const currentStateOfCharge =
        selectedSite.battery?.socAndChargeCapacity?.find(
          (soc) => soc.stateOfChargePercentage != null,
        )?.stateOfChargePercentage ?? 0

      const customerStateOfCharge = CUSTOMER_SOC_FLAG
        ? customStateOfCharge
        : currentStateOfCharge

      const assignment = assignments?.find(
        ({ assignmentEffectiveTimestamp }) =>
          assignmentEffectiveTimestamp != null,
      )
      const isLegacyPvs = assignments?.some(
        (assignment) =>
          DATALOGGER_DEVICE_TYPE.includes(
            assignment?.deviceType?.toLowerCase(),
          ) &&
          !assignment?.devices?.some((device) =>
            NON_LEGACY_COMM_PROTOCOL.includes(device?.comProto),
          ),
      )
      newSharedState.assignmentSerialNumber = assignment?.deviceSerialNumber
      newSharedState.timezone = timezone
      newSharedState.siteDetails = siteProps
      newSharedState.isStorage = selectedSite.siteType === SITE_TYPES.STORAGE
      newSharedState.isProductionSite =
        selectedSite.siteType === SITE_TYPES.PROD_ONLY ||
        selectedSite.siteType === null
      newSharedState.isDaytime =
        siteProps.systemTime.isBefore(siteProps.sunset) &&
        siteProps.systemTime.isAfter(siteProps.sunrise)
      newSharedState.systemSize = selectedSite.systemSize / 1000
      newSharedState.stateOfCharge = customerStateOfCharge * 100
      newSharedState.isLegacyPvs = isLegacyPvs
      newSharedState.batteryMode =
        selectedSite.battery?.operationMode ?? 'UNAVAILABLE'
      newSharedState.firstCommissionDate = selectedSite.firstCommissionDate
      newSharedState.siteKey = selectedSite.siteKey

      setSharedState(newSharedState)
    }
  }, [selectedSite, setSharedState])

  useEffect(() => {
    fbTrackEvent(FirebaseEvents.GLOBAL_HOME, { partyID: currentPartyId })
  }, [currentPartyId])

  const updateCustomInterval = useCallback((start, end) => {
    const daysDiff = Math.abs(moment(start).diff(moment(end), 'day'))

    if (daysDiff === 0) {
      return INTERVALS.DAY
    }
    return INTERVALS.CUSTOM
  }, [])

  const onIntervalChange = useCallback(
    async ({ start, end }, interval: INTERVALS) => {
      if (sharedState.timezone) {
        setIsGraphLoading(true)
        setDates({
          start,
          end,
        })
        const updatedInterval =
          interval === INTERVALS.CUSTOM
            ? updateCustomInterval(start, end)
            : interval

        setDataInterval(updatedInterval)
      }
    },
    [updateCustomInterval, sharedState.timezone],
  )

  const onGraphTabSelection = useCallback(
    (index) => {
      setIsShowingPowerGraph(index === TAB_INDEXES.POWER)
    },
    [setIsShowingPowerGraph],
  )

  const [isDownloadPopupVisible, setIsDownloadPopupVisible] = useState(false)

  const handleDownloadPopupVisibility = () => {
    setIsDownloadPopupVisible(
      (isDownloadPopupVisible) => !isDownloadPopupVisible,
    )
  }

  const handleSetTimeframeData = (e) => {
    onDownloadDataInExcel(e)
  }

  const onDownloadDataInExcel = useCallback(
    async (dataTimeInterval?: string) => {
      const fromDate = moment(dates.start).format(dateFormat)
      const toDate = moment(dates.end).format(dateFormat)
      const unit = isShowingPowerGraph ? 'kW' : 'kWh'
      const data = isShowingPowerGraph ? power : energy
      const periodicity =
        dataTimeInterval === 'hourly'
          ? INTERVALS_PERIODICITY.HOURLY
          : dataTimeInterval === 'daily'
          ? INTERVALS_PERIODICITY.DAILY
          : undefined

      const fromDateWithoutSeconds = moment(dates.start).format('YYYYMMDD')
      const toDateWithoutSeconds = moment(dates.end).format('YYYYMMDD')

      if (periodicity === 'hourly' && sharedState.timezone) {
        fbTrackEvent(FirebaseEvents.BUTTON_EVENT, {
          ElementID: 'Hourly_Data_Export',
        })

        const { data: dataByTheHour } = await client.query({
          query: ENERGY_RANGE_EXCEL_DOWNLOAD_FLAG
            ? FETCH_ENERGY_RANGE
            : FETCH_ENERGY_DATA,
          variables: {
            siteKey: selectedSite?.siteKey,
            interval: 'hour',
            end: moment(toDate).tz(sharedState.timezone).format(dateFormat),
            start: moment(fromDate).tz(sharedState.timezone).format(dateFormat),
          },
        })

        const dataByTheHourEnergySeries = ENERGY_RANGE_EXCEL_DOWNLOAD_FLAG
          ? dataByTheHour.energyRange.energyDataSeries
          : dataByTheHour.energy.energyDataSeries

        exportToExcel(
          `sunpower_export_${fromDateWithoutSeconds}_${toDateWithoutSeconds}`,
          dataByTheHourEnergySeries,
          unit,
          sharedState.isProductionSite,
          sharedState.isStorage,
          isShowingPowerGraph,
          dataInterval,
          periodicity,
          sharedState.timezone,
        )

        setIsDownloadPopupVisible(false)
      } else {
        fbTrackEvent(FirebaseEvents.BUTTON_EVENT, {
          ElementID: 'Daily_Data_Export',
        })
        exportToExcel(
          `sunpower_export_${fromDateWithoutSeconds}_${toDateWithoutSeconds}`,
          data,
          unit,
          sharedState.isProductionSite,
          sharedState.isStorage,
          isShowingPowerGraph,
          dataInterval,
          periodicity,
        )

        setIsDownloadPopupVisible(false)
      }
    },
    [sharedState.isProductionSite, dates, isShowingPowerGraph, power, energy],
  )

  const handleDownloadOptions = () => {
    const startDate = moment.tz(dates.start, timezone)
    const endDate = moment.tz(dates.end, timezone)
    const isCustomDateSameMonth =
      dataInterval === INTERVALS.CUSTOM && startDate.isSame(endDate, 'month')

    const isUnder31Days =
      INTERVALS.CUSTOM && Math.abs(startDate.diff(endDate, 'days')) < 31

    if (
      (DOWNLOAD_TIMEFRAME_FLAG || ENERGY_RANGE_EXCEL_DOWNLOAD_FLAG) &&
      (isCustomDateSameMonth ||
        dataInterval === INTERVALS.WEEK ||
        dataInterval === INTERVALS.CUSTOM ||
        dataInterval === INTERVALS.MONTH) &&
      isUnder31Days
    ) {
      handleDownloadPopupVisibility()
    } else {
      onDownloadDataInExcel()
    }
  }

  const nowBatteryPower = useMemo(() => currentLevels?.storage, [currentLevels])
  const batteryStatus = useMemo(
    () =>
      nowBatteryPower > 0
        ? BATTERY_STATUS.POWERING_HOME
        : nowBatteryPower < 0
        ? BATTERY_STATUS.CHARGING
        : nowBatteryPower === 0 && sharedState?.stateOfCharge === 0
        ? BATTERY_STATUS.EMPTY
        : BATTERY_STATUS.READY,
    [nowBatteryPower, sharedState],
  )
  const isSavingsLoading = isEnergyMixLoading && energy.totalProduction > 0
  const savingsDateTitle = useDateRangeLabel(
    dates.start,
    dates.end,
    dataInterval,

    timezone,
  )
  const { alerts } = useAlerts(selectedSiteKey)
  const hasICD = React.useMemo(
    () => ICD_ALERT_FLAG && alerts.some((a) => a.alertType === ALERTS_ID.ICD),
    [ICD_ALERT_FLAG, alerts],
  )
  const hasGraphData = React.useMemo(() => {
    const hasPowerData = !!power.consumption.length || !!power.production.length
    const hasEnergyData =
      !!energy.consumption.length || !!energy.production.length
    return hasPowerData || hasEnergyData
  }, [energy, power])

  const battery = selectedSite?.battery
  const backupDaysLeftFormatted = battery?.backupTimeLeft?.formatted
  const backUpSocLevel = battery?.backUpReserveSocLevel ?? 0.0

  const isDayTime = useMemo(() => {
    return (
      !['0.0', DEFAULT_NON_ZERO].includes(formatNumber(currentPower)) ||
      sharedState.isDaytime
    )
  }, [currentPower, sharedState.isDaytime])

  useEffect(() => {
    if (dataInterval !== INTERVALS.DAY) {
      // if interval is DAY, we should default to ENERGY tab.
      setIsShowingPowerGraph(false)
    }
  }, [dataInterval])

  useEffect(() => {
    setIsDownloadPopupVisible(false)
  }, [dates])

  const showNewLegend = ENERGY_POWER_CHARTS_REFACTOR_FLAG
  const currentWindowSize = useWindowSize()
  const oldEnergyGraphMarkup = useMemo(() => {
    return (
      <ProductionConsumptionGraph
        energyData={energy}
        powerData={power}
        startDate={dates.start}
        endDate={dates.end}
        interval={dataInterval}
        hasConsumption={!sharedState.isProductionSite}
        onTabSelection={onGraphTabSelection}
        graphType={isShowingPowerGraph ? GRAPH_TYPES.POWER : GRAPH_TYPES.ENERGY}
        windowWidth={currentWindowSize[0]}
      />
    )
  }, [
    currentWindowSize[0],
    sharedState.isProductionSite,
    energy,
    power,
    isShowingPowerGraph,
    dataInterval,
    dates.end,
    dates.start,
  ])

  const initMarkUpOptions = {
    homeConsumption: true,
    solarProduction: true,
    netGrid: true,
    netStorage: true,
  }

  const [newEnergyMarkUpOptions, setnewEnergyMarkUpOptions] =
    useState(initMarkUpOptions)

  useEffect(() => {
    setnewEnergyMarkUpOptions(initMarkUpOptions)
  }, [selectedSite])

  const newEnergyGraphMarkup = useMemo(() => {
    return (
      <React.Fragment>
        <GraphsLegendWithTotals
          gridTotal={energy.totalGrid}
          homeTotal={energy.totalConsumption}
          solarTotal={energy.totalProduction}
          storageTotal={energy.totalStorage}
          siteType={selectedSite?.siteType}
          newEnergyMarkUpOptions={newEnergyMarkUpOptions}
          setnewEnergyMarkUpOptions={setnewEnergyMarkUpOptions}
        />
        <RechartsEnergyPowerGraph
          energyData={energy}
          powerData={power}
          startDate={dates.start}
          endDate={dates.end}
          timezone={timezone}
          interval={dataInterval}
          tooltips={energy.tooltips}
          hasConsumption={!sharedState.isProductionSite}
          graphType={
            isShowingPowerGraph ? GRAPH_TYPES.POWER : GRAPH_TYPES.ENERGY
          }
          siteType={selectedSite?.siteType}
          isLegacyPvs={sharedState?.isLegacyPvs}
          windowWidth={currentWindowSize[0]}
          newEnergyMarkUpOptions={newEnergyMarkUpOptions}
        />
      </React.Fragment>
    )
  }, [
    currentWindowSize[0],
    sharedState.isProductionSite,
    energy,
    power,
    isShowingPowerGraph,
    selectedSite?.siteType,
    newEnergyMarkUpOptions,
  ])

  const energyGraphMarkup = ENERGY_POWER_CHARTS_REFACTOR_FLAG
    ? newEnergyGraphMarkup
    : oldEnergyGraphMarkup

  const energyGraphRechartsMarkup = useMemo(() => {
    return (
      <React.Fragment>
        <GraphsLegendWithTotals
          gridTotal={energy.totalGrid}
          homeTotal={energy.totalConsumption}
          solarTotal={energy.totalProduction}
          storageTotal={energy.totalStorage}
          siteType={selectedSite?.siteType}
          newEnergyMarkUpOptions={newEnergyMarkUpOptions}
          setnewEnergyMarkUpOptions={setnewEnergyMarkUpOptions}
        />
        <RechartsEnergyPowerGraph
          energyData={energy}
          powerData={power}
          startDate={dates.start}
          endDate={dates.end}
          timezone={timezone}
          interval={dataInterval}
          tooltips={energy.tooltips}
          hasConsumption={!sharedState.isProductionSite}
          graphType={
            isShowingPowerGraph ? GRAPH_TYPES.POWER : GRAPH_TYPES.ENERGY
          }
          siteType={selectedSite?.siteType}
          isLegacyPvs={sharedState?.isLegacyPvs}
          windowWidth={currentWindowSize[0]}
          newEnergyMarkUpOptions={newEnergyMarkUpOptions}
        />
        {!showNewLegend && (
          <GraphsLegend
            isPower={isShowingPowerGraph}
            siteType={selectedSite?.siteType}
          />
        )}
      </React.Fragment>
    )
  }, [
    currentWindowSize[0],
    sharedState.isProductionSite,
    energy,
    power,
    isShowingPowerGraph,
    newEnergyMarkUpOptions,
  ])

  const currentPowerGraphMarkup = (
    <CurrentPower
      nowProducing={currentPower}
      isDaytime={isDayTime}
      isLoading={currentPowerLoading}
      sysSize={sharedState.systemSize}
      hasICD={hasICD}
      windowWidth={currentWindowSize[0]}
    />
  )

  const graphMarkup =
    selectedSite?.siteType === SITE_TYPES.STORAGE && STORAGE_GRAPH_FLAG
      ? energyGraphRechartsMarkup
      : energyGraphMarkup

  const modalWithProps = cloneElement(modal ? modal : <div />, {
    siteKey: selectedSite?.siteKey,
    partyId: data?.party?.partyId,
    deviceUuid: vPPEnrollmentDevice?.deviceUuid,
    currentEvent: vPPEnrollmentEvent,
  })

  const connectedSolutionsBanner = useMemo(() => {
    if (
      !DYNAMIC_VPP_FLAG ||
      !(dynamicEnrollmentStatus === ENROLLMENT_STATUS.ACT)
    ) {
      return null
    }
    return (
      <ConnectedSolutionsTopBanner
        status={
          programEventState === EVENT_STATUS.EVENT_IN_PROGRESS ||
          programEventState === EVENT_STATUS.EVENT_SCHEDULED
        }
        redirectPath={paths.VPP_DASHBOARD}
      />
    )
  }, [DYNAMIC_VPP_FLAG, dynamicEnrollmentStatus, programEventState])

  return (
    <Layout sitesList={data?.party?.sites} siteKey={selectedSite?.siteKey}>
      <section className="section dashboard">
        {partyDataLoading ? (
          <div className="dashboard-loader-wrapper">
            <div className="dashboard-loader-content">
              <Loader />
              <p>Loading house data...</p>
            </div>
          </div>
        ) : (
          <div className="tile is-ancestor is-vertical">
            <div className="tile is-parent">
              <FilterBar
                onChange={onIntervalChange}
                commissionDate={sharedState.firstCommissionDate}
                timezone={timezone}
                siteKey={selectedSiteKey}
              />
            </div>
            <div className="tile dashboard-header">
              <div className="tile is-parent graph-section-container">
                <WidgetWrapper
                  headerComponent={
                    dataInterval === INTERVALS.DAY ? (
                      <WidgetTabs
                        tabs={[{ label: 'Energy' }, { label: 'Power' }]}
                        onTabSelection={onGraphTabSelection}
                      />
                    ) : null
                  }
                  customButtonsBefore={[
                    {
                      icon: downloadIcon,
                      alt: 'Download data in Excel format',
                      onClick: handleDownloadOptions,
                    },
                  ]}
                  isLoading={isGraphLoading}
                >
                  {graphMarkup}
                </WidgetWrapper>
                {isDownloadPopupVisible && (
                  <OptionsMenuPopup
                    handleDownloadPopupVisibility={
                      handleDownloadPopupVisibility
                    }
                    handleSetTimeframeData={(e) => handleSetTimeframeData(e)}
                  />
                )}
              </div>
              {!sharedState.isProductionSite && (
                <div className="tile is-4 is-parent">
                  <WidgetWrapper
                    title="Energy mix"
                    isLoading={isEnergyMixLoading}
                  >
                    <EnergyMix
                      energyMixPercentage={energy.energyMixPercentage}
                      energyConsumed={energy.totalConsumption}
                      energyFromSolar={energy.totalProduction}
                      showData={!hasICD || hasGraphData}
                      windowWidth={currentWindowSize[0]}
                    />
                  </WidgetWrapper>
                </div>
              )}
            </div>
            <div className="tile">
              <div className="tile is-4 is-parent">
                <WidgetWrapper
                  title="Current power"
                  infoLink={paths.INFO_CURRENT_POWER}
                  isLoading={currentPowerLoading}
                >
                  {currentPowerGraphMarkup}
                </WidgetWrapper>
              </div>
              <div className="tile is-4 is-parent">
                <WidgetWrapper title="System information">
                  <SystemInfo
                    siteKey={selectedSite?.siteKey}
                    siteDetails={sharedState.siteDetails}
                  />
                </WidgetWrapper>
              </div>
              <div className="tile is-4 is-parent">
                <WidgetWrapper
                  boldTitle="Savings for"
                  title={savingsDateTitle}
                  isLoading={isSavingsLoading}
                >
                  <Savings
                    siteType={selectedSite?.siteType}
                    timezone={timezone}
                    siteKey={selectedSiteKey}
                    endDate={dates.end}
                    startDate={dates.start}
                    interval={dataInterval}
                  />
                </WidgetWrapper>
              </div>
            </div>
            <div className="tile">
              {sharedState.isStorage && (
                <div className="tile is-8 is-parent">
                  <WidgetWrapper
                    title="Storage"
                    infoLink={
                      sharedState.stateOfCharge === 0
                        ? paths.INFO_STORAGE_EMPTY
                        : paths.INFO_STORAGE
                    }
                    hasContentPadding={false}
                    isLoading={isPowerLoading}
                    topBannerComponent={connectedSolutionsBanner}
                  >
                    <Storage
                      stateOfCharge={sharedState.stateOfCharge}
                      batteryMode={sharedState.batteryMode}
                      batteryStatus={batteryStatus}
                      backupDaysLeft={backupDaysLeftFormatted}
                      backUpReserveSocLevel={backUpSocLevel}
                      dynamicVppEvent={vPPEnrollmentEvent}
                      dynamicVppEventStatus={programEventState}
                      onOptedOut={onOptedOut}
                      currentLevel={currentLevels}
                      siteType={selectedSite?.siteType}
                      loading={partyDataLoading}
                    />
                  </WidgetWrapper>
                </div>
              )}
            </div>
            <div className="tile is-parent">
              <MobileMonitoringExperience />
            </div>
          </div>
        )}
        {modal && (
          <Modal
            hideTopBar={hideTopBar}
            title={modalTitle}
            fromPath={paths.ROOT}
            noStylesModal={noStylesModal}
          >
            {modalWithProps}
          </Modal>
        )}
      </section>
    </Layout>
  )
}
