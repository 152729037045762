import './style.scss'

import React from 'react'

import { INTERVALS_PERIODICITY } from '../../shared/intervals'

interface TimeframeData {
  handleSetTimeframeData: (data: string) => void
  handleDownloadPopupVisibility: () => void
}

function OptionsMenuPopup(props: TimeframeData) {
  return (
    <div className="menu-wrapper">
      <div
        className="arrow-up"
        onClick={() => props.handleDownloadPopupVisibility()}
      />
      <div className="options-menu-popup box">
        <div
          className="option-container"
          onClick={() =>
            props.handleSetTimeframeData(INTERVALS_PERIODICITY.HOURLY)
          }
        >
          Download <b>Hourly Data</b>
        </div>
        <hr />
        <div
          className="option-container"
          onClick={() =>
            props.handleSetTimeframeData(INTERVALS_PERIODICITY.DAILY)
          }
        >
          Download <b>Daily Data</b>
        </div>
      </div>
    </div>
  )
}

export default OptionsMenuPopup
