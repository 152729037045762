import './styles/legend.scss'

import React from 'react'

import GridLegend from './assets/grid-legend.svg'
import HomeLegend from './assets/home-legend.svg'
import SolarLegend from './assets/solar-legend.svg'
import StorageLegend from './assets/storage-legend.svg'

const energySourceToLegendIcon = {
  home: HomeLegend,
  solar: SolarLegend,
  grid: GridLegend,
  storage: StorageLegend,
}

const LegendItem = ({ type, title }: { type: string; title: string }) => {
  const [boldTitle, endTitle] = title.split(' ')

  return (
    <div className="legend">
      <img src={energySourceToLegendIcon[type]} alt={title} />
      <div className="legend-title">
        <span className="bold">{boldTitle}</span> {endTitle}
      </div>
    </div>
  )
}

export const GraphsLegend = ({
  isPower = false,
  siteType = '',
}: {
  isPower: boolean
  siteType: string
}) => {
  const consumptionLegend = [
    <LegendItem
      key="graph-legend-home"
      type="home"
      title={`Home ${isPower ? 'Power' : 'Usage'}`}
    />,
    <LegendItem
      key="graph-legend-solar"
      type="solar"
      title={`Solar ${isPower ? 'Power' : 'Energy'}`}
    />,
    <LegendItem
      key="graph-legend-grid"
      type="grid"
      title={`Grid ${isPower ? 'Power' : 'Energy'}`}
    />,
  ]
  const productionLegend = (
    <LegendItem
      key="graph-legend-solar"
      type="solar"
      title={`Solar ${isPower ? 'Power' : 'Energy'}`}
    />
  )
  const storageLegend = [
    <LegendItem
      key="graph-legend-home"
      type="home"
      title={`Home ${isPower ? 'Power' : 'Usage'}`}
    />,
    <LegendItem
      key="graph-legend-solar"
      type="solar"
      title={`Solar ${isPower ? 'Power' : 'Energy'}`}
    />,
    <LegendItem
      key="graph-legend-grid"
      type="grid"
      title={`Grid ${isPower ? 'Power' : 'Energy'}`}
    />,
    <LegendItem
      type="storage"
      key="graph-legend-storage"
      title={`Storage ${isPower ? 'Power' : 'Energy'}`}
    />,
  ]
  const legend =
    siteType === 'consumption'
      ? consumptionLegend
      : siteType === 'storage'
      ? storageLegend
      : productionLegend
  const legendClass =
    siteType === 'production' ? 'legends-container' : 'legends-container'
  return <div className={`${legendClass}`}>{legend}</div>
}
