import React from 'react'

import { Cloudy } from './assets/cloudy'
import { Foggy } from './assets/foggy'
import { NightTime } from './assets/night-time'
import { PartlyCloudy } from './assets/partly-cloudy'
import { PartlyRainy } from './assets/partly-rainy'
import { PartlySnowy } from './assets/partly-snowy'
import { Rainy } from './assets/rainy'
import { Snowy } from './assets/snowy'
import { Sunny } from './assets/sunny'
import { Thunderstorm } from './assets/thunderstorm'

// this was the only way I could achieve recharts to show the icons in the charts,
export const Weather = ({
  condition,
  customX = 0,
  customY = 0,
}: {
  condition: string
  customX?: number
  customY?: number
}) => {
  switch (condition) {
    case 'FOGGY':
      return <Foggy x={customX} y={customY} />
    case 'CLEAR_NIGHT':
      return <NightTime x={customX} y={customY} />
    case 'CLOUDY':
      return <Cloudy x={customX} y={customY} />
    case 'PARTLY_CLOUDY':
      return <PartlyCloudy x={customX} y={customY} />
    case 'PARTLY_SNOWY':
      return <PartlySnowy x={customX} y={customY} />
    case 'PARTLY_RAINY':
      return <PartlyRainy x={customX} y={customY} />
    case 'RAINY':
      return <Rainy x={customX} y={customY} />
    case 'SNOWY':
      return <Snowy x={customX} y={customY} />
    case 'SUNNY':
      return <Sunny x={customX} y={customY} />
    case 'THUNDERSTORM':
      return <Thunderstorm x={customX} y={customY} />
    default:
      return <Sunny x={customX} y={customY} />
  }
}
