import './styles.scss'

import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Footer } from '../../../../components/footer'
import backIcon from './assets/back-orange-arrow.svg'

type Props = {
  children: JSX.Element | JSX.Element[]
  className?: string
  title: string
  endOrnament?: JSX.Element
}
export const VPPLayout = ({
  children,
  className,
  title,
  endOrnament,
}: Props) => {
  const history = useHistory()

  const handleGoBack = () => history.goBack()
  return (
    <div className="layout-wrapper">
      <nav
        className={clsx('simple-navbar level is-mobile')}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="header-items-container level-item has-text-centered navbar-title">
          <div className="navbar-back" onClick={handleGoBack}>
            <img src={backIcon} alt="go back" />
          </div>
          {title.toUpperCase()}
          {endOrnament ? (
            <div className="navbar-end-component">{endOrnament}</div>
          ) : null}
        </div>
      </nav>
      <section className="section has-bar column">
        <div className={className ? className : ''}>{children}</div>
      </section>
      <Footer />
    </div>
  )
}
