import './style.scss'

import clsx from 'clsx'
import moment, { unitOfTime } from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { useDateRangeLabel } from '../../hooks/useDateRangeLabel'
import { getWeatherIconForCondition } from '../../shared/getWeatherIcon'
import { INTERVALS } from '../../shared/intervals'
import { Calendar } from '../calendar'
import calendarLightIcon from '../filter-bar/assets/calendar-light.svg'
import calendarIcon from '../filter-bar/assets/calendar.svg'
import leftArrowLightIcon from '../filter-bar/assets/left-arrow-light.svg'
import leftArrowIcon from '../filter-bar/assets/left-arrow.svg'
import rightArrowLightIcon from '../filter-bar/assets/right-arrow-light.svg'
import rightArrowIcon from '../filter-bar/assets/right-arrow.svg'
import { Loader } from '../loader'
import calendarActiveIcon from './assets/calendar-dark-active.svg'

// Format from toDateString used to convert dates in/out bulma-calendar
export const DATE_STRING_FORMAT = 'ddd MMM DD YYYY'

export const Datepicker = ({
  onChange = (dates: { start: number; end: number }, interval: INTERVALS) => {},
  commissionDate = 0,
  timezone,
  siteKey = '',
  lightArrows = false,
  isRange = true,
  datesData = {
    start: moment().tz(timezone).startOf(INTERVALS.DAY).valueOf(),
    end: moment().tz(timezone).endOf(INTERVALS.DAY).valueOf(),
  },
  weatherCondition = 'UNAVAILABLE',
  loading = false,
}) => {
  const [interval, setIvl] = useState<any>(INTERVALS.DAY)
  const [toDisabled, setToDisabled] = useState(true)
  const [fromDisabled, setFromDisabled] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  // Using moment objects does not trigger useEffect
  const [dates, setDates] = useState({
    start: moment().tz(timezone).startOf(INTERVALS.DAY).valueOf(),
    end: moment().tz(timezone).endOf(INTERVALS.DAY).valueOf(),
  })
  const isLifetime = interval === INTERVALS.LIFETIME
  const isCustom = interval === INTERVALS.CUSTOM
  useEffect(() => {
    if (timezone) {
      onChange(dates, interval)
    }
  }, [dates, interval, timezone, onChange])
  useEffect(() => {
    if (siteKey && timezone) {
      setDates({
        start: moment().tz(timezone).startOf(INTERVALS.DAY).valueOf(),
        end: moment().tz(timezone).endOf(INTERVALS.DAY).valueOf(),
      })
    }
  }, [siteKey, timezone])

  useEffect(() => {
    const commissionDateMoment = moment(commissionDate).clone()
    const startDateMoment = moment(dates.start).tz(timezone)
    const toDateMoment = moment(dates.end).tz(timezone)

    switch (interval) {
      case INTERVALS.DAY: {
        const maximumTo = moment().tz(timezone).endOf(INTERVALS.DAY)
        setToDisabled(
          moment(toDateMoment)
            .add(1, interval as unitOfTime.Base)
            .isAfter(maximumTo),
        )
        const startDateCopy = startDateMoment.clone()
        const minimumDay = commissionDateMoment.clone().startOf(INTERVALS.DAY)
        const checkDay = startDateCopy
          .subtract(1, interval as unitOfTime.Base)
          .isBefore(minimumDay)
        setFromDisabled(checkDay)
        break
      }
      default: {
        break
      }
    }
  }, [dates.start, dates.end, interval, commissionDate, timezone])

  const onAddInterval = React.useCallback(() => {
    if (!toDisabled) {
      setDates({
        start: moment(dates.start)
          .tz(timezone)
          .add(1, interval as unitOfTime.Base)
          .startOf(interval)
          .valueOf(),
        end: moment(dates.end)
          .tz(timezone)
          .add(1, interval as unitOfTime.Base)
          .endOf(interval)
          .valueOf(),
      })
    }
  }, [setDates, dates.start, dates.end, toDisabled, timezone, interval])

  const onSubtractInterval = React.useCallback(() => {
    if (!fromDisabled) {
      const start = moment(dates.start)
        .tz(timezone)
        .subtract(1, interval as unitOfTime.Base)
        .startOf(interval)
        .valueOf()
      const end = moment(dates.end)
        .tz(timezone)
        .subtract(1, interval as unitOfTime.Base)
        .endOf(interval)
        .valueOf()
      setDates({
        start: start,
        end: end,
      })
    }
  }, [setDates, dates.start, dates.end, interval, fromDisabled, timezone])

  const onCustomDateSelect = React.useCallback(
    (date) => {
      const timezoneStartDate = moment(
        date.startDate.toDateString(),
        DATE_STRING_FORMAT,
      )
        .endOf('day')
        .tz(timezone)

      const timezoneEndDate = isRange
        ? moment(date.endDate.toDateString(), DATE_STRING_FORMAT).tz(timezone)
        : moment(date.startDate.toDateString(), DATE_STRING_FORMAT)
            .endOf('day')
            .tz(timezone)

      setDates({
        start: timezoneStartDate.valueOf(),
        end: timezoneEndDate.valueOf(),
      })
      setShowCalendar(false)
    },
    [setDates, setShowCalendar, isRange, timezone],
  )

  const dateLabel = useDateRangeLabel(
    dates.start,
    dates.end,
    interval,
    timezone,
  )
  const leftArrowMarkup = lightArrows ? leftArrowLightIcon : leftArrowIcon
  const rightArrowMarkup = lightArrows ? rightArrowLightIcon : rightArrowIcon
  const calendarIconMarkup = lightArrows ? calendarLightIcon : calendarIcon
  const activeCalendar = showCalendar ? calendarActiveIcon : calendarIconMarkup
  const weatherIcon = useMemo(() => {
    return getWeatherIconForCondition(weatherCondition)
  }, [weatherCondition])
  return (
    <div className="panels-datepicker">
      <div
        data-testid="arrow-prev"
        onClick={onSubtractInterval}
        className={clsx('arrow', {
          disabled: fromDisabled || isLifetime || isCustom,
        })}
      >
        <img src={leftArrowMarkup} alt="change filter" />
      </div>
      {loading ? (
        <div className="panels-loader">
          <Loader />
        </div>
      ) : (
        <section className="switcher-label-toggle">
          <img src={weatherIcon} alt="weather icon" />
          <span>{dateLabel}</span>
          <div
            onClick={() => setShowCalendar(true)}
            className={`calendar-icon ${showCalendar ? 'active' : ''}`}
          >
            <img src={activeCalendar} alt="calendar icon" />
          </div>
        </section>
      )}
      <div
        data-testid="arrow-next"
        onClick={onAddInterval}
        className={clsx('arrow', {
          disabled: toDisabled || isLifetime || isCustom,
        })}
      >
        <img src={rightArrowMarkup} alt="change filter" />
      </div>
      {showCalendar && (
        <OutsideClickHandler onOutsideClick={() => setShowCalendar(false)}>
          <Calendar
            onChange={onCustomDateSelect}
            className="filter-calendar"
            options={{
              startDate: moment(dates.start)
                .tz(timezone)
                .startOf(INTERVALS.DAY)
                .format(DATE_STRING_FORMAT),
              endDate: moment(dates.start)
                .tz(timezone)
                .endOf(INTERVALS.DAY)
                .format(DATE_STRING_FORMAT),
              isRange: isRange,
              maxDate: moment().toDate(),
              minDate: moment(commissionDate).toDate(),
            }}
          />
        </OutsideClickHandler>
      )}
    </div>
  )
}
