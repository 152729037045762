import './style.scss'
import { useState } from 'react'
import axios from 'axios'
import { Loader } from '../../components/loader'
import paths from '../Router/paths'

const Login = ({ location, history }) => {
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleLogin = () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_EDP_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    setLoading(true)
    if (email && password) {
      axiosInstance
        .post('v1/auth/okta/signin', { username: email, password })
        .then((res) => {
          history.push({
            pathname: `${paths.CALLBACK}`,
            hash: `id_token=${res.data.id_token}&access_token=${res.data.access_token}`,
          })
        })
        .catch((err) => {
          setError(err.response.data.message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <div className="login-layout">
      <b>Sign In</b>
      <div className="login-form">
        <div className="login-group">
          <label htmlFor="">Email</label>
          <input
            name="email"
            type="text"
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="login-group">
          <label htmlFor="">Password</label>
          <input
            name="password"
            type="password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="login-button" onClick={handleLogin}>
          {loading ? <Loader /> : <p>Login</p>}
        </button>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  )
}

export default Login
