import './feedback.scss'

import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as React from 'react'
import { useCookies } from 'react-cookie'

import { LayoutWithBackground } from '../../components/layout-with-background'
import { Modal } from '../../components/modal'
import { Rate } from '../../components/rate'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { DataLayerEventName } from '../../shared/typings/dataLayer'
import paths from '../Router/paths'
import checkImg from './assets/check.svg'
import { SAVE_FEEDBACK } from './mutation'

export const FeedbackPage = ({ modal }: { modal?: JSX.Element }) => {
  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])
  const session = cookies[USER_SESSION_INFO] ?? {}
  const loginOverride = cookies[LOGIN_OVERRIDE]

  const [sendMutation, result] = useMutation(SAVE_FEEDBACK)

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      stars: 0,
      comment: '',
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {}

      if (!values.stars) {
        errors.stars = 'Please select a star to rate'
      }

      return errors
    },
    onSubmit: async (values, { resetForm, setFieldValue }) => {
      const variables = {
        ...values,
        siteKey: cookies[USER_SELECTED_SITE_KEY],
        partyId: loginOverride ?? session.partyId,
        fromWebApp: true,
      }

      try {
        await sendMutation({ variables })
        setFieldValue('stars', 0)
        resetForm({ values: { stars: 0, comment: '' } })
      } catch (error) {
        window.dataLayer.push({
          event: DataLayerEventName.FEEDBACK_SUBMIT_ERROR,
          eventData: { message: error.message },
        })
      }
    },
  })

  return (
    <LayoutWithBackground className="feedback" title="Give us feedback">
      <form onSubmit={handleSubmit}>
        <div className="feedback-content">
          <h3>We want to hear from you!</h3>
          <p className="feedback-text is-uppercase mb-3">
            Rate the app experience:
          </p>
          {touched.stars && errors.stars && (
            <div className="has-text-danger">{errors.stars}</div>
          )}
          <Rate
            className="mb-6"
            totalStars={5}
            selectedStars={values.stars}
            onStarPress={(value) => setFieldValue('stars', value)}
          />
          <textarea
            placeholder="Any additional thoughts?"
            cols={80}
            rows={6}
            className={clsx('textarea', {
              'is-danger': errors.comment && touched.comment,
            })}
            value={values.comment}
            name="comment"
            onChange={handleChange}
          />
          <div className="feedback-buttons-container">
            {result.called && result.data && (
              <div className="feedback-message">
                <img src={checkImg} className="check-img" />
                RATING RECEIVED
              </div>
            )}
            {result.error && (
              <div className="feedback-message is-danger">
                Error sending feedback
              </div>
            )}
            <button
              type="submit"
              disabled={result.loading || !values.stars}
              className={clsx('button feedback-button is-primary', {
                'is-loading': result.loading,
              })}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.FEEDBACK}>
            {modal}
          </Modal>
        )}
      </>
    </LayoutWithBackground>
  )
}
