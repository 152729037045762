import './style.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useMemo } from 'react'
import { Bar, Line } from 'recharts'

import { calculateDaysDifference } from '../../shared/calculateDaysDifference'
import { INTERVALS } from '../../shared/intervals'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import { TEnergyMarkup } from '../graphs-legend/site-legends-new'
import { calcYTickValues, graphAxisData } from './axisCalculations'
import { combineTheSeriesData, combineTheYAxisSeriesData } from './calcGraph'
import { GraphContainer } from './graph-container'
import { EnergyDataType } from './types'

type TooltipsType = {
  tooltipConsumptionTotals: object[]
  tooltipGridTotals: object[]
  tooltipProductionTotals: object[]
  tooltipStorageTotals: object[]
}

type EnergyGraphProps = {
  startDate: number
  endDate: number
  timezone: string
  interval: string
  data: EnergyDataType
  siteType: string
  hasConsumption?: boolean
  tooltips?: TooltipsType | null
  energyMarkUpOptions: TEnergyMarkup
}

export const RechartsEnergyGraph = ({
  startDate,
  endDate,
  interval,
  data,
  timezone,
  siteType,
  hasConsumption = false,
  tooltips,
  energyMarkUpOptions,
}: EnergyGraphProps) => {
  const flags = useFlags() as LaunchDarklyFlags
  const useCustomTooltipsValues = flags.customDatesNvFlag ?? false

  const daysDifference = calculateDaysDifference(startDate, endDate, timezone)

  const isCustom = INTERVALS.CUSTOM === interval?.toLowerCase()

  const isCustomAndIsOneDayDiff = isCustom && daysDifference === 1

  const customDaysDifference = isCustomAndIsOneDayDiff
    ? daysDifference + 2
    : daysDifference

  let {
    consumption = [],
    grid = [],
    production = [],
    storage = [],
  } = isCustom && tooltips ? {} : data

  if (useCustomTooltipsValues && tooltips && isCustom) {
    const customDataConsumption: any[] = []
    tooltips?.tooltipConsumptionTotals.forEach((item) => {
      const total = item['total'].toString()
      const date = item['dateString']
      const customConsumptionItem = [date, total, '0']
      customDataConsumption.push(customConsumptionItem)
    })

    consumption = customDataConsumption

    const customDataGrid: any[] = []
    tooltips?.tooltipGridTotals.forEach((item) => {
      const total = item['total'].toString()
      const date = item['dateString']
      const customGridItem = [date, total, '0']
      customDataGrid.push(customGridItem)
    })

    grid = customDataGrid

    const customDataProduction: any[] = []
    tooltips?.tooltipProductionTotals.forEach((item) => {
      const total = item['total'].toString()
      const date = item['dateString']
      const customProductionItem = [date, total, '0']
      customDataProduction.push(customProductionItem)
    })

    production = customDataProduction

    const customDataStorage: any[] = []
    tooltips?.tooltipStorageTotals.forEach((item) => {
      const total = item['total'].toString()
      const date = item['dateString']
      const customStorageItem = [date, total, '0']
      customDataStorage.push(customStorageItem)
    })

    storage = customDataStorage
  }

  const xAxisTicks = useMemo(
    () =>
      graphAxisData({
        startDate: startDate,
        endDate: endDate,
        timezone: timezone,
        isPower: false,
        isLegacyPvs: undefined,
        customDaysDifference: isCustomAndIsOneDayDiff
          ? customDaysDifference - 1
          : undefined,
      }),
    [
      startDate,
      endDate,
      isCustomAndIsOneDayDiff,
      customDaysDifference,
      timezone,
    ],
  )
  const { tickValues, tickFormat, maxValue } = xAxisTicks[interval]

  const graphData = useMemo(() => {
    return combineTheSeriesData({
      maxDataPoint: maxValue,
      storage: storage,
      production: production,
      consumption: consumption,
      grid: grid,
      interval: interval,
      startDate: startDate,
      endDate: endDate,
      timezone: timezone,
      customDaysDifference: customDaysDifference,
    })
  }, [
    production,
    maxValue,
    storage,
    consumption,
    grid,
    interval,
    startDate,
    endDate,
    timezone,
    customDaysDifference,
  ])

  const yAxisGraphData = useMemo(
    () =>
      combineTheYAxisSeriesData(
        storage,
        production,
        consumption,
        grid,
        interval,
        startDate,
        endDate,
        timezone,
      ),
    [
      storage,
      production,
      consumption,
      grid,
      interval,
      startDate,
      endDate,
      timezone,
    ],
  )

  const yAxisTicks = useMemo(
    () => calcYTickValues(siteType, yAxisGraphData),
    [siteType, yAxisGraphData],
  )

  const consumptionTypeBars = [
    energyMarkUpOptions.solarProduction ? (
      <Bar
        key="energy-bar-production"
        dataKey="production"
        fill="#EF8b22"
        xAxisId="timestampAxis"
      />
    ) : null,
    energyMarkUpOptions.homeConsumption ? (
      <Line
        strokeWidth={2}
        type="natural"
        dot={{ fill: '#000' }}
        key="energy-line-consumption"
        dataKey="consumption"
        stroke="#404040"
        xAxisId="timestampAxis"
      />
    ) : null,
    energyMarkUpOptions.netGrid ? (
      <Bar
        key="energy-bar-grid"
        dataKey="grid"
        fill="#0071B9"
        xAxisId="timestampAxis"
      />
    ) : null,
  ]
  const productionTypeBars = energyMarkUpOptions.solarProduction ? (
    <Bar
      key="energy-bar-production"
      dataKey="production"
      fill="#EF8b22"
      xAxisId="timestampAxis"
    />
  ) : null

  const storageTypeBars = [
    energyMarkUpOptions.solarProduction ? (
      <Bar
        key="energy-bar-production"
        dataKey="production"
        fill="#EF8b22"
        xAxisId="timestampAxis"
      />
    ) : null,
    energyMarkUpOptions.netGrid ? (
      <Bar
        key="energy-bar-grid"
        dataKey="grid"
        fill="#0071B9"
        xAxisId="timestampAxis"
      />
    ) : null,
    energyMarkUpOptions.netStorage ? (
      <Bar
        key="energy-bar-storage"
        dataKey="storage"
        fill="#6AA442"
        xAxisId="timestampAxis"
      />
    ) : null,
    hasConsumption && energyMarkUpOptions.homeConsumption && (
      <Line
        strokeWidth={2}
        type="natural"
        key="energy-line-consumption"
        dataKey="consumption"
        stroke="#404040"
        dot={{ fill: '#000' }}
        xAxisId="timestampAxis"
      />
    ),
  ]

  const graphVisuals =
    siteType === 'consumption'
      ? consumptionTypeBars
      : siteType === 'storage'
      ? storageTypeBars
      : productionTypeBars

  return (
    <GraphContainer
      customTooltipsAndValues={interval === INTERVALS.CUSTOM ? tooltips : null}
      data={graphData}
      yAxisTicks={yAxisTicks}
      interval={interval}
      daysDifference={daysDifference}
      tickValues={tickValues}
      tickFormat={(tick) => tickFormat(tick, timezone)}
      siteType={siteType}
    >
      {graphVisuals}
    </GraphContainer>
  )
}
