import { get } from 'lodash'
import moment from 'moment'

export const baseHeaders = () => {
  //const idToken = document.querySelector('meta[name="csrf-token"]').content; // TODO: adapt to the new monorepo
  const idToken = ''

  return {
    'Content-Type': 'application/json',
    'X-CSRF-Token': idToken,
  }
}

export const isMilestoneSwitchRequired = (commonData, local) => {
  const customerCheck = commonData
  const hasInstallTrackerActivated = customerCheck
    ? customerCheck.milestone === 'install-tracker'
    : false
  const hasActivationTrackerActivated = customerCheck
    ? customerCheck.milestone === 'activation-tracker'
    : false
  const hasMonitoringActivated = customerCheck
    ? customerCheck.milestone === 'energy-link'
    : false
  const hasOutstandingCustomerCheckAction = customerCheck
    ? (isProposalAfterActivatedDate(commonData) && customerCheck.proposal) ||
      customerCheck.credit ||
      customerCheck.agreement ||
      customerCheck.utilDocs
    : false
  const hasPendingContractCreditShortcut = local
    ? local.overriddenMilestone &&
      local.shortcutData &&
      ['contract', 'credit-check'].includes(local.shortcutData.targetPage)
    : false
  const needsMilestoneSwitch =
    (hasInstallTrackerActivated ||
      hasActivationTrackerActivated ||
      hasMonitoringActivated) &&
    (hasOutstandingCustomerCheckAction || hasPendingContractCreditShortcut)
  return needsMilestoneSwitch
}

export const isProposalAfterActivatedDate = (commonData) => {
  const customerCheck = commonData
  const milestone = get(customerCheck, 'milestone')
  const installTrackerActivatedDate = get(
    customerCheck,
    'installTrackerActivatedDate',
  )
  const activationTrackerActivatedDate = get(
    customerCheck,
    'activationTrackerActivatedDate',
  )
  const latestQuoteSentToCustomer = get(
    customerCheck,
    'latestQuoteSentToCustomer',
  )
  const hasLatestQuoteAndITActivated =
    !!installTrackerActivatedDate && !!latestQuoteSentToCustomer
  const hasLatestQuoteAndATActivated =
    !!activationTrackerActivatedDate && !!latestQuoteSentToCustomer
  if (milestone === 'energy-link') return true // energy-link milestone can skip the proposal date and activation date comparison
  if (
    hasLatestQuoteAndITActivated &&
    moment(latestQuoteSentToCustomer).isAfter(
      moment(installTrackerActivatedDate),
    )
  ) {
    return true
  } else if (
    hasLatestQuoteAndATActivated &&
    moment(latestQuoteSentToCustomer).isAfter(
      moment(activationTrackerActivatedDate),
    )
  ) {
    return true
  } else {
    return false
  }
}
