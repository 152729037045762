import './styles.scss'

import React from 'react'
import Lottie from 'react-lottie'

import { AccordionFAQsPanels } from '../../components/faq-panels'
import { Layout } from '../../components/layout'
import { Modal } from '../../components/modal'
import { useWindowSize } from '../../shared/hooks'
import panel_animation from '../AlertModals/assets/panel_animation/panel_animation'
import paths from '../Router/paths'

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: panel_animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const FAQPanels = ({ modal }: { modal?: JSX.Element }) => {
  const windowSize = useWindowSize()
  const width = windowSize[0]

  return (
    <Layout title="PANEL LEVEL DATA">
      <>
        <div className="container">
          <div className="header-container">
            <div className="animation-container">
              <Lottie
                options={defaultOptions}
                width={width < 767 ? width : 630}
              />
            </div>
            <div className="labels-container">
              <div className="title">Introducing Panel Level Data: </div>
              <div className="subtitle">
                The power of individual microinverters in your hands!
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="faqs-title">FAQs</div>
            <div className="underline" />
            <AccordionFAQsPanels />
          </div>
        </div>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.F_A_Q_PANELS}>
            {modal}
          </Modal>
        )}
      </>
    </Layout>
  )
}
