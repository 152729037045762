import { useApolloClient, useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  getSubscriptionParams,
  getWebsocketClient,
  setSubscriptionParams,
} from '../../config/apollo'
import { DataLayerEventName } from '../typings/dataLayer'
import { FETCH_CURRENT_POWER_LEVEL, SUBSCRIBE_POWER_DATA } from './query'

export const CURRENT_POWER_POLL_INTERVAL = 1000 * 60 * 5 /* 5 minutes */

export type CurrentLevels = {
  production: number
  consumption: number
  storage: number
  grid: number
}

const defaultResponse = {
  production: 0,
  consumption: 0,
  storage: 0,
  grid: 0,
}

export const useCurrentPowerLevels = (
  siteKey: string | undefined,
  hasLivedata: boolean = false,
  deviceSerialNumber: string,
): [CurrentLevels, boolean] => {
  const [currentLevels, setCurrentLevels] =
    useState<CurrentLevels>(defaultResponse)
  const [liveDataLoading, setLiveDataLoading] = useState(false)
  const [forceApi, setForceApi] = useState<number | null>(null)
  const forceApiRef = useRef<number | null>(null)
  const client = useApolloClient()
  const liveDataSubscription = useRef<any>()
  const liveDataObserver = useRef<any>()
  const liveDataErrorListener = useRef<any>()
  const [fetchCurrentPower, { data, loading, stopPolling, startPolling }] =
    useLazyQuery(FETCH_CURRENT_POWER_LEVEL, {
      fetchPolicy: 'cache-and-network',
      variables: { siteKey },
      pollInterval: CURRENT_POWER_POLL_INTERVAL,
    })

  const shouldConnectLiveData = useMemo(
    () => !!(siteKey && hasLivedata && deviceSerialNumber),
    [deviceSerialNumber, hasLivedata, siteKey],
  )

  const connectToLiveData = useCallback(() => {
    liveDataSubscription.current?.unsubscribe()
    setLiveDataLoading(true)

    // Creates observer from Apollo client (this starts the websocket connection)
    liveDataObserver.current = client.subscribe({
      query: SUBSCRIBE_POWER_DATA,
    })

    // Subscribes to the observer results
    liveDataSubscription.current = liveDataObserver.current.subscribe(
      ({ data: { receiveLiveData } }) => {
        if (liveDataLoading) {
          setLiveDataLoading(false)
          stopPolling?.()
        }
        setCurrentLevels(receiveLiveData ?? defaultResponse)
      },
      (error: object) => {
        console.log(error)
      },
    )
  }, [stopPolling, client])

  useEffect(() => {
    if (siteKey) {
      fetchCurrentPower()
    }
  }, [siteKey, fetchCurrentPower])

  useEffect(() => {
    const { currentPower } = data || {
      currentPower: {
        production: '0',
        consumption: '0',
        storage: '0',
        grid: '0',
      },
    }

    const convertedData = {
      production: parseFloat(currentPower?.production ?? '0'),
      consumption: parseFloat(currentPower?.consumption ?? '0'),
      storage: parseFloat(currentPower?.storage ?? '0'),
      grid: parseFloat(currentPower?.grid ?? '0'),
    }

    setCurrentLevels(convertedData)
  }, [data])

  useEffect(() => {
    const currentParams = getSubscriptionParams()
    if (shouldConnectLiveData && liveDataSubscription.current === undefined) {
      if (currentParams.siteKey !== siteKey) {
        getWebsocketClient()?.close(true)
        setSubscriptionParams(siteKey, deviceSerialNumber)
      }
      startPolling?.(CURRENT_POWER_POLL_INTERVAL)
      connectToLiveData()
    }
  }, [hasLivedata, shouldConnectLiveData])

  useEffect(() => {
    if (!forceApi) {
      // Switches back to API polling if there was a websocket error
      liveDataErrorListener.current = getWebsocketClient()?.onError(
        (liveDataError) => {
          if (liveDataLoading) {
            setLiveDataLoading(false)
          }
          forceApiRef.current = moment().valueOf()
          setForceApi(moment().valueOf())
          window.dataLayer.push({
            event: DataLayerEventName.LIVE_DATA_ERROR,
            eventData: { stack: liveDataError.stack },
          })
        },
      )
    }

    return () => liveDataErrorListener.current?.()
  }, [forceApi, liveDataLoading])

  return [currentLevels, loading]
}
