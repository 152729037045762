import './style.scss'

import React, { useState } from 'react'

export const MoreButton = ({ children }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={`${!open ? 'more-button not-open' : 'more-button'}`}>
      {open && <div className="more-content py-5">{children}</div>}
      {!open ? (
        <button
          className="button is-white is-size-5"
          onClick={() => setOpen(true)}
        >
          More
          <span className="chevron down ml-4"></span>
        </button>
      ) : (
        <button
          className="button is-white is-size-5"
          onClick={() => setOpen(false)}
        >
          Less
          <span className="chevron up ml-4"></span>
        </button>
      )}
    </div>
  )
}
