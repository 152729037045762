import './layoutWithBackground.scss'

import clsx from 'clsx'
import React from 'react'

import { Layout } from '../layout'
import homeDefault from './assets/home-default.svg'
import homeDollar from './assets/home-dollar.svg'
import homeQuestionMark from './assets/home-question-mark.svg'

export const HOME_ASSETS = {
  DEFAULT: homeDefault,
  QUESTION: homeQuestionMark,
  DOLLAR: homeDollar,
}

type Props = {
  children: JSX.Element | JSX.Element[]
  className?: string
  title: string
  asset?: string
  invertCurve?: boolean
  backgroundOverlayComponent?: JSX.Element | JSX.Element[]
}
export const LayoutWithBackground = ({
  children,
  className,
  title,
  asset = HOME_ASSETS.DEFAULT,
  invertCurve = false,
  backgroundOverlayComponent,
}: Props) => {
  const backgroundOverlayMarkup = backgroundOverlayComponent ? (
    backgroundOverlayComponent
  ) : (
    <img src={asset} alt="house icon" className="house-img" />
  )
  const backgroundCurveClassname = invertCurve
    ? 'bg-box-invert-background'
    : 'bg-box-background'

  const layoutBodyMarkup = (
    <div className="bg-box">
      <div className={backgroundCurveClassname} />
      <div className={clsx('bg-box-content box px-0 py-0', className)}>
        {backgroundOverlayMarkup}
        {children}
      </div>
    </div>
  )

  return <Layout title={title}>{layoutBodyMarkup}</Layout>
}
