import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import { animated, config, useSpring } from 'react-spring'

import moonBackgroundIl from '../assets/moon-background.png'
import moonIl from '../assets/moon.png'
import sunBackgroundIl from '../assets/sun-background.png'
import sunIl from '../assets/sun.png'

const GLOW_TIME = 1
const backgroundSky = '#4FAAD1'
const backgroundSkyOrange = '#FA9E00'
const backgroundSkyDark = '#172A47'

type BackgroundSkyProps = {
  width: number
  showPanelSlider: boolean
  activeIndex: number
  sunrise: number
  sunset: number
  isPageLoading: boolean
  isPowerTimelapse: boolean
}

export const BackgroundSky = ({
  width,
  showPanelSlider,
  activeIndex,
  sunrise = 7,
  sunset = 19,
  isPageLoading = false,
  isPowerTimelapse = false,
}: BackgroundSkyProps) => {
  const [isLoading, setIsPageLoading] = useState<boolean>(isPageLoading)

  const { o } = useSpring(
    !isLoading && !isPowerTimelapse
      ? {
          from: { o: 0 },
          o: activeIndex,
          config: { ...config.molasses, duration: 0 },
        }
      : {
          from: { o: 0 },
          o: activeIndex,
          config: config.molasses,
          reset: activeIndex === 0,
        },
  )

  useEffect(() => {
    if (isLoading || isPowerTimelapse) {
      setIsPageLoading(false)
    }
  }, [isLoading, isPowerTimelapse])

  const dynamicDayRange = useMemo(() => {
    return [
      0,
      sunrise,
      sunrise + GLOW_TIME / 2,
      sunrise + GLOW_TIME,
      sunset - GLOW_TIME,
      sunset - GLOW_TIME / 2,
      sunset,
      24,
    ]
  }, [sunrise, sunset])

  return (
    <div
      className={clsx('header-clouds-container', {
        'content-with-slider': showPanelSlider && width > 749,
      })}
    >
      <div className="clouds-container">
        <animated.div
          style={{
            flex: 1,
            height: 300,
            display: 'flex',
            backgroundColor: o.to({
              range: dynamicDayRange,
              output: [
                backgroundSkyDark,
                backgroundSkyDark,
                backgroundSkyOrange,
                backgroundSky,
                backgroundSky,
                backgroundSkyOrange,
                backgroundSkyDark,
                backgroundSkyDark,
              ],
            }),
          }}
        >
          <animated.div
            style={{
              position: 'absolute',
              height: 55,
              top: o
                .to({
                  range: dynamicDayRange,
                  output: [10, 30, 70, 100, 100, 70, 30, 10],
                })
                .to((o) => `${o}%`),
              left: o
                .to({
                  range: dynamicDayRange,
                  output: [50, 15, 10, 5, 95, 90, 85, 50],
                })
                .to((o) => `calc(${o}% - 29px)`),
            }}
          >
            <img
              src={moonIl}
              style={{ width: 58, height: 55 }}
              alt="Moon Illustration"
            />
          </animated.div>
          <animated.div
            style={{
              position: 'absolute',
              top: o
                .to({
                  range: dynamicDayRange,
                  output: [100, 70, 50, 0, 0, 50, 70, 100],
                })
                .to((o) => `${o}%`),
              left: o
                .to({
                  range: dynamicDayRange,
                  output: [50, 90, 95, 90, 10, 5, 5, 50],
                })
                .to((o) => `calc(${o}% - 57px)`),
            }}
          >
            <img
              src={sunIl}
              style={{ width: 114, height: 116 }}
              alt="Sun Illustration"
            />
          </animated.div>
          <animated.img
            src={sunBackgroundIl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              width: '100%',
              height: 200,
              opacity: o.to({
                range: [0, sunrise, sunset, 24],
                output: [0, 1, 1, 0],
              }),
            }}
          />
          <animated.img
            src={moonBackgroundIl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              width: '100%',
              height: 200,
              objectFit: 'cover',
              opacity: o.to({
                range: [0, sunrise + GLOW_TIME, sunset - GLOW_TIME, 24],
                output: [1, 0, 0, 1],
              }),
            }}
          />
        </animated.div>
      </div>
      <div className="header-circle" />
    </div>
  )
}
