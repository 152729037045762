import './style.scss'
import '../../styles/setupMode.scss'

import React from 'react'

interface SliderProps {
  onSliderChange: (e: any) => void
  sliderValue: string
  min: string
  max: string
  leftDescriptiveLabel?: string | JSX.Element
  rightDescriptiveLabel?: string | JSX.Element
}

export const Slider = ({
  onSliderChange = (e: any) => {},
  sliderValue = '1',
  min = '1',
  max = '50',
  leftDescriptiveLabel = 'more savings',
  rightDescriptiveLabel = 'more reserves',
}: SliderProps) => {
  return (
    <section className="slider-with-indicators-container ">
      <section className="slider-details columns is-mobile is-vcentered is-centered">
        <span className="column left-indicator">{min}%</span>
        <div className="range-wrap">
          <input
            onChange={onSliderChange}
            min={min}
            max={max}
            value={sliderValue}
            step="1"
            type="range"
            className="range spwr-slider column is-fullwidth is-large is-circle"
            id="sliderWithValue"
          />
        </div>
        <span className="column right-indicator">{max}%</span>
      </section>
      <section className="slider-subtitles">
        <div className="is-uppercase slider-indicator">
          {leftDescriptiveLabel}
        </div>
        <div className="is-uppercase slider-indicator">
          {rightDescriptiveLabel}
        </div>
      </section>
    </section>
  )
}
