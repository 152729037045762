import './style.scss'

import React from 'react'
import { useHistory } from 'react-router-dom'

import editIcon from '../widget-wrapper/assets/edit-icon.svg'

type ButtonLinks = {
  onEditClick?: string
}

type SliderCard = {
  title?: String
  value?: String
  body?: String
  buttonLinks?: ButtonLinks
}
export const SliderCard = ({
  title = '',
  value = '0%',
  body = '',
  buttonLinks = {},
}: SliderCard) => {
  const history = useHistory()

  const goToPath = ({ link, state }) => {
    history.push({
      pathname: link,
      state: state,
    })
  }

  return (
    <section className="slider-card">
      <header>
        <h3>{title}</h3>
        <aside className="header-side-title-container">
          <h4>{value}</h4>
          <img
            className={'widget-icon mr-3'}
            src={editIcon}
            alt="edit"
            onClick={() =>
              buttonLinks && buttonLinks?.onEditClick
                ? goToPath({
                    link: buttonLinks.onEditClick,
                    state: { isDefaultReserveLevel: true },
                  })
                : ''
            }
          />
        </aside>
      </header>
      <div className="divider" />
      <section className="slider-card-body">{body}</section>
    </section>
  )
}
