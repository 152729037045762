import './styles.scss'

import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useDynamicVpp } from '../../../../shared/hooks/useDynamicVpp'
import { DocusignURL, StepType } from '../../../../shared/typings/site'
import { ProgramSignUpSteps } from '../../../../shared/typings/site'
import paths from '../../../Router/paths'
import checkIcon from '../../assets/check-icon.svg'
import InfoModalIcon from '../../assets/info-modal-icon.svg'
import InfoModal from '../info-modal/info-modal'
import { OhmForm } from '../ohm-form'

type stepProps = ProgramSignUpSteps & {
  handleNext: (stepNumber: number) => void
  docusignURL?: DocusignURL
}

export const DynamicStep = (props: stepProps) => {
  const {
    currentStep,
    setCurrentStep,
    handleUpdateTextField,
    selectedEnrollment,
    selectedSiteKey,
  } = useDynamicVpp()

  const {
    fieldInitialValue,
    sequenceOrder,
    pageSubtitle,
    pageTitle,
    buttonText,
    fieldName,
    stepType,
    docusignURL,
    tooltipTitle,
    tooltipBody,
  } = props
  const history = useHistory()

  const { isOhmConnect } = selectedEnrollment ?? { isOhmConnect: false }
  const ohmForm = useRef<HTMLFormElement | null>(null)

  const [inputValue, setInputValue] = useState(fieldInitialValue)
  const [showModal, setShowModal] = useState(false)

  if (stepType === StepType.SIGNATURE && docusignURL) {
    return <DocuSignStep {...props} />
  }
  const active = currentStep === sequenceOrder
  const completed = fieldInitialValue !== ''

  const hasModal = tooltipTitle && tooltipBody

  const handleOhmRedirect = () => {
    ohmForm.current?.submit()
    history.push(paths.VPP_DASHBOARD)
  }

  const device_uuid = selectedEnrollment?.enrollmentDevice?.deviceUuid ?? ''

  return (
    <>
      <div
        className="enroll-step"
        onClick={() => {
          setCurrentStep(sequenceOrder)
        }}
      >
        <div>
          <div className="enroll-title-group">
            <p className={clsx('step-title', { 'enroll-step-active': active })}>
              {`${sequenceOrder}. ${pageSubtitle}`}
              {hasModal && (
                <img
                  src={InfoModalIcon}
                  alt=""
                  className="modal-icon"
                  onClick={() => setShowModal(true)}
                />
              )}
            </p>
            {completed && !active && <img src={checkIcon} alt="check mark" />}
          </div>
          {active && (
            <div className="enroll-step-input-section">
              <div>
                <p className="enroll-input-label">{pageTitle}</p>
                <input
                  type="text"
                  className="enroll-input-text"
                  value={inputValue}
                  onChange={(event) => {
                    setInputValue(event.target.value)
                  }}
                />
              </div>

              <button
                className="enroll-step-next"
                disabled={inputValue === ''}
                onClick={() => {
                  handleUpdateTextField(
                    inputValue ?? '',
                    fieldName ?? '',
                    isOhmConnect ? handleOhmRedirect : undefined,
                  )
                }}
              >
                {buttonText}
              </button>
            </div>
          )}
          {isOhmConnect && (
            <OhmForm
              ref={ohmForm}
              siteKey={selectedSiteKey}
              deviceUuid={device_uuid}
            />
          )}
        </div>
      </div>
      {showModal && (
        <InfoModal
          title={tooltipTitle ?? ''}
          body={tooltipBody ?? ''}
          handleClose={() => setShowModal(false)}
          buttonText="Okay"
        />
      )}
    </>
  )
}

export const DocuSignStep = ({
  sequenceOrder,
  pageSubtitle,
  documentStepIsCompleted,
  docusignURL,
  tooltipTitle,
  tooltipBody,
}: stepProps) => {
  const { currentStep, setCurrentStep } = useDynamicVpp()

  const [showModal, setShowModal] = useState(false)
  const active = currentStep === sequenceOrder

  const redirectToDocusignExternalPage = () => {
    if (docusignURL && docusignURL.webviewUrl) {
      window.location.href = docusignURL.webviewUrl
    }
  }
  const hasModal = tooltipTitle && tooltipBody
  return (
    <>
      <div
        className="enroll-step"
        onClick={() => {
          if (!active) {
            setCurrentStep(sequenceOrder)
          }
        }}
      >
        <div className="step-docusign-section">
          <p className={clsx('step-title', { 'enroll-step-active': active })}>
            {`${sequenceOrder}. ${pageSubtitle}`}
            {hasModal && (
              <img
                src={InfoModalIcon}
                alt=""
                className="modal-icon"
                onClick={() => setShowModal(true)}
              />
            )}
          </p>
          {active && !documentStepIsCompleted && (
            <div
              className="enroll-step-docusign-button"
              onClick={() => {
                redirectToDocusignExternalPage()
              }}
            >
              Sign Agreement
            </div>
          )}
          {documentStepIsCompleted && <img src={checkIcon} alt="check mark" />}
        </div>
      </div>
      {showModal && (
        <InfoModal
          title={tooltipTitle ?? ''}
          body={tooltipBody ?? ''}
          handleClose={() => setShowModal(false)}
          buttonText="Okay"
        />
      )}
    </>
  )
}
