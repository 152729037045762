import img_0 from './images/img_0.png'
import img_1 from './images/img_1.png'
import img_2 from './images/img_2.png'
import img_3 from './images/img_3.png'
import img_4 from './images/img_4.png'
import img_5 from './images/img_5.png'

export default {
  v: '5.7.4',
  fr: 29.9700012207031,
  ip: 0,
  op: 599.00002439777,
  w: 750,
  h: 468,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 28, h: 28, u: '', p: img_0, e: 0 },
    { id: 'image_1', w: 551, h: 208, u: '', p: img_1, e: 0 },
    { id: 'image_2', w: 52, h: 52, u: '', p: img_2, e: 0 },
    { id: 'image_3', w: 1306, h: 695, u: '', p: img_3, e: 0 },
    { id: 'image_4', w: 1306, h: 696, u: '', p: img_4, e: 0 },
    { id: 'image_5', w: 1306, h: 696, u: '', p: img_5, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Artboard 1 copy@1x.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 34,
                  s: [0],
                },
                { t: 61.0000024845809, s: [95] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [350, 370, 0], ix: 2, l: 2 },
            a: { a: 0, k: [653, 347.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 900.000036657751,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Inside-lines.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [350, 370, 0], ix: 2, l: 2 },
            a: { a: 0, k: [653, 348, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: 'a',
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 23,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-1308, -20],
                          [-1308, 676],
                          [-2, 676],
                          [-2, -20],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 26,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-1122.572, -24],
                          [-1122.572, 672],
                          [-109.714, 708.857],
                          [577.143, -16.857],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 30.0000012219251,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-10, -48],
                          [-10, 648],
                          [1050, 654],
                          [1406, -40],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: 'Mask 1',
            },
          ],
          ip: 0,
          op: 900.000036657751,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'outside-lines.png',
          cl: 'png',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [350, 370, 0], ix: 2, l: 2 },
            a: { a: 0, k: [653, 348, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: 'a',
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-66.138, -201.377],
                          [-279.216, 779.196],
                          [-138.914, 676.898],
                          [47.223, -179.694],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 23.0000009368092,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [84.701, -276.787],
                          [-166.092, 415.169],
                          [1018.841, 849.498],
                          [1442.121, -10.816],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: 'Mask 1',
            },
          ],
          ip: 0,
          op: 900.000036657751,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Small-energy-atom.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 207,
              s: [-24, 232, 0],
              to: [65.167, 6.833, 0],
              ti: [-0.48, 0.44, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 249,
              s: [367, 273, 0],
              to: [0.99, -0.909, 0],
              ti: [0.44, 0.82, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 283,
              s: [491.5, 98.5, 0],
              to: [-0.125, -0.232, 0],
              ti: [118.417, 8.083, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 318,
              s: [-41.5, 68.5, 0],
              to: [-87.979, -6.006, 0],
              ti: [-63.583, -33.25, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 371,
              s: [-24, 232, 0],
              to: [13.804, 7.219, 0],
              ti: [-32.668, -3.373, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 379,
              s: [63.495, 239.555, 0],
              to: [121.273, 12.522, 0],
              ti: [-0.376, 0.345, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 413,
              s: [370, 268, 0],
              to: [0.99, -0.909, 0],
              ti: [0.44, 0.82, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 447,
              s: [488.5, 100.5, 0],
              to: [-0.125, -0.232, 0],
              ti: [84.5, 4.667, 0],
            },
            { t: 482.000019632262, s: [-18.5, 72.5, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [14, 14, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Outline.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [204, 168, 0], ix: 2, l: 2 },
        a: { a: 0, k: [275.5, 104, 0], ix: 1, l: 2 },
        s: { a: 0, k: [107.985, 107.985, 100], ix: 6, l: 2 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, 0],
                      [0, 219.756],
                      [77.325, 219.756],
                      [77.325, 0],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 158,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, 0],
                      [0, 219.756],
                      [77.325, 219.756],
                      [77.325, 0],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 183.000007453743,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0, 0],
                      [0, 219.756],
                      [569.058, 219.756],
                      [569.058, 0],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Energy-atoms.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 131,
              s: [0],
            },
            { t: 143.000005824509, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-2, 69, 0], ix: 2, l: 2 },
        a: { a: 0, k: [26, 26, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'Energy-atoms.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 112,
              s: [0],
            },
            { t: 126.000005132085, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [222, 84, 0], ix: 2, l: 2 },
        a: { a: 0, k: [26, 26, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'Energy-atoms.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 95,
              s: [0],
            },
            { t: 109.000004439661, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [486, 99, 0], ix: 2, l: 2 },
        a: { a: 0, k: [26, 26, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'Energy-atoms.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 82,
              s: [0],
            },
            { t: 94.0000038286985, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [64, 241, 0], ix: 2, l: 2 },
        a: { a: 0, k: [26, 26, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'Energy-atoms.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 68,
              s: [0],
            },
            { t: 83.0000033806593, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [366, 267, 0], ix: 2, l: 2 },
        a: { a: 0, k: [26, 26, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: 'Panel level',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [341, 232, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 350, 0], ix: 1, l: 2 },
        s: { a: 0, k: [69.143, 69.143, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 700,
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [375, 234, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [891.957, 533.282], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.962009564568, 0.962009564568, 0.962009564568, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [30.978, 2.641], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
