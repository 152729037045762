import React from 'react'

const MIN_VALUE = 35
export default function BatteryLevel({ level = 0 }: { level: number }) {
  const pathAxis = MIN_VALUE - level * MIN_VALUE + 1
  const levelPath = `M 1 ${pathAxis} H 20 V 35.2006 C 20 35.9001 19.4329 36.4672 18.7333 36.4672 H 2.2667 C 1.5671 36.4672 1 35.9001 1 35.2006 V 6 Z`
  return (
    <svg
      width="21"
      height="37"
      viewBox="0 0 21 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={levelPath} fill="#6AA442" />
      <path
        d="M1.00093 1.77897L1.00093 34.8907C1.00093 34.8907 0.90895 36.2855 2.5549 36.2855H18.6658C18.6658 36.2855 20.1181 36.3381 19.9923 34.8381V1.77897C19.9923 1.77897 19.7938 1 17.7509 1H2.09016C2.09016 1 1.00093 1 1.00093 1.77897Z"
        stroke="black"
        strokeWidth={'1.01333'}
        strokeMiterlimit={'10'}
      />

      <path
        d="M1 7.50316C1.60004 7.18605 2.21626 7.01811 2.83559 7.00293H18.518C18.518 7.00293 20 7.10297 20 8.20348"
        stroke="black"
        strokeWidth="0.633333"
        strokeMiterlimit="10"
      />

      <path
        d="M9.75025 10.6267H11.2492C11.5194 10.6267 11.7302 10.8365 11.7302 11.0853V13.9861C11.7302 14.235 11.5194 14.4448 11.2492 14.4448H9.75025C9.48009 14.4448 9.26924 14.235 9.26924 13.9861V11.0853C9.26924 10.8365 9.48009 10.6267 9.75025 10.6267Z"
        stroke="black"
        strokeWidth="0.253333"
      />
    </svg>
  )
}
