import { useFlags } from 'launchdarkly-react-client-sdk'
import { last } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { Group, Rect, Text } from 'react-konva'

import { SWITCHER_MODES } from '../../../pages/PanelsPage'
import { getPanelLayoutBorderColor } from '../../../pages/PanelsPage/getPanelStatus'
import { LaunchDarklyFlags } from '../../../shared/typings/launchDarklyFlags'
import {
  computePanelRotation,
  computePanelTextRotation,
} from '../computePanelRotation'
import { PanelData } from '..'

export const PANEL_HEIGHT = 132
export const PANEL_WIDTH = 84
const TEXT_FONT_SIZE = 30
const SUBTEXT_FONT_SIZE = 16
const TEXT_CONTAINER_HEIGHT = PANEL_HEIGHT - 15
const SUBTEXT_CONTAINER_HEIGHT = PANEL_HEIGHT + 35
const SELECTED_PANEL_OUTLINE = '#000'

const energyColorCodeMap = {
  0: 'rgba(196, 199, 199, 1)',
  1: 'rgba(141, 207, 228, 1)',
  2: 'rgba(115, 207, 242, 1)',
  3: 'rgba(96, 186, 228, 1)',
  4: 'rgba(76, 163, 213, 1)',
  5: 'rgba(50, 135, 194, 1)',
  6: 'rgba(29, 112, 178, 1)',
  7: 'rgba(6, 86, 160, 1)',
}

const powerColorCodeMap = {
  0: 'rgba(196, 199, 199, 1)',
  1: 'rgba(227, 208, 141, 1)',
  2: 'rgba(255, 221, 121, 1)',
  3: 'rgba(255, 209, 105, 1)',
  4: 'rgba(255, 192, 80, 1)',
  5: 'rgba(255, 176, 58, 1)',
  6: 'rgba(255, 155, 27, 1)',
  7: 'rgba(255, 138, 2, 1)',
}

type CustomPanelLayoutData = PanelData & {
  id: string
  x: number
  y: number
  rotation: number
  orientation: string
  azimuth: number
  isDragging: boolean
}
type PanelProps = {
  panel: CustomPanelLayoutData
  index: number
  onPanelTap: () => void
  mode: SWITCHER_MODES
  cursorAnimatedValue?: number
  isSelected: boolean
  isToday?: boolean
}

export const Panel = ({
  panel,
  index,
  onPanelTap,
  mode,
  cursorAnimatedValue = -1,
  isSelected,
  isToday,
}: PanelProps) => {
  const flags = useFlags() as LaunchDarklyFlags
  const tspanRef = useRef<any>()
  const rectRef = useRef<any>()

  const DCE_ICE_ALERT_FLAG = flags?.dceIceAlertFlag ?? false
  const ALERT_DEVICE_DOWN_FLAG = flags?.alertDeviceDownFlag ?? false

  const orientationAdjustedRotation = useMemo(
    () =>
      (computePanelRotation(panel.rotation, panel.azimuth, panel.orientation) ??
        0) % 360,
    [panel],
  )

  const textRotation = useMemo(
    () => computePanelTextRotation(orientationAdjustedRotation),
    [orientationAdjustedRotation],
  )

  const productionUnit = useMemo(
    () => (mode === SWITCHER_MODES.ENERGY ? 'kWh' : 'W'),
    [mode],
  )

  const stroke = useMemo(
    () =>
      getPanelLayoutBorderColor(
        panel,
        DCE_ICE_ALERT_FLAG,
        ALERT_DEVICE_DOWN_FLAG,
      ),
    [panel, DCE_ICE_ALERT_FLAG, ALERT_DEVICE_DOWN_FLAG],
  )
  const computedValues = useMemo(() => {
    return [...Array(25).keys()].map((hour) => {
      let color
      let production

      if (mode === SWITCHER_MODES.ENERGY) {
        const energyColorCode = panel.energyColorCode || 0
        color = energyColorCodeMap[energyColorCode]
        production = panel.dailyEnergyProduction?.toFixed(2) ?? 'N/A'
      } else {
        const item = panel.hourlyData?.[hour]
        color = powerColorCodeMap[item?.powerColorCode ?? 0]

        if (!panel.hourlyData?.[hour] && !isToday) {
          production = 0
        } else {
          production = item?.power?.toFixed(0) ?? 'N/A'
        }
      }

      return { production, color }
    })
  }, [mode, panel, isToday])

  const panelValue = useMemo(() => {
    if (mode === SWITCHER_MODES.ENERGY)
      return !panel.dailyEnergyProduction ||
        panel.dailyEnergyProduction === null
        ? 'N/A'
        : panel.dailyEnergyProduction?.toFixed(2)

    const latestHourlyData = last(panel.hourlyData)
    return !latestHourlyData.power || latestHourlyData.power === null
      ? 'N/A'
      : latestHourlyData.power.toFixed(1)
  }, [panel, mode])

  const backgroundColor = useMemo(() => {
    if (mode === SWITCHER_MODES.ENERGY) {
      const energyColorCode = panel.energyColorCode || 0
      return energyColorCodeMap[energyColorCode]
    }

    const latestHourlyData = last(panel.hourlyData)

    return powerColorCodeMap[latestHourlyData.powerColorCode || 0]
  }, [mode, panel])

  useEffect(() => {
    if (rectRef.current) {
      if (cursorAnimatedValue !== -1) {
        const animatedValueCursor = Math.floor(cursorAnimatedValue)
        tspanRef.current.text(computedValues[animatedValueCursor].production)
      } else {
        tspanRef.current.text(panelValue)
      }
    }
  }, [computedValues, mode, cursorAnimatedValue, panelValue, panel])

  useEffect(() => {
    if (rectRef.current) {
      if (cursorAnimatedValue !== -1) {
        const animatedValueCursor = Math.floor(cursorAnimatedValue)
        rectRef.current.fill(computedValues[animatedValueCursor]?.color)
      } else {
        rectRef.current.fill(backgroundColor)
      }
    }
  }, [computedValues, mode, cursorAnimatedValue, backgroundColor, panel])

  useEffect(() => {
    if (rectRef.current) {
      rectRef.current.stroke(isSelected ? SELECTED_PANEL_OUTLINE : stroke)
    }
  }, [isSelected, stroke])

  const textColor = useMemo(
    () => (mode === SWITCHER_MODES.ENERGY ? 'white' : 'black'),
    [mode],
  )

  const handleOnMouseOver = (isOver: boolean) => () => {
    document.body.style.cursor = isOver ? 'pointer' : 'default'
  }

  const offsetXWidth =
    orientationAdjustedRotation > 0 || textRotation > 0 ? PANEL_WIDTH / 2 : 0
  const offsetYHeight =
    orientationAdjustedRotation > 0 || textRotation > 0 ? PANEL_HEIGHT / 2 : 0

  const textXLocation = offsetXWidth
  const textYLocation = PANEL_HEIGHT * 0.3

  const subTextXLocation = offsetXWidth
  const subTextYLocation = PANEL_HEIGHT * 0.6

  const portraitPanelTextMarkup = (
    <Group
      rotation={textRotation}
      offset={{
        x: offsetXWidth,
        y: 0,
      }}
      x={0}
      y={textRotation > 180 ? PANEL_HEIGHT - 20 : 0}
    >
      <Text
        fontSize={TEXT_FONT_SIZE}
        stroke={textColor}
        fill={textColor}
        align={'center'}
        width={PANEL_WIDTH}
        ref={tspanRef}
        fontStyle={'bold'}
        x={textXLocation}
        y={textYLocation}
        text={panelValue}
      />
      <Text
        fontSize={SUBTEXT_FONT_SIZE}
        fontFamily={'Arial'}
        align={'center'}
        stroke={textColor}
        strokeWidth={1}
        lineJoin={'round'}
        fill={textColor}
        fontStyle="bold"
        fontWeight="100"
        width={PANEL_WIDTH}
        x={subTextXLocation}
        y={subTextYLocation}
        text={productionUnit}
      />
    </Group>
  )

  const landscapePanelTextMarkup = (
    <Group
      rotation={textRotation}
      offset={{
        x: offsetXWidth,
        y: offsetYHeight,
      }}
      x={offsetXWidth}
      y={offsetYHeight}
    >
      <Text
        fontSize={TEXT_FONT_SIZE}
        stroke={textColor}
        fill={textColor}
        align={'center'}
        width={PANEL_WIDTH}
        height={TEXT_CONTAINER_HEIGHT}
        ref={tspanRef}
        fontStyle={'bold'}
        text={panelValue}
        verticalAlign={'middle'}
      />
      <Text
        fontSize={SUBTEXT_FONT_SIZE}
        fontFamily={'Open Sans'}
        align={'center'}
        stroke={textColor}
        strokeWidth={1}
        lineJoin={'round'}
        fill={textColor}
        fontStyle="bold"
        fontWeight="100"
        height={SUBTEXT_CONTAINER_HEIGHT}
        width={PANEL_WIDTH}
        text={productionUnit}
        verticalAlign={'middle'}
      />
    </Group>
  )

  const panelTextMarkup =
    orientationAdjustedRotation > 0 || textRotation > 0
      ? landscapePanelTextMarkup
      : portraitPanelTextMarkup

  return (
    <Group
      onMouseOver={handleOnMouseOver(true)}
      onMouseOut={handleOnMouseOver(false)}
      key={`panel-${index}`}
      x={panel.x + offsetXWidth}
      y={panel.y + offsetYHeight}
      offset={{
        x: offsetXWidth,
        y: offsetYHeight,
      }}
      rotation={orientationAdjustedRotation}
      onClick={onPanelTap}
    >
      <Rect
        serialNumber={panel.serialNumber}
        id={panel.id}
        ref={rectRef}
        width={PANEL_WIDTH}
        height={PANEL_HEIGHT}
        fill={backgroundColor}
        stroke={stroke}
      />
      {panelTextMarkup}
    </Group>
  )
}
