import './style.scss'

import clsx from 'clsx'
import React, { useMemo, useState } from 'react'

type TabsProps = {
  className?: string
  onTabSelection?: (index: number) => any
  tabs: {
    label: string
    className?: string
  }[]
  showSeparator?: boolean
  lazy?: boolean
}

export const WidgetTabs = ({
  tabs,
  className,
  onTabSelection,
  showSeparator = false,
}: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const onTabClick = React.useCallback(
    (ix) => {
      setSelectedTab(ix)
      if (onTabSelection) {
        onTabSelection(ix)
      }
    },
    [onTabSelection],
  )

  return (
    <div className="tabs-wrapper">
      <div className={clsx('tabs is-centered', className)}>
        <ul className={clsx({ 'underline-separator': showSeparator })}>
          {tabs.map((tab, ix) => {
            return (
              <li
                key={tab.label}
                className={clsx({ 'is-active tab-title': selectedTab === ix })}
              >
                <a onClick={() => onTabClick(ix)}>{tab.label}</a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
