import './style.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment-timezone'
import React, { useEffect, useRef } from 'react'

import { SWITCHER_MODES } from '../../../pages/PanelsPage'
import { WeatherConditions } from '../../../pages/PanelsPage/panel-detail'
import { LaunchDarklyFlags } from '../../../shared/typings/launchDarklyFlags'
import { HourlyPowerProduction } from '../index'
import panHand from './assets/pan-hand.svg'
import panelsEnergy from './assets/panels-legend-energy.svg'
import panelsPower from './assets/panels-legend-power.svg'
import zoomInIcon from './assets/zoom-in.svg'
import zoomOutIcon from './assets/zoom-out.svg'
import { TimelapseContainer } from './timelapse-chart-container'

interface PanelLayoutProps {
  activeIndex: number
  timeframe: string
  total: number
  mode: SWITCHER_MODES
  weatherConditions: WeatherConditions
  hourlyPowerProduction: HourlyPowerProduction[] | null
  isTimelapseRunning: boolean
  onPressTimelapse: () => void
  zoomIn: () => void
  zoomOut: () => void
  setActiveIndex: (index: number) => void
  onPlayTimelapse: () => void
}
export const PanelLayoutActions = ({
  timeframe = '',
  total = 0,
  mode,
  weatherConditions,
  hourlyPowerProduction,
  activeIndex,
  setActiveIndex,
  isTimelapseRunning,
  zoomIn = () => {},
  zoomOut = () => {},
  onPressTimelapse = () => {},
  onPlayTimelapse,
}: PanelLayoutProps) => {
  const timeStampRef = useRef<any>()
  const totalRef = useRef<any>()
  const flags = useFlags() as LaunchDarklyFlags
  const ENERGY_POWER_PANELS_FLAG = flags.energyPowerPanelsFlag ?? false

  const units = mode === 'ENERGY' ? 'kWh' : 'kW'

  const timeframeTitle = mode === SWITCHER_MODES.ENERGY ? 'energy from' : 'at'
  const totalTitle = `TOTAL${mode === SWITCHER_MODES.POWER ? ' POWER' : ''}`

  useEffect(() => {
    let cursorTimestamp
    let cursorTotal
    if (mode === SWITCHER_MODES.POWER) {
      const correctSelectedIndex = Math.round(activeIndex)
      const hourMoment = moment().hour(correctSelectedIndex).minutes(0)
      cursorTimestamp = hourMoment.format('hh:mmA')
      cursorTotal =
        !hourlyPowerProduction?.[correctSelectedIndex] ||
        !hourlyPowerProduction[correctSelectedIndex]?.value
          ? 'N/A'
          : hourlyPowerProduction?.[correctSelectedIndex]
          ? `${(
              hourlyPowerProduction[correctSelectedIndex]?.value ?? 0
            ).toFixed(2)} ${units}`
          : '0 kW'
    } else {
      cursorTotal = `${total} ${units}`
      cursorTimestamp = timeframe
    }

    if (timeStampRef.current) timeStampRef.current.innerText = cursorTimestamp

    totalRef.current.innerText = cursorTotal
  }, [activeIndex, mode, timeframe, total, hourlyPowerProduction, units])

  const zoomActions = (
    <section className="actions-container">
      <div className="zoom-container">
        <img
          src={zoomInIcon}
          alt="zoom in"
          className="zoom-in"
          onClick={zoomIn}
        />
        <div className="divider" />
        <img
          src={zoomOutIcon}
          alt="zoom out"
          className=" zoom-out"
          onClick={zoomOut}
        />
      </div>
      <aside className="pan-action">
        <img src={panHand} alt="pan around action" />
        <div className="action-instructions">Click &amp; Drag to Pan</div>
      </aside>
    </section>
  )

  return (
    <div className="panel-actions-container">
      <div className="layout-summary-container">
        <div className="actions-upper-container">
          <section className="layout-totals-container">
            <aside className="layout-timeframe">
              <div className="summary-title">{timeframeTitle}</div>
              <div className="summary-amount" ref={timeStampRef}>
                {timeframe}
              </div>
            </aside>
            <div className="divider" />
            <aside className="layout-timeframe">
              <div className="summary-title">{totalTitle}</div>
              <div className="summary-amount total" ref={totalRef}>
                {`${total} ${units}`}
              </div>
            </aside>
          </section>
          <div className="actions-mobile">{zoomActions}</div>
        </div>
        {ENERGY_POWER_PANELS_FLAG ? (
          <section className="timelapse-container">
            {mode === SWITCHER_MODES.ENERGY ? (
              <div className="timelapse-content" onClick={onPressTimelapse}>
                View Power Timelapse
              </div>
            ) : (
              <TimelapseContainer
                isTimelapseRunning={isTimelapseRunning}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                hourlyPowerProduction={hourlyPowerProduction}
                weatherConditions={weatherConditions}
                onPlayTimelapse={onPlayTimelapse}
              />
            )}
          </section>
        ) : (
          <div className="empty-container" />
        )}
        <div className="actions-web">{zoomActions}</div>
      </div>
      <img
        className="panels-legend-indicator"
        src={mode === SWITCHER_MODES.ENERGY ? panelsEnergy : panelsPower}
        alt="panels legend coloring"
      />
    </div>
  )
}
