import './style.scss'

import clsx from 'clsx'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { find } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { SwitchToggle } from '../../components/switch-toggle'
import { Datepicker } from '../../components/datepicker'
import { Layout } from '../../components/layout'
import { Loader } from '../../components/loader'
import { Modal } from '../../components/modal'
import { PanelData, PanelsLayout } from '../../components/panels-layout'
import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { usePanels } from '../../hooks'
import { usePartyData, useWindowSize } from '../../shared/hooks'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import paths from '../Router/paths'
import infoIcon from './assets/info-icon.svg'
import { BackgroundSky } from './header'
import { PanelSliderDetail, WeatherConditions } from './panel-detail'
import { PanelsList } from './panels-list'

const DEFAULT_TIMEZONE = 'America/New_York'

export enum SWITCHER_MODES {
  ENERGY = 'ENERGY',
  POWER = 'POWER',
}

export const decomposeTime = (time: string) => {
  const [h, m = '0', s = '0'] = time.split(':')
  return {
    hour: parseInt(h),
    minutes: parseInt(m),
    seconds: parseInt(s),
  }
}

export const PanelsPage = ({
  modalTitle,
  modal,
  hideTopBar,
}: {
  modalTitle?: string
  modal?: JSX.Element
  hideTopBar?: boolean
}) => {
  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  ])
  const [showPanelSlider, setShowPanelSlider] = useState<PanelData | null>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [currentHour, setCurrentHour] = useState<boolean>(false)
  const [pageLoaded, setPageLoaded] = useState<boolean>(false)
  const [isPowerTimelapse, setIsPowerTimelapse] = useState<boolean>(false)
  const [loadingMode, setLoadingMode] = useState(false)
  const [energyMode, setEnergyMode] = useState<SWITCHER_MODES>(
    SWITCHER_MODES.ENERGY,
  )

  const session = cookies[USER_SESSION_INFO] ?? {}
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const flags = useFlags() as LaunchDarklyFlags
  const PANELS_LAYOUT_FLAG = flags?.panelsLayoutFlag ?? false
  const PANELS_FAQ_FLAG = flags?.panelsFaqFlag ?? false
  const PANEL_DETAILS_PAGE_WEB_FLAG = flags?.panelDetailsPageWebFlag ?? false
  const ENERGY_POWER_PANELS_FLAG = flags?.energyPowerPanelsFlag ?? false
  const history = useHistory()
  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )
  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )
  const currentSiteKey = useMemo(() => (isKiosk ? selectedSiteKey : null), [
    isKiosk,
    selectedSiteKey,
  ])
  const { data, loading: partyDataLoading } = usePartyData(
    currentPartyId,
    currentSiteKey,
  )
  const selectedSite = useMemo(
    () =>
      (data?.party?.sites ?? []).find(
        (site) => site.siteKey === selectedSiteKey,
      ),
    [data, selectedSiteKey],
  )
  const [dates, setDates] = useState({
    start: 0,
    end: 0,
  })

  useEffect(() => {
    if (
      selectedSite &&
      !(
        selectedSite?.hasPanelLayout &&
        selectedSite?.hasMI &&
        selectedSite?.isEnabledInNightvision
      )
    ) {
      history.push(paths.ROOT)
    }
  }, [selectedSite, history, data])

  const { timezone, firstCommissionDate = 0 } = selectedSite
    ? selectedSite
    : { timezone: DEFAULT_TIMEZONE, firstCommissionDate: 0 } // forcing the timezone to have a default from the selected state

  const {
    panels,
    weather,
    siteHourlyPowerProduction,
    loading: panelsLayoutLoading,
  } = usePanels(
    dates.start,
    timezone,
    selectedSite?.siteKey,
    PANELS_LAYOUT_FLAG,
  )

  useEffect(() => {
    if (!panelsLayoutLoading && panels.length > 0) {
      setPageLoaded(true)
      if (!loadingMode) {
        setLoadingMode(true)
      }
      if (!!showPanelSlider) {
        const updatedPanel = find(
          panels,
          (panel) => panel.serialNumber === showPanelSlider.serialNumber,
        )
        setShowPanelSlider(updatedPanel)
      }
    }
  }, [dates, panelsLayoutLoading, panels, showPanelSlider, loadingMode])

  const datePickerHandler = (datesData) => {
    setDates(datesData)
  }
  const handleOpenDetailsSlider = useCallback(
    (panel: PanelData) => {
      if (PANEL_DETAILS_PAGE_WEB_FLAG) setShowPanelSlider(panel)
    },
    [PANEL_DETAILS_PAGE_WEB_FLAG],
  )

  const handleClosePanelDetail = () => {
    setShowPanelSlider(null)
  }

  const handleOnChangeEnergyMode = () =>
    setEnergyMode((prevState) =>
      prevState === SWITCHER_MODES.ENERGY
        ? SWITCHER_MODES.POWER
        : SWITCHER_MODES.ENERGY,
    )

  const handleOnPressTimelapse = () => {
    setIsPowerTimelapse(true)
    setEnergyMode(SWITCHER_MODES.POWER)
  }

  const handleOnPressFAQ = () => {
    history.push(paths.F_A_Q_PANELS)
  }
  const windowSize = useWindowSize()
  const [width, height] = useMemo(() => {
    const currentWidth = windowSize[0]
    if (currentWidth < 749 && currentWidth > 400) {
      return [600, 400]
    } else if (currentWidth < 400) {
      return [400, 400]
    } else {
      return [950, 550]
    }
  }, [windowSize])

  const weatherConditions =
    weather && weather.days ? weather.days[0].conditions : 'SUNNY'

  const [sunrise, sunset] = useMemo(() => {
    const sunriseInfo = decomposeTime(weather?.days[0].sunrise ?? '6')
    const sunsetInfo = decomposeTime(weather?.days[0].sunset ?? '18')
    return [
      sunriseInfo.hour + sunriseInfo.hour / 60,
      sunsetInfo.hour + sunsetInfo.hour / 60,
    ]
  }, [weather])
  const maxTimeForTheDay = useMemo(
    () =>
      siteHourlyPowerProduction &&
      siteHourlyPowerProduction.length > 0 &&
      siteHourlyPowerProduction.length !== 24
        ? siteHourlyPowerProduction.length - 1
        : 24,
    [siteHourlyPowerProduction],
  )

  useEffect(() => {
    if (
      !currentHour &&
      activeIndex === 0 &&
      maxTimeForTheDay &&
      !panelsLayoutLoading &&
      panels.length
    ) {
      setCurrentHour(true)
      setActiveIndex(maxTimeForTheDay)
    }
  }, [
    panels,
    currentHour,
    setCurrentHour,
    maxTimeForTheDay,
    activeIndex,
    setActiveIndex,
    panelsLayoutLoading,
  ])

  const backgroundSky = useMemo(() => {
    return partyDataLoading || panelsLayoutLoading || !loadingMode ? (
      ''
    ) : (
      <BackgroundSky
        showPanelSlider={!!showPanelSlider}
        width={width}
        activeIndex={activeIndex}
        sunrise={sunrise}
        sunset={sunset}
        isPageLoading={pageLoaded}
        isPowerTimelapse={isPowerTimelapse}
      />
    )
  }, [
    partyDataLoading,
    panelsLayoutLoading,
    showPanelSlider,
    width,
    activeIndex,
    sunrise,
    sunset,
  ])

  const panelsLayoutMarkup = useMemo(() => {
    return (
      <PanelsLayout
        width={width}
        height={height}
        panels={panels}
        timezone={timezone}
        dates={dates}
        onPanelTap={handleOpenDetailsSlider}
        mode={energyMode}
        weatherConditions={weather as WeatherConditions}
        hourlyPowerProduction={siteHourlyPowerProduction}
        onPressTimelapse={handleOnPressTimelapse}
        selectedPanel={showPanelSlider}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    )
  }, [
    width,
    height,
    panels,
    timezone,
    dates,
    handleOpenDetailsSlider,
    energyMode,
    weather,
    siteHourlyPowerProduction,
    showPanelSlider,
    activeIndex,
    setActiveIndex,
  ])

  const panelsListMarkup = useMemo(() => {
    return (
      <PanelsList
        loading={panelsLayoutLoading}
        panels={panels}
        handleListCellClick={handleOpenDetailsSlider}
      />
    )
  }, [panels, panelsLayoutLoading, handleOpenDetailsSlider])

  const panelsPageDetails = useMemo(() => {
    return (partyDataLoading || panelsLayoutLoading) && !loadingMode ? (
      <Loader />
    ) : (
      [panelsLayoutMarkup, panelsListMarkup]
    )
  }, [
    partyDataLoading,
    panelsLayoutLoading,
    panelsLayoutMarkup,
    panelsListMarkup,
    loadingMode,
  ])

  const panelsHeaderMarkup = (
    <div
      className={clsx('panels-header', {
        'display-on-load': pageLoaded,
      })}
    >
      <div className="panel-toolbar">
        {ENERGY_POWER_PANELS_FLAG ? (
          <div className="toggle-container">
            <div
              className={clsx('toggle-label', {
                'toggle-label-selected': SWITCHER_MODES.ENERGY === energyMode,
              })}
            >
              Energy
            </div>
            <SwitchToggle
              value={SWITCHER_MODES.ENERGY !== energyMode}
              onChange={handleOnChangeEnergyMode}
            />
            <div
              className={clsx('toggle-label', {
                'toggle-label-selected': SWITCHER_MODES.POWER === energyMode,
              })}
            >
              Power
            </div>
          </div>
        ) : null}
      </div>
      <Datepicker
        loading={(partyDataLoading || panelsLayoutLoading) && loadingMode}
        onChange={datePickerHandler}
        commissionDate={firstCommissionDate}
        timezone={timezone}
        siteKey={selectedSiteKey}
        lightArrows={true}
        isRange={false}
        datesData={dates}
        weatherCondition={weatherConditions}
      />
    </div>
  )

  return (
    <Layout
      title="Panels"
      siteKey={selectedSite?.siteKey}
      sitesList={data?.party?.sites}
      containerClassName="container-helper"
    >
      <>
        {backgroundSky}
        <div
          className={clsx('panels-page', {
            'content-with-slider': showPanelSlider && width > 749,
          })}
        >
          {PANELS_FAQ_FLAG ? (
            <img
              src={infoIcon}
              alt="info"
              className="info-icon"
              onClick={handleOnPressFAQ}
            />
          ) : null}

          {panelsHeaderMarkup}

          {panelsPageDetails}
          {modal && (
            <Modal
              title={modalTitle}
              hideTopBar={hideTopBar}
              fromPath={paths.PANELS}
            >
              {modal}
            </Modal>
          )}
          {!!showPanelSlider && (
            <PanelSliderDetail
              loading={panelsLayoutLoading}
              panel={showPanelSlider}
              handleClose={handleClosePanelDetail}
              datesData={dates}
              timezone={timezone}
              weatherConditions={weather as WeatherConditions}
            />
          )}
        </div>
      </>
    </Layout>
  )
}
