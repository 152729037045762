import './index.scss'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { datadogRum } from '@datadog/browser-rum'
import packageJson from './../package.json';
import App from './App'

window.dataLayer = window.dataLayer ?? []

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID || "",
  clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || "",
  site: process.env.REACT_APP_DATADOG_RUM_ENVIRONMENT || "",
  env: process.env.REACT_APP_ENV,
  service: 'mysunpower-web-postcomm',
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  version: packageJson.version,
});

window.addEventListener('load', () => {
  root.render(
    <App />,
  )
})
