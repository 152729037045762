import React, { Dispatch, SetStateAction, createContext, useState } from 'react'

type ThemeContextType = {
  providedChatSession: any
  setProvidedChatSession: Dispatch<SetStateAction<any>>
}

type propsType = {
  children: JSX.Element[] | JSX.Element
}

export const ChatContext = createContext<ThemeContextType>({
  providedChatSession: undefined,
  setProvidedChatSession: () => {},
})

const ChatContextProvider = ({ children }: propsType) => {
  const [providedChatSession, setProvidedChatSession] = useState<any>(undefined)

  return (
    <ChatContext.Provider
      value={{ providedChatSession, setProvidedChatSession }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export default ChatContextProvider
