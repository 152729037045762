import { gql } from '@apollo/client'

export const FETCH_PARTY_INFO = gql`
  query FetchPartyInfo($partyId: String!) {
    party(partyId: $partyId) {
      partyId
      displayName
      email
      phone
      firstName
      lastName
      sites {
        siteKey
        hasWifi
        financeType
        siteName
        siteType
        address1
        hasMI
        hasPanelLayout
        isEnabledInNightvision
        city
        state
        postalCode
        systemSize
        performanceReport {
          subscriptionId
          siteKey
          reportTypeName
          reportDisplayName
          statusIndicator
          fileFormat
          scheduleDay
          nextRun
          nextPeriod {
            start
            end
          }
          expirationDate
          subscribers {
            email
            statusIndicator
          }
        }

        assignments(assignmentType: COMMISSION) {
          deviceSerialNumber
          deviceType
          deviceKey
          assignmentEffectiveTimestamp

          devices(deviceType: "logger", deviceStatus: true) {
            dvcKey
            comProto
          }
        }
      }
    }
  }
`

export const FETCH_NETWORK_INFO = gql`
  query FetchNetworkInfo($serialNumber: String!) {
    deviceStatus(serialNumber: $serialNumber) {
      dvcKey
      netIntfRptCtnt {
        cellMdmStatVal
        cellPrvdNm
        cellSimStatVal
        cellSgnlStrenDbm
        cellStatEnum
        currNetIntfEnum
        edpConnFl
        inetConnFl
        msgCrtEps
        prevNetIntfEnum
        sgnlStrenSta0Dbm
        sta0Ssid
        wifiStatEnum
        wifiIconBars
        cellIconBars
      }
    }
  }
`
