import { isEqual } from 'lodash'
import React, { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { calcYTickValues } from './calcGraph'
import { GraphTooltip } from './tooltip'

export const EnergyGraph = ({
  data,
  axisRender,
  xTicks,
  tickFormat,
  weatherDecorator,
  yTicks,
}) => {
  const energyYTicks = useMemo(() => calcYTickValues(yTicks), [yTicks])
  const [focusBar, setFocusBar] = useState<number | null>(null)
  const [mouseLeave, setMouseLeave] = useState(true)
  const tooltipMarkup =
    data && data.length > 0 ? (
      <Tooltip cursor={{ fill: 'transparent' }} content={<GraphTooltip />} />
    ) : null
  return (
    <BarChart
      width={370}
      height={215}
      data={data}
      margin={{
        top: 50,
        bottom: 10,
        right: 30,
      }}
      onMouseMove={(state) => {
        if (state.isTooltipActive && state.activeTooltipIndex) {
          setFocusBar(state.activeTooltipIndex)
          setMouseLeave(false)
        } else {
          setFocusBar(null)
          setMouseLeave(true)
        }
      }}
    >
      <CartesianGrid end={0} dx={15} vertical={false} strokeDasharray="3 3" />
      <XAxis
        dataKey="xIndex"
        axisLine={false}
        tickLine={false}
        orientation={'top'}
        fontSize="10px"
        interval={0}
        scale="auto"
        ticks={xTicks}
        tick={axisRender(tickFormat, weatherDecorator)}
      />
      <YAxis
        axisLine={false}
        allowDecimals={true}
        tickLine={false}
        ticks={energyYTicks}
        tickFormatter={(axisVal) => {
          const valindx = energyYTicks.indexOf(axisVal)
          if (valindx === energyYTicks.length - 1) {
            return `${axisVal} Wh`
          }
          return axisVal
        }}
        scale={isEqual(energyYTicks, [0]) ? 'auto' : 'linear'}
      />
      {tooltipMarkup}
      <Bar dataKey="energy" barSize={8}>
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={focusBar === index || mouseLeave ? '#EE8B23' : '#FADCBD'}
          />
        ))}
      </Bar>
      <ReferenceLine stroke="#000000" y={0} />
    </BarChart>
  )
}
