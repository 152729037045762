import './style.scss'

import moment from 'moment-timezone'
import React, { useMemo } from 'react'
import { Area, Line } from 'recharts'

import { calculateDaysDifference } from '../../shared/calculateDaysDifference'
import { limitDaysInPast } from '../../shared/constants'
import { TEnergyMarkup } from '../graphs-legend/site-legends-new'
import { calcYTickValues, graphAxisData } from './axisCalculations'
import { combineTheSeriesData, combineTheYAxisSeriesData } from './calcGraph'
import { GraphContainer } from './graph-container'
import { EnergyDataType } from './types'

type PowerGraphProps = {
  startDate: number
  endDate: number
  timezone: string
  interval: string
  data: EnergyDataType
  siteType: string
  hasConsumption?: boolean
  isLegacyPvs?: boolean
  energyMarkUpOptions: TEnergyMarkup
}

export const RechartsPowerGraph = ({
  startDate,
  endDate,
  timezone,
  interval,
  data,
  siteType,
  hasConsumption = false,
  isLegacyPvs = false,
  energyMarkUpOptions,
}: PowerGraphProps) => {
  const { storage = [], production = [], consumption = [], grid = [] } = data

  const currentDate = moment().tz(timezone)
  const selectedDate = moment(endDate).tz(timezone)
  const isOverLimitDaysInPast =
    currentDate && selectedDate
      ? Math.abs(moment.duration(selectedDate.diff(currentDate)).asDays()) >=
        limitDaysInPast
      : undefined

  const xAxisTicks = useMemo(
    () =>
      graphAxisData({
        startDate: startDate,
        endDate: endDate,
        isPower: true,
        isLegacyPvs: isLegacyPvs,
        isOverLimitDaysInPast: isOverLimitDaysInPast,
      }),
    [startDate, endDate, isLegacyPvs],
  )

  const { tickValues, tickFormat, maxValue } = xAxisTicks[interval]

  const graphData = useMemo(() => {
    return combineTheSeriesData({
      maxDataPoint: maxValue,
      storage: storage,
      production: production,
      consumption: consumption,
      grid: grid,
      interval: interval,
      startDate: startDate,
      endDate: endDate,
      timezone: timezone,
      isPower: true,
      isLegacyPvs: isLegacyPvs,
    })
  }, [
    maxValue,
    storage,
    production,
    consumption,
    grid,
    interval,
    startDate,
    endDate,
    timezone,
    isLegacyPvs,
  ])

  const yAxisGraphData = useMemo(
    () =>
      combineTheYAxisSeriesData(
        storage,
        production,
        consumption,
        grid,
        interval,
        startDate,
        endDate,
        timezone,
      ),
    [
      storage,
      production,
      consumption,
      grid,
      interval,
      startDate,
      endDate,
      timezone,
    ],
  )
  const daysDifference = calculateDaysDifference(startDate, endDate, timezone)

  const yAxisTicks = useMemo(
    () => calcYTickValues(siteType, yAxisGraphData),
    [siteType, yAxisGraphData],
  )

  const consumptionAreaGraph = [
    energyMarkUpOptions.solarProduction ? (
      <Area
        key="power-area-production"
        dataKey="production"
        fill="#EF8b22"
        stroke="#F9D7B7"
        xAxisId="timestampAxis"
        opacity={0.6}
      />
    ) : null,
    energyMarkUpOptions.netGrid ? (
      <Area
        key="power-area-grid"
        dataKey="grid"
        fill="#0071B9"
        stroke="#90bede"
        xAxisId="timestampAxis"
        opacity={0.8}
      />
    ) : null,
    energyMarkUpOptions.homeConsumption ? (
      <Line
        key="power-line-consumption"
        dataKey="consumption"
        stroke="#000000"
        dot={false}
        xAxisId="timestampAxis"
      />
    ) : null,
  ]
  const productionAreaGraph = (
    <Area
      dataKey="production"
      key="power-area-production"
      fill="#EF8b22"
      stroke="#F9D7B7"
      xAxisId="timestampAxis"
      opacity={0.6}
    />
  )
  const storageAreaGraph = [
    energyMarkUpOptions.solarProduction ? (
      <Area
        dataKey="production"
        key="power-area-production"
        fill="#EF8b22"
        stroke="#F9D7B7"
        xAxisId="timestampAxis"
        opacity={0.6}
      />
    ) : null,
    energyMarkUpOptions.netGrid ? (
      <Area
        dataKey="grid"
        key="power-area-grid"
        fill="#0071B9"
        stroke="#90bede"
        xAxisId="timestampAxis"
        opacity={0.8}
      />
    ) : null,
    energyMarkUpOptions.netStorage ? (
      <Area
        key="power-area-storage"
        dataKey="storage"
        fill="#6AA442"
        stroke="#C4DAB5"
        xAxisId="timestampAxis"
        opacity={0.7}
      />
    ) : null,
    hasConsumption && energyMarkUpOptions.homeConsumption && (
      <Line
        key="power-line-consumption"
        dataKey="consumption"
        stroke="#000000"
        dot={false}
        xAxisId="timestampAxis"
      />
    ),
  ]

  const graphVisuals =
    siteType === 'consumption'
      ? consumptionAreaGraph
      : siteType === 'storage'
      ? storageAreaGraph
      : productionAreaGraph
  return (
    <GraphContainer
      data={graphData}
      yAxisTicks={yAxisTicks}
      interval={interval}
      isPower
      daysDifference={daysDifference}
      tickValues={tickValues}
      tickFormat={tickFormat}
      siteType={siteType}
    >
      {graphVisuals}
    </GraphContainer>
  )
}
