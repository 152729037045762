import './style.scss'

import { useMutation, useQuery } from '@apollo/client'
import clsx from 'clsx'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { Loader } from '../../components/loader'
import { NumericInput } from '../../components/numeric-input'
import { TextInput } from '../../components/text-input'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { DataLayerEventName } from '../../shared/typings/dataLayer'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import { FETCH_PARTY_INFO } from '../Profile/query'
import paths from '../Router/paths'
import { UPDATE_PROFILE_INFO } from './query'

export const validateUserDetails = ({ firstName, lastName, phone }) => {
  const phoneErrorMessage = 'Phone number must be a valid number.'
  if ((firstName && firstName.length === 0) || firstName === '') {
    return 'First Name is Required.'
  }
  if ((lastName && lastName.length === 0) || lastName === '') {
    return 'Last Name is Required.'
  }
  if (phone && phone.includes('_')) {
    return phoneErrorMessage
  } else {
    const isValid = isValidPhoneNumber(`+1${phone}`)
    return isValid ? '' : phoneErrorMessage
  }
}
export const ProfileEditModal = ({}) => {
  const history = useHistory()
  const [userDetailsState, setUserDetailsState] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    partyId: '',
  })
  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])
  const flags = useFlags() as LaunchDarklyFlags
  const errorMessage = useMemo(() => {
    return validateUserDetails(userDetailsState)
  }, [userDetailsState])
  const PROFILE_EDIT_FLAG = flags?.profileEditFlag ?? false
  const session = cookies[USER_SESSION_INFO] ?? {}
  const loginOverride = cookies[LOGIN_OVERRIDE]

  const { data, loading } = useQuery(FETCH_PARTY_INFO, {
    fetchPolicy: 'cache-first',
    variables: { partyId: loginOverride ?? session.partyId },
  })

  const [
    updateParty,
    { data: userUpdateDetailsData, loading: partyLoading, error: partyError },
  ] = useMutation(UPDATE_PROFILE_INFO, {})

  useEffect(() => {
    if (data?.party) {
      const { firstName, lastName, phone, partyId } = data.party
      setUserDetailsState({
        firstName,
        lastName,
        phone: phone,
        partyId,
      })
    }
  }, [data])

  useEffect(() => {
    if (!PROFILE_EDIT_FLAG) {
      history.push(paths.PROFILE)
    }
  }, [history])
  useEffect(() => {
    if (!partyLoading && userUpdateDetailsData) {
      window.dataLayer.push({
        event: DataLayerEventName.EDIT_PROFILE_SUCCESS_WEB,
      })
      history.replace(paths.PROFILE)
    }
  }, [partyLoading, userUpdateDetailsData])

  useEffect(() => {
    if (partyError) {
      window.dataLayer.push({
        event: DataLayerEventName.EDIT_PROFILE_FAILURE_WEB,
      })
    }
  }, [partyError])

  const { firstName, lastName, phone, partyId } = userDetailsState
  const updateUserDetails = useCallback(
    (value, key) => {
      setUserDetailsState((previousState) => {
        return { ...previousState, [key]: value }
      })
    },
    [setUserDetailsState],
  )

  const cancelHandler = useCallback((e) => history.push(paths.PROFILE), [])
  const firstNameHandler = useCallback(
    (e) => updateUserDetails(e.target.value, 'firstName'),
    [],
  )
  const lastNameHandler = useCallback(
    (e) => updateUserDetails(e.target.value, 'lastName'),
    [],
  )
  const phoneNumberHandler = useCallback(
    (values) => {
      return updateUserDetails(values['formattedValue'], 'phone')
    },
    [updateUserDetails],
  )
  const submitUpdateUserDetails = () => {
    updateParty({
      variables: {
        params: {
          partyId,
          firstName,
          lastName,
          phone: userDetailsState.phone,
        },
      },
    })
  }
  const userDetailsMarkup = useMemo(
    () => (
      <section key="user-deatils-0" className="user-details">
        <section className="text-input-container">
          <label htmlFor={'first-name-input'}>First Name</label>
          <TextInput
            placeholder="First Name"
            inputName="first-name-input"
            value={firstName}
            handler={firstNameHandler}
            maxLength={20}
          />
        </section>
        <section className="text-input-container">
          <label htmlFor={'last-name-input'}>Last Name</label>
          <TextInput
            placeholder="Last Name"
            inputName="last-name-input"
            value={lastName}
            handler={lastNameHandler}
            maxLength={20}
          />
        </section>
        <section className="text-input-container">
          <label htmlFor={'phone-number-input'}>Phone</label>
          <NumericInput
            placeholder="###-###-####"
            inputName="phone-number-input"
            value={phone}
            handler={phoneNumberHandler}
            mask="_"
            format={'###-###-####'}
          />
        </section>
      </section>
    ),
    [firstName, lastName, phone],
  )
  const disableUpdate = errorMessage !== ''
  const userActionsContainer = useMemo(
    () => (
      <section key="user-actions-0" className="actions-container">
        <h4 className="error-message mb-6">{errorMessage}</h4>
        <button className="cancel button mr-5" onClick={cancelHandler}>
          Cancel
        </button>
        <button
          onClick={submitUpdateUserDetails}
          className={clsx('update button ', {
            disabled: disableUpdate,
          })}
        >
          Update
        </button>
      </section>
    ),
    [errorMessage, submitUpdateUserDetails],
  )
  const modalMarkup =
    loading || partyLoading ? (
      <Loader />
    ) : (
      [userDetailsMarkup, userActionsContainer]
    )
  return <div className="profile-edit-modal mb-6">{modalMarkup}</div>
}
