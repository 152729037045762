import ExportJsonExcel from 'js-export-excel'
import moment from 'moment-timezone'

import { FirebaseEvents, fbTrackEvent } from './firebaseAnalytics'

export type ExcelData = {
  production: any[]
  consumption: any[]
  storage?: any[]
  grid?: any[]
}
const exportPeriodFormat = {
  day: 'dddd, M/DD/YYYY - h:00a',
  week: 'MMM DD, YYYY',
  month: 'MMM DD YYYY',
  year: 'MMM YYYY',
  lifetime: 'YYYY',
  custom: 'MMM DD, YYYY',
}
const calculateCumulativeData = (data, sum = 0.0) => {
  return data.map((item, index, items) => {
    const prevVal = items[index][1] === 'N/A' ? '0.00' : items[index][1]
    const currValue = parseFloat(prevVal)
    sum = sum + currValue
    return [...item, +sum.toFixed(2)]
  })
}
export const cleanDataBeforeExport = (data) => {
  return data.map(([date, energy, _]) => [
    date,
    isNaN(energy) ? energy : +energy,
  ])
}

export const fillEmptyData = (currentData, longestSeries) =>
  longestSeries.map(([date], ix) => currentData[ix] ?? [date, 'N/A', '0'])

const formatPeriodDisplay = (
  isPower,
  interval,
  periodicity,
  timestamp,
  timezone,
) => {
  const nextHour =
    interval === 'day' && timezone
      ? moment(timestamp).tz(timezone).clone().add(1, 'hour').format('h:00a')
      : moment(timestamp).clone().add(1, 'hour').format('h:00a')

  const formattedTimestamp = moment(timestamp).format(
    exportPeriodFormat[periodicity === 'hourly' ? 'day' : interval],
  )

  const dayWithHourTimestamp = `${formattedTimestamp} - ${nextHour}`

  return !isPower
    ? interval === 'day' || periodicity === 'hourly'
      ? dayWithHourTimestamp
      : formattedTimestamp
    : timestamp
}

export const exportToExcel = (
  fileName,
  data: ExcelData,
  unit,
  isProductionOnly = false,
  isStorage = false,
  isPower = false,
  interval = 'day',
  periodicity = '',
  timezone = undefined,
) => {
  const { production = [], consumption = [], grid = [], storage = [] } = data

  const longestSeries = [production, consumption, grid, storage].reduce(
    (longest = [], curr = []) =>
      longest.length < curr.length ? curr : longest,
    [],
  )

  const sheetName = isPower ? 'Power' : 'Energy'
  const cleanedData = {
    production: fillEmptyData(production, longestSeries),
    consumption: fillEmptyData(consumption, longestSeries),
    grid: fillEmptyData(grid, longestSeries),
    storage: fillEmptyData(storage, longestSeries),
  }

  const productionWithCumulativeData = calculateCumulativeData(
    cleanDataBeforeExport(cleanedData.production),
  )

  const consumptionWithCumulativeData = calculateCumulativeData(
    cleanDataBeforeExport(cleanedData.consumption),
  )
  const gridWithCumulativeData = calculateCumulativeData(
    cleanDataBeforeExport(cleanedData.grid),
  )
  const storageWithCumulativeData = calculateCumulativeData(
    cleanDataBeforeExport(cleanedData.storage),
  )

  const productionSheetData = productionWithCumulativeData.map(
    (item, index) => {
      return !isPower
        ? [
            formatPeriodDisplay(
              isPower,
              interval,
              periodicity,
              item[0],
              timezone,
            ),
            item[1],
            item[2],
          ]
        : [
            formatPeriodDisplay(
              isPower,
              interval,
              periodicity,
              item[0],
              timezone,
            ),
            item[1],
          ]
    },
  )

  const consumptionDataExport = productionWithCumulativeData.map(
    (item, index) => {
      return !isPower
        ? [
            formatPeriodDisplay(
              isPower,
              interval,
              periodicity,
              item[0],
              timezone,
            ),
            item[1],
            consumptionWithCumulativeData[index][1],
            gridWithCumulativeData[index][1],
            item[2],
            consumptionWithCumulativeData[index][2],
            gridWithCumulativeData[index][2],
          ]
        : [
            formatPeriodDisplay(
              isPower,
              interval,
              periodicity,
              item[0],
              timezone,
            ),
            item[1],
            consumptionWithCumulativeData[index][1],
            gridWithCumulativeData[index][1],
          ]
    },
  )
  const storageDataExport = productionWithCumulativeData.map((item, index) => {
    return !isPower
      ? [
          formatPeriodDisplay(
            isPower,
            interval,
            periodicity,
            item[0],
            timezone,
          ),
          parseFloat(item[1]),
          parseFloat(consumptionWithCumulativeData[index][1]),
          parseFloat(storageWithCumulativeData[index][1]),
          parseFloat(gridWithCumulativeData[index][1]),
          parseFloat(item[2]),
          parseFloat(consumptionWithCumulativeData[index][2]),
          parseFloat(storageWithCumulativeData[index][2]),
          parseFloat(gridWithCumulativeData[index][2]),
        ]
      : [
          formatPeriodDisplay(
            isPower,
            interval,
            periodicity,
            item[0],
            timezone,
          ),
          parseFloat(item[1]),
          parseFloat(consumptionWithCumulativeData[index][1]),
          parseFloat(storageWithCumulativeData[index][1]),
          parseFloat(gridWithCumulativeData[index][1]),
        ]
  })
  const productionSheetHeaders = !isPower
    ? ['Period', `Solar Production (${unit})`, `Cumulative (${unit})`]
    : ['Period', `Solar Production (${unit})`]
  const productionData = {
    sheetData: productionSheetData,
    sheetName: sheetName,
    sheetHeader: productionSheetHeaders,
    columnWidths: [20, 10, 10],
  }

  const consumptionSheetHeaders = !isPower
    ? [
        'Period',
        `Solar Production (${unit})`,
        `Home Usage (${unit})`,
        `Net Grid ${sheetName}(${unit})`,
        `Cumulative Solar Production (${unit})`,
        `Cumulative Home Usage (${unit})`,
        `Cumulative Net Grid ${sheetName} (${unit})`,
      ]
    : [
        'Period',
        `Solar Production (${unit})`,
        `Home Usage (${unit})`,
        `Net Grid ${sheetName}(${unit})`,
      ]
  const consumptionData = {
    sheetData: consumptionDataExport,
    sheetName: sheetName,
    sheetHeader: consumptionSheetHeaders,
    columnWidths: [20, 10, 10, 10, 10, 10, 10],
  }
  const storageSheetHeaders = !isPower
    ? [
        'Period',
        `Solar Production (${unit})`,
        `Home Usage (${unit})`,
        `Net Storage (${unit})`,
        `Net Grid ${sheetName}(${unit})`,
        `Cumulative Solar Production (${unit})`,
        `Cumulative Home Usage (${unit})`,
        `Cumulative Net Storage (${unit})`,
        `Cumulative Net Grid ${sheetName} (${unit})`,
      ]
    : [
        'Period',
        `Solar Production (${unit})`,
        `Home Usage (${unit})`,
        `Net Storage (${unit})`,
        `Net Grid ${sheetName}(${unit})`,
      ]
  const storageSheetData = {
    sheetData: storageDataExport,
    sheetName: sheetName,
    sheetHeader: storageSheetHeaders,
    columnWidths: [20, 10, 10, 10, 10, 10, 10, 10, 10],
  }

  const toExcel = new ExportJsonExcel({
    fileName,
    datas: [
      isStorage
        ? storageSheetData
        : !isProductionOnly
        ? consumptionData
        : productionData,
    ].filter((d) => !!d),
  })

  toExcel.saveExcel()
  fbTrackEvent(FirebaseEvents.BUTTON_EVENT, {
    ElementID: 'CSV_generated',
  })
}
