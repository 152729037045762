import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment-timezone'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { AlertData } from '../../../../components/panels-layout'
import { LaunchDarklyFlags } from '../../../../shared/typings/launchDarklyFlags'
import paths from '../../../Router/paths'
import { PANEL_ALERTS_ID } from '../../getPanelStatus'

export const PanelMessaging = ({
  status,
  timezone,
}: {
  status: AlertData
  timezone: string
}) => {
  const flags = useFlags() as LaunchDarklyFlags

  const history = useHistory()
  const handleGoToContactUs = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  const ALERT_DEVICE_DOWN_FLAG = flags?.alertDeviceDownFlag ?? false
  const DCE_ICE_ALERT_FLAG = flags?.dceIceAlertFlag ?? false

  return (
    <div className="messaging-container">
      {PANEL_ALERTS_ID.ID === status?.alertType && ALERT_DEVICE_DOWN_FLAG && (
        <MessagingContent
          title="This panel has stopped working."
          timeStamp={status?.eventTimestamp}
          content="Please"
          linkText="contact us."
          onClickLink={() => handleGoToContactUs(paths.CONTACT_US)}
          timezone={timezone}
        />
      )}
      {PANEL_ALERTS_ID.ICE === status?.alertType && DCE_ICE_ALERT_FLAG && (
        <MessagingContent
          title="This panel is having trouble communicating with the monitoring
            device"
          content="Panels talk to the monitoring device through the power lines. That
            can sometimes be challenging. For more info, read our FAQ"
          onClickLink={() => handleGoToContactUs(paths.F_A_Q_PANELS)}
          linkText="here."
          timeStamp={status?.eventTimestamp}
          timezone={timezone}
        />
      )}
      {PANEL_ALERTS_ID.PANEL_TEMP_ZERO_PROD === status?.alertType && (
        <MessagingContent
          title="It looks like this panel is temporarily not producing any power."
          content="This behavior can be expected, depending on the panel shading. It
            usually resolves itself but if it persists for more than 48 hours,
            we will flag it as a production alert. For more info, read our
            detailed FAQ"
          onClickLink={() => handleGoToContactUs(paths.F_A_Q_PANELS)}
          linkText="here."
          timezone={timezone}
        />
      )}
      {PANEL_ALERTS_ID.PANEL_TEMP_NO_COMMS === status?.alertType && (
        <MessagingContent
          title="We’ve temporarily lost communication with this panel."
          content="Panels talk to the monitoring device through the power lines. That
            can sometimes be challenging. If this persists for more than 48
            hours, we will flag it as a communication alert. For more info, read
            our detailed FAQ"
          onClickLink={() => handleGoToContactUs(paths.F_A_Q_PANELS)}
          linkText="here."
          timezone={timezone}
        />
      )}
      {!status && (
        <div className="title-messaging">Panel is reporting as expected.</div>
      )}
    </div>
  )
}

const MessagingContent = ({
  title,
  timeStamp,
  content,
  onClickLink,
  linkText,
  timezone,
}: {
  title: string
  content: string
  timeStamp?: string
  linkText?: string
  onClickLink?: () => void
  timezone: string
}) => {
  return (
    <>
      <div className="title-messaging">{title}</div>
      {timeStamp && (
        <AlertTimeStamp eventTimestamp={timeStamp} timezone={timezone} />
      )}
      <div className="content">
        {content}
        {linkText && onClickLink && (
          <button onClick={onClickLink} className="support-email">
            {linkText}
          </button>
        )}
      </div>
    </>
  )
}

const AlertTimeStamp = ({
  eventTimestamp,
  timezone,
}: {
  eventTimestamp: string
  timezone: string
}) => {
  const alertTime = useMemo(
    () =>
      eventTimestamp
        ? moment.tz(eventTimestamp, timezone).format('hh:mm A')
        : null,
    [eventTimestamp, timezone],
  )

  const alertDay = useMemo(
    () =>
      eventTimestamp
        ? moment.tz(eventTimestamp, timezone).format('MM/DD/YY')
        : null,
    [eventTimestamp, timezone],
  )

  return (
    <div className="sub-title">{`Alert trigger time: ${alertTime} on ${alertDay}`}</div>
  )
}
