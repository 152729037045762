import clsx from 'clsx'
import React from 'react'

import { SITE_TYPES } from '../../shared/sharedConstants'
import GridLegend from './assets/grid-legend.svg'
import HomeLegend from './assets/home-legend.svg'
import SolarLegend from './assets/solar-legend.svg'
import StorageLegend from './assets/storage-legend.svg'
import { formatLegendValue } from './format-legend-value'

const energySourceToLegendIcon = {
  home: HomeLegend,
  solar: SolarLegend,
  grid: GridLegend,
  storage: StorageLegend,
}

const NewLegendItem = ({
  type,
  title,
  total,
  siteType,
  checked,
  handleCheck,
  selectable = true,
}: {
  type: string
  title: string
  total: number
  siteType: string
  checked?: boolean
  handleCheck?: (newValue: boolean) => void
  selectable?: boolean
}) => {
  return (
    <div
      data-testid={`${type}-legend`}
      className={clsx('newlegend-container', {
        disabled: !checked,
        selectable: selectable,
      })}
      onClick={() => {
        handleCheck && handleCheck(!checked)
      }}
    >
      <img className="icon" src={energySourceToLegendIcon[type]} alt={title} />
      <div
        data-testid={`${type}-legend-title`}
        className={clsx('newlegend-title', `${type}-title`)}
      >
        {title}
      </div>
      <div className="newlegend-dots" />
      <div
        data-testid={`${type}-legend-total`}
        className={clsx('newlegend-total', siteType + '-total')}
      >
        {formatLegendValue(total)} kWh
      </div>
    </div>
  )
}

export type TEnergyMarkup = {
  homeConsumption: boolean
  solarProduction: boolean
  netGrid: boolean
  netStorage: boolean
}

export const consumptionLegendNew = (
  siteType: string,
  homeTotal: number,
  solarTotal: number,
  gridTotal: number,
  newEnergyMarkUpOptions: TEnergyMarkup,
  setnewEnergyMarkUpOptions: (TEnergyMarkup) => void,
) => {
  return (
    <div className="new-legends-header-container consumption-group-container">
      <NewLegendItem
        siteType={siteType}
        type="home"
        title="Home Consumption"
        total={homeTotal}
        checked={newEnergyMarkUpOptions?.homeConsumption}
        handleCheck={(newValue) => {
          setnewEnergyMarkUpOptions({
            ...newEnergyMarkUpOptions,
            homeConsumption: newValue,
          })
        }}
      />
      <NewLegendItem
        siteType={siteType}
        type="solar"
        title="Solar Production"
        total={solarTotal}
        checked={newEnergyMarkUpOptions?.solarProduction}
        handleCheck={(newValue) => {
          setnewEnergyMarkUpOptions({
            ...newEnergyMarkUpOptions,
            solarProduction: newValue,
          })
        }}
      />
      <NewLegendItem
        siteType={siteType}
        type="grid"
        title={
          gridTotal < 0 ? 'Net Grid Usage (Export)' : 'Net Grid Usage (Import)'
        }
        total={gridTotal}
        checked={newEnergyMarkUpOptions?.netGrid}
        handleCheck={(newValue) => {
          setnewEnergyMarkUpOptions({
            ...newEnergyMarkUpOptions,
            netGrid: newValue,
          })
        }}
      />
    </div>
  )
}

export const productionLegendNew = (siteType: string, solarTotal: number) => (
  <div className="new-legends-header-container">
    <NewLegendItem
      siteType={siteType}
      type="solar"
      title="Solar Production"
      total={solarTotal}
      checked={true}
      selectable={false}
    />
  </div>
)

export const storageLegendNew = (
  siteType: string,
  homeTotal: number,
  solarTotal: number,
  gridTotal: number,
  storageTotal: number,
  newEnergyMarkUpOptions: TEnergyMarkup,
  setnewEnergyMarkUpOptions: (TEnergyMarkup) => void,
) => {
  return (
    <div className="new-legends-header-container storage-group-container">
      <NewLegendItem
        siteType={siteType}
        type="home"
        title="Home Consumption"
        total={homeTotal}
        checked={newEnergyMarkUpOptions?.homeConsumption}
        handleCheck={(newValue) => {
          setnewEnergyMarkUpOptions({
            ...newEnergyMarkUpOptions,
            homeConsumption: newValue,
          })
        }}
      />
      <NewLegendItem
        siteType={siteType}
        type="solar"
        title="Solar Production"
        total={solarTotal}
        checked={newEnergyMarkUpOptions?.solarProduction}
        handleCheck={(newValue) => {
          setnewEnergyMarkUpOptions({
            ...newEnergyMarkUpOptions,
            solarProduction: newValue,
          })
        }}
      />
      <NewLegendItem
        siteType={siteType}
        type="grid"
        title={
          gridTotal < 0 ? 'Net Grid Usage (Export)' : 'Net Grid Usage (Import)'
        }
        total={gridTotal}
        checked={newEnergyMarkUpOptions?.netGrid}
        handleCheck={(newValue) => {
          setnewEnergyMarkUpOptions({
            ...newEnergyMarkUpOptions,
            netGrid: newValue,
          })
        }}
      />
      <NewLegendItem
        siteType={siteType}
        type={SITE_TYPES.STORAGE}
        title={
          storageTotal < 0
            ? 'Net Storage Usage (Charge)'
            : 'Net Storage Usage (Discharge)'
        }
        total={storageTotal}
        checked={newEnergyMarkUpOptions?.netStorage}
        handleCheck={(newValue) => {
          setnewEnergyMarkUpOptions({
            ...newEnergyMarkUpOptions,
            netStorage: newValue,
          })
        }}
      />
    </div>
  )
}
