import './styles.scss'

import clsx from 'clsx'
import React, { useState } from 'react'

import { Loader } from '../../../../components/loader'
import { useSupportCases } from '../../../../shared/hooks'
import { Case, CasesStatus } from '../../../../shared/typings/helpCenter'
import { helpCenterDateFormat } from '../../../../shared/valueFormatters'
import { getHourFromDate } from '../../../../shared/valueFormatters'
import ChevronImg from '../../assets/chevron-down.svg'
import EmptyComment from '../../assets/empty-comment.svg'
import SendImg from '../../assets/send.svg'
import { CaseModal } from '../case-modal'

type CaseCardProps = {
  supportCase: Case
  currentStatus: CasesStatus
  switchToggleToOpen: () => void
}

export const CaseCard = ({
  supportCase,
  currentStatus,
  switchToggleToOpen,
}: CaseCardProps) => {
  const {
    fetchCaseComments,
    handleAddCaseComment,
    addCaseLoading,
  } = useSupportCases()

  const {
    statusColor,
    subject,
    description,
    category,
    status,
    createdDate,
    caseNumber,
    caseComments,
    loadingComments,
  } = supportCase
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalLabels, setModalLabels] = useState({
    title: '',
    subtitle: '',
    boxText: '',
    closeBtnText: '',
    acceptBtnText: '',
  })
  const [commentsOpen, setCommentsOpen] = useState<boolean>(false)
  const [inputText, setInputText] = useState<string>('')

  const openStatusColor = '#F3A859'

  const modalLabelsReOpenCase = {
    title: 'Re-Open Case',
    subtitle: 'What is the reason for re-opening this case?',
    boxText: "What are some issues you're seeing?",
    closeBtnText: 'Cancel',
    acceptBtnText: 'Re-Open',
  }
  const requestedModalCase = {
    reOpenCase: 'reOpenCase',
  }
  const openModal = (requestedModal) => {
    if (requestedModal === requestedModalCase.reOpenCase) {
      setModalLabels(modalLabelsReOpenCase)
    }
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleSendComment = () => {
    if (inputText === '') return
    handleAddCaseComment(supportCase.caseNumber, inputText, () =>
      setInputText(''),
    )
  }

  const handleCommentsOpen = () => {
    if (!commentsOpen) {
      fetchCaseComments(supportCase.caseNumber)
    }
    setCommentsOpen(!commentsOpen)
  }

  return (
    <>
      {isModalOpen && (
        <CaseModal
          closeModal={closeModal}
          modalLabels={modalLabels}
          isModalOpen={isModalOpen}
          caseNumber={caseNumber}
          switchToggleToOpen={switchToggleToOpen}
        />
      )}
      <div className="support-case-card">
        {/* if case was moved from resolved to open, manually update header color until a new fetch request happens*/}
        <div
          className="support-case-card-status"
          style={{
            backgroundColor:
              currentStatus === CasesStatus.OPEN
                ? openStatusColor
                : statusColor,
            color: currentStatus === CasesStatus.OPEN ? 'white' : 'black',
          }}
        >
          {/* if case was moved from resolved to open, manually update title until a new fetch request happens*/}
          {currentStatus === CasesStatus.OPEN
            ? 'Open - Pending SunPower Action'
            : status}
        </div>
        <div className="support-case-card-id">
          {`Case No. #${caseNumber}`}
          {currentStatus === CasesStatus.RESOLVED && (
            <div
              className="support-card-reopen"
              onClick={() => openModal('reOpenCase')}
            >
              Re-Open Case
            </div>
          )}
        </div>
        <div className="support-case-card-info">
          <p className="card-info-title">Category</p>
          <p className="card-info-data">{category}</p>
          <p className="card-info-title">Tittle</p>
          <p className="card-info-data">{subject}</p>
          <p className="card-info-title">Description</p>
          <p className="card-info-data">{description}</p>
        </div>
        <div className="support-case-card-created">
          <b>Created: </b>
          <p className="support-case-card-created-date">
            {helpCenterDateFormat(createdDate)}
          </p>
        </div>
        <div className="support-case-card-comments">
          {!commentsOpen && (
            <div
              onClick={handleCommentsOpen}
              className="support-case-card-comments-link"
            >
              <b>Comments</b>
              <img
                src={ChevronImg}
                alt="open comments icon"
                className="support-case-card-comments-chevron closed"
              />
            </div>
          )}
          {commentsOpen && (
            <div
              onClick={handleCommentsOpen}
              className="support-case-card-comments-link"
            >
              {loadingComments ? (
                'Loading comments...'
              ) : (
                <>
                  <b>Comments</b>
                  <img
                    src={ChevronImg}
                    alt="open comments icon"
                    className="support-case-card-comments-chevron"
                  />
                </>
              )}
            </div>
          )}
          {commentsOpen && (
            <div className="support-case-card-comments-wrapper">
              {caseComments?.length === 0 && (
                <div className="no-comments">
                  <img src={EmptyComment} alt="empty comment state" />
                  <p>
                    Add any comments or questions you’d like to share with our
                    support team!
                  </p>
                </div>
              )}
              {caseComments?.map((comment, index) => (
                <ChatItem
                  comment={comment}
                  key={`comment-${index}-${caseNumber}`}
                />
              ))}
            </div>
          )}
          {commentsOpen && currentStatus !== CasesStatus.RESOLVED && (
            <div className="support-case-card-comments-input">
              <input
                type="text"
                placeholder="Write a comment"
                className="inner-input"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
              <button className="send-button" onClick={handleSendComment}>
                {addCaseLoading ? <Loader /> : <img src={SendImg} alt="" />}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const ChatItem = ({ comment }: { comment: any }) => {
  const { commentBody, createdBy, user, createdDate } = comment
  const isSunpower = createdBy !== 'Customer'
  return (
    <div className={clsx('comment-item', { sunpower: isSunpower })}>
      <div className="chat-content">
        <div
          className={clsx('chat-content-user', {
            sunpower: isSunpower,
          })}
        >
          {isSunpower ? 'SunPower Support' : user.name}
          <div className="creation-date">{getHourFromDate(createdDate)}</div>
        </div>
        <div className="chat-content-text">{commentBody}</div>
      </div>
      <div className="avatar-container">
        <div
          className={clsx('avatar', {
            sunpower: isSunpower,
          })}
        >
          S
        </div>
      </div>
    </div>
  )
}
