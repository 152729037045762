import { useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'

import { INTERVAL_OPTIONS } from '../intervals'
import { FETCH_ENV_SAVINGS_QUERY } from './query'

const initialValues = {
  value: 0,
  unit: '',
}

export function useSavings(siteKey, startDate, endDate, interval, timezone) {
  const [savingsState, setSavingsState] = useState({
    billSavings: initialValues,
    environmentalSavings: {
      carbondioxide: initialValues,
      carmiles: initialValues,
      gasoline: initialValues,
      trees: initialValues,
    },
  })

  const [fetchEnvSavingsData, { called, data, loading, error }] = useLazyQuery(
    FETCH_ENV_SAVINGS_QUERY,
  )

  useEffect(() => {
    if (siteKey && startDate && endDate && timezone) {
      fetchEnvSavingsData({
        variables: {
          siteKey: siteKey,
          interval: INTERVAL_OPTIONS[interval],
          end: moment(endDate).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'),
          start: moment(startDate).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'),
        },
      })
    }
  }, [siteKey, startDate, endDate, interval, timezone, fetchEnvSavingsData])

  useEffect(() => {
    if ((called && data) || error) {
      const {
        costSaving = initialValues,
        co2 = initialValues,
        tree = initialValues,
        mile = initialValues,
        gas = initialValues,
      } = data?.environmentalSavings ?? {}

      setSavingsState({
        billSavings: costSaving || initialValues,
        environmentalSavings: {
          carbondioxide: co2,
          carmiles: mile,
          trees: tree,
          gasoline: gas,
        },
      })
    }
  }, [called, data, error])

  return { savings: savingsState, loading }
}
