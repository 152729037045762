import './style.scss'
import '../../styles/setupMode.scss'

import { useMutation } from '@apollo/client'
import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { useCurrentSiteData } from '../../shared/hooks'
import { FETCH_PARTY_DATA } from '../../shared/hooks/query'
import { OPERATIONAL_MODES } from '../../shared/setupModeConstants'
import { SITE_TYPES } from '../../shared/sharedConstants'
import paths from '../Router/paths'
import { RESERVE_MODE_COMMAND } from './query'
import { ReserveModeSetupStep1 } from './Step1'
import { ReserveModeSetupStep2 } from './Step2'

const DEFAULT_DAYS = 3
const TOTAL_STEPS = 2

export const ReserveSetupPage = ({ step }: { step: number }) => {
  const history = useHistory()
  const { site, loading: loadingSite, partyId } = useCurrentSiteData()

  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const [extendedReserveMode, setExtendedReserveMode] = useState(false)
  const [days, setDays] = useState(DEFAULT_DAYS)
  const [successorMode, setSuccessorMode] = useState(
    OPERATIONAL_MODES.SELF_CONSUMPTION,
  )

  const handleCancel = useCallback(() => {
    history.push(paths.ROOT)
  }, [history])

  const [
    changeBatteryMode,
    { data: changeBatteryData, loading, error },
  ] = useMutation(RESERVE_MODE_COMMAND, {
    refetchQueries: [{ query: FETCH_PARTY_DATA, variables: { partyId } }],
  })

  useEffect(() => {
    if (changeBatteryData && !error) {
      history.push(paths.ROOT)
    }
  }, [changeBatteryData, loading, error, history])
  const notStorageSite = site?.siteType !== SITE_TYPES.STORAGE

  useEffect(() => {
    if (!loading && site && notStorageSite) {
      history.push(paths.ROOT)
    }
  }, [site, loadingSite])

  const handleStart = useCallback(() => {
    changeBatteryMode({
      variables: {
        params: {
          siteKey: selectedSiteKey,
          mode: OPERATIONAL_MODES.BACKUP_ONLY,
          backupDays: days,
          successorMode,
        },
      },
    })
  }, [history, days, selectedSiteKey, successorMode])

  const handleNext = useCallback(() => {
    if (extendedReserveMode) {
      changeBatteryMode({
        variables: {
          params: {
            siteKey: selectedSiteKey,
            mode: OPERATIONAL_MODES.BACKUP_ONLY,
            successorMode: OPERATIONAL_MODES.SELF_CONSUMPTION,
          },
        },
      })
    } else {
      history.push(paths.RESERVE_SETUP_STEP_2)
    }
  }, [history, extendedReserveMode, selectedSiteKey])

  if (loadingSite || !site || notStorageSite) {
    return null
  }

  return (
    <Layout title="Reserve Mode">
      <section className="section  setup-mode-column">
        <article
          className={cn('setup-mode-step column is-uppercase steps-container', {
            hidden: extendedReserveMode,
          })}
        >
          <b>Step {step}</b> of {TOTAL_STEPS}
        </article>
        {step === 1 ? (
          <ReserveModeSetupStep1
            days={days}
            handleDays={setDays}
            handleCancel={handleCancel}
            handleNext={handleNext}
            extendedReserveMode={extendedReserveMode}
            setExtendedReserveMode={setExtendedReserveMode}
          />
        ) : (
          <ReserveModeSetupStep2
            days={days}
            mode={successorMode}
            handleMode={setSuccessorMode}
            handleCancel={handleCancel}
            handleStart={handleStart}
          />
        )}
      </section>
    </Layout>
  )
}
