import React from 'react'

import faqRouterPlugin from './assets/faq-router-plugin.png'

export const questionsAndAnswers = [
  {
    // 1
    question: 'How  can I check the current status of my system?',
    answer: (
      <div>
        <b>A SunPower Equinox®</b> Hub is installed at your home. This device
        collects production data from your solar system, as well as energy
        consumption if enabled. This data is then transmitted to SunPower
        servers and displayed on your app.
        <br />
        <br />
        Using the web application: On your dashboard, you'll see a gauge that
        tells you if your system is now producing, waiting for data, or waiting
        for the sun. If there are any alerts for your system, you will see a
        banner at the top of the dashboard.
        <br />
        <br />
        Using the mobile application: On the Home tab, you’ll see the current
        power flow of your system which describes what is happening in your
        system right now. If there are any alerts for your system, you will see
        an alert icon.
      </div>
    ),
  },
  {
    // 2
    question:
      'Can I monitor my SunPower system from my smartphone or tablet?  ',
    answer: (
      <div>
        Yes, mySunPower is available on smartphone devices, both for iOS and
        Android devices. Search your app store for mySunPower to download the
        latest application. The app is optimized for mobile devices but you can
        still access it through your tablet device.
      </div>
    ),
  },
  {
    // 3
    question: 'How can I update my name, email, or password?  ',
    answer: (
      <div>
        To update your password, please logout and then follow the forgot
        password flow. To update your name or email, please email us at{' '}
        <a className="color-text-blue" href="mailto:mysunpower@sunpower.com">
          mysunpower@sunpower.com
        </a>
        .
      </div>
    ),
  },
  {
    // 4
    question: 'How can I check if there are any issues with my system?',
    answer: (
      <div>
        If there is an issue with your system, you’ll see a message both on the
        Web application and on the mobile app of mySunPower.
        <br />
        <br /> In the mobile app, you’ll see an alert icon in the home tab. Tap
        the alert icon for details regarding the issue.
        <br />
        <br />
        In the web app, you’ll see an alert banner in the top of the home page.
        Click the alert text for details regarding the issue.
      </div>
    ),
  },
  {
    // 5
    question:
      'What should I do if an alert says you’ve lost communication with my system?',
    answer: (
      <div>
        We're all working hard to stay connected. We've noticed that your
        SunPower Equinox® Hub monitoring system is experiencing issues
        connecting to your home internet. This could be due to a weak signal
        between your wireless router and your Equinox® Hub device, a change to
        your WiFi network or password, or a loss of connection from your
        internet provider. <br />
        <br />
        No need to worry: your solar system is most likely operating normally,
        but without internet connection we're not receiving your data and can't
        share it with you.
        <br />
        <br />
        In order to ensure that we can continue to provide you with data for
        your system, there are a few steps that you can take to reconnect your
        system:
        <br />
        <img src={faqRouterPlugin} alt="unplug and plug back in router" />
        <ol type="a">
          <li>
            Check to see if your home Wi-Fi is working properly and that the
            signal can reliably reach your Equinox® Hub monitoring device.
            <ol type="i">
              <li>
                Try restarting your router by unplugging it and plugging it back
                in. You can also restart your Equinox® Hub monitoring device by
                flipping the 15A circuit breaker that it is connected to.{' '}
              </li>
              <li>
                Check if there are any large obstructions between your router
                and the location of your Equinox® Hub. While wood, glass, and
                drywall may not have significant impact on signal strength,
                denser materials like concrete, metal, and brick can interfere
                with your signal. Large appliances, showers, and bookshelves may
                also interfere with Wi-Fi.
              </li>
              <li>
                Move your wireless router away from these obstructions or closer
                to your Equinox® Hub device location.
              </li>
              <li>
                To reduce interference, move your router away from other
                wireless devices, such as cordless phones or neighboring
                wireless routers.
              </li>
              <li>
                If your router is equipped with adjustable antennas, try
                pointing them towards your monitoring device.
              </li>
            </ol>
          </li>
          <li>
            Install a Wi-Fi range extender or replace Wi-Fi range extenders can
            boost your router's wireless signal and help it reach your Equinox®
            Hub monitoring device. These products are simple to set up and use,
            relatively inexpensive, and can be a great alternative to replacing
            your router. Below are a couple models that have been known to work
            well with our system:
            <ul>
              <li>TP-Link TL-WA855RE N300 WiFi Extender</li>
              <li>NETGEAR EX2700 N300 WiFi Range Extender</li>
              <li>
                If you choose to use another model, be sure they meet these
                minimum requirements:
                <ul>
                  <li>Supports 802.11 a/b/g/n 2.4 GHz</li>
                  <li>Provides a minimum of 200 Mbps connection speed</li>
                  <li>Has one 10/100 Mbps RJ45 Ethernet port </li>
                  <li>Can be plugged into an AC wall outlet </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>Replace your wireless router</li>
          <li>
            Newer wireless routers may have increased signal strength and employ
            features that make them less susceptible to interference from
            obstructions and other wireless devices. If your router is more than
            a few years old, upgrading it may improve the connection to your
            SunPower Equinox® Hub monitoring system as well as other devices in
            your home.
          </li>
          <li>
            Connect to your network with a Powerline Communication (PLC) Device
          </li>
          <li>
            Powerline communication (PLC) devices provide a hardwired connection
            to devices without requiring you to install ethernet cable
            throughout your home. These devices utilize your existing electrical
            wires to transmit data between two PLC adapters that plug directly
            into wall outlets. Below are some examples of PLC devices that have
            been proven to work with our system:
            <ul>
              <li>TRENDnet Powerline 500 AV Nano Adapter Kit</li>
              <li>TP-Link AV600 Powerline Ethernet Adapter</li>
              <li>NETGEAR PowerLINE 1000 Mbps, 1 Gigabit Port </li>
              <li>
                Be sure to use two adapters that are the some make and model to
                ensure compatibility. Plug in one adapter to an outlet near your
                router and connect the adapter to your router with an ethernet
                cable. Plug the second adapter in near your Equinox® Hub
                monitoring device. To connect your Equinox® Hub to the PLC
                adapter:
                <br />
                <ol type="i">
                  <li>
                    Remove the cover of your Equinox® Hub monitoring device and
                    connect and ethernet cable to the LAN port on the device.{' '}
                  </li>
                  <li>
                    Feed an ethernet cable through one of the bottom ports of
                    the device and connect it to the PLC adapter{' '}
                  </li>
                  <li>Reinstall the cover </li>
                  <li>Route the cable to your PLC adapter</li>
                  <li>
                    Follow the instructions provided with the PLC to activate
                    the connection
                  </li>
                </ol>
              </li>
            </ul>
            <article>
              If you've tried the steps above and are still unable to connect,
              please reach out to our Customer Support team by emailing{' '}
              <a
                className="color-text-blue"
                href="mailto:technical.support@sunpower.com"
              >
                technical.support@sunpower.com
              </a>
              .
            </article>
          </li>
        </ol>
      </div>
    ),
  },
  {
    // 6
    question:
      'What should I do if an alert says there is an issue with my system? ',
    answer: (
      <div>
        We aren't receiving data from your system for one of these reasons:
        weather conditions are not favorable for solar production, your internet
        connection isn't working, or because there is a problem with your
        system.
        <br />
        <br />
        If it is during daylight hours, the weather is favorable for solar
        energy production, and your internet is working, please contact support
        and troubleshoot your system issue. Support might ask you to check the
        status of the LEDs on the front of your Equinox® monitoring device which
        monitors your system and transmits your system data.
      </div>
    ),
  },
  {
    // 7
    question: 'Why is my WiFi network not included in the scan results?',
    answer: (
      <div>
        At some homes, the Equinox® monitoring device, which sends data to us,
        is located too far from the router to be able to communicate
        successfully. <br />
        <br />
        As a first step, consider moving your router closer to the Equinox®
        device to improve the communication between the two devices.
        <br />
        <br />
        <b>Note:</b> Access to public hotspots, such as XFINITY WiFi, is not
        supported.
      </div>
    ),
  },
  {
    // 8.
    question:
      'I see red LEDs on the Equinox® device that sends my solar data. What do they mean?',
    answer: (
      <div>
        We included a document in your Document Library that explain the LEDs,
        their status, indications, and basic troubleshooting. Refer to{' '}
        <a
          href="https://monitor.us.sunpower.com/content/doclibs/docs/SunPower_PVSN_LEDs.pdf"
          className="color-text-blue"
          target="_blank"
          rel="noreferrer"
        >
          Equinox® LED Indicators
        </a>{' '}
        for an explanation of the LEDs and when to contact Support.
      </div>
    ),
  },
  {
    // 9
    question: 'How can I download my energy and power data?',
    answer: (
      <div>
        <article>
          Use your computer's web browser to download your system's energy data.
          Data download is not available on mobile devices.
        </article>
        <ol type="a">
          <li>
            Login to your mySunPower application:
            <a
              className="color-text-blue"
              href="https://login.mysunpower.com"
              target="_blank"
              rel="noreferrer"
            >
              https://login.mysunpower.com
            </a>
          </li>
          <li>
            In the homepage, use the date picker to select the time setting you
            would like to download.
          </li>
          <li>
            Click the download icon in the header of the <b>Energy Graph.</b>
          </li>
          <li>
            Data is saved to your computer as a .csv file that you can then view
            and use in a spreadsheet program like Excel or Numbers.
          </li>
        </ol>
        <article>
          For more information about the Energy Table, including time periods
          and data intervals you can download, click or tap the info icon in the
          header.
        </article>
      </div>
    ),
  },
  {
    // 10
    question:
      'What is the difference between mySunPower and the Customer Portal?',
    answer: (
      <div>
        mySunPower is the new monitoring experience that will replace the
        SunPower Customer Portal (EnergyLink Home) in 2021. If you have access
        to mySunPower you should rely only on that application to monitor your
        system.
      </div>
    ),
  },
  {
    // 11
    question:
      'Why do I see negative consumption (below 0)? What does this mean?',
    answer: (
      <div>
        Each solar system is unique and oftentimes set up or installation issues
        may occur. If you see negative consumption reported for your home please
        email us at{' '}
        <a
          className="color-text-blue"
          href="mailto:technical.support@sunpower.com"
        >
          technical.support@sunpower.com
        </a>{' '}
        so we can take a look at your system and correct any issues that may
        show up.
      </div>
    ),
  },
  {
    // 12
    question: 'How can I add a second person to monitor my site?',
    answer: (
      <div>
        To add a second person to your system’s monitoring please email us at{' '}
        <a className="color-text-blue" href="mailto:mysunpower@sunpower.com">
          mysunpower@sunpower.com
        </a>
      </div>
    ),
  },
  {
    // 13
    question:
      'How can I update my account when I sell or refinance my home or due to a life change?',
    answer: (
      <div>
        If you finance or own your solar system, please contact support at{' '}
        <a
          className="color-text-blue"
          href="mailto:technical.support@sunpower.com"
        >
          technical.support@sunpower.com
        </a>{' '}
        to cancel your account and set up access for the new homeowner.
        <br />
        <br />
        If you lease your solar system, please check{' '}
        <a
          href="https://us.sunpower.com/solar-resources/lease-services"
          target="_blank"
          rel="noreferrer"
          className="color-text-blue"
        >
          SunPower's Lease Services Resources
        </a>{' '}
        for answers to questions about changing your lease and account when
        selling your home, refinancing your home, or due to a life change.
      </div>
    ),
  },
  {
    // 14
    question: 'How do I file for my tax credit?',
    answer: (
      <div>
        Homeowner's with solar systems may qualify for a one-time personal tax
        credit. The amount of the credit depends on the installation year and
        cost of your system.
        <br />
        <br />
        To find out the initial cost of your solar system, please contact your
        community sales representative. Only the original purchaser of the new
        home qualifies for the tax credit.
        <br />
        <br />
        If you qualify,{' '}
        <a
          className="color-text-blue"
          href="https://www.irs.gov/forms-pubs/about-form-5695"
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          latest copy of IRS form 5695 (Residential Energy Credits) at irs.gov
        </a>{' '}
        and follow the instructions to file for your federal tax credit.
      </div>
    ),
  },
  {
    // 15
    question: 'What browsers are supported?',
    answer: (
      <div>
        <article>The following browsers are supported</article>
        <ul>
          <li>Chrome</li>
          <li>Firefox</li>
          <li>Safari for Mac (version 9 or higher)</li>
        </ul>
      </div>
    ),
  },
  {
    // 16
    question: 'How can I get more help?',
    answer: (
      <div>
        For technical questions regarding your system please email{' '}
        <a
          className="color-text-blue"
          href="mailto:technical.support@sunpower.com"
        >
          technical.support@sunpower.com
        </a>
        . For any questions regarding your monitoring application please email{' '}
        <a className="color-text-blue" href="mailto:mysunpower@sunpower.com">
          mysunpower@sunpower.com
        </a>
        . You can also call our support team at:
        <br />
        <br />
        SunPower Support: 1-800-786-7693
        <br />
        <br />
        Office hours: Mon-Fri 6:00 am – 7:00 pm (PST), Sat 7:00 am – 4:00 pm
        (PST)
      </div>
    ),
  },
]
