import React from 'react'

import { WeatherIconProps } from './cloudy'

export const NightTime = ({ x = 0, y = 0 }: WeatherIconProps) => (
  <svg
    width="27"
    height="27"
    viewBox={`${x} ${y} 27 27`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="27" height="27" />
    <path
      d="M13.0415 8.92206L15.625 8.12535L16.4217 5.5419L17.2184 8.12535L19.8019 8.92206L17.2184 9.71877L16.4217 12.3022L15.625 9.71877L13.0415 8.92206Z"
      fill="#E6E6E6"
    />
    <path
      d="M19.1018 11.3026L19.8272 11.0789L20.0509 10.3535L20.2746 11.0789L21 11.3026L20.2746 11.5263L20.0509 12.2517L19.8272 11.5263L19.1018 11.3026Z"
      fill="#E6E6E6"
    />
    <path
      d="M11.86 7.00025C11.8147 7.00248 11.7699 7.01174 11.7274 7.02769C9.36002 7.90494 8 10.2048 8 12.8218C8 16.228 10.7676 18.9999 14.1742 18.9999C16.7914 18.9999 19.0961 17.6355 19.9734 15.2683C20.0025 15.1886 20.0079 15.1022 19.9889 15.0195C19.9699 14.9368 19.9273 14.8614 19.8663 14.8024C19.8054 14.7434 19.7286 14.7033 19.6454 14.6869C19.5621 14.6706 19.4759 14.6788 19.3971 14.7104C18.798 14.9513 18.1393 15.0763 17.4443 15.0763C14.5762 15.0763 11.924 12.4198 11.924 9.55202C11.924 8.85707 12.0444 8.19833 12.2854 7.59933C12.312 7.53146 12.3213 7.45804 12.3124 7.38567C12.3036 7.31331 12.2768 7.24429 12.2346 7.18484C12.1924 7.1254 12.1361 7.07739 12.0707 7.04517C12.0053 7.01294 11.9329 6.9975 11.86 7.00025Z"
      fill="#CACED3"
    />
  </svg>
)
