import maxBy from 'lodash/maxBy'

import {
  mapMaxEnergyToTickValue,
  range,
} from '../../../../components/recharts-production-consumption-graphs/axisCalculations'

export const prepareDataForGraphs = (maxDataPoint, hourlyData, loading) => {
  const resp = {}
  const maxXIndex = maxDataPoint

  range(Math.max(maxDataPoint, hourlyData.length)).map((index) => {
    if (loading) {
      resp[index] = {
        xIndex: index,
        energy: 0,
        power: 0,
      }
      return resp[index]
    }
    const { timestamp, energy, power } = hourlyData[index] ?? [null, null]

    if (timestamp === null) {
      resp[index] = {
        xIndex: index,
        energy: 0,
        power: 0,
      }
      return resp[index]
    }

    resp[index] = {
      xIndex: index,
      energy: energy != null ? energy * 1000 : null,
      power: power != null ? power : null,
      originalTimestamp: timestamp,
    }
    return resp[index]
  })

  return Object.keys(resp)
    .filter((xIndex) => xIndex <= maxXIndex)
    .map((xIndex) => resp[xIndex])
}

export const calcYTickValues = (graphData) => {
  const maxEnergy = maxBy(graphData.dataPoints, (data) =>
    data?.energy != null ? Math.abs(data.energy) : null,
  )?.energy

  if (maxEnergy === Number.NEGATIVE_INFINITY || !maxEnergy) {
    return [0]
  }

  const midRange = mapMaxEnergyToTickValue(maxEnergy)
  const maxRange = 2 * midRange

  return [0, midRange, maxRange]
}

export const combineTheYAxisSeriesData = (hourlyData) => {
  const energyDataPoints: any[] = []
  const powerDataPoints: any[] = []
  hourlyData.forEach(({ energy, power, xIndex }) => {
    energyDataPoints.push({
      energy,
      time: xIndex,
    })
    powerDataPoints.push({
      energy: power,
      time: xIndex,
    })
  })
  return {
    energy: {
      dataPoints: energyDataPoints,
    },
    power: {
      dataPoints: powerDataPoints,
    },
  }
}

export const getTimeRange = (idx: number, isEnergy: boolean): string =>
  idx === 0 || idx === 24
    ? `12:00am${isEnergy ? ' - 1:00am' : ''}`
    : idx === 12
    ? `12:00pm${isEnergy ? ' - 1:00pm' : ''}`
    : idx < 12
    ? `${idx}:00am${isEnergy ? ` - ${idx + 1}:00am` : ''}`
    : `${idx - 12}:00pm${isEnergy ? ` - ${idx - 11}:00pm` : ''}`
