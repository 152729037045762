import moment from 'moment'
import React, { useMemo } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  XAxis,
  YAxis,
} from 'recharts'

import { graphAxisData } from '../../components/recharts-production-consumption-graphs/axisCalculations'
import {
  CRITICAL_PEAK_GRADIENT,
  OFF_PEAK_GRADIENT,
  ON_PEAK_GRADIENT,
  PARTIAL_PEAK_GRADIENT,
  REFERENCE_LINE_BLACK_COLOR,
  X_AXIS_TEXT_COLOR,
} from '../../shared/sharedConstants'
import {
  PointsData,
  getBarColor,
  prepareDataForGraph,
} from './utilityPlanUtils'

const bold_hours = [0, 12, 24]

export const UtilityGraph = ({ graphData }: { graphData: PointsData }) => {
  const data = useMemo(() => prepareDataForGraph(graphData), [graphData])

  const xTickValues = useMemo(() => [0, 15, 30, 45], [])
  const xAxisTicks = useMemo(
    () =>
      graphAxisData({
        startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        isPower: false,
      })['TODAY'],
    [],
  )

  const { tickValues, tickFormat } = xAxisTicks

  return (
    <>
      <BarChart
        width={316}
        height={215}
        data={data}
        margin={{
          top: 10,
          bottom: 10,
        }}
        barCategoryGap={-0.5}
      >
        <defs>
          <linearGradient
            id="colorOn"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={ON_PEAK_GRADIENT[0]} />
            <stop offset="1" stopColor={ON_PEAK_GRADIENT[1]} />
          </linearGradient>
          <linearGradient
            id="colorPartial"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={PARTIAL_PEAK_GRADIENT[0]} />
            <stop offset="1" stopColor={PARTIAL_PEAK_GRADIENT[1]} />
          </linearGradient>
          <linearGradient
            id="colorOff"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={OFF_PEAK_GRADIENT[0]} />
            <stop offset="1" stopColor={OFF_PEAK_GRADIENT[1]} />
          </linearGradient>
          <linearGradient
            id="colorCritical"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={CRITICAL_PEAK_GRADIENT[0]} />
            <stop offset="1" stopColor={CRITICAL_PEAK_GRADIENT[1]} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        {data.length > 0 && (
          <XAxis
            dataKey="x"
            ticks={tickValues}
            tick={customXAxis(tickFormat) as any}
            scale="linear"
            orientation={'bottom'}
            interval={0}
            padding={{ left: 9, right: 20 }}
          />
        )}
        <YAxis
          dataKey="y"
          ticks={xTickValues}
          axisLine={false}
          allowDecimals={false}
          tickLine={false}
          scale="linear"
          hide
        />
        <Bar dataKey="y">
          {data.map(({ y }, index) => (
            <Cell key={`cell-${index}`} fill={`url(#${getBarColor(y)})`} />
          ))}
        </Bar>
        <ReferenceLine stroke={REFERENCE_LINE_BLACK_COLOR} y={0} />
      </BarChart>
    </>
  )
}

const customXAxis =
  (tickFormat) =>
  ({ x, y, payload }) => {
    if (!x || !y) return null
    const { value } = payload

    return (
      <svg x={x - 10} y={y - 20} height={50} width={50}>
        <text
          fill={X_AXIS_TEXT_COLOR}
          x="0"
          y="35"
          fontSize="10"
          fontWeight={bold_hours.includes(value) ? 700 : 400}
        >
          {tickFormat(value)}
        </text>
      </svg>
    )
  }
