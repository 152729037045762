import '../style.scss'

import React from 'react'

import alertHouseModal from '../assets/alert-modal-house.png'

export const AlertIDModalContents = () => {
  return (
    <div className="alert-modal-contents id">
      <div className="container has-text-centered">
        <img src={alertHouseModal} alt="Alert ID" />
        <p className="alert-message is-size-4">
          One or more devices are
          <br />
          not working properly.
        </p>
        <p className="alert-email-message is-size-5 mb-5">
          <span>Email us to fix this issue:</span>
          <a
            href="mailto:mysunpower@sunpower.com"
            className="support-email is-size-4"
          >
            mysunpower@sunpower.com
          </a>
        </p>
      </div>
    </div>
  )
}
