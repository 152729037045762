import './style.scss'
import '../../styles/setupMode.scss'

import { useMutation } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { Slider } from '../../components/slider'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { useCurrentSiteData } from '../../shared/hooks'
import { FETCH_PARTY_DATA } from '../../shared/hooks/query'
import { OPERATIONAL_MODES } from '../../shared/setupModeConstants'
import { SITE_TYPES } from '../../shared/sharedConstants'
import paths from '../Router/paths'
import { SELF_SUPPLY_COMMAND } from './query'

const DEFAULT_SELF_SUPPLY = '20'

export const SelfSupplySetupPage = () => {
  const history = useHistory()
  const { site, loading: loadingSite, partyId } = useCurrentSiteData()

  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]

  const [selfSupplyVal, setSelfSupplyVal] = useState(DEFAULT_SELF_SUPPLY)

  const handleCancel = React.useCallback(() => {
    history.push(paths.ROOT)
  }, [history])

  const [
    changeBatteryMode,
    { data: changeBatteryData, loading, error },
  ] = useMutation(SELF_SUPPLY_COMMAND, {
    refetchQueries: [{ query: FETCH_PARTY_DATA, variables: { partyId } }],
  })

  useEffect(() => {
    if (changeBatteryData && !error) {
      history.push(paths.ROOT)
    }
  }, [changeBatteryData, loading, error, history])

  const notStorageSite = site?.siteType !== SITE_TYPES.STORAGE
  useEffect(() => {
    if (!loading && site && notStorageSite) {
      history.push(paths.ROOT)
    }
  }, [site, loadingSite])

  const handleSetUpSelfSupply = useCallback(() => {
    const reserveLevel = parseFloat(selfSupplyVal) / 100
    changeBatteryMode({
      variables: {
        params: {
          siteKey: selectedSiteKey,
          mode: OPERATIONAL_MODES.SELF_CONSUMPTION,
          reserveLevel: reserveLevel,
        },
      },
    })
  }, [history, selfSupplyVal, selectedSiteKey])

  const updateSliderValue = useCallback(
    (e) => {
      setSelfSupplyVal(e.target.value)
    },
    [setSelfSupplyVal],
  )

  if (loadingSite || !site || notStorageSite) {
    return null
  }

  return (
    <Layout title="Self supply Mode">
      <section className="section self-supply-setup-page setup-mode-column columns is-mobile is-vcentered">
        <article className="setup-mode-title column">
          How much reserve do you want <br />
          to save in case there’s an outage?
        </article>
        <div className="setup-mode-value">
          {selfSupplyVal}
          <span className="percentage ml-5">%</span>
        </div>
        <h5 className="is-uppercase setup-mode-saved-reserves">
          saved reserves
        </h5>
        <section className="column setup-mode-slider-container">
          <Slider
            min={'1'}
            max={'80'}
            onSliderChange={updateSliderValue}
            sliderValue={selfSupplyVal}
          />
        </section>
        <section className="column setup-mode-actions-container mt-5">
          <button className="cancel mr-5 mb-5" onClick={handleCancel}>
            cancel
          </button>
          <button className="go-to-slider mr-5" onClick={handleSetUpSelfSupply}>
            start
          </button>
        </section>
      </section>
    </Layout>
  )
}
