import React from 'react'
import Lottie from 'react-lottie'

import { dynamicEventCardDateFormat } from '../../../../shared/valueFormatters'
import batteryPulseAnimation from '../../assets/battery-pulse'

const bateryPulseOptions = {
  loop: true,
  autoplay: true,
  animationData: batteryPulseAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const PendingState = ({
  enrollmentSubmission,
}: {
  enrollmentSubmission: string
}) => {
  return (
    <div className="enroll-top-section">
      <div className="enroll-content-card">
        <div className="header-section">
          <div className="enroll-image">
            <Lottie
              options={bateryPulseOptions}
              className="enroll-image"
              width={150}
              resizeMode="cover"
            />
          </div>
          <b className="enroll-status pending-status">Pending Approval</b>
          <p className="enroll-info-text">
            We are reviewing your application and will notify you upon
            completion.
          </p>
          <p className="enroll-info-text">
            This process can take up to two weeks.
          </p>
          <b className="enroll-info-date-submitted">{`Application submitted on ${dynamicEventCardDateFormat(
            enrollmentSubmission,
          )}`}</b>
        </div>
      </div>
    </div>
  )
}
