import React from 'react'

import { DayWeather, WeatherConditions } from '..'

export const renderCustomAxisTick = (tickFormat, weatherDecorator) => ({
  x,
  y,
  payload,
}) => {
  if (!x || !y) return null
  const { value } = payload

  return (
    <svg x={x - 20} y={y - 55} height={50} width={50}>
      {weatherDecorator(value)}
      <text fill="#404040" x="0" y="35" fontSize="10">
        {tickFormat(value)}
      </text>
    </svg>
  )
}

const isDaytime = (day: DayWeather, value: number): boolean => {
  const sunset = parseInt(day.sunset.split(':')[0])
  const sunrise = parseInt(day.sunrise.split(':')[0])
  return value >= sunrise && value <= sunset
}

export const getWeatherConditions = (
  weatherData: WeatherConditions,
  value: number,
): string => {
  if (weatherData?.days?.length > 1) {
    const weatherDay = weatherData?.days[value]
    return weatherDay?.conditions !== 'UNAVAILABLE'
      ? weatherDay?.conditions
      : 'SUNNY'
  } else if (weatherData?.days.length === 1 && weatherData?.days[0].hours) {
    const day = weatherData?.days[0]
    const hour = day?.hours?.find((day) => {
      return day.datetime.match(`^${String(value).padStart(2, '0')}:00:00`)
    })
    if (hour?.conditions && hour?.conditions !== 'UNAVAILABLE') {
      return isDaytime(day, value) ? hour?.conditions : 'CLEAR_NIGHT'
    }
    if (day) {
      return isDaytime(day, value) ? 'SUNNY' : 'CLEAR_NIGHT'
    }
  }
  return 'SUNNY'
}
