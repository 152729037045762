export const MAX_DAYS_IN_YEAR = 365
export const MAX_DAYS_IN_MONTH = 31
export const HOURS_IN_A_DAY = 24
export const MONTHS_IN_A_YEAR = 12
export const QUARTER = 15
export const QUARTERS_IN_AN_HOUR = 4
export const QUARTERS_IN_A_DAY = QUARTERS_IN_AN_HOUR * HOURS_IN_A_DAY
export const MINUTES_IN_AN_HOUR = 60
export const MAX_DAYS_IN_WEEK = 7
export const FIVE_MINUTES_IN_AN_HOUR = 12
export const FIVE_MINUTES = 5
export const FIVE_MINUTE_CHUNKS_IN_A_DAY =
  FIVE_MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY
export const MAX_INDEX_TIMELAPSE_GRAPH = 24

export const CALIFORNIA_CODE = 'CA'

export enum SITE_TYPES {
  PROD_ONLY = 'production',
  CONSUMPTION = 'consumption',
  STORAGE = 'storage',
}

export enum GRAPH_TYPES {
  ENERGY = 'energy',
  POWER = 'power',
}

export const DEFAULT_TIMEZONE = 'America/New_York'

// GRAPH COLORS
export const REFERENCE_LINE_BLACK_COLOR = '#000000'

export const X_AXIS_TEXT_COLOR = '#404040'

// ON PEAK, OFF PEAK, CRITICAL PEAK and PARTIAL PEAK COLORS
export const ON_PEAK_GRADIENT = ['#F26829', '#F7921E']
export const PARTIAL_PEAK_GRADIENT = ['#045886', '#2B93CC']
export const OFF_PEAK_GRADIENT = ['#F8F8F8', '#F3F3F3']
export const CRITICAL_PEAK_GRADIENT = ['#6aa442', '#7ed321']
