import { camelCase, startCase } from 'lodash'
import moment from 'moment'

import { SeasonType } from '../../components/seasons-timeline/flat-timeline'
import { TariffPlanUIStructureResponse } from '../../shared/typings/site'

export type TimelineParsed = { tariffCode: string; seasons: SeasonType[] }

type SeasonSegment = {
  name: string | null
  seasonFromMonth: number
  seasonToMonth: number
  seasonColor: string
  seasonFromDay: number
  seasonToDay: number
}

export type SeasonParsed = {
  seasonId: number
  seasonName: string
  seasonFromDay: number
  seasonFromMonth: number
  seasonToMonth: number
  seasonToDay: number
  seasonColor: string
  peaks: PeakParsed[]
  graphDatapoints: GraphDatapoints
}

type PeakParsed = {
  touName: string
  touType: string
  touPeriods: TouPeriodParsed[]
}

type TouPeriodParsed = {
  calendarId: string | null
  end: string
  start: string
  touPeriodId: string | number | null
  touId: string | number | null
  fromDayOfWeek: number
  toDayOfWeek: number
}

type GraphDatapoints = {
  weekday: { pointsData: PointsData }
  weekend: { pointsData: PointsData }
}

export type PointsData = {
  criticalPeak: PeakPointer[]
  offPeak: PeakPointer[]
  onPeak: PeakPointer[]
  partialPeak: PeakPointer[]
}

type PeakPointer = {
  x: number
  y: number
}
const MONTHS_NUMBER = 12
const SEASONS = ['winter', 'summer', 'fall', 'spring']
const SEASON_COLORS = ['#6AA442', '#FFC72C', '#9D57A3', '#66B8D9']
const PEAK_TYPE_BAR_HEIGHT = {
  offPeak: 7,
  onPeak: 30,
  partialPeak: 15,
  criticalPeak: 45,
}

export const rangeToDates = (startDate: string, endDate: string): string => {
  if (!startDate || !endDate) return 'Invalid date'
  const startMonth = moment(startDate).format('MMMM')
  const endMonth = moment(endDate).format('MMMM')

  return `${startMonth} to ${endMonth}`
}

export const parseUtilityPlanToSeasonsTimeline = (
  utilityPlan: TariffPlanUIStructureResponse | null,
): TimelineParsed => {
  if (!utilityPlan) return {} as TimelineParsed

  const { seasons: seasonsObj, ...rest } = utilityPlan

  const seasons = Object.keys(seasonsObj).flatMap((key) =>
    SEASONS.includes(key) && utilityPlan.seasons[key]
      ? utilityPlan.seasons[key]
      : [],
  )

  let monthRanges: SeasonType[] = []
  let seasonSegments: SeasonSegment[] = []

  if (seasons.length) {
    // Create month ranges from seasons
    for (let index = 0; index < seasons.length; index++) {
      const {
        seasonName,
        seasonFromMonth,
        seasonToMonth,
        seasonFromDay,
        seasonToDay,
      } = seasons[index]

      // Season months are zero-based
      const seasonMonthCount =
        seasonToMonth > seasonFromMonth
          ? seasonToMonth - seasonFromMonth
          : MONTHS_NUMBER - 1 + seasonToMonth - seasonFromMonth

      const remainingYearMonths = MONTHS_NUMBER - 1 - seasonFromMonth
      const seasonColor = SEASON_COLORS[index]

      // Need to segment the season
      if (seasonMonthCount > remainingYearMonths) {
        const segment1 = {
          name: seasonName,
          seasonFromMonth: seasonFromMonth - 1,
          seasonToMonth: 11,
          seasonColor,
          seasonFromDay,
          seasonToDay,
        }

        const segment2 = {
          name: null, //in order to not show repetitive legends
          seasonFromMonth: 0,
          seasonToMonth: seasonMonthCount - remainingYearMonths - 1,
          seasonColor,
          seasonFromDay,
          seasonToDay,
        }

        seasonSegments.push(segment1, segment2)
      } else {
        seasonSegments.push({
          name: seasonName,
          seasonFromMonth: seasonFromMonth - 1,
          seasonToMonth: seasonToMonth - 1,
          seasonColor,
          seasonFromDay,
          seasonToDay,
        })
      }
    }

    // Sort by segment start month
    seasonSegments = seasonSegments.sort(
      (a, b) => a.seasonFromMonth - b.seasonFromMonth,
    )

    // Create seasons graph data
    monthRanges = seasonSegments.map((range) => ({
      color: range.seasonColor,
      name: range.name,
      endDate: moment({
        month: range.seasonToMonth,
        day: range.seasonToDay,
      }).format(),
      startDate: moment({
        month: range.seasonFromMonth,
        day: range.seasonFromDay,
      }).format(),
    }))
  }

  return {
    ...rest,
    seasons: monthRanges,
  }
}

const generatePeakData = () => ({
  pointsData: {
    onPeak: [],
    offPeak: [],
    criticalPeak: [],
    partialPeak: [],
  },
})

export const parseUtilityPlanToSeasons = (
  utilityPlan: TariffPlanUIStructureResponse | null,
): SeasonParsed[] => {
  if (!utilityPlan) return []
  const seasonsArr = Object.keys(utilityPlan.seasons).flatMap((key) =>
    SEASONS.includes(key) && utilityPlan.seasons[key]
      ? utilityPlan.seasons[key]
      : [],
  )

  const seasonsParsed = seasonsArr.map((season) => {
    const timeOfUseArr: any[] = []
    const { peaks } = season

    const peaksarr = Object.entries(peaks ?? {})

    peaksarr.forEach(([key, values]) => {
      // Season peak type data

      if (Array.isArray(values)) {
        let touType = camelCase(key)
        let touName = startCase(touType)
        let touPeriods: any = values.map(
          ({ timeOfUse }) => timeOfUse.touPeriods,
        )

        // Flat array
        touPeriods = [].concat.apply([], touPeriods)

        // Remove duplicates
        touPeriods = touPeriods.reduce((acc, current) => {
          const found = acc.find(
            (item) =>
              item.fromDayOfWeek === current.fromDayOfWeek &&
              item.toDayOfWeek === current.toDayOfWeek &&
              item.start === current.start &&
              item.end === current.end,
          )

          if (!found) return acc.concat([current])

          return acc
        }, [])

        timeOfUseArr.push({
          touType,
          touName,
          touPeriods,
        })
      }
    })

    const peakData = {
      weekend: generatePeakData(),
      weekday: generatePeakData(),
    }

    timeOfUseArr.forEach(({ touType, touPeriods }) => {
      if (touPeriods.length) {
        touPeriods.forEach((period) => {
          if (period.start && period.end) {
            //  Create points data from periods
            let duration = 0
            const startTime = moment(period.start, 'HH:mm a').subtract(
              1,
              'hours',
            )
            const endTime = moment(period.end, 'HH:mm a').subtract(1, 'hours')

            // Is next day
            if (endTime <= startTime) endTime.add(1, 'day')

            duration = endTime.diff(startTime, 'hours')

            const periodsPoints = Array.from(Array(duration), (_, i) => ({
              x: (startTime.hour() + i) % 24, // 24-Hour Time Format
              y: PEAK_TYPE_BAR_HEIGHT[touType],
            }))

            // is weekday
            if (period.fromDayOfWeek >= 0 && period.toDayOfWeek <= 4) {
              peakData.weekday.pointsData[touType].push(...periodsPoints)
            }
            // is weekend
            else if (period.fromDayOfWeek >= 5 && period.toDayOfWeek <= 6) {
              peakData.weekend.pointsData[touType].push(...periodsPoints)
              // all week
            } else {
              peakData.weekday.pointsData[touType].push(...periodsPoints)
              peakData.weekend.pointsData[touType].push(...periodsPoints)
            }
          }
        })
      }
    })

    return { ...season, peaks: timeOfUseArr, graphDatapoints: peakData }
  })
  return seasonsParsed
}

export const prepareDataForGraph = (graphData: PointsData): PeakPointer[] => {
  const { onPeak, partialPeak, criticalPeak, offPeak } = graphData || {}

  let fusedPeaks: Array<PeakPointer> = []
  if (onPeak) {
    fusedPeaks = [...fusedPeaks, ...onPeak]
  }
  if (partialPeak) {
    fusedPeaks = [...fusedPeaks, ...partialPeak]
  }
  if (criticalPeak) {
    fusedPeaks = [...fusedPeaks, ...criticalPeak]
  }
  if (offPeak) {
    fusedPeaks = [...fusedPeaks, ...offPeak]
  }

  fusedPeaks = fusedPeaks?.sort((a, b) => a.x - b.x)

  return fusedPeaks
}

export const getBarColor = (peak: number): string => {
  let color
  if (peak <= PEAK_TYPE_BAR_HEIGHT.offPeak) {
    color = 'colorOff'
  } else if (
    peak > PEAK_TYPE_BAR_HEIGHT.offPeak &&
    peak <= PEAK_TYPE_BAR_HEIGHT.partialPeak
  ) {
    color = 'colorPartial'
  } else if (
    peak > PEAK_TYPE_BAR_HEIGHT.partialPeak &&
    peak <= PEAK_TYPE_BAR_HEIGHT.onPeak
  ) {
    color = 'colorOn'
  } else if (
    peak > PEAK_TYPE_BAR_HEIGHT.onPeak &&
    peak <= PEAK_TYPE_BAR_HEIGHT.criticalPeak
  ) {
    color = 'colorCritical'
  }

  return color
}
