import './style.scss'

import classnames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'

import ChevronDown from './../../assets/chevron-down.svg'
import rightArrowIcon from './../../assets/right-arrow.svg'
import weekdaysIcon from './../../assets/weekdays-icon.svg'
import weekendsIcon from './../../assets/weekends-icon.svg'
import { UtilityGraph } from './../../UtilityGraph'
import { SeasonParsed, rangeToDates } from './../../utilityPlanUtils'

const WEEKEND_START = 5

enum TOGGLE_TYPES {
  WEEKDAY = 'weekday',
  WEEKEND = 'weekend',
}

type DetailCardProps = {
  selectedSeason: SeasonParsed | null
  isMobile?: boolean
  isSelected?: boolean
  onSelect?: () => void
}

export const DetailCard = ({
  selectedSeason,
  isMobile = false,
  isSelected = false,
  onSelect,
}: DetailCardProps) => {
  const [timePeriod, setTimePeriod] = useState<TOGGLE_TYPES>(
    TOGGLE_TYPES.WEEKDAY,
  )

  const {
    seasonName,
    seasonFromMonth,
    seasonToMonth,
    seasonFromDay,
    seasonToDay,
    graphDatapoints = {},
  } = selectedSeason || {}

  const toggleDayMode = () =>
    setTimePeriod(
      timePeriod === TOGGLE_TYPES.WEEKDAY
        ? TOGGLE_TYPES.WEEKEND
        : TOGGLE_TYPES.WEEKDAY,
    )
  const seasonDescription = selectedSeason
    ? rangeToDates(
        moment({
          month: (seasonFromMonth ?? 1) - 1,
          day: seasonFromDay,
        }).format(),
        moment({
          month: (seasonToMonth ?? 1) - 1,
          day: seasonToDay,
        }).format(),
      )
    : 'Please select a season'

  const filterBySelectedDay = (currentPeriod) =>
    (timePeriod === TOGGLE_TYPES.WEEKDAY &&
      currentPeriod.fromDayOfWeek < WEEKEND_START) ||
    (timePeriod === TOGGLE_TYPES.WEEKEND &&
      currentPeriod.toDayOfWeek >= WEEKEND_START)

  return (
    <div
      className={classnames('floating-card card-detail-column', {
        mobile: isMobile,
      })}
      onClick={() => {
        if (isMobile && onSelect) {
          onSelect()
        }
      }}
    >
      <div className="card-header-container">
        <div className="info-container">
          <h3 className="card-detail-title">{seasonName}</h3>
          <h2 className="card-detail-subtitle">{seasonDescription}</h2>
        </div>
        {isMobile && (
          <img
            src={ChevronDown}
            alt=""
            className={classnames({ selected: isSelected })}
          />
        )}
      </div>
      {(!isMobile || isSelected) && (
        <>
          <DaysToogle dayMode={timePeriod} onChange={toggleDayMode} />
          <UtilityGraph graphData={graphDatapoints[timePeriod]?.pointsData} />
          <h2 className="scheduled-times-title">Scheduled Times</h2>
          <div className="card-separator" />
          {selectedSeason?.peaks.map(
            ({ touType, touName, touPeriods }, index) => {
              const filteredTouPeriods = touPeriods.filter(filterBySelectedDay)
              if (filteredTouPeriods.length === 0) {
                return
              }
              return (
                <div className="peak-table" key={`${index}-${touName}`}>
                  <div className="table-header">
                    <div className={`${touType}-square times-square`} />
                    <h3 className="table-header-label">{`${touName} times`}</h3>
                  </div>
                  {filteredTouPeriods.map(({ start, end }, index) => (
                    <PeakTableCell
                      key={`${index}-${start}-${end}`}
                      startHour={start}
                      endHour={end}
                    />
                  ))}
                </div>
              )
            },
          )}
        </>
      )}
    </div>
  )
}

const DaysToogle = ({
  dayMode,
  onChange,
}: {
  dayMode: TOGGLE_TYPES
  onChange: () => void
}) => {
  const isWekendsActive = dayMode === TOGGLE_TYPES.WEEKEND
  const isWeekDaysActive = !isWekendsActive
  return (
    <div className="toggle-wrapper">
      <div
        className={classnames('toggle-option', {
          'active-option inverted': isWeekDaysActive,
          'inactive-option': isWekendsActive,
        })}
        onClick={() => {
          isWekendsActive && onChange()
        }}
      >
        <img src={weekdaysIcon} alt="weekends button" className="toggle-icon" />
        Weekdays
      </div>
      <div
        className={classnames('toggle-option', {
          'active-option': isWekendsActive,
          'inactive-option': isWeekDaysActive,
        })}
        onClick={() => {
          isWeekDaysActive && onChange()
        }}
      >
        <img src={weekendsIcon} alt="weekends button" className="toggle-icon" />
        Weekends
      </div>
    </div>
  )
}

const PeakTableCell = ({
  startHour,
  endHour,
}: {
  startHour: string
  endHour: string
}) => {
  return (
    <>
      <div className="table-separator" />
      <div className="table-cell">
        <div className="peak-time-container">
          <h3 className="title-cell">Start</h3>
          <h3 className="subtitle-cell">{startHour}</h3>
        </div>
        <img alt="arrow" src={rightArrowIcon} className="table-arrow" />
        <div className="peak-time-container">
          <h3 className="title-cell">End</h3>
          <h3 className="subtitle-cell">{endHour}</h3>
        </div>
      </div>
    </>
  )
}
