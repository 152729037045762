import '../style.scss'

import { useMutation } from '@apollo/client'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import iconClose from '../../../components/menu/assets/icon-close.svg'
import { useDynamicVpp } from '../../../shared/hooks'
import { getEventDataFormated } from '../../../shared/timeUtils'
import { VPPDeviceCurrentEvent } from '../../../shared/typings/site'
import { OPT_OUT_DEVICE } from './query'

interface AlertOptOutProps {
  deviceUuid?: string
  currentEvent?: VPPDeviceCurrentEvent
}

export const AlertOptOut = ({ deviceUuid, currentEvent }: AlertOptOutProps) => {
  const { fetchEnrollments } = useDynamicVpp()
  const history = useHistory()
  const [optOutDevice] = useMutation(OPT_OUT_DEVICE)

  const handleOnClose = useCallback(() => history.goBack(), [history])
  const parsedEvent = useMemo(
    () => getEventDataFormated(currentEvent || ({} as VPPDeviceCurrentEvent)),
    [currentEvent]
  )

  const eventId = useMemo(() => currentEvent?.eventId, [currentEvent])

  const handleOptOut = useCallback(() => {
    optOutDevice({
      variables: {
        params: {
          deviceUuid: deviceUuid,
          eventId: eventId,
        },
      },
    })
    fetchEnrollments()
    handleOnClose()
  }, [deviceUuid, eventId, fetchEnrollments, handleOnClose, optOutDevice])

  return (
    <div className='alert-modal-opted-out'>
      <div className='header'>
        <div>
          <img
            src={iconClose}
            alt='Close sidebar'
            className='close-icon'
            onClick={handleOnClose}
          />
        </div>
      </div>
      <div className='opt-out-title'>
        {parsedEvent.eventDate}
        <div className='opt-out-time'>
          {`- ${parsedEvent.startTime} - ${parsedEvent.endTime}`}
        </div>
      </div>
      <div className='opt-out-title opt-out-sub-title'>
        You're opted-in
        <div className='opt-out-time'>to this event</div>
      </div>
      <div className='opt-out-content'>
        Opting out of events reduces your average discharge power for the
        season, directly affecting your potential savings at the end of the
        season.
      </div>
      <div className='opt-out-content opt-out-bold'>
        Are you sure you want to opt out from this event?
      </div>
      <div className='opt-out-content'>
        You will not be able to opt back in.
      </div>
      <div className='buttons-container'>
        <button className='cancel' onClick={handleOnClose}>
          Cancel
        </button>
        <button onClick={handleOptOut} className='agree'>
          Yes, Opt-out
        </button>
      </div>
    </div>
  )
}
