import './style.scss'

import React from 'react'

import siteIcon from './assets/site-icon.svg'

export const SiteCard = ({ site, onClick }) => (
  <div
    key={site.siteKey}
    className="site-card mb-5"
    onClick={() => onClick(site.siteKey)}
  >
    <div className="site-card-overlay">
      <img className="site-icon" alt="site-icon" src={siteIcon} />
      <h4 className="has-text-centered has-text-weight-bold is-size-4 my-4 mx-4">
        {site.address1}, {site.state}
      </h4>
      <div className="bottom-border"></div>
    </div>
  </div>
)
