import moment from 'moment-timezone'

import { calculateDaysDifference } from '../../shared/calculateDaysDifference'
import { limitDaysInPast } from '../../shared/constants'
import {
  FIVE_MINUTES,
  FIVE_MINUTES_IN_AN_HOUR,
  QUARTER,
  QUARTERS_IN_AN_HOUR,
} from '../../shared/sharedConstants'
import { AGGREGATION_PERIOD, currentIndex, range } from './axisCalculations'

export const combineTheYAxisSeriesData = (
  storage,
  production,
  consumption,
  grid,
  interval,
  startDate,
  endDate,
  timezone,
) => {
  const dayDifference = moment
    .tz(endDate, timezone)
    .diff(moment.tz(startDate, timezone), 'day')

  const mapDataPoint = ([timestamp, value]) => {
    const axisTimestamp = currentIndex[interval](
      timestamp,
      startDate,
      dayDifference,
      timezone,
    )

    return {
      energy: parseFloat(value),
      time: axisTimestamp,
    }
  }

  return {
    production: {
      dataPoints: production.map(mapDataPoint),
    },
    storage: {
      dataPoints: storage.map(mapDataPoint),
    },
    consumption: {
      dataPoints: consumption.map(mapDataPoint),
    },
    grid: {
      dataPoints: grid.map(mapDataPoint),
    },
  }
}
export const needsShiftForSpace = (interval) => {
  const okIntervals = [
    AGGREGATION_PERIOD.ThisYear,
    AGGREGATION_PERIOD.Lifetime,
    AGGREGATION_PERIOD.Custom,
  ] as Array<string>
  return okIntervals.includes(interval)
}

const getCurrentIndex = (time, isLegacyPvs, timezone) => {
  const date = moment.tz(time, timezone)
  const hour = date.hour()
  const minute = date.minute()
  return (
    (isLegacyPvs ? QUARTERS_IN_AN_HOUR : FIVE_MINUTES_IN_AN_HOUR) * hour +
    minute / (isLegacyPvs ? QUARTER : FIVE_MINUTES)
  )
}

type CombineTheSeriesDataType = {
  maxDataPoint: number
  storage: object
  production: object
  consumption: object
  grid: object
  interval: string
  startDate?: number
  endDate?: number
  timezone: string
  isPower?: boolean
  isLegacyPvs?: boolean
  customDaysDifference?: undefined | number
}

export const combineTheSeriesData = (data: CombineTheSeriesDataType) => {
  const {
    maxDataPoint,
    storage,
    production,
    consumption,
    grid,
    interval,
    startDate,
    endDate,
    timezone,
    isPower,
    isLegacyPvs,
    customDaysDifference,
  } = data

  const dayDifference = customDaysDifference
    ? customDaysDifference
    : calculateDaysDifference(startDate, endDate, timezone)

  const currentDate = moment().tz(timezone)
  const selectedDate = moment(endDate).tz(timezone)
  const isOverLimitDaysInPast =
    currentDate && selectedDate
      ? Math.abs(moment.duration(selectedDate.diff(currentDate)).asDays()) >=
        limitDaysInPast
      : undefined

  const resp = {}
  const maxXIndex =
    interval === AGGREGATION_PERIOD.Today ? maxDataPoint + 1 : maxDataPoint
  // @ts-ignore

  const productionLength = Object.entries(production).length

  range(Math.max(maxDataPoint, productionLength)).map((index, prodIndx) => {
    const [timestamp, value] = production[index] ?? [null, null]

    const xIndex =
      isPower && !isOverLimitDaysInPast
        ? getCurrentIndex(timestamp, isLegacyPvs, timezone) // day hourly data
        : currentIndex[interval](timestamp, startDate, dayDifference, timezone)

    if (dayDifference > 31) {
      resp[index] = {
        xIndex: index,
        production: value != null ? parseFloat(value) : null,
        storage: storage[index] != null ? parseFloat(storage[index][1]) : null,
        consumption:
          consumption[index] != null && consumption[index][1] !== '0'
            ? parseFloat(consumption[index][1])
            : null,
        grid: grid[index] != null ? parseFloat(grid[index][1]) : null,
        originalTimestamp: timestamp,
      }
    } else {
      resp[xIndex] = {
        xIndex: xIndex,
        production: value != null ? parseFloat(value) : null,
        storage:
          storage[prodIndx] != null ? parseFloat(storage[prodIndx][1]) : null,
        consumption:
          consumption[prodIndx] != null && consumption[prodIndx][1] !== '0'
            ? parseFloat(consumption[prodIndx][1])
            : null,
        grid: grid[prodIndx] != null ? parseFloat(grid[prodIndx][1]) : null,
        originalTimestamp: timestamp,
      }
    }

    if (timestamp === null && !resp[index]) {
      resp[index] = {
        xIndex: index,
        production: null,
        consumption: null,
        storage: null,
        grid: null,
      }
      return resp[index]
    }

    if (resp[index]) {
      return resp
    }
  })

  return (
    Object.keys(resp)
      // @ts-ignore
      .filter((xIndex) => xIndex <= maxXIndex)
      .map((xIndex) => resp[xIndex])
  )
}
