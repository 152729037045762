import { useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'

import { limitDaysInPast } from '../constants'
import { findLatestValidDataPoint } from '../findLatestValidDataPoint'
import { INTERVALS } from '../intervals'
import { DataLayerEventName } from '../typings/dataLayer'
import { FETCH_POWER_DATA } from './query'

export const POWER_DATA_POLLING_INTERVAL = 60 * 1000 * 5 // 5 minutes

export const usePower = (
  siteKey,
  siteType,
  startDate,
  endDate,
  interval,
  isLegacyPvs,
  timezone,
) => {
  const [powerData, setPowerData] = useState<{
    production: any[]
    consumption: any[]
    storage: any[]
    grid: any[]
  }>({
    production: [],
    consumption: [],
    storage: [],
    grid: [],
  })

  const [initialCurrentPower, setInitialCurrentPower] = useState<number>(0)

  const currentDate = moment().tz(timezone)
  const selectedDate = moment(endDate).tz(timezone)

  const [fetchPowerData, { called, data, loading, error }] = useLazyQuery(
    FETCH_POWER_DATA,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      pollInterval: POWER_DATA_POLLING_INTERVAL,
    },
  )

  useEffect(() => {
    if (error) {
      window.dataLayer.push({
        event: DataLayerEventName.POWER_DATA_ERROR,
        eventData: { stack: error.stack },
      })
    }
  }, [error])

  useEffect(() => {
    if (
      interval === INTERVALS.DAY &&
      siteKey &&
      startDate &&
      endDate &&
      timezone &&
      !loading
    ) {
      const isOverLimitDaysInPast =
        Math.abs(
          moment
            // @ts-ignore
            .duration(selectedDate.diff(currentDate))
            .asDays(),
        ) >= limitDaysInPast

      fetchPowerData({
        variables: {
          siteKey: siteKey,
          interval: isOverLimitDaysInPast
            ? 'hour'
            : isLegacyPvs
            ? 'quarter_hour'
            : 'five_minute',
          end: moment(endDate).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'),
          start: moment(startDate).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'),
        },
      })
    }
  }, [siteKey, startDate, endDate, interval, timezone, isLegacyPvs])

  useEffect(() => {
    // Format API data
    if (called && data) {
      const {
        consumption,
        production,
        storage,
        grid,
      } = data.power.powerDataSeries
      setPowerData({
        consumption: consumption ?? [],
        production: production ?? [],
        storage: storage ?? [],
        grid: grid ?? [],
      })

      //setting initial current power data without live data updates only used at page load
      const latestValidDataPoint = findLatestValidDataPoint(production)
      const currentProductionValue = parseFloat(latestValidDataPoint[1])
      setInitialCurrentPower(currentProductionValue)
    }
  }, [called, data])

  const retValue = useMemo(
    () => ({ power: powerData, initialCurrentPower, loading: loading }),
    [loading, powerData, initialCurrentPower],
  )

  return retValue
}
