import { useLazyQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'

import { CustomTariffContext } from '../../context/customTariffContext'
import { errorCoder } from '../errorFormatter'
import { TariffPlanUIStructureResponse } from '../typings/site'
import { FETCH_UTILITY_RATE_PLANS_DATA_QUERY } from './query'

interface UseUtilityRatePlansProps {
  siteKey: string
  skip?: boolean
}

export type UseUtilityRatePlansReturnType = {
  data: {
    fetchTariffRatePlansBySiteKey: TariffPlanUIStructureResponse[]
  }
  loading: boolean
  called: boolean
  refetch?: Function
  error: any
  utilityPlanDetail: TariffPlanUIStructureResponse | null
  setUtilityPlanDetail: React.Dispatch<
    React.SetStateAction<TariffPlanUIStructureResponse | null>
  >
}

export const defaultvalue: UseUtilityRatePlansReturnType = {
  data: { fetchTariffRatePlansBySiteKey: [] },
  loading: true,
  called: false,
  refetch: () => {},
  error: {},
  utilityPlanDetail: null,
  setUtilityPlanDetail: () => {},
}

export const useUtilityRatePlans = ({
  siteKey,
  skip,
}: UseUtilityRatePlansProps): UseUtilityRatePlansReturnType => {
  const initValue = useContext(CustomTariffContext) ?? defaultvalue
  const {
    utilityPlans,
    setUtilityPlans,
    utilityPlanDetail,
    setUtilityPlanDetail,
  } = initValue

  const [
    fetchTariffRatePlansBySiteKey,
    { called, data, loading, refetch, error },
  ] = useLazyQuery(FETCH_UTILITY_RATE_PLANS_DATA_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      siteKey,
    },
    onError: (error: any) => {
      setUtilityPlans({
        data,
        loading: false,
        called,
        error: errorCoder(error),
        refetch,
      })
    },
  })

  useEffect(() => {
    if (
      !utilityPlans?.data?.fetchTariffRatePlansBySiteKey?.length &&
      !skip &&
      !error &&
      !called
    ) {
      fetchTariffRatePlansBySiteKey()
    }
  }, [called, error, fetchTariffRatePlansBySiteKey, skip, utilityPlans])

  useEffect(() => {
    if (data) {
      setUtilityPlans({
        data,
        loading,
        called,
        error,
        refetch,
      })
    }
  }, [data, called, loading, refetch, setUtilityPlans, error])

  return { ...utilityPlans, utilityPlanDetail, setUtilityPlanDetail }
}
