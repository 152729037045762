import React from 'react'

import { getTimeRange } from '../calcGraph'

export const GraphTooltip = (props) => {
  const { payload: tooltipData = [] } = props

  const tooltipComponent = tooltipData.map((item, index) => {
    const {
      value,
      dataKey,
      payload: { xIndex },
    } = item

    const timeStamp = getTimeRange(xIndex, dataKey === 'energy')
    return (
      <div key={`tooltip-item${index}`} className="tooltip">
        <div>{timeStamp}</div>
        <div className="energy">
          <b>{value}</b>
          {dataKey === 'energy' ? ' Wh' : ' W'}
        </div>
      </div>
    )
  })

  return tooltipData && tooltipData.length > 0 ? <>{tooltipComponent}</> : null
}
