export enum CONNECTION_STATUSES {
  ERROR = 'ERR',
  DISCONNECTED = 'AP0',
  POWER_LINE = 'PLC',
  WIFI = 'STA0',
  CELLULAR = 'CELL',
  ETHERNET = 'WAN',
  ETHERNET2 = 'LAN',
  ETHERNET3 = 'LAN2',
  ICD = 'ICD',
}
