import moment from 'moment-timezone'

import { INTERVALS } from '../shared/intervals'

export function useDateRangeLabel(startDate, endDate, interval, timezone) {
  const startDateMoment = moment(startDate).tz(timezone)
  const toDateMoment = moment(endDate).tz(timezone)

  if (
    startDateMoment.isSame(moment().tz(timezone).startOf(INTERVALS.DAY)) &&
    toDateMoment.isSame(moment().tz(timezone).endOf(INTERVALS.DAY))
  ) {
    return 'Today'
  }
  if (interval === INTERVALS.DAY || interval === INTERVALS.CUSTOM) {
    if (
      startDateMoment.format('MM DD YYYY') === toDateMoment.format('MM DD YYYY') // in the same day same year
    ) {
      return `${startDateMoment.format('MMM DD YYYY')}`
    } else {
      return `${startDateMoment.format('MMM DD YYYY')} - ${toDateMoment.format(
        'MMM DD YYYY',
      )}`
    }
  }

  if (interval === INTERVALS.WEEK) {
    return `${startDateMoment.format('MMM')} ${startDateMoment.format(
      'DD',
    )} - ${toDateMoment.format('DD')} ${startDateMoment.format('YYYY')}`
  }

  if (interval === INTERVALS.MONTH) {
    return startDateMoment.format('MMM YYYY')
  }

  if (interval === INTERVALS.YEAR) {
    return startDateMoment.format('YYYY')
  }

  if (interval === INTERVALS.LIFETIME) {
    return 'Lifetime'
  }

  return 'Today'
}
