import React from 'react'

import { AccordionFAQsPanels } from '../../../components/faq-panels'
import { Modal } from '../../../components/modal'
import paths from '../../Router/paths'
import { VPPLayout } from '../vpp-components/layout-vpp'
import { faqs } from './faqs'

export const DynamicFaqs = ({ modal }: { modal?: JSX.Element }) => {
  return (
    <VPPLayout title="FAQ's" className="faqs-content">
      <>
        <div className="faqs-main-container">
          <AccordionFAQsPanels FAQs={faqs} />
        </div>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.F_A_Q_DYNAMIC}>
            {modal}
          </Modal>
        )}
      </>
    </VPPLayout>
  )
}
