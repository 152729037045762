import './style.scss'

import clsx from 'clsx'
import React from 'react'

export const SelectableItem = ({
  onClick = (_) => {},
  displayText = '',
  selected = false,
  value,
}) => {
  const classes = clsx('selectable-item', { selected })
  return (
    <div className={classes} onClick={() => onClick(value)}>
      <span>{displayText}</span>
    </div>
  )
}
