import './style.scss'
import '../../styles/setupMode.scss'

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { useCurrentSiteData } from '../../shared/hooks'
import { SITE_TYPES } from '../../shared/sharedConstants'
import paths from '../Router/paths'
import SelfSupplyImage from './assets/self-supply-mode.svg'

export const SelfSupplyModePage = () => {
  const history = useHistory()
  const { site, loading } = useCurrentSiteData()

  const onCancelSavingsClick = React.useCallback(() => {
    history.push(paths.ROOT)
  }, [history])

  const onSetUpSelfSupply = React.useCallback(() => {
    history.push(paths.SELF_SUPPLY_SETUP)
  }, [history])

  const notStorageSite = site?.siteType !== SITE_TYPES.STORAGE
  useEffect(() => {
    if (!loading && site && notStorageSite) {
      history.push(paths.ROOT)
    }
  }, [site, loading, notStorageSite, history])

  return (
    <Layout title="Self supply Mode">
      <section className="section self-supply-mode-page setup-mode-page columns is-mobile is-vcentered">
        <img
          className="column self-supply-img"
          src={SelfSupplyImage}
          alt="self supply"
        />
        <article className="column setup-mode-details">
          <h4 className="is-bold">Self-Supply</h4>
          <h5 className="mt-5">
            Will maximize the use of your solar system. <br /> This setting is
            the most environmentally friendly.
          </h5>
          <h6 className="mt-5">
            You can still reserve up to 80% of the battery
            <br /> to prepare for an unexpected grid outage.
          </h6>
          <section className="actions-container mt-5">
            <button
              className="cancel button mr-5 mb-5"
              onClick={onCancelSavingsClick}
            >
              cancel
            </button>
            <button className="back button mr-5" onClick={onSetUpSelfSupply}>
              set up self-supply
            </button>
          </section>
        </article>
      </section>
    </Layout>
  )
}
