import React from 'react'

export const EnvironmentalSavingsInfo = () => {
  return (
    <div className="content">
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          What can I learn from the Environmental Savings widget?
        </h3>
        <p className="is-size-5-5">
          This widget shows how your investment in a SunPower solar system
          benefits the environment. We display estimated carbon dioxide (CO2)
          emissions avoided and additional relatable carbon factors—like miles
          not driven, and mature trees grown.
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          Can I see my estimated environmental savings for different date
          ranges?
        </h3>
        <p className="is-size-5-5">
          You sure can. On the home page you can see your estimated savings for
          whatever time period you select with the date picker.
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          How are my environmental savings calculated?
        </h3>
        <p className="is-size-5-5">
          We take the energy produced by your system (kWh) and multiply it by a{' '}
          <a
            href="https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator"
            target="_blank"
            rel="noreferrer"
          >
            conversion value provided by the EPA
          </a>{' '}
          to estimate the carbon dioxide (CO2) emissions you've avoided. These
          calculations blend national rates to reflect regional differences.
          Please note that accuracy may vary based on the equivalencies
          available for your location.
        </p>
      </div>
    </div>
  )
}
