import './styles.scss'

import React from 'react'
import { useHistory } from 'react-router-dom'

import { useDynamicVpp } from '../../../../shared/hooks/useDynamicVpp'
import { VPPEnrollment } from '../../../../shared/typings/site'
import { dynamicEventCardDateFormat } from '../../../../shared/valueFormatters'
import paths from '../../../Router/paths'

export const ProgramCard = ({ enrollment }: { enrollment?: VPPEnrollment }) => {
  const history = useHistory()
  const { selectProgram } = useDynamicVpp()

  if (!enrollment) {
    return null
  }
  const { program } = enrollment
  if (!program) {
    return null
  }
  const {
    colorCode,
    programName,
    seasonStart,
    seasonEnd,
    expectedEventsPerSeason,
    estimatedPotentialEarningsPerSeason,
    webviewLink,
  } = program

  const handleLearnMore = () => window.open(webviewLink, '_blank')

  const handleSelect = () => {
    selectProgram(enrollment)
    history.push(paths.VIRTUAL_PROGRAM_ENROLL)
  }
  return (
    <div className="vpp-program-card">
      <div
        className="card-color-mark"
        style={{ backgroundColor: `${colorCode}` }}
      ></div>
      <div className="card-program-name">{programName}</div>
      <div className="card-program-separator"></div>
      <div className="card-program-row">
        <p>Season Dates</p>
        <p>{`${dynamicEventCardDateFormat(
          seasonStart,
        )} - ${dynamicEventCardDateFormat(seasonEnd)}`}</p>
      </div>

      <div className="card-program-row">
        <p>Expected Events</p>
        <p>{`${expectedEventsPerSeason ?? ''}`}</p>
      </div>

      <div className="card-program-row">
        <p>Potential Earnings (per year)</p>
        <p>{`$ ${estimatedPotentialEarningsPerSeason ?? ''}`}</p>
      </div>

      <div className="card-program-row buttons-row">
        {webviewLink ? (
          <b className="card-learn-more-button" onClick={handleLearnMore}>
            Learn More
          </b>
        ) : (
          <div />
        )}
        <button className="card-select-more-button" onClick={handleSelect}>
          Select
        </button>
      </div>
    </div>
  )
}
