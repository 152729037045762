import './my-reports.scss'

import React from 'react'
import { Link } from 'react-router-dom'

import { InfoWidget } from '../info-widget'
import myReportsIcon from './assets/my-reports-icon.svg'

export const MyReportsEmpty = () => {
  return (
    <div className="my-reports-empty has-text-centered">
      <img
        src={myReportsIcon}
        className="has-text-centered"
        alt="my-reports-icon"
      />

      <h3 className="has-text-weight-bold mt-4">SET UP MONTHLY REPORTS</h3>

      <p className="mt-5 mb-3">
        Receive a monthly report offering insights into your system performance,
        delivered via email.
      </p>

      <Link
        to="/profile/reports-setup"
        className="button feedback-button is-primary mt-5"
      >
        SET UP
      </Link>
    </div>
  )
}

export const MyReports = ({ nextReport, range, emailedTo }) => {
  return (
    <InfoWidget
      className="my-account"
      list={[
        {
          title: 'Next report',
          value: nextReport,
        },
        { title: 'Range', value: range },
        { title: 'Recipient(s)', value: emailedTo },
      ]}
      icon={myReportsIcon}
    />
  )
}
