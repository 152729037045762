import './styles.scss'

import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../../components/layout'
import { useDynamicVpp } from '../../../shared/hooks/useDynamicVpp'
import { ENROLLMENT_STATUS } from '../../../shared/typings/site'
import paths from '../../Router/paths'
import { AcceptedState } from './AcceptedStatus' // Pending flag to be used
import { PendingState } from './PendingStatus'
import { RejectedApplication } from './RejectedStatus'

export const DynamicStatus: React.FC = () => {
  const history = useHistory()
  const { selectedEnrollment } = useDynamicVpp()

  const enrollmentStateReason = useMemo(
    () => selectedEnrollment?.enrollmentStateReason || '',
    [selectedEnrollment],
  )

  const enrollmentSubmission = useMemo(
    () => selectedEnrollment?.enrollmentSubmission || '',
    [selectedEnrollment],
  )
  const enrollmentStatus = useMemo(
    () => selectedEnrollment?.enrollmentStatus || ENROLLMENT_STATUS.PND,
    [selectedEnrollment],
  )
  const handleResubmit = useCallback(() => {
    history.push(paths.VIRTUAL_PROGRAM_ENROLL)
  }, [history])

  const renderState = () => {
    switch (enrollmentStatus) {
      case ENROLLMENT_STATUS.PND:
        return <PendingState enrollmentSubmission={enrollmentSubmission} />
      case ENROLLMENT_STATUS.REJ:
      case ENROLLMENT_STATUS.RSB:
        return (
          <RejectedApplication
            canResubmit={enrollmentStatus === ENROLLMENT_STATUS.RSB}
            onClickSubmitApp={handleResubmit}
            enrollmentStateReason={enrollmentStateReason}
          />
        )
      default:
        return <div />
    }
  }

  return (
    <Layout
      title="SunPower VPP"
      sectionClassName="section-enroll"
      containerClassName="container-enroll"
    >
      {renderState()}
    </Layout>
  )
}
