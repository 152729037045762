export const VPP_CONECTED_SOLUTIONS_PROGRAM_ID = [
  'eversource-bat', //ConnectedSolutions Eversource
  'capelightcompact-bat', //ConnectedSolutions Cape Light Compact
  'nationalgrid-ne-bat', //ConnectedSolutions National Grid
]
export const VPP_OHM_CONNECT_PROGRAM_ID = 'ohm-connect'
export const DEFAULT_OHM_LOGIN = 'https://login.ohmconnect.com'
export const UTILITY_ACCOUNT_NUMBER_TEXT_FIELD = 'utilityAccountNumber'

export type VPPEnrollment = {
  enrollmentId?: string
  enrollmentStatus?: ENROLLMENT_STATUS
  program?: VPPProgram
  enrollmentDevices?: [VPPDevice]
  enrollmentDevice?: VPPDevice
  eligibilityStartDate?: string
  eligibilityEndDate?: string
  enrollmentSubmissionDate?: string
  enrollmentAcceptanceDate?: string
  enrollmentEndDate?: string
  enrollmentSubmission?: string
  enrollmentStateReason?: string
  hasSubmittedEnrollment?: boolean
  isEligible?: boolean
  isActive?: boolean
  utilityAccountNumber?: string
  isOhmConnect?: boolean // not from the backend
  isEnrolled?: boolean // not from the backend
}

export type VPPProgram = {
  programDescription?: string
  seasonStart?: string
  seasonEnd?: string
  seasonId?: string
  programName?: string
  programId?: string
  expectedEventsPerSeason?: number
  colorCode?: string
  webviewLink?: string
  estimatedPotentialEarningsPerSeason?: string
}

export type DocusignURL = {
  envelopeId?: string
  webviewUrl?: string
}

export type PerformanceMetricBySeason = {
  metric_title?: string
  metric_value_formatted?: string
  metric_type?: string
}

export type EventPerformanceList = {
  deviceUuid?: string
  events?: any[]
}

export type PerformanceBySeason = {
  avgPerformanceKw?: string
  estimatedPayment?: string
  eventsParticipated?: string
  totalEvents?: string
  eventPerformanceList?: EventPerformanceList
}

export type CurrentSiteTariffDetails = {
  siteKey?: string
  value?: any
  valueId?: string
  statusIndicator?: any
  label?: string
  utilityId?: string
  utilityName?: string
  tariffCode?: string
  tariffName?: string
  tariffId?: string
}

export type TariffPlanUIStructureResponse = {
  tariffId: string
  tariffCode: string
  tariffName: string
  seasons: TariffSeasonalPeak
}

type TariffSeasonalPeak = {
  summer?: TariffSeason
  winter?: TariffSeason
  spring?: TariffSeason
  fall?: TariffSeason
}

type TariffSeason = {
  seasonId?: number
  seasonName?: string
  seasonFromMonth?: number
  seasonFromDay: number
  seasonToMonth?: number
  seasonToDay: number
  peaks?: any
}

export enum ENROLLMENT_STATUS {
  STARTED = 'Started', // The enrollment process has been started
  ACT = 'ACT', //ACCEPTED
  OIN = 'OIN', //OPT-IN (the site is yet to be enrolled and can opt-in)
  PND = 'PND', //PENDING (the site has been submitted and is awaiting acceptance by the utility)
  UNE = 'UNE', //UN-ENROLLED (the site has un-enrolled from the program)
  REJ = 'REJ', //REJECTED (the enrollment request has been rejected)
  RSB = 'RSB', //RESUBMIT (The program requires additional information, or updated information, for this enrollment. Please update the information then submit the enrollment again)
}

export enum EVENT_STATUS {
  EVENT_IN_PROGRESS = 'EVENT_IN_PROGRESS',
  EVENT_NOT_SCHEDULED = 'ENROLLED_NOT_EVENT_SCHEDULED',
  EVENT_SCHEDULED = 'EVENT_SCHEDULED',
  EVENT_ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export const ALLOWED_STATUS: ENROLLMENT_STATUS[] = [
  ENROLLMENT_STATUS.ACT,
  ENROLLMENT_STATUS.PND,
  ENROLLMENT_STATUS.RSB,
  ENROLLMENT_STATUS.REJ,
]

export const PENDING_STATUS: ENROLLMENT_STATUS[] = [
  ENROLLMENT_STATUS.PND,
  ENROLLMENT_STATUS.RSB,
  ENROLLMENT_STATUS.REJ,
]

export type VPPDevice = {
  deviceStatus?: string
  deviceStatusCode?: string
  deviceUuid?: string
  sunpowerDeviceId?: string
  defaultReserveLevel: number
  currentEvent: VPPDeviceCurrentEvent
}

export type VPPDeviceCurrentEvent = {
  eventId?: string
  optIn?: string
  programId?: string
  eventDatetime?: string
  eventLength?: number // in seconds
  reserveLevel?: number
  eventStatus?: EVENT_STATUS
}

export type Site = {
  address1?: string
  assignments?: any[]
  battery?: any
  city?: string
  firstCommissionDate?: string
  currentWeather?: any
  financeType?: any
  hasLivedata?: boolean
  hasMI?: boolean
  hasPanelLayout?: boolean
  hasWifi?: boolean
  isEnabledInNightvision?: boolean
  postalCode?: string
  siteKey?: string
  siteName?: string
  siteType?: string
  state?: string
  systemSize?: number
  timezone?: string
}

export type ProgramSignUpSteps = {
  buttonText?: string
  documentStepIsCompleted: boolean
  documentTemplateId?: string | number
  fieldInitialValue?: string
  fieldName?: string
  fieldTitle?: string
  pageBody?: string
  pageSubtitle?: string
  pageTitle?: string
  sequenceOrder: number
  stepType: string
  tooltipBody?: string
  tooltipTitle?: string
}

export enum StepType {
  TEXT_FIELD = 'TEXT_FIELD',
  SIGNATURE = 'SIGNATURE',
}
