import React from 'react'
// import { Link } from 'react-router-dom'

import paths from '../../pages/Router/paths'

export const questionsAndAnswers = [
  {
    // 1
    question: 'What is panel level data?',
    answer: (
      <div>
        With panel level data you can monitor how each individual panel is
        performing on a daily basis. Explore how different parts of your system
        are affected by shading, angle towards the sun, weather conditions and
        seasonality.
      </div>
    ),
  },
  {
    // 2
    question: 'How often is the data updated in the Panels tab?',
    answer: (
      <div>
        The energy and power data in the Panels tab is updated every 5 minutes
      </div>
    ),
  },
  {
    // 3
    question: 'What do the colored status indicators mean?',
    answer: (
      <div>
        There are four different status indicators:
        <ol>
          <li>Green: The panel is operating as normal. </li>
          <li>
            Grey: The panel is temporarily not producing power or has
            temporarily stopped communicating with the SunPower PVS monitoring
            device.
          </li>
          <li>
            Orange: The panel has stopped communicating with the SunPower PVS
            monitoring device.
          </li>
          <li>Red: The panel has stopped producing power. </li>
        </ol>
        <br />
        The Panel Details page outlines what to do if a panel is showing a grey,
        orange or red status indicator.
      </div>
    ),
  },
  {
    // 4
    question:
      'What should I do if I see a status indicator about a panel not producing or not communicating?',
    answer: (
      <div>
        Please reach out to your installer if a panel’s status is red (no
        production) or orange (no communication).  They will try to determine
        the cause and offer a solution.
      </div>
    ),
  },
  {
    // 5
    question:
      'What should I do if I see a status indicator that my panel is temporarily not producing power or not communicating with the SunPower monitoring device (PVS)?',
    answer: (
      <div>
        A grey status indicating a temporary loss in production or communication
        is normal. Please allow 48 hours for the panel to return to its normal
        operation before seeking assistance.  If it has not resumed normal
        operation after that time, reach out to your installer.
      </div>
    ),
  },
  {
    // 6
    question:
      'Can I set up a notification to receive status indicator changes?',
    answer: (
      <div>
        This functionality is not available at this time, but we hope to offer
        it in the near future.
      </div>
    ),
  },
  {
    // 7
    question:
      'I don’t see the layout of my panels or it looks wrong. What should I do?',
    answer: (
      <div>
        If you don’t see your layout or it does not seem to match the system
        installed at your home, please reach out to your installer as they will
        need to create or update it for you. However, sometimes it is not
        possible for a virtual layout to be created. In that case, you can still
        monitor each individual panel’s performance using the list view and
        panel details page.
      </div>
    ),
  },
  {
    // 8.
    question: 'Why aren’t all my panels producing the same amount of energy?',
    answer: (
      <div>
        There are many factors that affect solar production. For example,
        production depends on where the panels are placed on the roof, the angle
        of the panel relative to the sun, weather conditions, daytime shading
        and obstructions such as leaves or snow.
      </div>
    ),
  },
  {
    // 9
    question: 'What is the difference between the Energy and Power views?',
    answer: (
      <div>
        The Energy view shows total solar energy production (kilowatt-hours /
        kWh) over a period of time. To see the total energy produced in a given
        day, reference the panel layout or list view. To see the energy produced
        every hour, tap on the individual panel you want to learn about or tap
        on the serial number in the list view.
        <br />
        <br />
        The Power view shows the maximum solar power level (kilowatts / kW)
        generated during each hour of the day.
      </div>
    ),
  },
  {
    // 10
    question:
      'When will my panels communicate? I do not see them communicating 24/7.',
    answer: (
      <div>
        Great question! The panels will be in communication only during the day,
        as the communication is solar powered. The panels will wake up in the
        morning and go to sleep at night. Panels that face the East are early
        risers, while panels that face the West will keep communicating until
        sunset. Panels that face the South typically communicate between sunrise
        and sunset.
      </div>
    ),
  },
  {
    // 11
    question:
      'I don’t see data for some of my panels but I do see solar production values for my full system in the Analyze tab and in the Home tab. What does this mean?',
    answer: (
      <div>
        Don’t worry, your solar system is still generating solar energy. It is
        likely that your installer included a production meter when they
        installed your SunPower system. The production meter is a device that
        measures the solar production values for the entire system, and it
        communicates directly with the SunPower PVS monitoring device. While the
        panels may have temporarily lost communication with the monitoring
        device, the production meter is still communicating and reporting the
        solar energy generation correctly.
      </div>
    ),
  },
  {
    // 12
    question: 'Why do panels sometimes lose communication?',
    answer: (
      <div>
        Panels talk to the monitoring device over Power Line Communication
        (PLC). PLC sends signals directly through the copper wires that connect
        each panel to your home’s electrical service box and, in turn, the
        utility grid. In addition to carrying the power to your home, these
        wires are also carrying data.
        <br />
        <br />
        Occasionally, information carried over PLC is interrupted due to noise
        on the line from other panels, electronics in your home, and even
        electronics at the neighbors. Common sources of interference include
        appliances with motors (fans, water pumps, pool pumps, refrigerators,
        and freezers); power strips, surge protectors, and uninterruptible power
        supplies (UPSs); large workshop equipment; battery chargers or AC
        adapters; plug-in pest deterrents; and failing Compact Fluorescent
        Lightbulbs (CFLs). These devices are common in our homes, so we
        generally recommend using production meters to track system performance.
        <br />
        <br />
        Each solar circuit in your system is connected to a breaker in your
        service panel. If the breaker trips, communication with the panels on
        that circuit will be interrupted. Please check your service panel to
        confirm that all solar breakers are in the “ON” position.
        <br />
        <br />
        If you have additional questions, please reach out to your installer.
      </div>
    ),
  },
  {
    // 13
    question: 'How often should I clean my panels?',
    answer: (
      <div>
        We recommend cleaning your panels 1-2 times a year, though rain and snow
        act as great natural solvents. For more information about cleaning your
        panels please read our detailed answer{' '}
        <a
          href="https://us.sunpower.com/home-solar-panel-cleaning-tips-checklist "
          target="_blank"
          rel="noreferrer"
          className="color-text-blue"
        >
          here
        </a>
        .
      </div>
    ),
  },
  {
    // 14
    question:
      'I have more questions about panel level data, how can I get more help?  ',
    answer: (
      <div>
        If you still have questions feel free to{' '}
        {/* <Link to={paths.CONTACT_US} className="color-text-blue"> */}
          contact us
        {/* </Link>{' '} */}
        directly.
      </div>
    ),
  },
]
