import "./style.scss";

// import bulmaCollapsible from "@creativebulma/bulma-collapsible";
import React, { useEffect, useRef } from "react";

import chevronDown from "../header/assets/chevron-down.svg";
import { questionsAndAnswers } from "./faqs";

interface AccordionProps {
  FAQs?: any[];
}

export const AccordionFAQsPanels = ({
  FAQs = questionsAndAnswers,
}: AccordionProps) => {
  const collapsibles = useRef(null);
  // useEffect(() => {
  //   collapsibles.current = bulmaCollapsible.attach(".is-collapsible", {
  //     container: collapsibles.current,
  //   });
  // }, []);
  const faqMarkupList = FAQs.map((faqitem, index) => {
    return (
      <article key={`faq-key-${index}`} className="message">
        <a
          className="message-header"
          href={`#collapsible-message-accordion-${index}`}
          data-action="collapse"
        >
          <span className="question-text">{faqitem.question}</span>
          <div className="chevron-container">
            <img
              src={chevronDown}
              alt={`faq-answer-${index + 1}`}
              className="question-chev"
            />
          </div>
        </a>
        <div
          id={`collapsible-message-accordion-${index}`}
          className="message-body is-collapsible"
          data-parent="accordion_first"
        >
          <div className="message-body-content">{faqitem.answer}</div>
        </div>
      </article>
    );
  });

  return (
    <div ref={collapsibles} id="accordion_first">
      {faqMarkupList}
    </div>
  );
};
