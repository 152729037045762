import '../marketing-styles.scss'

import React, { useCallback, useEffect } from 'react'
import Lottie from 'react-lottie'
import { useHistory, useLocation } from 'react-router-dom'

import { Modal } from '../../../components/modal'
import { useWindowSize } from '../../../shared/hooks'
import { useDynamicVpp } from '../../../shared/hooks/useDynamicVpp'
import paths from '../../Router/paths'
import discharging_animation from '../assets/discharging-animation'
import controlsBatteryImg from '../assets/how-it-works/controls-battery.svg'
import earnMoneyImg from '../assets/how-it-works/dynamic-earnings.svg'
import eventNotificationMobile from '../assets/how-it-works/Event-Notifications-MOBILE.png'
import eventNotificationWeb from '../assets/how-it-works/Event-Notifications-WEB.png'
import { VPPLayout } from '../vpp-components/layout-vpp'
import { ConnectedSolutionsHeader } from '../vpp-components/marketing-header'
import { Stepper } from '../vpp-components/steper'

const dischargingAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: discharging_animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const tabsInfo = [
  {
    id: 1,
    tabTitle: 'Control your battery',
    infoTitle: 'Maintail full control of your battery',
    infoText:
      'You maintain full control over your battery settings and can adjust your battery reserve levels or decide whether to opt out of specific VPP events directly through your mySunPower® app. Once a VPP event gets called by the utility, SunPower will send you a notification, letting you know the time and date of the event.',
    webImage: controlsBatteryImg,
    mobileImage: controlsBatteryImg,
  },
  {
    id: 2,
    tabTitle: 'Hassle free participation',
    infoTitle: 'Hassle free participation',
    infoText:
      'Your SunVault battery will automatically begin discharging energy from your home into the grid during VPP events (usually 1-3 hrs long). No action is needed from you, unless you want to change your battery reserve level or opt out of the event.',
    webImage: eventNotificationWeb,
    mobileImage: eventNotificationMobile,
  },
  {
    id: 3,
    tabTitle: 'Earn money',
    infoTitle: 'Earn money for your VPP participation',
    infoText:
      'SunPower will measure your participation during VPP events and you will receive compensation for your participation according to your selected program guidelines, either per event or per season.',
    webImage: earnMoneyImg,
    mobileImage: earnMoneyImg,
  },
]

export const DynamicMarketing = ({ modal }: { modal?: JSX.Element }) => {
  const windowSize = useWindowSize()
  const history = useHistory()
  const location = useLocation<{ onlyInfo: boolean }>()
  const { selectedEnrollment, fetchEnrollments } = useDynamicVpp()
  const isEnrolled = selectedEnrollment?.isEnrolled ?? false

  const onlyInfo = location.state?.onlyInfo ?? false

  const handleOnGoToFAQ = useCallback(() => {
    history.push(paths.F_A_Q_DYNAMIC)
  }, [history])

  const handleGoToList = useCallback(() => {
    history.push(paths.VIRTUAL_PROGRAMS_LIST)
  }, [history])

  useEffect(() => {
    fetchEnrollments()
  }, [])

  return (
    <VPPLayout
      title="SunPower VPP"
      endOrnament={
        <button className="to-faq-button" onClick={handleOnGoToFAQ}>
          FAQ's
        </button>
      }
    >
      <div className="connected-solutions">
        <ConnectedSolutionsHeader
          mainTittle={
            <div>
              <h1>Sell your SunVault power and earn $$$ every years.</h1>
            </div>
          }
          isEnrolledInVPP={onlyInfo || isEnrolled}
          handleEnroll={handleGoToList}
          buttonText="Select program"
          enrollmentLoader={false}
        />
        <div className="program-details-container">
          <section className="info-section desktop-animation">
            <Lottie
              options={dischargingAnimationOptions}
              height={windowSize[0] > 1082 ? 525 : windowSize[0] / 2 - 30}
              width={windowSize[0] > 1082 ? 525 : windowSize[0] / 2 - 30}
            />
          </section>
          <section className="info-section">
            <h1 className="section-subtittle">SunPower Virtual Power Plant</h1>
            <div className="mobile-animation">
              <Lottie
                options={dischargingAnimationOptions}
                height={windowSize[0] - 30}
                width={windowSize[0] - 30}
              />
            </div>
            <ul className="section-info-list">
              <li className="bold-mobile">
                When demand for energy peaks and the electric grid is stressed
                trying to get enough energy to every customer, utilities need to
                buy extra energy to meet demand and help prevent blackouts. They
                often turn to expensive, emergency power plants, which can often
                emit even more pollution.
              </li>
              <li>
                Now, utilities can turn to SunPower's Virtual Power Plant*
                (VPP), where groups of SunPower customers can use their
                SunVault® storage system to sell energy to utilities or for
                minimizing use of grid electricity during peak times. By
                participating in SunPower's VPP, you can earn extra money,
                support the grid, and reduce the need for unsustainable fossil
                fuel energy.
              </li>
            </ul>
          </section>
        </div>
        <Stepper
          title="How It Works"
          tabsInfo={tabsInfo}
          hidenLogo={true}
          subText={
            'SunPower participates in Virtual Power Plant programs* that will compensate you for the energy that you reduce or share with the grid. Once you enroll in an available program in your area, SunPower will help to manage your participation.'
          }
        />
        {isEnrolled && (
          <div className="program-details-container bottom-section">
            <div className="row">
              <section className="info-section">
                <h1 className="section-subtittle">How to get started</h1>
                <p className="section-paragraph ">
                  It's simple to participate. Click on the "Select Program"
                  button above, choose a program, and follow the enrollment
                  steps. Once enrolled, participate in VPP events as they are
                  called and see your earnings grow!
                </p>
              </section>
            </div>
            <div className="row">
              <section className="info-section">
                <h1 className="section-subtittle">Disclaimer</h1>
                <p className="section-paragraph">
                  By clicking "Select Program" I consent to SunPower sharing my
                  information with our VPP partners to provide and to enroll in
                  the VPP program. SunPower can call or text me (including SMS
                  or MMS) (including via prerecorded messages and/or automated
                  technology belonging to or hosted by third parties) at the
                  telephone number on file even if it is on a state or Federal
                  Do Not Call List. Message and data rates may apply, Message
                  frequency varies, Text STOP to cancel. My consent is not a
                  condition of purchase. Please see our Privacy Statement and
                  Terms of Use.
                </p>
              </section>
            </div>
          </div>
        )}
      </div>
      <>
        {modal && (
          <Modal
            hideTopBar={true}
            title={''}
            fromPath={paths.MARKETING_CONNECTED_SOLUTIONS}
          >
            {modal}
          </Modal>
        )}
      </>
    </VPPLayout>
  )
}
