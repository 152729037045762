import React from 'react'

export const EnergyGraphInfo = () => {
  return (
    <div className="content">
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          What can I learn from the energy and power graphs?
        </h3>
        <p className="is-size-5-5">
          The <span className="has-text-weight-bold">Energy</span> graph shows
          how much electricity your system produces over a period of time
          (measured in kWh or thousands of watt-hours). Energy is useful for
          analyzing the effectiveness of your system, especially over time.
        </p>
        <p className="is-size-5-5">
          The <span className="has-text-weight-bold">Power</span> graph shows
          the rate at which your system is converting sunlight into electricity
          at a specific point in time (measured in kW or thousands of watts).
          Power is useful when analyzing short-term variations in performance
          due to temporary external factors like weather changes.
        </p>
      </div>
      <div className="mb-5 ">
        <h3 className="is-size-4 has-text-weight-semibold">
          How do I use the graph?
        </h3>
        <ul className="is-size-5-5">
          <li>
            Use the date picker above the graph to select the time period you
            would like to view.
          </li>
          <li>Green bars show how much energy your system produces.</li>
          <li>Hover over any point to get detailed information.</li>
        </ul>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          How do you determine what the Y-axis on my graph is set to?
        </h3>
        <p className="is-size-5-5">
          For energy graphs, the Y-axis will change depending on several
          factors, including what time period you have selected (e.g., day,
          week, month, etc.). In most cases the Y-axis for the day chart is set
          to approximately 110% of your system size. However, in most cases the
          Y-axis for the week chart is set to 110% of the maximum value
          displayed for that week.
        </p>
        <p className="is-size-5-5">
          For power graphs, the Y-axis is set to approximately 110% of your
          system size. This allows you to monitor power over time without having
          to worry about a constantly bouncing Y-axis obscuring your analysis.
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          How can I see the energy my home uses?
        </h3>
        <p className="is-size-5-5">
          You may be able to see your home energy use with the help of a
          Consumption Monitoring Kit. With this kit, you can view energy use in
          your charts and compare the energy you're producing with what you're
          consuming.
        </p>
        <p className="is-size-5-5">
          <span className="has-text-weight-bold">Please note:</span> Not all
          homes are compatible with the Consumption Monitoring Kit; it depends
          in part on the type of electrical panel installed at your home. In
          fact, it's possible your dealer determined that your home was
          incompatible during installation.
        </p>
        <p className="is-size-5-5">
          If you are interested in learning more, please contact your SunPower
          dealer to determine if the Consumption Monitoring Kit is an option for
          your home.
        </p>
      </div>
    </div>
  )
}
