import './styles.scss'

import moment from 'moment-timezone'
import React from 'react'

import { Loader } from './../../../../components/loader'
import { VPPProgram } from '../../../../shared/typings/site'
import mktConnectedSolutions from '../../assets/Connected-Solution-Image.svg'
import lightSunPowerLogo from '../../assets/lightSunpowerLogo.svg'

interface ConnectedSolutionsHeaderInterface {
  enrollmentStatus?: string
  buttonText?: string
  backgroundImage?: string
  mainTittle?: JSX.Element
  vppProgram?: VPPProgram
  isEnrolledInVPP?: boolean | null
  handleEnroll: () => void
  enrollmentLoader: boolean
  sideButtonText?: string
  hiddenLogo?: boolean
  footer?: JSX.Element
}

export const ConnectedSolutionsHeader = ({
  buttonText = 'Enroll Today',
  backgroundImage = `url(${mktConnectedSolutions})`,
  mainTittle,
  vppProgram = { programDescription: '', seasonStart: '', seasonEnd: '' },
  isEnrolledInVPP,
  handleEnroll,
  enrollmentLoader,
  sideButtonText,
  hiddenLogo = false,
  footer,
}: ConnectedSolutionsHeaderInterface) => {
  const { programDescription, seasonStart, seasonEnd } = vppProgram

  return (
    <section
      className="wrapper-container"
      style={{ backgroundImage: backgroundImage }}
    >
      <section className="top-information-container">
        <section className="left-container">
          {!hiddenLogo && (
            <img
              src={lightSunPowerLogo}
              alt="mySunPowerLogo"
              className="sunpower-logo"
            />
          )}
          {mainTittle}
          {programDescription && <h1>{programDescription}</h1>}
          {seasonStart && seasonEnd && (
            <h2>
              <span>Program</span>
              {` starts ${moment(seasonStart).format('MM/DD/YYYY')} `}
              <span>and ends</span>
              {` ${moment(seasonEnd).format('MM/DD/YYYY')}`}
            </h2>
          )}
          {!isEnrolledInVPP ? (
            <section className="actions-container">
              <button
                id="enroll-button"
                className="button is-white is-size-5 is-primary"
                onClick={handleEnroll}
                type="button"
              >
                {enrollmentLoader ? <Loader /> : buttonText}
              </button>
              {sideButtonText && <h3>{sideButtonText}</h3>}
            </section>
          ) : null}
        </section>
        <section className="right-container"></section>
      </section>
      <section className="footer-container">{footer}</section>
    </section>
  )
}
