import './style.scss'

import moment from 'moment-timezone'
import React from 'react'

import iconClose from '../../../../components/menu/assets/icon-close.svg'
import arrowLeft from './assets/arrow-left.svg'

interface PastEventsSliderProps {
  pastEvents: any | null
  title: string
  vppProgramName?: string
  handleClose: () => void
}

export const PastEventsSlider = ({
  title,
  handleClose,
  pastEvents,
}: PastEventsSliderProps) => {
  const { eventPerformanceList } = pastEvents

  const events = eventPerformanceList && eventPerformanceList[0]?.events

  const formattedList: {
    eventStart: string | null
    eventEnd: string | null
    energyDischarged: string | null
  }[] = []

  // Clean up list by only leaving used params before rendering
  Object.keys(events).forEach(function (key) {
    formattedList.push({
      eventStart: events[key].eventStart,
      eventEnd: events[key].eventEnd,
      energyDischarged: events[key].energyDischarged,
    })
  })

  const eventList = formattedList?.map((item, index) => {
    const { energyDischarged, eventStart, eventEnd } = item
    const tz = moment.tz.guess()

    return (
      <div className="list-items-container" key={`item-${index}`}>
        <div className="left-text">
          {eventStart && eventEnd
            ? moment.tz(eventStart, tz).format('MM/DD/YYYY') +
              ', ' +
              moment.tz(eventStart, tz).format('h:mma') +
              ' - ' +
              moment.tz(eventEnd, tz).format('h:mma')
            : 'N/A'}
        </div>
        <div className="right-text">
          {energyDischarged !== null
            ? parseFloat(energyDischarged).toFixed(2)
            : 'N/A'}
        </div>
      </div>
    )
  })

  return (
    <div className="slider-container">
      <aside className="slider">
        <div className="toolbar">
          <button
            className="closeSideNav arrow-left"
            aria-label="close"
            onClick={handleClose}
          >
            <img src={arrowLeft} alt="Go back" />
          </button>
          <div className="title">{title}</div>
          <button
            className="closeSideNav"
            aria-label="close"
            onClick={handleClose}
          >
            <img src={iconClose} alt="Close sidebar" />
          </button>
        </div>
        <div className="slider-cards-container">
          <div className="subtitle-labels-container">
            <div className="left-text">Event Date</div>
            <div className="right-text">Energy discharged</div>
          </div>
          {eventList}
        </div>
      </aside>
      <div onClick={handleClose} className="backdrop" />
    </div>
  )
}
