import './style.scss'

import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import paths from '../Router/paths'
import dollarIcon from './assets/cost-savings-dollar.svg'
import reserveIcon from './assets/reserve-mode.svg'
import leafIcon from './assets/self-supply-leaf.svg'

type BatteryOperationalModeEditProps = {
  isCostSavings?: boolean
}

export const BatteryOperationalModeEdit = ({
  isCostSavings = false,
}: BatteryOperationalModeEditProps) => {
  const history = useHistory()

  const onCostSavingsClick = useCallback(() => {
    history.push({
      pathname: paths.COST_SAVINGS_MODE,
      state: {
        commandModal: true,
      },
    })
  }, [history])

  const onSelfSupply = useCallback(() => {
    history.push({
      pathname: paths.SELF_SUPPLY_MODE,
      state: {
        commandModal: true,
      },
    })
  }, [history])

  const onReserveMode = useCallback(() => {
    history.push({
      pathname: paths.RESERVE_MODE,
      state: {
        commandModal: true,
      },
    })
  }, [history])
  return (
    <div className="battery-operational-mode-edit-contents is-centered">
      <section className="options-container is-mobile columns is-vcentered">
        {isCostSavings && (
          <div
            onClick={onCostSavingsClick}
            className="container battery-mode column has-text-centered mr-4 mb-4"
          >
            <img src={dollarIcon} className="mt-2" alt="cost savings mode" />
            <h4 className="mt-3">Cost Savings</h4>
            <p>
              Save money with solar and battery during expensive energy peak
              rates.
            </p>
          </div>
        )}
        <div
          className="container battery-mode column has-text-centered mr-4 mb-4"
          onClick={onSelfSupply}
        >
          <img className="mt-2" src={leafIcon} alt="self supply mode" />
          <h4 className="mt-3">Self Supply</h4>
          <p>
            Will maximize the use of your solar system energy during the day.
          </p>
        </div>
        <div
          className="container battery-mode column has-text-centered mr-4 mb-4"
          onClick={onReserveMode}
        >
          <img className="mt-2" src={reserveIcon} alt="self supply mode" />
          <h4 className="mt-3">Reserve </h4>
          <p>Reserve 100% of your battery in case there’s a power outage.</p>
        </div>
      </section>
    </div>
  )
}
