import './style.scss'

import React from 'react'

import { AppleStoreBadge } from '../apple-store-badge'
import { PlayStoreBadge } from '../play-store-badge'
import sunPowerLogo from './assets/sunpower-logo.png'

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="columns">
        <div className="column is-third main-logo-container">
          <div className="content">
            <img src={sunPowerLogo} alt="SunPower Logo" />
            <p className="copyright-web">
              <strong>@2023 SunPower Corporation.</strong>
              &nbsp;All rights reserved.
            </p>
          </div>
        </div>
        <div className="column is-third" />
        <div className="column is-third">
          <div className="columns is-vcentered is-mobile is-multiline footer-info">
            <div className="column has-text-centered is-one-quarter-desktop is-full-mobile footer-store-links">
              <AppleStoreBadge imageHeight="30" />
              <PlayStoreBadge imageHeight="30" />
            </div>
            <div className="column py-0 footer-links">
              <a
                href="https://us.sunpower.com/terms"
                target="_blank"
                rel="noreferrer"
                className="is-size-6 is-size-6-mobile"
              >
                Terms of use agreement
              </a>
              <a
                href="https://us.sunpower.com/trademarks"
                target="_blank"
                rel="noreferrer"
                className="is-size-6 is-size-6-mobile"
              >
                Trademarks & brand compliance
              </a>
              <a
                href="https://us.sunpower.com/privacy"
                className="is-size-6 is-size-6-mobile"
                target="_blank"
                rel="noreferrer"
              >
                Privacy statements
              </a>
            </div>
          </div>
        </div>
        <p className="copyright-mobile">
          <strong>@2023 SunPower Corporation.</strong>
          &nbsp;All rights reserved.
        </p>
      </div>
    </footer>
  )
}
