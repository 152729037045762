import { useMutation } from '@apollo/client'
import { History } from 'history'
import React, { useEffect, useState } from 'react'

import { Slider } from '../../components/slider'
import { FETCH_PARTY_DATA } from '../../shared/hooks/query'
import { OPERATIONAL_MODES } from '../../shared/setupModeConstants'
import { CurrentSiteTariffDetails } from '../../shared/typings/site'
import paths from '../Router/paths'
import rightArrowIcon from './assets/right-arrow.svg'
import UtilityRatePlanIcon from './assets/utility-rate-plan.svg'
import { COST_SAVINGS_COMMAND } from './query'

export const CostSavingSlider = ({
  selectedSiteKey,
  partyId,
  onCancel,
  history,
  onClickUtilityRatePlan,
  currentPlan,
  showCustomTariff,
}: {
  selectedSiteKey: string
  partyId: string
  currentPlan: CurrentSiteTariffDetails | null
  showCustomTariff: boolean
  onCancel: () => void
  onClickUtilityRatePlan: () => void
  history: History<History.UnknownFacade>
}) => {
  const [costSavingsVal, setCostSavingsVal] = useState('20')

  const [
    changeBatteryMode,
    { data: changeBatteryData, loading: commandLoading, error },
  ] = useMutation(COST_SAVINGS_COMMAND, {
    refetchQueries: [{ query: FETCH_PARTY_DATA, variables: { partyId } }],
  })

  useEffect(() => {
    if (changeBatteryData && !error) {
      history.push(paths.ROOT)
    }
  }, [changeBatteryData, commandLoading, error, history])

  const sendCostSavingsCommand = () => {
    const reserveLevel = parseFloat(costSavingsVal) / 100
    changeBatteryMode({
      variables: {
        params: {
          siteKey: selectedSiteKey,
          mode: OPERATIONAL_MODES.ENERGY_ARBITRAGE,
          reserveLevel: reserveLevel,
        },
      },
    })
  }
  const sliderSavingsActions = (
    <>
      <button className="cancel button mr-5 mb-5" onClick={onCancel}>
        cancel
      </button>
      <button className="go-to-slider button" onClick={sendCostSavingsCommand}>
        start
      </button>
    </>
  )
  const updateSliderValue = (e) => {
    setCostSavingsVal(e.target.value)
  }
  return (
    <section className="section cost-savings-mode-slider columns is-mobile is-vcentered">
      <article className="cost-savings-title colummn">
        How much reserve do you want <br />
        to save in case there’s an outage?
      </article>
      <section className="column">
        <article className="cost-savings-details-container">
          <h4 className="cost-savings-value">
            {costSavingsVal}
            <span className="percentage ml-5">%</span>
          </h4>
          <h5 className="is-uppercase">saved reserves</h5>
        </article>
      </section>
      <section className="column slider-container mb-6">
        <Slider
          min={'1'}
          max={'80'}
          onSliderChange={updateSliderValue}
          sliderValue={costSavingsVal}
        />
      </section>
      {showCustomTariff && (
        <section className="utility-rate-plan-container">
          <div
            className="utility-rate-plan-button"
            onClick={onClickUtilityRatePlan}
          >
            <img
              src={UtilityRatePlanIcon}
              alt="uitlity rate plan icon"
              className="urp-icon"
            />
            <div className="label-container">
              <p className="info-label">Utility rate plan</p>
              <p className="rate-plan-label">{currentPlan?.tariffCode}</p>
            </div>
            <img
              src={rightArrowIcon}
              alt="Right arrow icon"
              className="right-arrow-icon"
            />
          </div>
        </section>
      )}
      <section className="column">{sliderSavingsActions}</section>
    </section>
  )
}
