import './style.scss'
import '../../styles/setupMode.scss'

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { useCurrentSiteData } from '../../shared/hooks'
import { SITE_TYPES } from '../../shared/sharedConstants'
import paths from '../Router/paths'
import ReserveImage from './assets/reserve-mode.svg'

export const ReserveModePage = () => {
  const history = useHistory()
  const { site, loading } = useCurrentSiteData()

  const onCancelSavingsClick = React.useCallback(() => {
    history.push(paths.ROOT)
  }, [history])

  const onReserveModeSetup = React.useCallback(() => {
    history.push(paths.RESERVE_SETUP_STEP_1)
  }, [history])

  const notStorageSite = site?.siteType !== SITE_TYPES.STORAGE
  useEffect(() => {
    if (!loading && site && notStorageSite) {
      history.push(paths.ROOT)
    }
  }, [site, loading, notStorageSite, history])

  return (
    <Layout title="Reserve Mode">
      <section className="section reserve-mode-page setup-mode-page columns is-mobile is-vcentered">
        <img
          className="column reserve-img"
          src={ReserveImage}
          alt="Reserve mode"
        />
        <article className="column setup-mode-details">
          <h4 className="is-bold">Reserve Mode</h4>
          <h5 className="mt-5">
            Will reserve 100% of your battery’s charge <br />
            in case there is a power outage.
          </h5>
          <section className="actions-container mt-5">
            <button
              className="cancel button mr-5 mb-5"
              onClick={onCancelSavingsClick}
            >
              cancel
            </button>
            <button className="back button mr-5" onClick={onReserveModeSetup}>
              SET UP RESERVE MODE
            </button>
          </section>
        </article>
      </section>
    </Layout>
  )
}
