import '../styles.scss'

import React from 'react'

import { Loader } from '../../../../components/loader'
import { ProgramSignUpSteps, StepType } from '../../../../shared/typings/site'

export const Document = ({
  index,
  step,
  onPressSignature,
  isFetchingSignatureURL,
}: {
  index: number
  step: ProgramSignUpSteps
  onPressSignature: () => void
  isFetchingSignatureURL: boolean
}) => {
  if (step.stepType === StepType.TEXT_FIELD) {
    return (
      <div className="enrollment-document">
        <div className="document-separator" />
        <div className="document-name">{`${index + 1}. ${
          step?.pageTitle
        }`}</div>
        <div className="document-input">
          <div>{step.fieldInitialValue}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="enrollment-document">
      <div className="document-separator" />
      <div className="document-name">{`${index + 1}. ${step?.pageTitle}`}</div>
      <div className={`document-signature`} onClick={onPressSignature}>
        View Signed Document
        {isFetchingSignatureURL && <Loader />}
      </div>
    </div>
  )
}
