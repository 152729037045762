import { gql, useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'

const FETCH_PANELS_QUERY = gql`
  query Panels($date: String!, $siteKey: String!) {
    panels(date: $date, siteKey: $siteKey) {
      hasPanelLayout
      siteDailyEnergyProduction {
        timestamp
        value
      }
      siteHourlyPowerProduction {
        timestamp
        value
      }
      panels {
        serialNumber
        dailyEnergyProduction
        sevenDayAverage
        lastCommunicationTimestamp
        peakPowerProduction {
          timestamp
          value
        }
        energyColorCode
        alerts {
          alertStatus
          deviceSerialNumber
          deviceType
          deviceKey
          alertId
          alertType
          eventTimestamp
        }
        hourlyData {
          timestamp
          power
          energy
          powerColorCode
        }
        layout {
          xCoordinate
          yCoordinate
          rotation
          azimuth
          orientation
        }
      }
      weather {
        days {
          conditions
          datetime
          datetimeEpoch
          maxTemperature
          minTemperature
          sunrise
          sunset
          hours {
            conditions
            datetime
            datetimeEpoch
            temperature
          }
        }
        timezone
        tzoffset
      }
    }
  }
`
export function usePanels(startDate, timezone, siteKey, flag = false) {
  const [panels, setPanels] = useState({
    hasPanelLayout: false,
    panels: [],
    weather: {
      days: [{ conditions: 'SUNNY', sunset: '19:15:53', sunrise: '06:35:55' }],
    },
    siteDailyEnergyProduction: {},
    siteHourlyPowerProduction: [],
  })
  const [fetchPanels, { called, data, loading, error }] = useLazyQuery(
    FETCH_PANELS_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  )
  useEffect(() => {
    if (siteKey && flag && startDate && timezone && !loading) {
      const today = moment(startDate).tz(timezone).format('YYYY-MM-DD')
      fetchPanels({
        variables: {
          date: today,
          siteKey: siteKey,
        },
      })
    }
  }, [siteKey, fetchPanels, timezone, flag, startDate])
  useEffect(() => {
    if (data) {
      const { panels } = data
      setPanels(panels)
    }
  }, [called, data])

  return {
    panels: panels.panels,
    weather: panels.weather,
    siteDailyEnergyProduction: panels.siteDailyEnergyProduction,
    siteHourlyPowerProduction: panels.siteHourlyPowerProduction,
    loading,
    error,
  }
}
