import { useQuery } from '@apollo/client'
import React, { forwardRef, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../../../config/cookies'
import { getEnvVars } from '../../../../config/envVars'
import { FETCH_PARTY_DATA } from '../../../../shared/hooks/query'
import { DEFAULT_OHM_LOGIN } from '../../../../shared/typings/site'

type OhmFormProps = {
  siteKey: string
  deviceUuid: string
}

export const OhmForm = forwardRef<HTMLFormElement, OhmFormProps>(
  (props, ref) => {
    const envVars = getEnvVars()
    const [ohmFormSource, setOhmFormSource] = useState<URL | null>(null)
    const { siteKey, deviceUuid } = props
    const [cookies] = useCookies([
      USER_SESSION_INFO,
      LOGIN_OVERRIDE,
      USER_SELECTED_SITE_KEY,
    ])
    const session = cookies[USER_SESSION_INFO] ?? {}
    const loginOverride = cookies[LOGIN_OVERRIDE]

    const { data: partyData, loading: loadingPartyData } = useQuery(
      FETCH_PARTY_DATA,
      {
        variables: { partyId: loginOverride ?? session.partyId },
      },
    )

    let currentSite: null | object = null

    if (partyData && !loadingPartyData) {
      const selected = partyData.party?.sites?.filter((currentSite) => {
        return currentSite.siteKey === cookies[USER_SELECTED_SITE_KEY]
      })
      currentSite = selected.length > 0 ? selected[0] : null
    }

    useEffect(() => {
      const newSource = new URL(
        envVars.ohm_config?.authUrl ?? DEFAULT_OHM_LOGIN,
      )
      newSource.searchParams.append('user_uid', siteKey)
      newSource.searchParams.append(
        'client_id',
        envVars.ohm_config?.clientId ?? '',
      )
      newSource.searchParams.append(
        'redirect_uri',
        envVars.ohm_config?.redirectUri ?? '',
      )
      newSource.searchParams.append('response_type', 'code')
      setOhmFormSource(newSource)
    }, [])

    return (
      <form
        id="ohm_connect_form"
        method="post"
        className="ohm-form"
        ref={ref}
        target="_blank"
        action={ohmFormSource?.href ?? ''}
      >
        <input type="hidden" name="email" value={session['email']} />
        <input type="hidden" name="first_name" value={session['name']} />
        <input
          type="hidden"
          name="zip_code"
          value={currentSite ? currentSite['postalCode'] : ''}
        />
        <input type="hidden" name="device_uuid" value={deviceUuid} />
      </form>
    )
  },
)
