import './styles.scss'

import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Case, CasesStatus } from '../../../../shared/typings/helpCenter'
import EmptyCasesImg from '../../assets/empty-cases.svg'
import { CaseCard } from '..'
import { useCookies } from 'react-cookie'
import { MY_SUNPOWER_CUSTOMER_CHECK } from '../../../../config/cookies'

type CasesSliderProps = {
  open: boolean
  loading: boolean
  cases: Case[]
}

export const CasesSlider = ({ open, loading, cases }: CasesSliderProps) => {
  const [selectedStatus, setSelectedStatus] = useState<CasesStatus>(
    CasesStatus.OPEN,
  )

  const [toggleToOpen, setToggleToOpen] = useState(false)

  const switchToggleToOpen = () => {
    setToggleToOpen(true)
  }

  // Moves toggle to 'open' after the request to Re-Open case is successful
  useEffect(() => {
    if (toggleToOpen) {
      setSelectedStatus(CasesStatus.OPEN)
      setToggleToOpen(false)
    }
  }, [toggleToOpen])

  const [cookies] = useCookies([
    MY_SUNPOWER_CUSTOMER_CHECK
  ])
  const customerCheckCookie = cookies[MY_SUNPOWER_CUSTOMER_CHECK]
  const canSwitchToSales = useMemo(
    () => customerCheckCookie?.go_to_mysunpower ?? false,
    [customerCheckCookie],
  )

  const filteredCases = useMemo(() => {
    return cases.filter(
      (c) =>
        (selectedStatus === CasesStatus.OPEN &&
          c.status?.toLocaleLowerCase().includes(selectedStatus)) ||
        (selectedStatus === CasesStatus.RESOLVED &&
          c.status?.toLocaleLowerCase().includes(selectedStatus)),
    )
  }, [cases, selectedStatus])

  if (!open) return null

  return (
    <div className="slider-container cases-slider-wrapper">
      <aside
        className={clsx('cases-slider', {
          hasAdminBaner: canSwitchToSales,
        })}
      >
        <div className="toogle-container">
          <div className={clsx('toogle')}>
            <div
              className={clsx('button-toogle', {
                active: selectedStatus === CasesStatus.OPEN,
              })}
              onClick={() => setSelectedStatus(CasesStatus.OPEN)}
            >
              Open
            </div>
            <div
              className={clsx('button-toogle', {
                active: selectedStatus === CasesStatus.RESOLVED,
              })}
              onClick={() => setSelectedStatus(CasesStatus.RESOLVED)}
            >
              Resolved
            </div>
          </div>
        </div>
        <div className="case-list-container">
          {loading && (
            <div className="loading-container">
              <p>Loading cases...</p>
            </div>
          )}
          {filteredCases.length > 0 &&
            !loading &&
            filteredCases.map((c) => (
              <CaseCard
                key={c.caseNumber}
                supportCase={c}
                currentStatus={
                  toggleToOpen && selectedStatus === CasesStatus.RESOLVED
                    ? CasesStatus.OPEN
                    : selectedStatus
                }
                switchToggleToOpen={switchToggleToOpen}
              />
            ))}

          {filteredCases.length === 0 && !loading && (
            <div className="empty-container">
              <img src={EmptyCasesImg} alt="empty state" />
              <p>{`You have no ${
                selectedStatus === CasesStatus.OPEN ? 'open' : 'resolved'
              } cases`}</p>
            </div>
          )}
        </div>
      </aside>
    </div>
  )
}
