import moment from 'moment-timezone'

import { toCommaNumber } from './toCommaNumber'

const DAY_FORMAT = 'ddd, DD/MM'
const HOUR_FORMAT = 'hh:mma'
const PROGRAM_EVENT_FORMAT = 'M/D/YY'

export const DEFAULT_NON_ZERO = '--'
export const formatNumber = (num: number, decimalNum = 1) =>
  num > 0 ? (Math.round(num * 100) / 100).toFixed(decimalNum) : DEFAULT_NON_ZERO

export const formatCommaNumber = (num: number, decimals: number = 1) =>
  num
    ? toCommaNumber(Number((Math.round(num * 100) / 100).toFixed(decimals)))
    : DEFAULT_NON_ZERO

export const currencyFormat = (value, decimals = 2) =>
  value > 0
    ? '$' + value.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : DEFAULT_NON_ZERO

export const dateFormat = (startDate: number = 0, endDate: number = 0) => {
  if (!startDate && !endDate) {
    return ''
  }
  let finalLabel = ''
  const convStartDate = moment.unix(startDate)
  const convEndDate = moment.unix(endDate)

  if (convStartDate.isSame(convEndDate, 'day')) {
    const hour = `${convStartDate.format(HOUR_FORMAT)} - ${convEndDate.format(
      HOUR_FORMAT,
    )}`
    const date = moment.unix(startDate).format(DAY_FORMAT)
    finalLabel = `${date}, ${hour}`
  } else {
    const initDate = `${convStartDate.format(`${DAY_FORMAT} ${HOUR_FORMAT}`)}`
    const endDate = `${convEndDate.format(`${DAY_FORMAT} ${HOUR_FORMAT}`)}`
    finalLabel = `${initDate} - ${endDate}`
  }
  return finalLabel
}

export const helpCenterDateFormat = (date: string) => {
  const momentDate = moment(date)
  return momentDate.format('dddd, M/D/YY, [at] hh:mma')
}

export const getHourFromDate = (date: string) => {
  const momentDate = moment(date)
  return momentDate.format('hh:mma')
}

export const eventsDateFormat = (
  eventDatetime: string,
  eventLength: number = 0,
) => {
  const startDate = moment(eventDatetime)
  const endDate = moment(eventDatetime)
  endDate.add(eventLength, 'seconds')
  return dateFormat(startDate.unix(), endDate.unix())
}

export const dynamicEventCardDateFormat = (eventDatetime?: string) => {
  if (!eventDatetime) {
    return ''
  }
  const eventDate = moment(eventDatetime)
  return eventDate.format(PROGRAM_EVENT_FORMAT)
}
