import { gql } from '@apollo/client'

export const FETCH_PROFILE_INFO = gql`
  query FetchNetworkInfo($serialNumber: String!) {
    deviceStatus(serialNumber: $serialNumber) {
      dvcKey
      netIntfRptCtnt {
        cellMdmStatVal
        cellPrvdNm
        cellSimStatVal
        cellSgnlStrenDbm
        cellStatEnum
        currNetIntfEnum
        edpConnFl
        inetConnFl
        msgCrtEps
        prevNetIntfEnum
        sgnlStrenSta0Dbm
        sta0Ssid
        wifiStatEnum
        wifiIconBars
        cellIconBars
      }
    }
  }
`
export const UPDATE_PROFILE_INFO = gql`
  mutation UpdateParty($params: UpdatePartyInput!) {
    updateParty(params: $params) {
      partyId
      firstName
      lastName
      displayName
      phone
    }
  }
`
