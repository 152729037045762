import './style.scss'

import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import paths from '../../pages/Router/paths'
import rightArrowIcon from './assets/right-arrow.svg'

interface ConnectedSolutionsTopBannerProp {
  status: boolean
  redirectPath?: string
}
export const ConnectedSolutionsTopBanner = ({
  status,
  redirectPath,
}: ConnectedSolutionsTopBannerProp) => {
  const history = useHistory()
  const mainClassName = clsx(
    'top-banner-container',
    status ? 'active' : 'inactive'
  )
  const iconClassname = clsx('arrow-icon', status && 'icon-active')

  const handleOnClickBanner = useCallback(
    () => history.push(redirectPath ?? paths.CONNECTED_SOLUTIONS),
    [history, redirectPath]
  )
  return (
    <div className={mainClassName} onClick={handleOnClickBanner}>
      <div className='bold-title'>ENROLLED</div>IN SUNPOWER VPP
      <img
        src={rightArrowIcon}
        alt='right-arrow-icon'
        className={iconClassname}
      />
    </div>
  )
}
