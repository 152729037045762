import './style.scss'

import { useLazyQuery, useQuery } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { isLegacyPvs } from '../../web-shared/isLegacyPvs'
import { Layout } from '../../components/layout'
import { Modal } from '../../components/modal'
import { MyAccount } from '../../components/my-account'
import { MyReports, MyReportsEmpty } from '../../components/my-reports'
import { Referrals } from '../../components/referrals'
import { SystemConnection } from '../../components/system-connection'
import { WidgetWrapper } from '../../components/widget-wrapper'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { ALERTS_ID, useAlerts } from '../../hooks'
import { CONNECTION_STATUSES } from '../../shared/connectionStatuses'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import paths from '../Router/paths'
import { FETCH_NETWORK_INFO, FETCH_PARTY_INFO } from './query'

export const PVS_5_6_REGEX = /PVS[5-6]/i
const REPORTS_DATE_FORMAT = 'M/D/YY'

export const Profile = ({
  modalTitle,
  modal,
  hideTopBar,
  noStylesModal = false,
}: {
  modalTitle?: string
  modal?: JSX.Element
  hideTopBar?: boolean
  noStylesModal?: boolean
}) => {
  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])
  const flags = useFlags() as LaunchDarklyFlags

  const PROFILE_EDIT_FLAG = flags?.profileEditFlag ?? false
  const REPORTS_SETUP_FLAG = flags?.reportsSetupFlag ?? false
  const ICD_ALERT_FLAG = flags?.icdAlertFlag ?? false
  const session = cookies[USER_SESSION_INFO] ?? {}
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const loginOverride = cookies[LOGIN_OVERRIDE]

  const history = useHistory()

  const { data, loading } = useQuery(FETCH_PARTY_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      partyId: loginOverride ?? session.partyId,
      siteKey: cookies[USER_SELECTED_SITE_KEY],
    },
  })
  const [
    fetchNetworkInfo,
    {
      data: networkInfoData,
      loading: networkInfoLoading,
      error: networkInfoError,
    },
  ] = useLazyQuery(FETCH_NETWORK_INFO, {
    fetchPolicy: 'network-only',
  })
  const [showWifiSettings, setShowWifiSettings] = useState(false)
  const { party = {} } = data ?? {}

  const selectedSite = useMemo(
    () => (party?.sites ?? []).find((site) => site.siteKey === selectedSiteKey),
    [party, selectedSiteKey],
  )

  const { alerts, loading: alertsLoading } = useAlerts(selectedSiteKey)

  const hasICD = useMemo(
    () => ICD_ALERT_FLAG && alerts.some((a) => a.alertType === ALERTS_ID.ICD),
    [alerts, ICD_ALERT_FLAG],
  )

  useEffect(() => {
    if (!selectedSite && data?.party?.sites.length) {
      history.push(paths.SITES_LIST)
    }
  }, [selectedSite, history, data])

  useEffect(() => {
    if (selectedSite && selectedSite.assignments.length > 0) {
      let assignment = selectedSite.assignments
        .filter(
          (currentAssignment) => currentAssignment.deviceType === 'DATALOGGER',
        )
        .shift()

      if (!assignment) {
        assignment = selectedSite.assignments[0]
      }

      fetchNetworkInfo({
        variables: { serialNumber: assignment.deviceSerialNumber },
      })
    }
  }, [fetchNetworkInfo, selectedSite])

  useEffect(() => {
    if (networkInfoData) {
      const isPvs5or6 = PVS_5_6_REGEX.test(
        networkInfoData?.deviceStatus?.dvcKey,
      )
      const isNetCommSomehowNull =
        networkInfoData?.deviceStatus?.netIntfRptCtnt === null
      const isUsingEthernet = [
        CONNECTION_STATUSES.ETHERNET,
        CONNECTION_STATUSES.ETHERNET2,
        CONNECTION_STATUSES.ETHERNET3,
        CONNECTION_STATUSES.POWER_LINE,
      ].includes(networkInfoData?.deviceStatus?.netIntfRptCtnt?.currNetIntfEnum)

      if (isPvs5or6 && !isUsingEthernet && !isNetCommSomehowNull) {
        setShowWifiSettings(true)
      } else {
        setShowWifiSettings(false)
      }
    }
  }, [selectedSite, networkInfoData])

  const performanceReport = useMemo(() => {
    if (selectedSite) {
      return selectedSite.performanceReport
    }
  }, [selectedSite])

  const showSystemWidget = useMemo(() => {
    if (networkInfoData) {
      const isDeviceStatusSomehowNull =
        networkInfoData?.deviceStatus === null || undefined

      if (
        (selectedSite && (!isLegacyPvs(selectedSite) || hasICD)) ||
        isDeviceStatusSomehowNull
      ) {
        return true
      } else {
        return false
      }
    }
  }, [selectedSite, networkInfoData, hasICD])
  const performanceReportRange = performanceReport
    ? `${moment(performanceReport.nextPeriod.start).format(
        REPORTS_DATE_FORMAT,
      )} to ${moment(performanceReport.nextPeriod.end).format(
        REPORTS_DATE_FORMAT,
      )}`
    : ''

  return (
    <Layout
      sitesList={data?.party?.sites}
      siteKey={selectedSite?.siteKey}
      contentFooter={<Referrals />}
      title="Profile"
    >
      <section className="section profile">
        <div className="tile is-ancestor is-vertical">
          <div className="tile">
            <div className="tile is-4 is-parent">
              <WidgetWrapper
                title="My Account"
                infoLink={paths.PROFILE_INFO_MY_ACCOUNT}
                isLoading={loading}
                editLink={
                  PROFILE_EDIT_FLAG ? paths.PROFILE_EDIT_MODAL : undefined
                }
              >
                <MyAccount
                  name={party.displayName || 'N/A'}
                  email={party.email || 'N/A'}
                  phone={party.phone || 'N/A'}
                />
              </WidgetWrapper>
            </div>
            {showSystemWidget && (
              <div className="tile is-4 is-parent">
                <WidgetWrapper
                  title="System connection"
                  isLoading={loading || networkInfoLoading || alertsLoading}
                  settingsLink={
                    showWifiSettings && !hasICD
                      ? paths.PROFILE_SETTINGS_WIFI
                      : null
                  }
                  hasContentPadding={false}
                >
                  <SystemConnection
                    networkReport={
                      networkInfoData?.deviceStatus?.netIntfRptCtnt
                    }
                    networkReportError={networkInfoError}
                    hasICD={hasICD}
                  />
                </WidgetWrapper>
              </div>
            )}
            {REPORTS_SETUP_FLAG && (
              <div className="tile is-4 is-parent">
                <WidgetWrapper
                  title="My Reports"
                  editLink={paths.PROFILE_REPORTS_SETUP}
                  isLoading={loading}
                >
                  {performanceReport ? (
                    <MyReports
                      nextReport={`${moment(
                        performanceReport.nextRun.split('T').shift(),
                      ).format(REPORTS_DATE_FORMAT)}`}
                      range={performanceReportRange}
                      emailedTo={performanceReport.subscribers
                        .filter((item) => item.statusIndicator !== 'INACTIVE')
                        .map((item) => item.email)
                        .join('\n')}
                    />
                  ) : (
                    <MyReportsEmpty />
                  )}
                </WidgetWrapper>
              </div>
            )}
            {modal && (
              <Modal
                noStylesModal={noStylesModal}
                title={modalTitle}
                hideTopBar={hideTopBar}
                fromPath={paths.PROFILE}
              >
                {modal}
              </Modal>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}
