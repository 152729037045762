import { useMutation } from '@apollo/client'
import bulmaCollapsible from '@creativebulma/bulma-collapsible'
import { History } from 'history'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import chevronDown from '../../components/header/assets/chevron-down.svg'
import {
  CurrentSiteTariffDetails,
  TariffPlanUIStructureResponse,
} from '../../shared/typings/site'
import { SET_TARIFF_FOR_SITE } from '../CostSavingsModePage/query'
import paths from '../Router/paths'
import costSavingsDiagram from './assets/cost-savings-modes-charts.svg'
import rightArrowIcon from './assets/right-arrow.svg'

export const UtilityRateDetails = ({
  siteKey,
  utilityPlans,
  currentPlan,
  history,
  utilityPlanDetail,
  setUtilityPlanDetail,
}: {
  siteKey: string
  utilityPlanDetail: any
  setUtilityPlanDetail: any
  utilityPlans: TariffPlanUIStructureResponse[]
  currentPlan: CurrentSiteTariffDetails | null
  history: History<History.UnknownFacade>
}) => {
  const [selectedPlan, setSelectedPlan] = useState(
    utilityPlanDetail || currentPlan,
  )
  const [updateTariff] = useMutation(SET_TARIFF_FOR_SITE)
  const collapsiblesRef = useRef(null)
  const overlayEl = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    collapsiblesRef.current = bulmaCollapsible.attach('.is-collapsible', {
      container: collapsiblesRef.current,
    })
  }, [])

  const handleOnClickRateDetails = useCallback(
    (rate: TariffPlanUIStructureResponse) => {
      setUtilityPlanDetail(rate)
      history.push(paths.UTILITY_RATE_PLAN_DETAIL)
    },
    [history, setUtilityPlanDetail],
  )
  const handleOnChangeRate = (rate: TariffPlanUIStructureResponse) => {
    if (overlayEl.current) {
      overlayEl.current.click()
    }
    setSelectedPlan(rate)
  }

  const handleContactUs = useCallback(() => {
    history.push(paths.CONTACT_US)
  }, [history])

  const handleOnSave = useCallback(() => {
    updateTariff({
      variables: {
        masterTariffId: selectedPlan?.tariffId,
        siteId: siteKey,
      },
    }).then(() => {
      setUtilityPlanDetail(selectedPlan)
      setSelectedPlan(null)
      history.push(paths.COST_SAVINGS_MODE, {
        fromUtilityRateSchedules: true,
      })
    })
  }, [history, selectedPlan, setUtilityPlanDetail, siteKey, updateTariff])

  const handleCancel = () => history.goBack()

  return (
    <div className="main-main-container">
      <section className="section utility-rate-schedules columns is-mobile">
        <img
          className="column"
          src={costSavingsDiagram}
          alt="cost savings over 24 hours"
        />
        <article className="column setup-mode-details">
          <h4 className="is-bold">Utility Rate Plan Schedules</h4>
          <section className="mt-5 utility-rate-description mb-5">
            <h3>
              SunVault saves you time and money by applying your utility
              company’s rate plan. Sunvault will then offset grid usage during
              peak energy hours.
            </h3>
          </section>
          <div className="collapsible-container">
            <div ref={collapsiblesRef} id="accordion_first">
              <div className="message">
                <a
                  className="message-header collapsible-header"
                  href="#collapsible-message"
                  data-action="collapse"
                  ref={overlayEl}
                >
                  <h3>
                    {selectedPlan
                      ? selectedPlan.tariffCode
                      : 'SELECT YOUR UTILITY’S RATE PLAN'}
                  </h3>
                  <img
                    src={chevronDown}
                    alt="open-options"
                    className="question-chev is-active"
                  />
                </a>
                <div
                  id="collapsible-message"
                  className="is-collapsible message-body collapsible-content"
                >
                  {utilityPlans.map((rate, index) => {
                    const { tariffCode } = rate
                    return (
                      <CollapsibleItem
                        key={`collapsible-item ${index}`}
                        name={tariffCode || ''}
                        selected={tariffCode === selectedPlan?.tariffCode}
                        onClickRateDetails={() =>
                          handleOnClickRateDetails(rate)
                        }
                        onChangeRate={() => handleOnChangeRate(rate)}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <section className="buttons-container mt-5">
            <button onClick={handleCancel} className="cancel  mr-5 mb-5">
              cancel
            </button>
            <button
              className="go-to-slider"
              disabled={!selectedPlan}
              onClick={handleOnSave}
            >
              Save
            </button>
          </section>
        </article>
      </section>
      <div className="contact-us" onClick={handleContactUs}>
        <h3>
          Can’t find your utility or rate plan?
          <div>
            <h3 className="anchor">Contact Us</h3>
          </div>
        </h3>
      </div>
    </div>
  )
}

const CollapsibleItem = ({
  name,
  selected,
  onClickRateDetails,
  onChangeRate,
}: {
  name: string
  selected: boolean
  onClickRateDetails: () => void
  onChangeRate: () => void
}) => {
  return (
    <div className="message-body-content collapsible-list-item">
      <div className="radio-container">
        <input
          type="radio"
          name="site_name"
          checked={selected}
          className="radio"
          onChange={onChangeRate}
        />
        <label>
          <h3 className="ml-5">{name}</h3>
        </label>
      </div>
      <img
        src={rightArrowIcon}
        alt="Right arrow icon"
        className="right-arrow-icon"
        onClick={onClickRateDetails}
      />
    </div>
  )
}
