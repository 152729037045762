import moment from 'moment-timezone'

import { SeasonType } from './flat-timeline'

export type SeasonsTimelineData = {
  index: number
  value: number
  currentSeason: boolean
}

export const indexToMonth = (tick: number): string =>
  moment().month(tick).format('MMM').toUpperCase()

export const dateParser = (date?: string): string =>
  date ? moment(date).format('ddd, MM/DD/YY') : ''

export const parserToSeasonsTimeline = (seasons: SeasonType[]) => {
  const resp = {}

  const firstSeasonStartDate = seasons.length < 2 && seasons[0]?.startDate
  const firstSeasonEndDate = seasons.length < 2 && seasons[0]?.endDate

  ;[...Array(12).keys()].map((index) => {
    let currentSeason = false
    if (firstSeasonStartDate && firstSeasonEndDate) {
      const indexDate = moment().month(index)

      const startDateMoment = moment(firstSeasonStartDate)
      const endDateMoment = moment(firstSeasonEndDate)
      currentSeason = indexDate.isBetween(startDateMoment, endDateMoment)
    }
    resp[index] = {
      xIndex: index,
      value: 0,
      currentSeason: currentSeason,
    }
    return resp[index]
  })

  const seasonsParsed = seasons.map((season: SeasonType) => {
    const { startDate, endDate } = season
    return {
      ...season,
      startDate: moment(startDate).month(),
      endDate: moment(endDate).month() + 1,
    }
  })

  return {
    data: Object.keys(resp).map((xIndex) => resp[xIndex]),
    seasonsParsed,
  }
}
