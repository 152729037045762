import React from 'react'
import {
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { Tooltip as GraphTooltip } from '../tooltip'
import { needsShiftForSpace } from './calcGraph'

type TooltipsType = {
  tooltipConsumptionTotals: object[]
  tooltipGridTotals: object[]
  tooltipProductionTotals: object[]
  tooltipStorageTotals: object[]
}

type GraphContainerProps = {
  data: any
  yAxisTicks: number[]
  children: React.ReactNode[] | React.ReactNode
  interval: string
  isPower?: boolean
  daysDifference?: number
  tickValues: number[]
  tickFormat: (v: number, timezone?: string) => string
  customTooltipsAndValues?: TooltipsType | null
  siteType?: string
}

export const GraphContainer = ({
  data,
  children,
  tickValues,
  tickFormat,
  interval,
  yAxisTicks,
  isPower = false,
  daysDifference = 0,
  customTooltipsAndValues,
  siteType,
}: GraphContainerProps) => {
  const tooltipMarkup =
    data && data.length > 0 ? (
      <Tooltip
        content={
          <GraphTooltip
            daysDifference={daysDifference}
            isPower={isPower}
            totalItems={data.length}
            fillConsumption={true}
            siteType={siteType}
          />
        }
      />
    ) : null

  const needShift = needsShiftForSpace(interval)
  const yAxisShift = needShift ? -33 : -25

  return (
    <ResponsiveContainer height={350}>
      <ComposedChart
        data={data}
        barSize={20}
        margin={{ top: 20, bottom: 20, right: 20 }}
      >
        <CartesianGrid end={0} dx={15} vertical={false} />
        {tooltipMarkup}
        <XAxis
          dataKey="xIndex"
          axisLine={false}
          tickLine={false}
          orientation={'bottom'}
          fontSize="10px"
          interval={0}
          dy={20}
          ticks={tickValues}
          tickFormatter={(axisVal) => {
            return tickFormat(axisVal)
          }}
          scale="auto"
          xAxisId="timestampAxis"
        />
        <YAxis
          axisLine={false}
          ticks={yAxisTicks}
          allowDecimals={true}
          dx={yAxisShift}
          textAnchor="middle"
          fontSize="10px"
          width={80}
          interval={0}
          scale="linear"
          tickFormatter={(axisVal) => {
            const valindx = yAxisTicks.indexOf(axisVal)
            if (valindx === 0 || valindx === yAxisTicks.length - 1) {
              return `${axisVal} ${isPower ? 'kW' : 'kWh'}`
            }
            return axisVal
          }}
          tickLine={false}
        />
        {children}
        <ReferenceLine y={0} xAxisId="timestampAxis" stroke="#000000" />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
