import React from 'react'

export type WeatherIconProps = {
  x?: number
  y?: number
}

export const Cloudy = ({ x = 0, y = 0 }: WeatherIconProps) => (
  <svg
    width="27"
    height="27"
    viewBox={`${x} ${y} 27 27`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="27" height="27" />
    <path
      d="M6.67328 15.3621L6.68077 15.3632H6.68835C6.80292 15.3632 6.92796 15.3216 7.02588 15.2457C7.12402 15.1697 7.20094 15.0536 7.20312 14.9053C7.42711 12.9861 9.1099 11.5002 11.1053 11.5002C13.0983 11.5002 14.7384 12.984 15.0074 14.9066C15.01 15.0545 15.0874 15.1728 15.197 15.2448C15.3057 15.3163 15.4461 15.3432 15.5839 15.3171C17.0222 15.1474 18.3314 16.3102 18.3314 17.7736C18.3314 19.1044 17.1983 20.184 15.8409 20.184H6.3696C4.96789 20.184 3.8791 19.1055 3.8791 17.7736C3.8791 16.3097 5.18908 15.1468 6.67328 15.3621ZM11.1053 10.5266C8.80814 10.5266 6.78274 12.1375 6.28466 14.3457C4.44455 14.3909 2.895 15.9005 2.895 17.7736C2.895 19.6306 4.44696 21.1576 6.3696 21.1576H15.8409C17.7636 21.1576 19.3155 19.6306 19.3155 17.7736C19.3155 15.8995 17.7649 14.3907 15.882 14.3457C15.4276 12.1364 13.4013 10.5266 11.1053 10.5266Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      strokeWidth="0.21"
    />
    <path
      d="M13.3389 8.78552L13.3385 8.78539L13.3363 8.79391C13.3064 8.90787 13.3216 9.03305 13.3724 9.1372C13.4227 9.24023 13.5122 9.33014 13.6374 9.36235C13.7728 9.41262 13.9143 9.4144 14.0335 9.36457C14.1545 9.31402 14.2464 9.21225 14.2785 9.07125C14.7797 7.73001 16.0963 6.86074 17.6009 6.86074C19.3811 6.86074 20.8792 8.12474 21.0605 9.81438L21.06 9.81444L21.0621 9.8242C21.0902 9.95865 21.162 10.0716 21.2675 10.1436C21.3721 10.215 21.5032 10.2415 21.6423 10.2161C22.9463 10.0896 24.1039 11.0787 24.1039 12.3097C24.1039 13.4585 23.1228 14.4024 21.9092 14.4024H20.1297C19.8525 14.4024 19.6082 14.6148 19.6082 14.8604C19.6082 15.1436 19.8447 15.3632 20.1297 15.3632H21.9092C23.646 15.3632 25.1 13.9801 25.1 12.3097C25.1 10.665 23.6906 9.29902 21.9895 9.2572C21.5265 7.28015 19.6775 5.9 17.6009 5.9C15.689 5.9 13.963 7.04205 13.3389 8.78552Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      strokeWidth="0.2"
    />
  </svg>
)
