import React, { createContext, useState } from 'react'
import { useCookies } from 'react-cookie'

import {
  MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
  MY_SUNPOWER_MONITORING_ID_TOKEN,
  MY_SUNPOWER_USER_CONTEXT,
  USER_SESSION_INFO,
} from './../config/cookies'

type UserDataReturnType = {
  data: null | { user: object }
  logoutIdToken: null | string
  fetched: boolean
  error: string
  loggingOut: boolean
}

type userContextType = {
  user: null | UserDataReturnType
  setUser: Function
  getoktaID: Function
  getQuickCustomerCheck: Function
  overriddeMilestone: Function
  loginUser: Function
  logoutUser: Function
}

type UserProviderPropsType = {
  children: JSX.Element
}

export const UserContext = createContext<userContextType | null>(null)

const userInitValue = {
  data: null,
  logoutIdToken: null,
  fetched: false,
  error: '',
  loggingOut: false,
}

const UserProvider = ({ children }: UserProviderPropsType) => {
  const [user, setUser] = useState<UserDataReturnType>(userInitValue)
  const [cookies, setCookie] = useCookies([
    MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
    MY_SUNPOWER_USER_CONTEXT,
    MY_SUNPOWER_MONITORING_ID_TOKEN,
    USER_SESSION_INFO,
  ])

  const getoktaID = (token: string) => {
    if (token) {
      const [headerEncoded, payloadEncoded, signature] = token.split('.');
      const payload = JSON.parse(atob(payloadEncoded));
      return payload.sub;
    } else {
      console.log('No token passed')
    }
  }

  const loginUser = (idToken: string, accessToken: string, userSessionInfo: object, COOKIES_OPTIONS: object) => {
    const cookieData = {
      value: idToken,
      domain: process.env.REACT_APP_BASE_URL,
      expires: 86000,
      secure: true
    }
    setCookie(
      MY_SUNPOWER_USER_CONTEXT,
      cookieData,
      COOKIES_OPTIONS,
    )
    setCookie(
      MY_SUNPOWER_MONITORING_ID_TOKEN,
      idToken,
      COOKIES_OPTIONS,
    )
    setCookie(
      MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
      accessToken,
      COOKIES_OPTIONS,
    )
    setCookie(
      USER_SESSION_INFO,
      userSessionInfo,
      COOKIES_OPTIONS
    )
    setUser({
      data: {
        user: {}
      },
      fetched: false,
      error: '',
      loggingOut: false,
      logoutIdToken: null
    })
  }

  const logoutUser = async () => {
    try {
      await fetch(`${process.env.REACT_APP_PRE_COMM_BASE_URL}/api/accounts`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_PRE_COMM_API_AUTH_TOKEN}`
        }
      });
    } catch (error) {
      console.error(error)
    }
  }

  const getQuickCustomerCheck = async (oktaId: string) => {
    try {
      const authToken = process.env.REACT_APP_PRE_COMM_API_AUTH_TOKEN //TODO: we need to get this token from graphql via pre comm api /api/authencation
      const response = await fetch(`${process.env.REACT_APP_PRE_COMM_BASE_URL}/api/quickCustomerCheck?okta_id=${oktaId}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });
      const customerCheckData = await response.json()
      setUser({
        data: {
          user: customerCheckData
        },
        fetched: true,
        error: '',
        loggingOut: false,
        logoutIdToken: null
      })
    } catch (error) {
      console.error(error)
    }
  }

  const overriddeMilestone = async (sfid: string, newMilestone: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PRE_COMM_BASE_URL}/api/accounts`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_PRE_COMM_API_AUTH_TOKEN}}`
        },
        body: JSON.stringify({
          sfid: sfid,
          overridden_milestone: newMilestone
        }),
      });
    } catch (error) {
      console.log({error});
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        getoktaID,
        getQuickCustomerCheck,
        overriddeMilestone,
        loginUser,
        logoutUser
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
