import maxBy from 'lodash/maxBy'
import moment from 'moment-timezone'

import { limitDaysInPast } from '../../shared/constants'
import {
  FIVE_MINUTES_IN_AN_HOUR,
  FIVE_MINUTE_CHUNKS_IN_A_DAY,
  HOURS_IN_A_DAY,
  MAX_DAYS_IN_MONTH,
  MAX_DAYS_IN_WEEK,
  MAX_DAYS_IN_YEAR,
  MONTHS_IN_A_YEAR,
  QUARTERS_IN_AN_HOUR,
  QUARTERS_IN_A_DAY,
} from '../../shared/sharedConstants'

export const ENERGY_SOURCE = {
  home: 'home',
  solar: 'solar',
  grid: 'grid',
  storage: 'storage',
}
const MAX_TICKS = 8

export const mapMaxEnergyToTickValue = (maxEnergy) => {
  const additionalWhiteSpace = maxEnergy * 0.1
  return maxEnergy + additionalWhiteSpace
}

export const AGGREGATION_PERIOD = {
  RightNow: 'RIGHT_NOW',
  Today: 'TODAY',
  ThisWeek: 'THIS_WEEK',
  ThisMonth: 'THIS_MONTH',
  ThisYear: 'THIS_YEAR',
  Lifetime: 'LIFETIME',
  Custom: 'CUSTOM',
}
export const range = (length) => [...Array(length).keys()]

const singleDayTicks = (tick) => {
  if (tick === 0 || tick === 24) {
    return `12am`
  }
  if (tick === 12) {
    return '12pm'
  }
  if (tick < 12) {
    return `${tick}am`
  }
  return `${tick - 12}pm`
}

const powerDayTicks = (tick) => {
  const normalizedTick = tick / QUARTERS_IN_AN_HOUR
  return singleDayTicks(normalizedTick)
}
const powerNonLegacyDayTicks = (tick) => {
  const normalizedTick = tick / FIVE_MINUTES_IN_AN_HOUR
  return singleDayTicks(normalizedTick)
}

export const calculateMonthDifference = (startDate, endDate, timezone) =>
  moment(moment.tz(endDate, timezone))
    .subtract(1, 'day')
    .startOf('month')
    .diff(moment.tz(startDate, timezone).startOf('month'), 'month') + 1

export const calculateYearDifference = (startDate, endDate, timezone = '') =>
  moment
    .tz(endDate, timezone)
    .subtract(1, 'day')
    .startOf('year')
    .diff(moment.tz(startDate, timezone).startOf('year'), 'year') + 1

export const calculateDayDifference = (startDate, endDate) =>
  moment(endDate).diff(moment(startDate), 'day')

const calculateCustomIndex = (date, starttime, dayDifference, timezone) => {
  if (dayDifference <= 1) {
    return moment.tz(date, timezone).hour()
  }

  if (dayDifference <= MAX_DAYS_IN_MONTH) {
    return moment.tz(date, timezone).diff(moment.tz(starttime, timezone), 'day')
  }

  if (dayDifference <= MAX_DAYS_IN_YEAR) {
    return moment
      .tz(date, timezone)
      .diff(moment.tz(starttime, timezone).startOf('month'), 'month')
  }

  return moment
    .tz(date, timezone)
    .diff(moment.tz(starttime, timezone).startOf('year'), 'year')
}
export const currentIndex = {
  [AGGREGATION_PERIOD.Today]: (date) => moment(date).hour(),
  [AGGREGATION_PERIOD.ThisWeek]: (date) => moment(date).day(),
  [AGGREGATION_PERIOD.ThisMonth]: (date) => moment(date).date() - 1,
  [AGGREGATION_PERIOD.ThisYear]: (date) => moment(date).month(),
  [AGGREGATION_PERIOD.Lifetime]: (date, startTime, _, timezone) => {
    return calculateYearDifference(startTime, date, timezone)
  },

  [AGGREGATION_PERIOD.Custom]: calculateCustomIndex,
}
const getMinuteChunksInADay = (isLegacyPvs) => {
  return isLegacyPvs ? QUARTERS_IN_A_DAY : FIVE_MINUTE_CHUNKS_IN_A_DAY
}
const getMinuteChunksInAHour = (isLegacyPvs) => {
  return isLegacyPvs ? QUARTERS_IN_AN_HOUR : FIVE_MINUTES_IN_AN_HOUR
}

const customPeriodGraphAxisData = (
  startDate,
  endDate,
  isPower,
  isLegacyPvs,
  customDaysDifference,
  timezone,
) => {
  const dayDifference = customDaysDifference
    ? customDaysDifference
    : calculateDayDifference(startDate, endDate)
  const livePowerMinuteChunksInADay = getMinuteChunksInADay(isLegacyPvs)
  const livePowerMinuteChunksInAHour = getMinuteChunksInAHour(isLegacyPvs)
  const powerTicks = isLegacyPvs ? powerDayTicks : powerNonLegacyDayTicks
  const tickValues = range(
    (isPower ? livePowerMinuteChunksInADay : HOURS_IN_A_DAY) + 1,
  ).filter(
    (index) =>
      (isPower ? index / livePowerMinuteChunksInAHour : index) % 3 === 0,
  )
  /* Single Day */
  if (dayDifference <= 1) {
    return {
      tickValues: tickValues,
      tickFormat: isPower ? powerTicks : singleDayTicks,
      maxValue: isPower ? livePowerMinuteChunksInADay : HOURS_IN_A_DAY,
    }
  }
  /* Range of days */
  if (dayDifference <= MAX_DAYS_IN_MONTH) {
    return {
      tickValues: range(dayDifference + 1).filter(
        /* Minimum between number of data points and MAX_TICKS */
        (index) =>
          index %
            Math.ceil(dayDifference / Math.min(dayDifference, MAX_TICKS)) ===
          0,
      ),
      tickFormat: (tick) =>
        moment.tz(startDate, timezone).add(tick, 'day').format('M/D'),
      maxValue: dayDifference,
    }
  }
  /* Range of months */
  if (dayDifference <= MAX_DAYS_IN_YEAR) {
    const monthDifference = calculateMonthDifference(
      startDate,
      endDate,
      timezone,
    )

    return {
      tickValues: range(monthDifference + 1 ?? 0),
      tickFormat: (tick) =>
        moment
          .tz(startDate, timezone)
          .add(tick, 'month')
          .format('MMM')
          .toUpperCase(),
      maxValue: monthDifference,
    }
  }
  const yearDifference = calculateYearDifference(startDate, endDate)
  /* Range of years */
  return {
    tickValues: range(yearDifference ?? 0),
    tickFormat: (tick) => moment(startDate).add(tick, 'year').format('YYYY'),
    maxValue: yearDifference,
  }
}

type GraphAxisDataType = {
  startDate: Date | number | string
  endDate: Date | number | string
  isPower?: boolean
  isLegacyPvs?: boolean
  customDaysDifference?: number | undefined
  timezone?: string
  isOverLimitDaysInPast?: boolean
}

export const graphAxisData = (data: GraphAxisDataType) => {
  const {
    startDate,
    endDate,
    isPower,
    isLegacyPvs,
    customDaysDifference,
    timezone = '',
    isOverLimitDaysInPast,
  } = data

  const livePowerMinuteChunksInADay = getMinuteChunksInADay(isLegacyPvs)
  const livePowerMinuteChunksInAHour = getMinuteChunksInAHour(isLegacyPvs)
  const powerTicks = isLegacyPvs ? powerDayTicks : powerNonLegacyDayTicks

  return {
    [AGGREGATION_PERIOD.Today]: {
      tickValues: range(
        (isPower && !isOverLimitDaysInPast
          ? livePowerMinuteChunksInADay
          : HOURS_IN_A_DAY) + 1,
      ).filter(
        (index) =>
          (isPower && !isOverLimitDaysInPast
            ? index / livePowerMinuteChunksInAHour
            : index) %
            3 ===
          0,
      ),
      tickFormat:
        isPower && !isOverLimitDaysInPast ? powerTicks : singleDayTicks,
      maxValue:
        isPower && !isOverLimitDaysInPast
          ? livePowerMinuteChunksInADay
          : HOURS_IN_A_DAY,
    },
    [AGGREGATION_PERIOD.ThisWeek]: {
      tickValues: [0, 1, 2, 3, 4, 5, 6],
      tickFormat: (tick, timezone) =>
        `${moment.tz(startDate, timezone).add(tick, 'day').format('M/DD')}`,
      maxValue: 7,
    },
    [AGGREGATION_PERIOD.ThisMonth]: {
      tickValues: [0, 6, 13, 20, 27],
      tickFormat: (tick, timezone) =>
        `${moment.tz(startDate, timezone).format('M')}/${tick + 1}`,
      maxValue: moment(startDate).daysInMonth(),
    },
    [AGGREGATION_PERIOD.ThisYear]: {
      tickValues: [0, 2, 4, 6, 8, 10],
      tickFormat: (tick, timezone) => {
        const val = moment()
          .tz(timezone)
          .set('month', tick)
          .format('MMM')
          .toUpperCase()
        return val
      },
      maxValue: MONTHS_IN_A_YEAR,
    },
    [AGGREGATION_PERIOD.Lifetime]: {
      tickValues: range(calculateYearDifference(startDate, endDate, timezone)),
      tickFormat: (tick, _) =>
        moment.tz(startDate, timezone).add(tick, 'year').format('YYYY'),
      maxValue: calculateYearDifference(startDate, endDate, timezone),
    },
    [AGGREGATION_PERIOD.Custom]: {
      ...customPeriodGraphAxisData(
        startDate,
        endDate,
        isPower,
        isLegacyPvs,
        customDaysDifference,
        timezone,
      ),
    },
  }
}

export const SOURCE_OFFSETS = {
  [ENERGY_SOURCE.home]: 1,
  [ENERGY_SOURCE.solar]: 0,
  [ENERGY_SOURCE.grid]: 1,
  [ENERGY_SOURCE.storage]: 2,
}

export const calcYTickValues = (siteType, graphData) => {
  const maxEnergy = Object.keys(graphData).reduce((max, currentSource) => {
    const currentSourceMaxEnergy = maxBy(
      graphData[currentSource]?.dataPoints,
      (data) => (data?.energy != null ? Math.abs(data.energy) : null),
    )?.energy
    const absCurrentSourceMaxEnergy =
      currentSourceMaxEnergy != null ? Math.abs(currentSourceMaxEnergy) : null
    return absCurrentSourceMaxEnergy == null || absCurrentSourceMaxEnergy <= max
      ? max
      : absCurrentSourceMaxEnergy
  }, Number.NEGATIVE_INFINITY)

  if (maxEnergy === Number.NEGATIVE_INFINITY) {
    return [0]
  }

  const maxRange = Math.round(mapMaxEnergyToTickValue(maxEnergy) * 10) / 10
  const midRange = Math.round((maxRange / 2) * 10) / 10

  return siteType === 'production'
    ? [0, midRange, maxRange]
    : [-maxRange, -midRange, 0, midRange, maxRange]
}
