import './style.scss'

import React from 'react'

import iconClose from '../../../../components/menu/assets/icon-close.svg'
import { SliderCard } from '../../../../components/slider-card'
import { VPPDevice } from '../../../../shared/typings/site'
import paths from '../../../Router/paths'
import BatteryLevel from './assets/batteryLevel'

interface SettingsSliderProps {
  vppDeviceData: VPPDevice | null
  title: string
  vppProgramName?: string
  handleClose: () => void
}
const BODY_TEXT =
  'Your battery will default to this reserve level for each discharge event. SunVault will not discharge below this level during discharge events.'
export const SettingsSlider = ({
  title,
  handleClose,
  vppDeviceData,
}: SettingsSliderProps) => {
  const batteryCharge = vppDeviceData ? vppDeviceData?.defaultReserveLevel : 0.2

  const batteryRounded = Math.round(batteryCharge * 100)

  return (
    <div className="slider-container">
      <aside className="slider">
        <div className="toolbar">
          <div className="title">{title}</div>
          <button
            className="closeSideNav"
            aria-label="close"
            onClick={handleClose}
          >
            <img src={iconClose} alt="Close sidebar" />
          </button>
        </div>
        <section className="divider-container">
          <div className="image-container">
            <BatteryLevel level={batteryCharge} />
          </div>
          <div className="divider" />
          <h4>We recommend a 20% reserve level:</h4>
        </section>
        <div className="slider-cards-container">
          <SliderCard
            title={'Battery default reserve level'}
            value={`${batteryRounded}%`}
            body={BODY_TEXT}
            buttonLinks={{
              onEditClick: paths.RESERVE_DYNAMIC_VPP,
            }}
          />
        </div>
      </aside>
      <div onClick={handleClose} className="backdrop" />
    </div>
  )
}
