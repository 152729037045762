import './style.scss'

import React, { useEffect, useState } from 'react'

import { Loader } from '../../../../components/loader'
import { useDynamicVpp } from '../../../../shared/hooks/useDynamicVpp'
import { PerformanceMetricBySeason } from '../../../../shared/typings/site'
import { PastEventsSlider } from '../past-events-slider'
import arrowPastEvents from './assets/arrow-past-events.svg'
import powerAndEarnings from './assets/power-and-earnings.svg'
import powerWithoutEarnings from './assets/power-without-earnings.svg'

export const SeasonOverview = () => {
  const {
    performanceMetricBySeason,
    loadingPerformanceMetricData,
    fetchPerformanceMetric,
    performanceBySeason,
    loadingPerformanceBySeasonData,
    fetchPerformanceBySeason,
  } = useDynamicVpp()

  useEffect(() => {
    if (
      !performanceMetricBySeason ||
      (performanceMetricBySeason && performanceMetricBySeason.length === 0)
    ) {
      fetchPerformanceMetric()
    }
  }, [performanceMetricBySeason])

  useEffect(() => {
    if (
      !performanceBySeason ||
      (performanceBySeason && !performanceBySeason.length)
    ) {
      fetchPerformanceBySeason()
    }
  }, [performanceBySeason])

  const powerDischarged = performanceMetricBySeason?.filter(
    (item) => item.metric_type === 'avg_performance_kw',
  )

  const earnings = performanceMetricBySeason?.filter(
    (item) => item.metric_type === 'estimated_payment',
  )

  const metricLabel = (metric: PerformanceMetricBySeason) => {
    return (
      <div>
        <div>{metric.metric_title}</div>
        <div className="value-label">{metric.metric_value_formatted}</div>
      </div>
    )
  }

  //@ts-ignore
  const { totalEvents, eventsParticipated } = performanceBySeason

  const [openSettingsSlider, setOpenSettingsSlider] = useState<boolean>(false)

  const handleOpenSettingsSlider = () => {
    if (performanceBySeason) {
      setOpenSettingsSlider((prevState) => !prevState)
    }
  }

  return (
    <div className="season-overview">
      {loadingPerformanceMetricData && <Loader />}
      {!loadingPerformanceMetricData && (
        <div className="event-state card no-padding">
          <div className="event-state-header">Season Overview</div>
          <div className="performance-metrics-data">
            <div className="state-content">
              {powerDischarged &&
                powerDischarged.length > 0 &&
                (!earnings || earnings.length === 0) && (
                  <>
                    <div className="power-without-earnings-label">
                      {metricLabel(powerDischarged[0])}
                    </div>
                    <img
                      className="power-without-earnings-img"
                      src={powerWithoutEarnings}
                      alt="Sunpower Vault average power discharged"
                    />
                  </>
                )}
              {earnings &&
                earnings.length > 0 &&
                powerDischarged &&
                powerDischarged.length > 0 && (
                  <>
                    <div className="power-discharge-label">
                      {metricLabel(powerDischarged[0])}
                    </div>
                    <img
                      className="power-earnings-img"
                      src={powerAndEarnings}
                      alt="Sunpower Vault earnings and power discharged"
                    />
                    <div className="earnings-label">
                      {metricLabel(earnings[0])}
                    </div>
                  </>
                )}
              <br />
            </div>
          </div>
          <div className="performance-by-season-data">
            {loadingPerformanceBySeasonData && <Loader />}
            {!loadingPerformanceBySeasonData && (
              <div className="state-content">
                Events Participated
                <div className="events-by-season-count">
                  {eventsParticipated + ' of ' + totalEvents}
                </div>
              </div>
            )}
          </div>
          <div className="event-state-footer">
            Past events
            <div className="right-side-wrapper">
              <div className="total-events">{totalEvents}</div>
              <div
                className="arrow-past-events"
                onClick={() => handleOpenSettingsSlider()}
              >
                <img src={arrowPastEvents} alt="arrow pointing to the right" />
              </div>
            </div>
          </div>
        </div>
      )}
      {openSettingsSlider && (
        <PastEventsSlider
          pastEvents={performanceBySeason}
          handleClose={handleOpenSettingsSlider}
          title="Earnings and rewards"
        />
      )}
    </div>
  )
}
