import '../styles.scss'

import React from 'react'

import rejectedPVS from '../../assets/rejected_pvs.png'

export const RejectedApplication = ({
  enrollmentStateReason = '',
  canResubmit,
  onClickSubmitApp,
}: {
  enrollmentStateReason?: string
  canResubmit?: boolean
  onClickSubmitApp: () => void
}) => {
  return (
    <div className="enroll-top-section">
      <div className="enroll-content-card">
        <div className="rejected-container">
          <img
            src={rejectedPVS}
            alt="rejected PVS :("
            className="rejected-pvs"
          />
          <div className="rejected-title">Application Rejected</div>
          <div className="rejected-body">
            Your application was rejected for the following reasons:
          </div>
          <div className="rejected-body">{`\u2022 ${enrollmentStateReason}`}</div>
          {canResubmit && (
            <button className="resubmit-button" onClick={onClickSubmitApp}>
              Edit Application
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
