export default {
  v: '5.7.4',
  fr: 29.9700012207031,
  ip: 0,
  op: 59.0000024031193,
  w: 375,
  h: 812,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Battery Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [187.5, 406, 0], ix: 2, l: 2 },
        a: { a: 0, k: [93.5, 161, 0], ix: 1, l: 2 },
        s: { a: 0, k: [142.781, 142.781, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0.703],
                    [0.893, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.918, 0],
                    [0, -0.658],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.114, -0.263],
                    [-0.805, -0.263],
                    [0.572, -1.317],
                    [-0.767, -2.305],
                    [-1.114, -2.305],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.428, -0.091],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.379, -0.319],
                    [0, -0.584],
                    [0.263, -0.328],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.849, 0],
                    [0.415, 0.355],
                    [0, 0.456],
                    [-0.26, 0.328],
                  ],
                  v: [
                    [0.093, 0.475],
                    [2.085, 3.215],
                    [0.899, 3.215],
                    [-0.94, 0.582],
                    [-1.114, 0.582],
                    [-1.114, 3.215],
                    [-2.085, 3.215],
                    [-2.085, -3.215],
                    [-0.948, -3.215],
                    [0.895, -2.737],
                    [1.52, -1.329],
                    [1.126, -0.155],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.466666696586, 0.466666696586, 0.466666696586, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [118.056, 40.217], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.775, -2.304],
                    [-0.803, -2.304],
                    [-0.803, -0.758],
                    [1.7, -0.758],
                    [1.7, 0.155],
                    [-0.803, 0.155],
                    [-0.803, 2.303],
                    [1.775, 2.303],
                    [1.775, 3.216],
                    [-1.775, 3.216],
                    [-1.775, -3.216],
                    [1.775, -3.216],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.466666696586, 0.466666696586, 0.466666696586, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [111.685, 40.217], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-3.438, -3.266],
                    [-1.765, 1.032],
                    [-0.015, -3.578],
                    [1.661, 1.032],
                    [3.439, -3.266],
                    [4.492, -3.266],
                    [1.618, 3.574],
                    [-0.035, -0.984],
                    [-1.761, 3.578],
                    [-4.493, -3.266],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.466666696586, 0.466666696586, 0.466666696586, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [103.381, 40.267], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.678],
                    [-0.476, -0.458],
                    [-0.623, 0],
                    [-0.464, 0.466],
                    [0, 0.677],
                    [0.459, 0.463],
                    [0.671, 0],
                    [0.461, -0.467],
                  ],
                  o: [
                    [0, 0.711],
                    [0.475, 0.457],
                    [0.673, 0],
                    [0.463, -0.472],
                    [0, -0.687],
                    [-0.455, -0.467],
                    [-0.669, 0],
                    [-0.461, 0.461],
                  ],
                  v: [
                    [-2.383, -0.014],
                    [-1.667, 1.74],
                    [-0.02, 2.427],
                    [1.687, 1.727],
                    [2.383, 0.003],
                    [1.695, -1.722],
                    [0.005, -2.422],
                    [-1.691, -1.722],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 1.03],
                    [-0.665, 0.649],
                    [-0.927, 0],
                    [-0.655, -0.656],
                    [0, -0.918],
                    [0.66, -0.644],
                    [0.938, 0],
                    [0.662, 0.575],
                  ],
                  o: [
                    [0, -0.905],
                    [0.66, -0.65],
                    [0.918, 0],
                    [0.658, 0.655],
                    [0, 0.925],
                    [-0.664, 0.648],
                    [-0.83, 0],
                    [-0.727, -0.635],
                  ],
                  v: [
                    [-3.362, -0.027],
                    [-2.366, -2.359],
                    [0.016, -3.335],
                    [2.374, -2.351],
                    [3.361, 0.01],
                    [2.37, 2.364],
                    [-0.033, 3.335],
                    [-2.271, 2.472],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.466666696586, 0.466666696586, 0.466666696586, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [94.083, 40.214], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0.686],
                    [0.92, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.892, 0],
                    [0, -0.664],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.833, -0.296],
                    [-0.471, -0.296],
                    [0.866, -1.325],
                    [-0.513, -2.321],
                    [-0.833, -2.321],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.274, -0.075],
                    [-0.211, -0.208],
                    [0, -0.55],
                    [0.393, -0.344],
                    [0.669, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.541, 0],
                    [0.278, 0.075],
                    [0.369, 0.361],
                    [0, 0.588],
                    [-0.395, 0.344],
                    [0, 0],
                  ],
                  v: [
                    [-0.833, 0.604],
                    [-0.833, 3.215],
                    [-1.804, 3.215],
                    [-1.804, -3.215],
                    [-0.705, -3.215],
                    [0.516, -3.102],
                    [1.25, -2.679],
                    [1.804, -1.312],
                    [1.213, 0.088],
                    [-0.383, 0.604],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.466666696586, 0.466666696586, 0.466666696586, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [87.002, 40.216], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.831, 3.239],
                    [-2.831, -3.631],
                    [1.859, 1.277],
                    [1.859, -3.192],
                    [2.831, -3.192],
                    [2.831, 3.631],
                    [-1.86, -1.263],
                    [-1.86, 3.239],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.466666696586, 0.466666696586, 0.466666696586, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [79.589, 40.193], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-0.181, -0.256],
                    [-0.49, 0],
                    [-0.269, 0.369],
                    [0, 0.562],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.422, -0.439],
                    [0.691, 0],
                    [0.473, 0.488],
                    [0, 0.677],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0.553],
                    [0.27, 0.369],
                    [0.492, 0],
                    [0.181, -0.247],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.677],
                    [-0.476, 0.488],
                    [-0.691, 0],
                    [-0.421, -0.439],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.41, -3.274],
                    [-1.41, 0.599],
                    [-1.139, 1.812],
                    [-0.002, 2.366],
                    [1.139, 1.812],
                    [1.411, 0.599],
                    [1.411, -3.274],
                    [2.38, -3.274],
                    [2.38, 0.867],
                    [1.749, 2.541],
                    [-0.002, 3.274],
                    [-1.748, 2.541],
                    [-2.381, 0.867],
                    [-2.381, -3.274],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.466666696586, 0.466666696586, 0.466666696586, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [71.599, 40.275], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.417, -0.722],
                    [0, 0],
                    [0.134, 0.077],
                    [0.219, 0],
                    [0.179, -0.154],
                    [0, -0.227],
                    [-0.466, -0.192],
                    [0, 0],
                    [-0.241, -0.304],
                    [0, -0.442],
                    [0.395, -0.386],
                    [0.59, 0],
                    [0.364, 0.331],
                    [0.088, 0.6],
                    [0, 0],
                    [-0.111, -0.143],
                    [-0.382, 0],
                    [-0.201, 0.203],
                    [0, 0.312],
                    [0.035, 0.104],
                    [0.073, 0.087],
                    [0.117, 0.077],
                    [0.161, 0.069],
                    [0, 0],
                    [0, 0.717],
                    [-0.369, 0.325],
                    [-0.55, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.148, -0.256],
                    [-0.137, -0.089],
                    [-0.269, 0],
                    [-0.176, 0.15],
                    [0, 0.313],
                    [0, 0],
                    [0.523, 0.211],
                    [0.242, 0.305],
                    [0, 0.592],
                    [-0.397, 0.389],
                    [-0.558, 0],
                    [-0.36, -0.33],
                    [0, 0],
                    [0.044, 0.377],
                    [0.201, 0.278],
                    [0.302, 0],
                    [0.2, -0.202],
                    [0, -0.124],
                    [-0.035, -0.105],
                    [-0.075, -0.087],
                    [-0.117, -0.076],
                    [0, 0],
                    [-0.88, -0.371],
                    [0, -0.482],
                    [0.368, -0.327],
                    [0.742, 0],
                  ],
                  v: [
                    [1.896, -2.254],
                    [1.107, -1.787],
                    [0.686, -2.287],
                    [0.15, -2.421],
                    [-0.522, -2.191],
                    [-0.788, -1.625],
                    [-0.089, -0.867],
                    [0.553, -0.604],
                    [1.699, 0.168],
                    [2.061, 1.287],
                    [1.469, 2.753],
                    [-0.01, 3.337],
                    [-1.391, 2.84],
                    [-2.062, 1.445],
                    [-1.079, 1.229],
                    [-0.846, 2.011],
                    [0.029, 2.428],
                    [0.783, 2.124],
                    [1.083, 1.353],
                    [1.031, 1.01],
                    [0.87, 0.722],
                    [0.583, 0.476],
                    [0.167, 0.258],
                    [-0.456, -0.001],
                    [-1.775, -1.634],
                    [-1.221, -2.846],
                    [0.158, -3.337],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.466666696586, 0.466666696586, 0.466666696586, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [64.75, 40.213], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.617, 0],
                    [0, 0],
                    [0, 0.618],
                    [0, 0],
                    [-0.617, 0],
                    [0, 0],
                    [0, -0.617],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.617, 0],
                    [0, 0],
                    [0, -0.617],
                    [0, 0],
                    [0.617, 0],
                    [0, 0],
                    [0, 0.618],
                  ],
                  v: [
                    [7.927, 13.915],
                    [-7.928, 13.915],
                    [-9.044, 12.797],
                    [-9.044, -12.797],
                    [-7.928, -13.915],
                    [7.927, -13.915],
                    [9.045, -12.797],
                    [9.045, 12.797],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.749019607843, 0.749019607843, 0.749019607843, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.905, 92.991], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.314, 0],
                    [0, 0],
                    [0, 3.314],
                    [0, 0],
                    [-3.314, 0],
                    [0, 0],
                    [0, -3.314],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.314, 0],
                    [0, 0],
                    [0, -3.314],
                    [0, 0],
                    [3.314, 0],
                    [0, 0],
                    [0, 3.314],
                  ],
                  v: [
                    [56.978, 137.384],
                    [-56.978, 137.384],
                    [-62.978, 131.384],
                    [-62.978, -131.384],
                    [-56.978, -137.384],
                    [56.978, -137.384],
                    [62.978, -131.384],
                    [62.978, 131.384],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.749019607843, 0.749019607843, 0.749019607843, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.905, 157.038], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.315, 0],
                    [0, 0],
                    [0, -3.315],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, -3.315],
                    [0, 0],
                    [3.314, 0],
                    [0, 0],
                  ],
                  v: [
                    [62.978, 20.888],
                    [-62.978, 20.888],
                    [-62.978, -14.887],
                    [-56.977, -20.888],
                    [56.977, -20.888],
                    [62.978, -14.887],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870588295133, 0.878431432387, 0.898039275525, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.905, 40.542], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.314, 0],
                    [0, 0],
                    [0, 3.314],
                    [0, 0],
                    [-3.314, 0],
                    [0, 0],
                    [0, -3.314],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.314, 0],
                    [0, 0],
                    [0, -3.314],
                    [0, 0],
                    [3.314, 0],
                    [0, 0],
                    [0, 3.314],
                  ],
                  v: [
                    [56.978, 137.384],
                    [-56.978, 137.384],
                    [-62.978, 131.384],
                    [-62.978, -131.384],
                    [-56.978, -137.384],
                    [56.978, -137.384],
                    [62.978, -131.384],
                    [62.978, 131.384],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.749019607843, 0.749019607843, 0.749019607843, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [91.905, 157.038], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 59.0000024031193,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [100],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 30,
              s: [20],
            },
            { t: 59.0000024031193, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [187.5, 406, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [240.99, 240.99], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 497, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478431372549, 0.662745098039, 0.349019607843, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-3.56, 10.085], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 59.0000024031193,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
