export const enum ORIENTATION_TYPES {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}
const LANDSCAPE_ORIENTATION_OFFSET = 90

const VERTICAL_RIGHT_START_LIMIT = 45
const VERTICAL_RIGHT_END_LIMIT = 135

const VERTICAL_LEFT_START_LIMIT = 225
const VERTICAL_LEFT_END_LIMIT = 315

export const computePanelRotation = (
  rotation: number,
  azimuth: number | null,
  orientation: string,
) => {
  if (azimuth !== null) {
    return orientation === ORIENTATION_TYPES.LANDSCAPE
      ? azimuth + LANDSCAPE_ORIENTATION_OFFSET
      : azimuth
  }
  return rotation
}

export const computePanelTextRotation = (rotation: number) => {
  if (
    rotation > VERTICAL_RIGHT_START_LIMIT &&
    rotation < VERTICAL_RIGHT_END_LIMIT
  ) {
    return 270
  }

  if (
    rotation > VERTICAL_LEFT_START_LIMIT &&
    rotation < VERTICAL_LEFT_END_LIMIT
  ) {
    return 90
  }

  if (
    rotation > VERTICAL_RIGHT_END_LIMIT &&
    rotation < VERTICAL_LEFT_START_LIMIT
  ) {
    return 180
  }

  return 0
}
