import React from 'react'

import arrowIcon from './assets/arrow.svg'
import { SeasonType } from './flat-timeline'
import { dateParser } from './timeline-calcs'

interface TimelineRangeProps {
  season?: SeasonType
  isVppProgram?: boolean
}

export const TimelineRange = ({ season, isVppProgram }: TimelineRangeProps) => {
  return (
    <div className="range-container">
      {isVppProgram ? (
        <div className="season-container">
          <div>{`Season Start:`}</div>
          <div>{dateParser(season?.startDate)}</div>
        </div>
      ) : (
        <div>{`Start: ${dateParser(season?.startDate)}`}</div>
      )}
      <img src={arrowIcon} alt="arrow icon" className="arrow-icon" />
      {isVppProgram ? (
        <div className="season-container">
          <div>{`Season End:`}</div>
          <div>{dateParser(season?.endDate)}</div>
        </div>
      ) : (
        <div>{`End: ${dateParser(season?.endDate)}`}</div>
      )}
    </div>
  )
}
