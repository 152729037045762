import './style.scss'

// import bulmaCollapsible from '@creativebulma/bulma-collapsible'
import React, { useEffect, useRef } from 'react'

import chevronDown from '../../components/header/assets/chevron-down.svg'
import { questionsAndAnswers } from './questions-and-answers'

export const AccordionFAQs = () => {
  const collapsibles = useRef(null)
  // useEffect(() => {
  //   collapsibles.current = bulmaCollapsible.attach('.is-collapsible', {
  //     container: collapsibles.current,
  //   })
  // }, [])
  const faqMarkupList = questionsAndAnswers.map((faqitem, index) => {
    return (
      <article key={`faq-key-${index}`} className="message">
        <a
          className="message-header"
          href={`#collapsible-message-accordion-${index}`}
          data-action="collapse"
        >
          <span className="question-text">{faqitem.question}</span>
          <img
            src={chevronDown}
            alt={`faq-answer-${index + 1}`}
            className="ml-2 question-chev"
          />
        </a>
        <div
          id={`collapsible-message-accordion-${index}`}
          className="message-body is-collapsible"
          data-parent="accordion_first"
        >
          <div className="message-body-content">{faqitem.answer}</div>
        </div>
      </article>
    )
  })
  const accordionContainerMarkup = (
    <div ref={collapsibles} id="accordion_first">
      {faqMarkupList}
    </div>
  )

  return accordionContainerMarkup
}
