export const colors = {
  transparent: 'transparent',
  panelGreen: '#89B76A',
  panelOrange: '#F29F05',
  panelRed: '#CA0E0E',
  panelGray: '#9F9F9F',
}

export const detailColors = {
  transparent: 'transparent',
  panelGreen: '#84B36380',
  panelOrange: '#F7921E80',
  panelRed: '#F8484980',
  panelGray: '#E0E2E380',
}

export enum PANEL_ALERTS_ID {
  ID = 'SystemDownInverter',
  ICE = 'CommunicationOutageInverterICE',
  PANEL_TEMP_ZERO_PROD = 'mySunPowerPanelTempZeroProduction',
  PANEL_TEMP_NO_COMMS = 'mySunPowerPanelTempNoCommunication',
}

export const getPanelStatus = (panel) => {
  const alerts = panel?.alerts
  const alert = alerts?.find(
    (anAlert) =>
      Object.keys(PANEL_ALERTS_ID)
        .map((key) => PANEL_ALERTS_ID[key])
        .indexOf(anAlert.alertType) !== -1,
  )

  return alert || null
}

export const getPanelStatusColor = (
  panel,
  DCE_ICE_ALERT_FLAG,
  ALERT_DEVICE_DOWN_FLAG,
) => {
  const alert = getPanelStatus(panel)
  if (alert?.alertType === PANEL_ALERTS_ID.ID && ALERT_DEVICE_DOWN_FLAG) {
    return colors.panelRed
  } else if (alert?.alertType === PANEL_ALERTS_ID.ICE && DCE_ICE_ALERT_FLAG) {
    return colors.panelOrange
  } else if (
    alert?.alertType === PANEL_ALERTS_ID.PANEL_TEMP_NO_COMMS ||
    alert?.alertType === PANEL_ALERTS_ID.PANEL_TEMP_ZERO_PROD
  ) {
    return colors.panelGray
  }

  return colors.panelGreen
}

export const getPanelLayoutBorderColor = (
  panel,
  DCE_ICE_ALERT_FLAG,
  ALERT_DEVICE_DOWN_FLAG,
) => {
  const alert = getPanelStatus(panel)
  if (alert?.alertType === PANEL_ALERTS_ID.ID && ALERT_DEVICE_DOWN_FLAG) {
    return colors.panelRed
  } else if (alert?.alertType === PANEL_ALERTS_ID.ICE && DCE_ICE_ALERT_FLAG) {
    return colors.panelOrange
  } else if (
    alert?.alertType === PANEL_ALERTS_ID.PANEL_TEMP_NO_COMMS ||
    alert?.alertType === PANEL_ALERTS_ID.PANEL_TEMP_ZERO_PROD
  ) {
    return colors.panelGray
  }

  return colors.transparent
}

export const getPanelDetailColor = (
  panel,
  DCE_ICE_ALERT_FLAG,
  ALERT_DEVICE_DOWN_FLAG,
) => {
  const alert = getPanelStatus(panel)
  if (alert?.alertType === PANEL_ALERTS_ID.ID && ALERT_DEVICE_DOWN_FLAG) {
    return detailColors.panelRed
  } else if (alert?.alertType === PANEL_ALERTS_ID.ICE && DCE_ICE_ALERT_FLAG) {
    return detailColors.panelOrange
  } else if (
    alert?.alertType === PANEL_ALERTS_ID.PANEL_TEMP_NO_COMMS ||
    alert?.alertType === PANEL_ALERTS_ID.PANEL_TEMP_ZERO_PROD
  ) {
    return detailColors.panelGray
  }

  return detailColors.panelGreen
}
