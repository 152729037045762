import { gql } from '@apollo/client'

export const EXCHANGE_MUTATION = gql`
  mutation postThirdPartyToken(
    $authorizationCode: String!
    $enrollmentId: String!
    $redirectUri: String!
  ) {
    postThirdPartyToken(
      authorizationCode: $authorizationCode
      enrollmentId: $enrollmentId
      redirectUri: $redirectUri
    ) {
      accessToken
      details
    }
  }
`
