import React, { useCallback, useMemo } from 'react'

import { WeatherConditions } from '../../../pages/PanelsPage/panel-detail'
import { getWeatherConditions } from '../../../pages/PanelsPage/panel-detail/recharts-graphs/weatherConditions'
import { MAX_INDEX_TIMELAPSE_GRAPH } from '../../../shared/sharedConstants'
import { Weather } from '../../weather'
import squareIcon from './assets/square.svg'
import triangleIcon from './assets/traingle.svg'
import { TimelapsePowerChart } from './timelapse-chart'
import { prepareDataForTimelapse, singleDayTicks } from './timelapseCalc'
import { HourlyPowerProduction } from '..'

export const renderCustomAxisTick = (tickFormat, weatherDecorator) => ({
  x,
  y,
  payload,
}) => {
  if (!x || !y) return null
  const { value } = payload

  return (
    <svg x={x - 20} y={y - 5} height={55} width={50}>
      {weatherDecorator(value, { y: -6, x: 0 })}
      <text fill="#404040" x={0} y={9} fontSize="10">
        {tickFormat(value)}
      </text>
    </svg>
  )
}

type TimelapseGraphProps = {
  weatherConditions: WeatherConditions
  hourlyPowerProduction: HourlyPowerProduction[] | null
  activeIndex: number | null
  setActiveIndex: (index: number) => void
  onPlayTimelapse: () => void
  isTimelapseRunning: boolean
}

export const TimelapseContainer = ({
  activeIndex,
  weatherConditions,
  hourlyPowerProduction,
  isTimelapseRunning,
  setActiveIndex,
  onPlayTimelapse,
}: TimelapseGraphProps) => {
  const handleOnMouseMove = (state: any) => {
    if (state) setActiveIndex(state.activeTooltipIndex)
  }

  const timeLapseData = useMemo(() => {
    const data = prepareDataForTimelapse(25, hourlyPowerProduction)
    return data
  }, [hourlyPowerProduction])

  if (
    timeLapseData &&
    timeLapseData.length === 25 &&
    !timeLapseData[MAX_INDEX_TIMELAPSE_GRAPH].originalTimestamp
  ) {
    timeLapseData[MAX_INDEX_TIMELAPSE_GRAPH] = timeLapseData[0]
  }

  const weatherDecorator = useCallback(
    (value, viewbox) => {
      const { y = 0, x = 0 } = viewbox
      return (
        <Weather
          condition={getWeatherConditions(weatherConditions, value)}
          customX={x}
          customY={y}
        />
      )
    },
    [weatherConditions],
  )

  return (
    <div className="timelapse-graph-container">
      <div className="play-button" onClick={onPlayTimelapse}>
        <img
          src={isTimelapseRunning ? squareIcon : triangleIcon}
          alt="play/pause button"
        />
      </div>
      <div className="graph-container">
        <div className="graph-background" />
        <TimelapsePowerChart
          activeIndex={activeIndex}
          data={timeLapseData}
          axisRender={renderCustomAxisTick}
          tickFormat={singleDayTicks}
          weatherDecorator={weatherDecorator}
          onMouseMove={handleOnMouseMove}
        />
      </div>
    </div>
  )
}
