import './style.scss'

import clsx from 'clsx'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useMemo, useState } from 'react'

import { Loader } from '../loader'
import { SignalIndicator } from '../signal-indicator'
import checkMark from './assets/checkmark.svg'
import data from './fixtures/wifi-apns.json'

export type WifiNetwork = {
  bssid: string
  chnVal: string
  rssiDbm: number
  secCapblStr: string
  ssid: string
  level?: number
}

const calcLevel = (dbm: number) => {
  if (dbm >= -67) {
    return 5
  } else if (dbm >= -72) {
    return 4
  } else if (dbm >= -77) {
    return 3
  } else if (dbm >= -82) {
    return 2
  } else if (dbm >= -87) {
    return 1
  } else {
    return 0
  }
}

const normalizeSsid = (ssid) => {
  return ssid.replace(/'/, '_')
}

export const NetworksList = ({
  networks = [],
  currentNetwork = '',
  isLoading = false,
  onSelect = (network: WifiNetwork) => {},
}: {
  networks: any[]
  currentNetwork?: string
  isLoading?: boolean
  onSelect?: (network: WifiNetwork) => void
}) => {
  const [selectedNetwork, setSelectedNetwork] = useState('')

  networks = useMemo(
    () =>
      uniqBy(
        (networks as any)
          .map((network: WifiNetwork) => {
            return {
              ...network,
              level: calcLevel(network.rssiDbm),
            }
          })
          .sort((a, b) => b.level - a.level),
        'ssid',
      ),
    [networks],
  )

  const onNetworkClick = useCallback(
    (network: WifiNetwork) => {
      setSelectedNetwork(network.ssid)
      onSelect(network)
    },
    [setSelectedNetwork, onSelect],
  )

  const renderedNetworks = networks.map((network: WifiNetwork) => {
    const level = calcLevel(network.rssiDbm)
    return (
      <li
        className={clsx('py-2 px-2 is-size-5', {
          selected: selectedNetwork === network.ssid,
        })}
        key={network.bssid}
        onClick={() => onNetworkClick(network)}
      >
        <div className="level">
          <div className="level-left">
            {currentNetwork === normalizeSsid(network.ssid) ? (
              <div className="current-network">
                <img src={checkMark} alt="Current network" />
              </div>
            ) : null}
            {network.ssid}
          </div>
          <div className="level-right px-3 py-3">
            <SignalIndicator level={level} />
          </div>
        </div>
      </li>
    )
  })

  return (
    <div className="networks-list">
      {isLoading ? <Loader /> : <ul className="my-3">{renderedNetworks}</ul>}
    </div>
  )
}
