import qs from 'query-string'
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'

import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_CUSTOMER_CHECK,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { useRemoveCookies } from '../../hooks/useRemoveCookies'
import { decodeJwtToken } from '../../shared/decodeToken'
import paths from '../Router/paths'

export const COOKIES_OPTIONS = { path: '/monitor' }

export const KioskCallback = ({ location, history }) => {
  const params = qs.parse(location.hash)
  const [, setCookie, removeCookie] = useCookies([
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])
  const removeCookies = useRemoveCookies(COOKIES_OPTIONS)

  useEffect(() => {
    removeCookies()

    // Removing any existing customer check cookie as it's not supported in Kiosk mode
    removeCookie(MY_SUNPOWER_CUSTOMER_CHECK, { path: '/' })

    if (params.accessToken && params.partyId && params.siteId) {
      const decodedToken = decodeJwtToken(params.accessToken.toString())
      const { partyId, siteId } = params
      const { email } = decodedToken

      const userSessionInfo = {
        email,
        partyId,
        isVendor: true,
      }
      setCookie(
        MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
        params.accessToken,
        COOKIES_OPTIONS,
      )
      setCookie(USER_SESSION_INFO, userSessionInfo, COOKIES_OPTIONS)
      setCookie(USER_SELECTED_SITE_KEY, siteId, COOKIES_OPTIONS)
      setCookie(LOGIN_OVERRIDE, partyId, COOKIES_OPTIONS)
      history.replace(paths.ROOT)
      window.location.reload()
    }
  }, [params, setCookie, removeCookies, removeCookie, history])

  return null
}
