import './style.scss'

import clsx from 'clsx'
import { get } from 'lodash'
import React, { useContext, useMemo } from 'react'
import { useCookies } from 'react-cookie'

import { isMilestoneSwitchRequired } from '../../shared/utility'
import { UserContext } from './../../context/userContext'
import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { usePartyData } from '../../shared/hooks'
import { Banner } from '../banner'
import { Footer } from '../footer'
import { Header } from '../header'
import SwitchMilestone from '../switchMilestone/SwitchMilestone'

type LayoutProps = {
  children: JSX.Element
  sitesList?: any[]
  siteKey?: string | null
  contentFooter?: JSX.Element
  title?: string | undefined
  containerClassName?: string
  sectionClassName?: string
  headerOptions?: {
    children?: {
      left?: JSX.Element | null
      right?: JSX.Element | null
      middle?: JSX.Element | null
    }
    hiddenLogo?: boolean
    hiddenMenu?: boolean
  }
}

export const Layout = ({
  children,
  sitesList = [],
  siteKey = null,
  title = undefined,
  contentFooter,
  containerClassName = '',
  sectionClassName = '',
  headerOptions,
}: LayoutProps) => {
  const userContext = useContext(UserContext)
  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  ])

  const switchMilestone = isMilestoneSwitchRequired(
    userContext?.user?.data?.user,
    userContext?.user?.data?.local,
  )

  const session = cookies[USER_SESSION_INFO] ?? {}
  const loginOverride = cookies[LOGIN_OVERRIDE]

  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]

  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )
  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )
  const currentSiteKey = useMemo(
    () => (isKiosk ? selectedSiteKey : null),
    [isKiosk, selectedSiteKey],
  )
  const { data } = usePartyData(currentPartyId, currentSiteKey)

  const hasAcceptedLicense = get(
    data,
    'party.userConfigs[0].panelDataLicenseAccepted',
    false,
  )

  const panelDataLicenseAccepted =
    session?.isAdmin || isKiosk || hasAcceptedLicense

  return (
    <React.Fragment>
      {switchMilestone && (
        <SwitchMilestone />
      )}
      <Header
        className={clsx({
          'has-header': switchMilestone,
        })}
        sitesList={sitesList}
        siteKey={siteKey}
        title={title}
        panelDataLicenseAccepted={panelDataLicenseAccepted}
        options={headerOptions}
      />
      <Banner
        className={clsx({
          'has-header': switchMilestone,
        })}
        siteKey={siteKey}
        siteList={sitesList}
      />
      <div
        className={clsx('columns main is-centered my-0', {
          'has-header': switchMilestone,
        })}
      >
        <section className={clsx('section has-bar column', sectionClassName)}>
          <div
            className={containerClassName ? containerClassName : 'container'}
          >
            {children}
          </div>
        </section>
      </div>
      {contentFooter}
      <Footer />
    </React.Fragment>
  )
}
