import './style.scss'

import { find } from 'lodash'
import React, { useMemo } from 'react'
import { Bar, BarChart, ReferenceLine, XAxis } from 'recharts'

import { TimelineLegends } from './legends'
import { TimelineRange } from './range'
import {
  SeasonsTimelineData,
  indexToMonth,
  parserToSeasonsTimeline,
} from './timeline-calcs'

export type SeasonType = {
  name: string | null
  startDate: string
  endDate: string
  color?: string
}

interface SeasonsTimelineProps {
  seasons: SeasonType[]
  width?: number
  height?: number
  isVppProgram?: boolean
}

export const SeasonsTimeline = ({
  seasons,
  width = 288,
  height = 1,
  isVppProgram,
}: SeasonsTimelineProps) => {
  const { data, seasonsParsed } = useMemo(
    () => parserToSeasonsTimeline(seasons),
    [seasons],
  )
  const isFlat = seasons.length < 2
  const referenceLines = useMemo(
    () =>
      seasonsParsed.map((season, index) => {
        const { endDate, startDate, color } = season
        return (
          <ReferenceLine
            key={`${index}-${startDate}`}
            segment={[
              {
                x: startDate,
                y: 1,
              },
              {
                x: endDate,
                y: 1,
              },
            ]}
            stroke={color}
            strokeWidth={isFlat ? 1 : 25}
          />
        )
      }),
    [seasonsParsed, isFlat],
  )

  const renderCustomAxisTick = (data: SeasonsTimelineData[]) => (
    props: any,
  ) => {
    const {
      x,
      y,
      payload: { value },
    } = props

    const month = find(data, { xIndex: value })

    return (
      <text
        x={x + 5}
        y={y + 5}
        fontSize={8}
        fill={(month && month.currentSeason) || !isFlat ? '#404040' : '#BFBFBF'}
        fontWeight={month && month.currentSeason ? 'bold' : 'normal'}
      >
        {indexToMonth(value)}
      </text>
    )
  }
  return (
    <div className="flat-container">
      {isFlat ? (
        <TimelineRange season={seasons[0]} isVppProgram={isVppProgram} />
      ) : null}
      <BarChart
        data={data}
        margin={{ top: 17 }}
        width={width}
        height={isFlat ? 50 : 100}
      >
        <XAxis
          dataKey="xIndex"
          axisLine={false}
          tickLine={isFlat ? false : true}
          tick={renderCustomAxisTick(data)}
          ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
          type="number"
          interval={0}
          scale="auto"
        />
        <ReferenceLine y={0} stroke="#C4C4C4" width={height} opacity={0.4} />
        {referenceLines}
        <Bar dataKey="value" />
      </BarChart>
      {isFlat ? null : <TimelineLegends seasons={seasons} width={width} />}
    </div>
  )
}
