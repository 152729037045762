import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'

import { PanelData } from '../../../components/panels-layout'
import { LaunchDarklyFlags } from '../../../shared/typings/launchDarklyFlags'
import { PanelItem } from '../panel-item'

export const PanelsList = ({
  panels = [],
  handleListCellClick,
  loading,
}: {
  panels: PanelData[]
  handleListCellClick: (panel: PanelData) => void
  loading: boolean
}) => {
  const flags = useFlags() as LaunchDarklyFlags
  const PANEL_DETAILS_PAGE_WEB_FLAG = flags?.panelDetailsPageWebFlag ?? false
  const DCE_ICE_ALERT_FLAG = flags?.dceIceAlertFlag ?? false
  const ALERT_DEVICE_DOWN_FLAG = flags?.alertDeviceDownFlag ?? false
  const panelItemsListMarkup = panels.map((panel, index) => {
    return (
      <PanelItem
        loading={loading}
        key={`panel-item-${index}`}
        panel={panel}
        onClick={() => handleListCellClick(panel)}
        clickeable={PANEL_DETAILS_PAGE_WEB_FLAG}
        dceIceAlertFlag={DCE_ICE_ALERT_FLAG}
        alertDeviceDownFlag={ALERT_DEVICE_DOWN_FLAG}
      />
    )
  })
  return (
    <div className="panels-list">
      <section className="summary-value-total">
        <h4>Total Energy</h4>
      </section>
      <section className="panel-count">
        <h5>Panel Count: {panels.length}</h5>
      </section>
      <section className="list-container">
        <header className="panel-list-header">
          <h3>PANEL SERIAL #</h3>
          <h3>Total Energy (kWh)</h3>
        </header>
        {panelItemsListMarkup}
      </section>
    </div>
  )
}
