import moment from 'moment'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { supportMessageContentTypes } from '../../config/chat/config'
import initiateChat from '../../config/chat/initiateChat'
import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { ChatContext } from '../../context/chatContext'
import { SupportCasesContext } from '../../context/supportCasesContext'
import ChatSession from '../chatUtils/ChatSession'
import filterIncomingMessages from '../chatUtils/helper'
import { Direction } from '../typings/chat'
import { usePartyData } from './usePartyData'

export const useChat = () => {
  const [cookies] = useCookies([
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
    USER_SELECTED_SITE_KEY,
  ])

  const loginOverride = cookies[LOGIN_OVERRIDE]
  const session = cookies[USER_SESSION_INFO] ?? {}
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const isSPFMadmin = useMemo(() => {
    return !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN]
  }, [cookies])

  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )

  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )

  const { data: partyData, loading: partyDataLoading } =
    usePartyData(currentPartyId)
  const chatContext = useContext(ChatContext) ?? {}
  const supportCasesContext = useContext(SupportCasesContext) ?? {}
  const history = useHistory()

  const [connected, setConnected] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [initChat, setInitChat] = useState<boolean>(false)
  const [chatDate, setChatDate] = useState<number>(0)
  const [messages, setMessages] = useState<any[]>([])
  const [isChatEnded, setIsChatEnded] = useState<boolean>(false)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [textChat, setTextChat] = useState<string>('')

  const { isOnline } = useNavigatorOnline()

  const customIsNetworkOnline = useCallback(() => isOnline, [isOnline])

  const selectedSite = useMemo(
    () =>
      partyData?.party?.sites?.find((site) => site.siteKey === selectedSiteKey),
    [partyData, selectedSiteKey],
  )

  const startChatRequestInput = useMemo(() => {
    return {
      contactFlowId: process.env.REACT_APP_HELP_CENTER_CONTACT_FLOW_ID ?? '',
      instanceId: process.env.REACT_APP_HELP_CENTER_INSTANCE_ID ?? '',
      region: process.env.REACT_APP_HELP_CENTER_REGION ?? 'us-west-2',
      apiGatewayEndpoint: process.env.REACT_APP_HELP_CENTER_API_GATEWAY ?? '',
      ccpUrl: process.env.REACT_APP_HELP_CENTER_CCP_URL ?? '',
      chatDurationInMinutes:
        process.env.REACT_APP_HELP_CENTER_CHAT_DURATION ?? 120,
      name: '',
      contactAttributes: {},
      supportedMessagingContentTypes: supportMessageContentTypes.join(','),
    }
  }, [])

  const initChatInput = useMemo(() => {
    const { oktaUser } = supportCasesContext ?? { oktaUser: null }

    const commonAttributes = {
      siteType: selectedSite?.siteType ?? '',
      commissionDate: selectedSite?.firstCommissionDate ?? '',
      firstName: oktaUser?.firstName ?? '',
      lastName: oktaUser?.lastName ?? '',
      accountIdExists: 'false',
      requestId: uuidv4() as string,
      siteId: selectedSiteKey ?? '',
      partyIdExists: `${!!currentPartyId}`,
      partyId: currentPartyId ?? '',
      oktaId: oktaUser?.id ?? 'ownerOktaId',
    }

    let info = {
      ...startChatRequestInput,
      name: session.name ?? '',
      contactAttributes: {
        customerName: session.name ?? '',
        emailId: session.userEmail ?? '',
        oktaIdExists: `${!!session.uid}`,
        siteIdExists: `${!!selectedSiteKey}`,
        phoneNumberExists: `${!!session.phone}`,
        emailIdExists: `${!!session.userEmail}`,
        phoneNumber: session.phone ?? '',
        ...commonAttributes,
      },
    }
    if (isSPFMadmin) {
      info = {
        ...startChatRequestInput,
        name: `${oktaUser?.firstName} ${oktaUser?.lastName}`,
        contactAttributes: {
          customerName: `${oktaUser?.firstName} ${oktaUser?.lastName}`,
          emailId: oktaUser?.email ?? '',
          oktaIdExists: `${!!oktaUser?.id}`,
          siteIdExists: `${!!selectedSiteKey}`,
          phoneNumberExists: `${!!oktaUser?.phone}`,
          emailIdExists: `${!!oktaUser?.email}`,
          phoneNumber: oktaUser?.phone ?? '',
          ...commonAttributes,
        },
      }
    }
    return info
  }, [cookies, supportCasesContext])

  const handleStartChatFailure = useCallback(
    (e) => {
      setConnected(false)
      setLoading(false)
      history.goBack()
    },
    [history],
  )

  const handleStartChatSuccess = useCallback(() => {
    setLoading(false)
    setConnected(true)
  }, [])

  const handleIsTyping = useCallback(
    (data) => {
      if (data.length > 0) {
        if (!isTyping) setIsTyping(true)
      } else {
        setIsTyping(false)
      }
    },
    [isTyping],
  )

  const updateTranscript = useCallback(
    async (chatSession) => {
      try {
        const latestTranscript = await chatSession?.loadPreviousTranscript()

        if (!!latestTranscript && latestTranscript.length > 0) {
          if (chatDate === 0) {
            setChatDate(latestTranscript[0].transportDetails?.sentTime)
          }

          setMessages(filterIncomingMessages(latestTranscript))
        }
      } catch {}
    },
    [chatDate],
  )

  const chatSessionListen = useCallback(
    (chatSession) => {
      // Add event listeners to chat session
      chatSession.onIncoming(() => updateTranscript(chatSession))
      chatSession.onOutgoing(() => updateTranscript(chatSession))
      chatSession.onTyping(handleIsTyping)
      chatSession?.client?.onDeliveredReceipt(() =>
        updateTranscript(chatSession),
      )
      chatSession?.client?.onReadReceipt(() => updateTranscript(chatSession))
      chatSession.onChatDisconnected(async () => {
        setIsChatEnded(true)
      })
    },
    [handleIsTyping, updateTranscript],
  )

  const createNewSession = useCallback(
    async (handleStartChatFailure) => {
      const chatDetails = await initiateChat(
        initChatInput,
        handleStartChatFailure,
      ).catch((e) => {})
      const chatSession = new ChatSession(
        chatDetails,
        initChatInput.name,
        initChatInput.region,
        customIsNetworkOnline, // pass down the custom "isNetworkOnline" function
      )
      return chatSession
    },
    [initChatInput, customIsNetworkOnline],
  )

  const submitChatInitiation = useCallback(async () => {
    if (!supportCasesContext?.oktaUser || (partyDataLoading && !partyData)) {
      return // is we comes from SPFM admin page, we need to wait to get the user info trough the getOktaUserQuery
    }
    setInitChat(true)
    setLoading(true)
    try {
      const chatSession = await createNewSession(handleStartChatFailure)

      await chatSession
        ?.openChatSession()
        ?.then(handleStartChatSuccess)
        .catch(handleStartChatFailure)

      chatContext.setProvidedChatSession(chatSession)
      chatSessionListen(chatSession)
    } catch (error) {
      handleStartChatFailure(error)
    }
  }, [
    chatSessionListen,
    createNewSession,
    handleStartChatFailure,
    handleStartChatSuccess,
    chatContext,
  ])

  const restoreChatSession = useCallback(() => {
    handleStartChatSuccess()
    setMessages(
      filterIncomingMessages(chatContext.providedChatSession.transcript),
    )

    setChatDate(
      chatContext.providedChatSession.transcript[0].transportDetails?.sentTime,
    )

    chatSessionListen(chatContext.providedChatSession)

    if (chatContext.providedChatSession.contactStatus === 'ended') {
      setIsChatEnded(true)
    }
  }, [chatSessionListen, handleStartChatSuccess, chatContext])

  const handleOnClickSend = useCallback(() => {
    const content = {
      data: textChat,
      type: 'text/plain',
    }
    const filteredMessages = filterIncomingMessages(
      chatContext.providedChatSession.transcript,
    )
    const optimisticMessage = {
      id: uuidv4(),
      type: 'MESSAGE',
      content,
      displayName: initChatInput.name,
      transportDetails: {
        direction: Direction.Outgoing,
        sentTime: moment().unix(),
        status: 'SendSuccess',
      },
    }

    setMessages([...filteredMessages, optimisticMessage])
    chatContext.providedChatSession?.client?.sendMessage(content)
    setTextChat('')
  }, [initChatInput, chatContext, textChat])

  const handleOnClickNewChat = useCallback(() => {
    setMessages([])
    setLoading(true)
    setIsChatEnded(false)
    submitChatInitiation().catch(() => {})
    chatContext.setProvidedChatSession(undefined)
  }, [chatContext, submitChatInitiation])

  useEffect(() => {
    if (!loading && !connected && !initChat) {
      if (chatContext.providedChatSession) {
        restoreChatSession()
      } else {
        submitChatInitiation()
      }
    }
  }, [
    connected,
    initChat,
    loading,
    chatContext,
    restoreChatSession,
    submitChatInitiation,
    handleOnClickNewChat,
    supportCasesContext,
  ])

  return {
    ...chatContext,
    createNewSession,
    loading,
    messages,
    chatDate,
    isChatEnded,
    handleOnClickSend,
    textChat,
    setTextChat,
    handleOnClickNewChat,
  }
}

//
const useNavigatorOnline = () => {
  const [value, setValue] = useState(window.navigator.onLine)

  useEffect(() => {
    function handleOnlineStatus() {
      setValue(window.navigator.onLine)
    }

    window.addEventListener('online', handleOnlineStatus)
    window.addEventListener('offline', handleOnlineStatus)

    return () => {
      window.removeEventListener('online', handleOnlineStatus)
      window.removeEventListener('offline', handleOnlineStatus)
    }
  }, [])

  return { isOnline: value, isOffline: !value }
}
