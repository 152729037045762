import React, { createContext, useEffect, useState } from 'react'

import {
  DocusignURL,
  PerformanceBySeason,
  PerformanceMetricBySeason,
  ProgramSignUpSteps,
  VPPEnrollment,
} from '../shared/typings/site'

const LOCAL_STORAGE_KEY = 'DYNAMIC_VPP_SELECTED_OPTIONS'

type VppContextType = {
  enrollments: VPPEnrollment[]
  setEnrollments: (enrollments: VPPEnrollment[]) => void
  selectedSiteKey: string
  setSelectedSiteKey: (newValue: string) => void
  enrollmentFetched: boolean
  setEnrollmentFetched: (boolean) => void
  selectedEnrollment: VPPEnrollment | null
  setSelectedEnrollment: (VPPEnrollment) => void
  programSteps: ProgramSignUpSteps[]
  setProgramSteps: (programSteps: ProgramSignUpSteps[]) => void
  setProgramStepsFetched: (string) => void
  programStepsFetched: string
  currentStep: number
  setCurrentStep: (number) => void
  docusignURL: DocusignURL
  setDocusignURL: (docusignURL: DocusignURL) => void
  setDocusignURLFetched: (boolean) => void
  docusignURLFetched: boolean
  performanceMetricBySeason: PerformanceMetricBySeason[]
  setPerformanceMetricBySeason: (
    performanceMetricBySeason: PerformanceMetricBySeason[]
  ) => void
  performanceMetricBySeasonFetched: boolean
  setPerformanceMetricBySeasonFetched: (boolean) => void
  performanceBySeason: PerformanceBySeason[]
  setPerformanceBySeason: (performanceBySeason: PerformanceBySeason[]) => void
  performanceBySeasonFetched: boolean
  setPerformanceBySeasonFetched: (boolean) => void
}

const initialValue = {
  enrollments: [],
  setEnrollments: (enrollments: VPPEnrollment[]) => null,
  selectedSiteKey: '',
  setSelectedSiteKey: (newValue: string) => {},
  enrollmentFetched: false,
  setEnrollmentFetched: (boolean) => {},
  selectedEnrollment: null,
  setSelectedEnrollment: (enrollment: VPPEnrollment) => {},
  programSteps: [],
  setProgramSteps: (programSteps: ProgramSignUpSteps[]) => null,
  setProgramStepsFetched: (string) => {},
  programStepsFetched: '',
  currentStep: -1,
  setCurrentStep: (number) => {},
  docusignURL: {},
  setDocusignURL: (docusignURL: DocusignURL) => null,
  setDocusignURLFetched: (boolean) => {},
  docusignURLFetched: false,
  performanceMetricBySeason: [],
  setPerformanceMetricBySeason: (
    performanceMetricBySeason: PerformanceMetricBySeason[]
  ) => null,
  performanceMetricBySeasonFetched: false,
  setPerformanceMetricBySeasonFetched: (boolean) => {},
  performanceBySeason: [],
  setPerformanceBySeason: (performanceBySeason: PerformanceBySeason[]) => null,
  performanceBySeasonFetched: false,
  setPerformanceBySeasonFetched: (boolean) => {},
}

export const VppContext = createContext<VppContextType>(initialValue)

type DynamicVppProviderPropsType = {
  children: JSX.Element
}

const DynamicVppProvider = ({ children }: DynamicVppProviderPropsType) => {
  const [currentStep, setCurrentStep] = useState<number>(-1)

  const [enrollmentFetched, setEnrollmentFetched] = useState(false)
  const [enrollments, setEnrollments] = useState<VPPEnrollment[]>([])
  const [programSteps, setProgramSteps] = useState<ProgramSignUpSteps[]>([])
  const [programStepsFetched, setProgramStepsFetched] = useState('')
  const [docusignURL, setDocusignURL] = useState<DocusignURL>({})
  const [docusignURLFetched, setDocusignURLFetched] = useState(false)
  const [selectedSiteKey, setSelectedSiteKey] = useState<string>('')
  const [performanceMetricBySeason, setPerformanceMetricBySeason] = useState<
    PerformanceMetricBySeason[]
  >([])
  const [performanceBySeason, setPerformanceBySeason] = useState<
    PerformanceBySeason[]
  >([])

  const [
    performanceMetricBySeasonFetched,
    setPerformanceMetricBySeasonFetched,
  ] = useState(false)

  const [performanceBySeasonFetched, setPerformanceBySeasonFetched] =
    useState(false)

  const [selectedEnrollment, setSelectedEnrollment] =
    useState<VPPEnrollment | null>(null)

  // Persisting selecting data
  useEffect(() => {
    if (window && !selectedEnrollment) {
      const storagedData = window.localStorage.getItem(LOCAL_STORAGE_KEY)
      if (storagedData) {
        const data = JSON.parse(storagedData)

        setSelectedSiteKey(data.selectedSiteKey)
        setSelectedEnrollment(data.selectedEnrollment)
      }
    }
  }, [])

  useEffect(() => {
    if (selectedEnrollment && window) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          selectedSiteKey,
          selectedEnrollment,
        })
      )
    }
  }, [selectedEnrollment])

  return (
    <VppContext.Provider
      value={{
        enrollments: enrollments,
        setEnrollments: setEnrollments,
        selectedSiteKey,
        setSelectedSiteKey,
        enrollmentFetched,
        setEnrollmentFetched,
        selectedEnrollment,
        setSelectedEnrollment,
        programSteps,
        setProgramSteps,
        programStepsFetched,
        setProgramStepsFetched,
        currentStep,
        setCurrentStep,
        docusignURL,
        setDocusignURL,
        docusignURLFetched,
        setDocusignURLFetched,
        performanceMetricBySeason,
        setPerformanceMetricBySeason,
        performanceMetricBySeasonFetched,
        setPerformanceMetricBySeasonFetched,
        performanceBySeason,
        setPerformanceBySeason,
        performanceBySeasonFetched,
        setPerformanceBySeasonFetched,
      }}
    >
      {children}
    </VppContext.Provider>
  )
}

export default DynamicVppProvider
