import { gql } from '@apollo/client'

export const SAVE_FEEDBACK = gql`
  mutation SaveFeedback(
    $partyId: String!
    $siteKey: String!
    $comment: String
    $stars: Float!
  ) {
    updateUserConfigs(
      data: {
        partyId: $partyId
        siteKey: $siteKey
        appRating: { stars: $stars, comment: $comment, fromWebApp: true }
      }
    ) {
      appRating {
        comment
        date
        fromWebApp
        stars
      }
    }
  }
`
