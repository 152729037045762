export const findLatestValidDataPoint = (
  dataSeries: [string, string, string][],
) => {
  let latestValidPoint = [
    dataSeries[dataSeries.length - 1]?.[0] ?? '0',
    '0',
    '0',
  ]

  for (let i = dataSeries.length - 1; i >= 0; i--) {
    const dataPoint = dataSeries[i]
    const isValid = dataPoint[dataPoint.length - 1] !== '100'
    if (isValid) {
      latestValidPoint = dataPoint
      break
    }
  }

  return latestValidPoint
}
