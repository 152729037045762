import './style.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { Dispatch, SetStateAction, useCallback } from 'react'

import ToggleSwitch from '../../components/toggle'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import infiniteImg from './assets/infinite.svg'
import minusImg from './assets/minus.svg'
import plusImg from './assets/plus.svg'

const MIN = 1
const MAX = 5

export const ReserveModeSetupStep1 = ({
  days,
  handleDays,
  handleCancel,
  handleNext,
  extendedReserveMode,
  setExtendedReserveMode,
}: {
  days: number
  handleDays: (days: number) => void
  handleCancel: () => void
  handleNext: () => void
  extendedReserveMode: boolean
  setExtendedReserveMode: Dispatch<SetStateAction<boolean>>
}) => {
  const handleMinus = useCallback(() => {
    if (days > MIN) {
      handleDays(days - 1)
    }
  }, [days, handleDays])

  const handlePlus = useCallback(() => {
    if (days < MAX) {
      handleDays(days + 1)
    }
  }, [days, handleDays])

  const handleOnChangeExtendedReserveMode = () => {
    setExtendedReserveMode((prevSate) => !prevSate)
  }

  const flags = useFlags() as LaunchDarklyFlags
  const EXTENDED_RESERVE_MODE_FLAG = flags?.extendedReserveModeFlag ?? false

  return (
    <React.Fragment>
      <article className="setup-mode-title column">
        Select how many days you want to <br />
        leave your battery in reserve mode:
      </article>
      <section className="columns setup-mode-days-setter is-mobile is-vcentered is-centered">
        <span className="column">
          <button
            className="setup-mode-btn-rounded"
            disabled={days === MIN || extendedReserveMode}
            onClick={handleMinus}
          >
            <img src={minusImg} />
          </button>
        </span>

        <div>
          <div className="setup-mode-value">
            {extendedReserveMode ? (
              <img className="infinite-svg" src={infiniteImg} />
            ) : (
              days
            )}
          </div>
          <h5 className="is-uppercase setup-mode-days">
            {extendedReserveMode ? 'infinite Days' : 'days'}
          </h5>
          {!extendedReserveMode && days === MIN && (
            <h4 className="setup-mode-days legend">MINIMUM</h4>
          )}
          {!extendedReserveMode && days === MAX && (
            <h4 className="setup-mode-days legend">MAXIMUM</h4>
          )}
        </div>

        <span className="column right-column">
          <button
            className="setup-mode-btn-rounded"
            disabled={days === MAX || extendedReserveMode}
            onClick={handlePlus}
          >
            <img src={plusImg} />
          </button>
        </span>
      </section>
      {EXTENDED_RESERVE_MODE_FLAG && (
        <>
          <div className="columns is-mobile is-vcentered is-centered">
            <ToggleSwitch
              id="extended-reserve-mode"
              name="extended-reserve-mode"
              checked={extendedReserveMode}
              onChange={handleOnChangeExtendedReserveMode}
            />
            <label htmlFor="daily" className="switch-label">
              Extended Reserve Mode
            </label>
          </div>
          <div className="columns is-mobile is-vcentered is-centered">
            <div className="bubble-chat">
              This keeps the battery in reserve mode, until you switch to
              another mode.
            </div>
          </div>
        </>
      )}
      <section className="setup-mode-actions-container mt-5">
        <button className="btn-cancel button mr-5 mb-5" onClick={handleCancel}>
          Cancel
        </button>
        <button className="btn-start button" onClick={handleNext}>
          {extendedReserveMode ? 'Start' : 'Next'}
        </button>
      </section>
    </React.Fragment>
  )
}
