import './style.scss'

import last from 'lodash/last'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import moment from 'moment-timezone'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Group, Layer, Stage } from 'react-konva'

import { SWITCHER_MODES } from '../../pages/PanelsPage'
import { WeatherConditions } from '../../pages/PanelsPage/panel-detail'
import { Panel } from './panel'
import { PANEL_HEIGHT, PANEL_WIDTH } from './panel'
import { PanelLayoutActions } from './panel-layout-actions'

export const PANEL_LAYOUT_WIDTH = 600
export const PANEL_LAYOUT_HEIGHT = 500

export type PanelGraphData = {
  timestamp: string
  energy?: number | null
  power?: number | null
  powerColorCode?: number | null
}

export type PanelLayoutData = {
  xCoordinate?: number | null
  yCoordinate?: number | null
  rotation?: number | null
  azimuth?: number | null
  orientation?: string | null
}

export type HourlyPowerProduction = {
  value: number | null
  timestamp: string
}

export type DailyEnergyProduction = {
  value: number | null
  timestamp: string
}

export type PanelData = {
  serialNumber: string
  dailyEnergyProduction: number | null
  energyColorCode: number
  lastCommunicationTimestamp: string
  sevenDayAverage?: number | null
  peakPowerProduction?: HourlyPowerProduction | null
  alerts?: AlertData[] | null
  hourlyData?: PanelGraphData[] | null
  layout?: PanelLayoutData | null
}

export type AlertData = {
  alertStatus: string
  deviceType: string
  deviceKey: string
  alertType: string
  eventTimestamp: string
}

const isValidPanel = (x, y) =>
  x !== null && y !== null && x !== undefined && y !== undefined

function remapPanels(panels) {
  return panels
    .filter((panel) =>
      isValidPanel(panel.layout?.xCoordinate, panel.layout?.yCoordinate),
    )
    .map((panel, i) => {
      return {
        ...panel,
        id: `panel-layout-id-${i.toString()}`,
        x: panel.layout ? panel.layout.xCoordinate : 0,
        y: panel.layout ? panel.layout.yCoordinate : 0,
        rotation: panel.layout ? panel.layout.rotation : 0,
        orientation: panel.layout ? panel.layout.orientation : 0,
        azimuth: panel.layout ? panel.layout.azimuth : 0,
        isDragging: false,
      }
    })
}

type PanelLayoutProps = {
  panels: PanelData[] | never[]
  height: number
  width: number
  mode?: SWITCHER_MODES
  cursorAnimatedValue?: number
  timezone?: string
  dates?: { start: number; end: number }
  onPanelTap: (panel: PanelData) => void
  weatherConditions: WeatherConditions
  hourlyPowerProduction: HourlyPowerProduction[] | null
  onPressTimelapse: () => void
  selectedPanel: PanelData | null
  activeIndex: number
  setActiveIndex: Dispatch<SetStateAction<number>>
}
export const PanelsLayout = ({
  panels = [],
  height = PANEL_LAYOUT_HEIGHT,
  width = PANEL_LAYOUT_WIDTH,
  timezone = 'utc',
  dates = { start: 0, end: 0 },
  mode = SWITCHER_MODES.ENERGY,
  weatherConditions,
  hourlyPowerProduction,
  selectedPanel,
  onPanelTap = () => {},
  onPressTimelapse = () => {},
  activeIndex,
  setActiveIndex,
}: PanelLayoutProps) => {
  const updatedPanels = remapPanels(panels)
  const [layoutPanels, setLayoutPanels] = useState(updatedPanels)

  const [isTimelapseRunning, setTimelapseRun] = useState<boolean>(false)
  useEffect(() => {
    setLayoutPanels(remapPanels(panels))
    setActiveIndex(0)
  }, [panels, setActiveIndex])

  useEffect(() => {
    let timer
    if (isTimelapseRunning) {
      timer = setInterval(() => {
        if (23 >= activeIndex) {
          setActiveIndex((prevCount) => prevCount + 1)
        } else {
          setTimelapseRun(false)
        }
      }, 200)
    }

    return () => clearInterval(timer)
  }, [isTimelapseRunning, setActiveIndex, setTimelapseRun, activeIndex])

  const panelsTotal = useMemo(() => {
    // @ts-ignore reducing down to a number Typescript doesnt like https://github.com/microsoft/TypeScript/issues/36390#issuecomment-840082057
    return panels.reduce((accumulator: number, panel: PanelData) => {
      if (mode === SWITCHER_MODES.ENERGY) {
        return panel.dailyEnergyProduction
          ? accumulator + panel.dailyEnergyProduction
          : accumulator
      }

      const latestPower = last(hourlyPowerProduction)

      return !latestPower || !latestPower.value || latestPower.value === null
        ? 0.0
        : latestPower.value
    }, 0.0)
  }, [panels, mode, hourlyPowerProduction])

  const selectedDate = moment.tz(dates.end, timezone)
  const dateIsToday = selectedDate.isSame(moment().tz(timezone), 'day')

  const panelsTimeframe = useMemo(() => {
    if (mode === SWITCHER_MODES.ENERGY) {
      const finalHour = dateIsToday
        ? moment().tz(timezone).format('h:mm a')
        : '11:59 pm'
      return `12:00 am - ${finalHour}`
    }

    if (!hourlyPowerProduction) return '12:00 am'
    const finalHour = dateIsToday
      ? moment().tz(timezone).format('h:mm a')
      : '12:00 am'
    return finalHour
  }, [mode, hourlyPowerProduction, dateIsToday, timezone])

  const isSinglePanel = useMemo(
    () =>
      panels.filter((panel) =>
        isValidPanel(panel.layout?.xCoordinate, panel.layout?.yCoordinate),
      )?.length === 1,
    [panels],
  )
  const minX = useMemo(
    () =>
      minBy(panels, (panel) => panel.layout?.xCoordinate)?.layout
        ?.xCoordinate ?? 0,
    [panels],
  )

  const minY = useMemo(
    () =>
      minBy(panels, (panel) => panel.layout?.yCoordinate)?.layout
        ?.yCoordinate ?? 0,
    [panels],
  )

  const maxX = useMemo(() => {
    if (isSinglePanel) {
      return minX + PANEL_WIDTH
    }
    return (
      maxBy(panels, (panel) => panel.layout?.xCoordinate)?.layout
        ?.xCoordinate ?? 0
    )
  }, [panels, isSinglePanel, minX])

  const maxY = useMemo(() => {
    if (isSinglePanel) {
      return minY + PANEL_HEIGHT
    }
    return (
      maxBy(panels, (panel) => panel.layout?.yCoordinate)?.layout
        ?.yCoordinate ?? 0
    )
  }, [panels, isSinglePanel, minY])

  const xZoomRatio = useMemo(() => {
    return maxX && minX && maxX - minX !== 0 ? width / (maxX - minX) : width
  }, [maxX, minX, width])

  const yZoomRatio = useMemo(() => {
    return maxY && minY && maxY - minY !== 0 ? height / (maxY - minY) : height
  }, [maxY, minY, height])

  const zoomRatio = useMemo(
    () => (xZoomRatio < yZoomRatio ? xZoomRatio : yZoomRatio) * 0.6,
    [xZoomRatio, yZoomRatio],
  )

  const [zoom, setZoom] = useState({ x: zoomRatio, y: zoomRatio })
  useEffect(() => {
    setZoom({ x: zoomRatio, y: zoomRatio })
  }, [zoomRatio])
  const offsetX = useMemo(
    () => -(minX! * zoom.x) + (width - (maxX - minX) * zoom.x) / 2,
    [minX, zoom, maxX, width],
  )
  const offsetY = useMemo(
    () => -(minY! * zoom.y) + (height - (maxY - minY) * zoom.y) / 2,
    [minY, zoom, maxY, height],
  )

  const zoomIn = () => {
    if (zoom.x <= 5) {
      setZoom({
        x: zoom.x + 0.1,
        y: zoom.y + 0.1,
      })
    }
  }
  const zoomOut = () => {
    if (zoom.x > 0) {
      setZoom({
        x: zoom.x - 0.1,
        y: zoom.y - 0.1,
      })
    }
  }

  const handleActiveIndex = (index: number) => setActiveIndex(index)

  const handleOnPlayTimelapse = useCallback(() => {
    setActiveIndex(isTimelapseRunning ? 24 : 0)
    setTimelapseRun((prevSate) => !prevSate)
  }, [isTimelapseRunning, setActiveIndex, setTimelapseRun])

  return (
    <div className="panels-layout">
      <Stage width={width} height={height} y={0} x={0} draggable={true}>
        <Layer y={offsetY} x={offsetX}>
          <Group scale={{ x: zoom.x, y: zoom.y }}>
            {layoutPanels.map((panel, index) => (
              <Panel
                key={`module-panel-${index}`}
                panel={panel}
                index={index}
                mode={mode}
                onPanelTap={() => onPanelTap(panel)}
                cursorAnimatedValue={activeIndex}
                isSelected={selectedPanel?.serialNumber === panel.serialNumber}
                isToday={dateIsToday}
              />
            ))}
          </Group>
        </Layer>
      </Stage>
      <PanelLayoutActions
        mode={mode}
        total={parseFloat(panelsTotal.toFixed(2))}
        timeframe={panelsTimeframe}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        weatherConditions={weatherConditions}
        onPressTimelapse={onPressTimelapse}
        hourlyPowerProduction={hourlyPowerProduction}
        setActiveIndex={handleActiveIndex}
        activeIndex={activeIndex}
        onPlayTimelapse={handleOnPlayTimelapse}
        isTimelapseRunning={isTimelapseRunning}
      />
    </div>
  )
}
