const DATALOGGER_DEVICE_TYPE = ['datalogger', 'logger']
const NON_LEGACY_COMM_PROTOCOL = ['MQTT', null]

export const isLegacyPvs = (payload) => {
  if (payload?.assignments?.length === 0) {
    return true
  } else {
    return payload?.assignments?.some((assignment) => {
      return (
        DATALOGGER_DEVICE_TYPE.includes(
          assignment?.deviceType?.toLowerCase(),
        ) &&
        !assignment?.devices?.some((device) =>
          NON_LEGACY_COMM_PROTOCOL.includes(device?.comProto),
        )
      )
    })
  }
}
