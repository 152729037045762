import React from 'react'

import { WeatherIconProps } from './cloudy'

export const Sunny = ({ x = 0, y = 0 }: WeatherIconProps) => (
  <svg
    width="27"
    height="27"
    viewBox={`${x} ${y} 27 27`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="27" height="27" />
    <path
      d="M13.9238 6.74141C14.341 6.66554 14.6255 6.26723 14.5497 5.84995L14.2841 4.40844C14.2462 4.21877 14.0945 4.04806 13.8858 4.01013C13.6203 3.95323 13.3547 4.1429 13.2978 4.40844L13.0323 5.83098C13.0133 5.92582 13.0133 6.02065 13.0323 6.11549C13.1082 6.53277 13.5065 6.81728 13.9238 6.74141Z"
      fill="#FFD463"
    />
    <path
      d="M13.6577 19.2586C13.2405 19.3345 12.956 19.7328 13.0318 20.1501L13.2974 21.5916C13.3353 21.7813 13.487 21.952 13.6957 21.9899C13.9612 22.0468 14.2268 21.8571 14.2837 21.5916L14.5492 20.1501C14.5682 20.0552 14.5682 19.9604 14.5492 19.8656C14.4733 19.4673 14.075 19.1828 13.6577 19.2586Z"
      fill="#FFD463"
    />
    <path
      d="M19.339 17.7449C19.2778 17.6725 19.2166 17.6001 19.1284 17.5604C18.7741 17.3234 18.298 17.4476 18.061 17.8019C17.824 18.1562 17.9483 18.6323 18.3026 18.8693L19.5233 19.6554C19.6912 19.7718 19.9257 19.7673 20.0933 19.6306C20.3064 19.4654 20.36 19.1469 20.1949 18.9338L19.339 17.7449Z"
      fill="#FFD463"
    />
    <path
      d="M19.4185 8.67114L20.4071 7.58739C20.5406 7.4363 20.5891 7.21967 20.4872 7.02245C20.3592 6.77123 20.06 6.67595 19.8088 6.80398L18.5175 7.4678C18.4471 7.51513 18.3579 7.56489 18.2899 7.63103C18.0065 7.95445 18.0296 8.42959 18.3342 8.71541C18.6388 9.00122 19.1327 8.97575 19.4185 8.67114Z"
      fill="#FFD463"
    />
    <path
      d="M11.7834 16.5484C9.83603 15.4453 9.13272 12.9541 10.2452 10.9903C11.3578 9.02638 13.8561 8.34892 15.8034 9.45209C17.7508 10.5553 18.4448 13.0629 17.3416 15.0103C16.2384 16.9576 13.7308 17.6516 11.7834 16.5484Z"
      fill="#FFD463"
    />
    <path
      d="M9.16266 7.42347L7.79386 6.888C7.60661 6.80634 7.37736 6.85622 7.23941 7.02276C7.06237 7.22605 7.07142 7.54893 7.27471 7.72596L8.3641 8.70827C8.43818 8.76748 8.51227 8.82669 8.60647 8.84856C8.99993 9.01247 9.44297 8.79838 9.60688 8.40492C9.75183 8.01088 9.5567 7.56842 9.16266 7.42347Z"
      fill="#FFD463"
    />
    <path
      d="M6.62643 12.2984L5.1853 12.5719C4.98783 12.6127 4.81898 12.7568 4.78215 12.9757C4.73379 13.2535 4.91589 13.5093 5.19368 13.5576L6.62498 13.8017C6.70969 13.8061 6.8109 13.8198 6.90496 13.8077C7.32575 13.7191 7.59247 13.3252 7.52036 12.9138C7.44825 12.5023 7.03786 12.2262 6.62643 12.2984Z"
      fill="#FFD463"
    />
    <path
      d="M9.34648 17.3269C9.03613 17.0379 8.54675 17.048 8.25768 17.3584L7.26117 18.4333C7.13184 18.5771 7.08577 18.8008 7.18014 18.9908C7.29334 19.2376 7.60416 19.337 7.85101 19.2238L9.16514 18.5745C9.25249 18.533 9.3285 18.4763 9.39316 18.4044C9.66702 18.1054 9.65684 17.616 9.34648 17.3269Z"
      fill="#FFD463"
    />
    <path
      d="M20.9583 13.7033L22.3994 13.4298C22.5969 13.389 22.7657 13.2449 22.8026 13.026C22.8509 12.7482 22.6688 12.4924 22.391 12.4441L20.9598 12.2C20.875 12.1956 20.7738 12.1819 20.6798 12.194C20.259 12.2826 19.9923 12.6765 20.0644 13.0879C20.1365 13.4994 20.5469 13.7755 20.9583 13.7033Z"
      fill="#FFD463"
    />
  </svg>
)
