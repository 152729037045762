import './style.scss'

import React from 'react'
import { useHistory } from 'react-router-dom'

import paths from '../Router/paths'
import apiErrorPlugs from './assets/unplug-error.svg'

const DEFAULT_ERROR_MESSAGE =
  'We were unable to set your Sunvault mode at this time.'
export const APIErrorModal = ({
  toPath = paths.ROOT,
  errorMessage = DEFAULT_ERROR_MESSAGE,
}) => {
  const history = useHistory()
  const goToPath = () => {
    history.push(toPath)
  }
  return (
    <div className="api-error-contents columns is-vcentered">
      <section className="column">
        <img alt="api error" src={apiErrorPlugs} />
      </section>
      <article className="column">
        <span className="has-text-weight-bold is-size-7">
          Oops something went wrong..
        </span>
      </article>
      <article className="column">
        <h4>
          {errorMessage}
          <br /> Please try again later.
        </h4>
      </article>
      <article className="column">
        <h6 className="is-uppercase">
          if the issue persists, please email us:
        </h6>
        <h5 className="has-text-weight-bold is-size-6">
          mysunpower@sunpower.com
        </h5>
      </article>
      <article className="column">
        <button onClick={goToPath}>Okay</button>
      </article>
    </div>
  )
}
