import './style.scss'

import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'

import { useSupportCases } from '../../../../shared/hooks'
import { customModalStyles } from './constants/customModalStyles'

type modalLabelsType = {
  title?: string
  subtitle?: string
  boxText?: string
  closeBtnText: string
  acceptBtnText: string
}

interface CaseModalProps {
  closeModal: () => void
  modalLabels: modalLabelsType
  isModalOpen: boolean
  caseNumber: string
  switchToggleToOpen: (boolean) => void
}

export const CaseModal = ({
  closeModal,
  modalLabels,
  isModalOpen,
  caseNumber,
  switchToggleToOpen,
}: CaseModalProps) => {
  const { handleAddCaseComment } = useSupportCases()

  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  const [message, setCommentMessage] = useState('')

  const handleCommentChange = (event) => {
    setCommentMessage(event.target.value)
  }

  const reOpenCase = () => {
    if (caseNumber && message) {
      handleAddCaseComment(
        caseNumber,
        message,
        () => switchToggleToOpen(true),
        true, // status change to open
      )
      closeModal()
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customModalStyles}
    >
      <div className="case-modal-container">
        <h2 className="case-modal-title">{modalLabels.title}</h2>
        <p className="case-modal-subtitle">{modalLabels.subtitle}</p>
        <textarea
          className="message-box"
          placeholder={modalLabels?.boxText}
          value={message}
          onChange={handleCommentChange}
        />
        <div className="case-modal-btn-container">
          <div className="cancel-btn" onClick={closeModal}>
            {modalLabels?.closeBtnText}
          </div>
          <div className="accept-btn" onClick={reOpenCase}>
            {modalLabels?.acceptBtnText}
          </div>
        </div>
      </div>
    </Modal>
  )
}
