import { gql } from '@apollo/client'

export const FETCH_SITE_PARTIES = gql`
  query FetchSiteParties($siteId: String!) {
    site(siteKey: $siteId) {
      siteKey
      parties {
        partyId
        displayName
        relation
      }
    }
  }
`
export const FETCH_PARTY_SITES = gql`
  query FetchPartySites($partyId: String!) {
    party(partyId: $partyId) {
      partyId
      sites {
        siteKey
        siteName
      }
    }
  }
`
