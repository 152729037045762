import './style.scss'

import clsx from 'clsx'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useHistory } from 'react-router-dom'

import { getLaunchDarklyContext } from './../../shared/compileSessionInformation'
import {
  alertSeenTimestampVar,
  wifiAlertSeenTimestampVar,
} from '../../config/cache'
import { USER_SELECTED_SITE_KEY, USER_SESSION_INFO } from '../../config/cookies'
import paths from '../../pages/Router/paths'
import { setSiteData } from '../../shared/firebaseAnalytics'
import { DataLayerEventName } from '../../shared/typings/dataLayer'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import { Site } from '../../shared/typings/site'
import { Menu, MenuItem } from '../menu'
import { OverlayModal } from '../overlay-modal'
import { SiteSelection } from '../site-selection'
import profileIcon from './assets/bars-menu.svg'
import chevronDown from './assets/chevron-down.svg'
import chevronUp from './assets/chevron-up.svg'
import mySunPowerLogo from './assets/mysunpower-logo.svg'

interface HeaderInterface {
  sitesList?: any
  siteKey?: string | null
  title?: string
  className?: string
  flagOverride?: boolean
  panelDataLicenseAccepted?: boolean
  options?: {
    children?: {
      left?: JSX.Element | null
      right?: JSX.Element | null
      middle?: JSX.Element | null
    }
    hiddenLogo?: boolean
    hiddenMenu?: boolean
  }
}

export const Header = ({
  sitesList,
  siteKey,
  title = 'Home Monitoring',
  className = '',
  flagOverride = false,
  panelDataLicenseAccepted = false,
  options,
}: HeaderInterface) => {
  const ldClient = useLDClient()
  const history = useHistory()
  const flags = useFlags() as LaunchDarklyFlags
  const [sideNavBarState, setNavBarState] = useState(false)
  const [isSelectingSite, setIsSelectingSite] = useState<boolean>(false)
  const [cookies, setCookie] = useCookies([
    USER_SELECTED_SITE_KEY,
    USER_SESSION_INFO,
  ])
  const session = cookies[USER_SESSION_INFO] ?? {}
  const PANELS_LAYOUT_FLAG = flags?.panelsLayoutFlag ?? flagOverride
  const selectedSite: Site = useMemo(
    () => sitesList.find((site) => site.siteKey === siteKey),
    [sitesList, siteKey]
  )

  const hasSiteCookie = !!cookies[USER_SELECTED_SITE_KEY]

  const showThePanels = useMemo(() => {
    return (
      selectedSite?.hasPanelLayout &&
      selectedSite?.hasMI &&
      selectedSite?.isEnabledInNightvision
    )
  }, [selectedSite])

  const isAdminUserOrSiteSelection = !!history?.location?.pathname?.includes(
    paths.MONITORING_ADMIN_LOGIN
  )
  const isHomePage = history?.location?.pathname === paths.ROOT
  const logoIsHidden = options?.hiddenLogo ?? false
  const menuIsHidden = options?.hiddenMenu ?? false
  const headerChildren = options?.children ?? {}

  const onTitleClick = useCallback(() => {
    if (sitesList.length > 1) {
      setIsSelectingSite((prevState) => !prevState)
      return
    }
    if (isAdminUserOrSiteSelection) {
      history.push(paths.MONITORING_ADMIN_LOGIN)
      return
    }
    if (hasSiteCookie) {
      history.push(paths.ROOT)
      return
    }
  }, [history, sitesList, hasSiteCookie, isAdminUserOrSiteSelection])

  const toggleSideNav = useCallback(() => {
    setNavBarState((prevState) => !prevState)
  }, [])

  const closeSideNav = useCallback(() => {
    setNavBarState(false)
  }, [])

  const switchSite = useCallback(
    (siteKey) => {
      if (siteKey !== selectedSite.siteKey) {
        const selectedSite = sitesList.filter((currentSite) => {
          return currentSite.siteKey === siteKey
        })
        const launchDarklyContext = getLaunchDarklyContext(
          selectedSite[0] ?? {},
          session
        )
        ldClient?.identify(launchDarklyContext)
        setSiteData(session, selectedSite[0] ?? {})
        window.dataLayer.push({
          event: DataLayerEventName.SITE_SELECT,
          userData: {
            siteId: siteKey,
          },
        })
        alertSeenTimestampVar(null)
        wifiAlertSeenTimestampVar(null)
        setCookie(USER_SELECTED_SITE_KEY, siteKey)
        history.push(paths.ROOT)
      }
      setIsSelectingSite(false)
    },
    [history, selectedSite, setCookie]
  )

  const siteSelection = useMemo(
    () => (
      <OverlayModal onClose={() => setIsSelectingSite(false)}>
        <SiteSelection sites={sitesList} onSelectSite={switchSite} />
      </OverlayModal>
    ),
    [sitesList, switchSite]
  )

  const sideNavMenuMarkup = sideNavBarState ? (
    <Menu
      showPanels={PANELS_LAYOUT_FLAG && !!showThePanels}
      handleClose={closeSideNav}
      panelDataLicenseAccepted={panelDataLicenseAccepted}
      siteType={selectedSite?.siteType}
    />
  ) : null

  const buildPageTitle = (pageTitle = '') => {
    if (pageTitle === 'Home Monitoring' && sitesList.length > 1) {
      return [selectedSite?.address1, selectedSite?.state].join(', ')
    }
    return pageTitle
  }
  const showSiteSelection = isSelectingSite ? siteSelection : null
  const handlePageTitleClick = isHomePage ? onTitleClick : () => {}
  const mySunPowerLogoMarkup = hasSiteCookie ? (
    <Link to={paths.ROOT} className='navbar-item logo'>
      <img src={mySunPowerLogo} alt='mySunPower logo' />
    </Link>
  ) : (
    <span className='navbar-item logo'>
      <img src={mySunPowerLogo} alt='mySunPower logo' />
    </span>
  )
  // it is no longer just a page title, only tabs of page navigation
  // page nav items have bold on hover & when active, & if not on that page then not bold at all
  const pageTitleMarkup = (
    <h1
      onClick={handlePageTitleClick}
      className={clsx('is-flex', {
        'hide-cursor': !hasSiteCookie || !isHomePage,
      })}
    >
      {buildPageTitle(title)}
      {sitesList.length > 1 && isHomePage && (
        <img
          src={isSelectingSite ? chevronUp : chevronDown}
          alt='site-selection'
          className='ml-2'
        />
      )}
    </h1>
  )

  const headerTabItems =
    !isAdminUserOrSiteSelection && hasSiteCookie ? (
      <div data-testid="navbar-nav" className='header-tabs-container'>
        <MenuItem
          linkTo={paths.ROOT}
          label='Home'
          currentPath={history?.location}
        />
        <MenuItem
          linkTo={paths.PROFILE}
          label='Profile'
          currentPath={history?.location}
        />
        {showThePanels && (
          <MenuItem
            linkTo={
              panelDataLicenseAccepted
                ? paths.PANELS
                : paths.ALERT_LEGAL_DOCUMENT_MODAL
            }
            label='Panels'
            currentPath={history?.location}
          />
        )}
      </div>
    ) : (
      <h1>Admin View</h1>
    )
  const siteSelectionMarkup =
    sitesList.length > 1 && isHomePage ? (
      <div className='site-selection-area' onClick={handlePageTitleClick}>
        {[selectedSite?.address1, selectedSite?.state].join(', ')}{' '}
        <img
          src={isSelectingSite ? chevronUp : chevronDown}
          alt='site-selection'
          className='ml-2'
        />
      </div>
    ) : null
  const panelsHeaderMarkup = (
    <nav
      className={clsx('navbar-panels level is-mobile', className)}
      role='navigation'
      aria-label='main navigation'
    >
      <div className='header-items-container level-item has-text-centered navbar-title'>
        <div className='navbar-brand'>{mySunPowerLogoMarkup}</div>
        {headerTabItems}
        {siteSelectionMarkup}
        <div onClick={toggleSideNav} className='navbar-end profile-icon'>
          <img src={profileIcon} alt='open menu' />
          <h6>MENU</h6>
        </div>
      </div>
      {sideNavMenuMarkup}
      {showSiteSelection}
    </nav>
  )
  const noPanelsHeaderMarkup = (
    <nav
      className={clsx('navbar level is-mobile', className, {
        hiddenLogo: logoIsHidden,
      })}
      role='navigation'
      aria-label='main navigation'
    >
      <div className='level-item has-text-centered navbar-title'>
        {!logoIsHidden && (
          <div className='navbar-brand'>{mySunPowerLogoMarkup}</div>
        )}
        {pageTitleMarkup}
        {headerChildren?.right && (
          <div
            className={clsx('nav-var-right-container', {
              'has-menu': !menuIsHidden,
            })}
          >
            {headerChildren?.right}
          </div>
        )}
        {!menuIsHidden && (
          <div className='navbar-end profile-icon'>
            <img onClick={toggleSideNav} src={profileIcon} alt='open menu' />
          </div>
        )}
      </div>
      {sideNavMenuMarkup}
      {showSiteSelection}
    </nav>
  )
  return PANELS_LAYOUT_FLAG && showThePanels
    ? panelsHeaderMarkup
    : noPanelsHeaderMarkup
}
