import {
  CustomParams,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from '@firebase/analytics'
import { FirebaseApp, initializeApp } from '@firebase/app'

import paths from '../pages/Router/paths'
import {
  getAlertsData,
  getSiteData,
  getUserData,
} from './compileSessionInformation'

export const SUBSCRIBED_PAGES = [
  paths.ROOT,
  paths.PROFILE,
  paths.PANELS,
  paths.CONTACT_US,
  paths.LIFE_EVENTS,
  paths.F_A_Q,
  paths.PAYMENTS,
  paths.CONNECTED_SOLUTIONS,
]

const pathToName = (path: String): string => {
  let pageName = ''
  switch (path) {
    case paths.ROOT:
      pageName = 'home'
      break
    case paths.PROFILE:
      pageName = 'profile'
      break
    case paths.PANELS:
      pageName = 'panels'
      break
    case paths.CONTACT_US:
      pageName = 'help_and_support'
      break
    case paths.LIFE_EVENTS:
      pageName = 'life_events'
      break
    case paths.F_A_Q:
      pageName = 'faqs'
      break
    case paths.PAYMENTS:
      pageName = 'payments'
      break
    case paths.CONNECTED_SOLUTIONS:
      pageName = 'connected_solutions'
      break
    default:
      pageName = ''
  }
  return pageName
}

let app: null | FirebaseApp = null

export enum FirebaseEvents {
  LOGIN = 'global_user_session_start',
  SELECT_SITE = 'select_site',
  BUTTON_EVENT = 'button_event',
  PAGE_VIEW = 'page_view',
  GLOBAL_HOME = 'global_home_screen_shown',
  CONNECT_SYSTEM = 'global_connect_my_system_screen_shown',
}

export const initAnalytics = (firebaseConfig) => {
  if (firebaseConfig?.apiKey && firebaseConfig?.measurementId) {
    app = initializeApp(firebaseConfig)
  }
}

export const fbTrackEvent = (name: FirebaseEvents, options: object = {}) => {
  if (app) {
    const analitycs = getAnalytics(app)
    logEvent(analitycs, name as string, options)
  }
}

export const fbSetUserProperties = (options: CustomParams = {}) => {
  if (app) {
    const analitycs = getAnalytics(app)
    setUserProperties(analitycs, options)
  }
}

export const trackPageView = (path: string) => {
  fbTrackEvent(FirebaseEvents.PAGE_VIEW, {
    site: pathToName(path),
  })
}

export const setSiteData = (user: any, site: any, alerts?: any[]) => {
  const userData = getUserData(user)
  const siteData = getSiteData(site)
  const alertsData = getAlertsData(alerts)
  fbSetUserProperties({
    ...userData,
    ...siteData,
    ...alertsData,
  })
}

export const initUserId = (idUser: string) => {
  if (app) {
    const analitycs = getAnalytics(app)
    setUserId(analitycs, idUser)
  }
}
