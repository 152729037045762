import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

type ProtectedRouteProps = RouteProps & {
  isActive: boolean

  children: JSX.Element
}

export const ProtectedRoute = ({
  isActive,
  children,
  ...rest
}: ProtectedRouteProps) => {
  if (!isActive) {
    return <Redirect to="/" />
  }

  return <Route {...rest}>{children}</Route>
}
