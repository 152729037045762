import './style.scss'

import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'

export const DayPicker = ({ daySelected = 1, onChange = (v) => {} }) => {
  const onSelect = React.useCallback(
    (daySelected) => {
      onChange(daySelected)
    },
    [onChange],
  )
  const dayItems = Array(30)
    .fill(0)
    .map((item, index) => {
      const dayValue = index + 1
      return (
        <article
          key={`day-select-${index}`}
          className={`day ${daySelected === dayValue ? 'active' : ''}`}
          onClick={() => onSelect(dayValue)}
        >
          {dayValue}
        </article>
      )
    })
  return (
    <div className="day-picker">
      <h3 className="has-text-weight-bold is-size-4 mt-2 mb-2">
        Tap on the day:
      </h3>
      <section className="day-selection-container">{dayItems}</section>
    </div>
  )
}
