import './style.scss'

import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

import paths from '../../pages/Router/paths'
import { CONNECTION_STATUSES } from '../../shared/connectionStatuses'
import cellular0Icon from './assets/cellular-0-bars.svg'
import cellular1Icon from './assets/cellular-1-bars.svg'
import cellular2Icon from './assets/cellular-2-bars.svg'
import cellular3Icon from './assets/cellular-3-bars.svg'
import cellular4Icon from './assets/cellular-4-bars.svg'
import cellular5Icon from './assets/cellular-5-bars.svg'
import disconnectedIcon from './assets/disconnected.svg'
import errorIcon from './assets/error.svg'
import ethernetIcon from './assets/ethernet.svg'
import homeIcdIcon from './assets/home-icd.svg'
import powerLineIcon from './assets/power-line.svg'
import wifi0Icon from './assets/wifi-0-bars.svg'
import wifi1Icon from './assets/wifi-1-bars.svg'
import wifi2Icon from './assets/wifi-2-bars.svg'
import wifi3Icon from './assets/wifi-3-bars.svg'
import wifi4Icon from './assets/wifi-4-bars.svg'

export type NetworkReport = {
  cellMdmStatVal?: string
  cellPrvdNm?: string
  cellSgnlStrenDbm?: number
  cellSimStatVal?: string
  cellStatEnum?: string
  currNetIntfEnum?: string
  edpConnFl?: boolean | null
  inetConnFl?: boolean
  msgCrtEps?: number
  prevNetIntfEnum?: string
  sgnlStrenSta0Dbm?: number
  sta0Ssid?: string
  wifiStatEnum?: string
  cellIconBars?: number
  wifiIconBars?: number
}

const WIFI_STATUSES = {
  0: wifi0Icon,
  1: wifi1Icon,
  2: wifi2Icon,
  3: wifi3Icon,
  4: wifi4Icon,
  5: wifi4Icon,
}
const CELLULAR_STATUSES = {
  0: cellular0Icon,
  1: cellular1Icon,
  2: cellular2Icon,
  3: cellular3Icon,
  4: cellular4Icon,
  5: cellular5Icon,
}

export const CONNECTION_STATUSES_STYLES = {
  [CONNECTION_STATUSES.DISCONNECTED]: (_) => ({
    class: 'disconnected',
    icon: disconnectedIcon,
    description: 'No Communication',
  }),
  [CONNECTION_STATUSES.ERROR]: (_) => ({
    class: 'connection-error',
    icon: errorIcon,
    description: "We're unable to get the current communication method",
  }),
  [CONNECTION_STATUSES.ICD]: (_) => ({
    class: 'icd-alert',
    icon: homeIcdIcon,
    description: 'System is not communicating to the network',
  }),
  [CONNECTION_STATUSES.POWER_LINE]: (_) => ({
    class: 'power-line',
    icon: powerLineIcon,
    description: 'Power-Line Communication',
  }),
  [CONNECTION_STATUSES.WIFI]: (level = 0) => ({
    class: 'wifi',
    icon: WIFI_STATUSES[level],
    description: 'WiFi',
  }),
  [CONNECTION_STATUSES.CELLULAR]: (level) => ({
    class: 'cellular',
    icon: CELLULAR_STATUSES[level],
    description: 'Cellular',
  }),
  [CONNECTION_STATUSES.ETHERNET]: (_) => ({
    class: 'ethernet',
    icon: ethernetIcon,
    description: 'Ethernet',
  }),
  [CONNECTION_STATUSES.ETHERNET2]: (_) => ({
    class: 'ethernet',
    icon: ethernetIcon,
    description: 'Ethernet',
  }),
  [CONNECTION_STATUSES.ETHERNET3]: (_) => ({
    class: 'ethernet',
    icon: ethernetIcon,
    description: 'Ethernet',
  }),
}

export const SystemConnection = ({
  networkReport,
  networkReportError,
  hasICD = false,
}: {
  networkReport?: NetworkReport | null
  networkReportError?: Error | null
  hasICD?: boolean
}) => {
  const level =
    networkReport?.currNetIntfEnum === CONNECTION_STATUSES.WIFI
      ? networkReport?.wifiIconBars
      : networkReport?.cellIconBars

  let netEnum = (
    networkReport?.currNetIntfEnum ?? CONNECTION_STATUSES.ETHERNET
  ).toUpperCase()

  if (
    networkReportError ||
    networkReport === null ||
    networkReport === undefined
  ) {
    netEnum = CONNECTION_STATUSES.ERROR
  }

  if (hasICD) {
    netEnum = CONNECTION_STATUSES.ICD
  }

  const network = networkReport?.sta0Ssid
  const mobileNetwork = networkReport?.cellPrvdNm
  const connStyle = CONNECTION_STATUSES_STYLES[netEnum]?.(level) ?? {}

  return (
    <div className={clsx('system-connection', connStyle.class)}>
      {netEnum !== CONNECTION_STATUSES.ICD && (
        <>
          <div className="head mt-5">
            <h2 className="mb-0 description">Current communication method:</h2>
          </div>
          <div className="head my-3">
            <img src={connStyle.icon} alt="system-connection" />
          </div>
        </>
      )}
      {netEnum === CONNECTION_STATUSES.WIFI && network && (
        <div className="has-text-centered has-text-weight-bold">{network}</div>
      )}
      {netEnum === CONNECTION_STATUSES.CELLULAR && mobileNetwork && (
        <div className="has-text-centered has-text-weight-bold">
          {mobileNetwork}
        </div>
      )}
      {netEnum === CONNECTION_STATUSES.ICD && (
        <div className="head my-6 mx-6 px-6">
          <img src={connStyle.icon} alt="system-connection" />
        </div>
      )}
      <div className="status mb-6">
        <h2 className="is-size-5 has-text-centered has-text-weight-bold green-text">
          {connStyle.description}
        </h2>
        {netEnum === CONNECTION_STATUSES.ICD && (
          <p className="mt-6 mb-3">
            Check out the {<Link to={paths.F_A_Q}>FAQ</Link>} page for a
            troubleshooting guide
          </p>
        )}
      </div>
    </div>
  )
}
