import './style.scss'

import clsx from 'clsx'
import moment, { unitOfTime } from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { useDateRangeLabel } from '../../hooks/useDateRangeLabel'
import { INTERVALS } from '../../shared/intervals'
import { DataLayerEventName } from '../../shared/typings/dataLayer'
import { Calendar } from '../calendar'
import calendarIcon from './assets/calendar.svg'
import leftArrowIcon from './assets/left-arrow.svg'
import rightArrowIcon from './assets/right-arrow.svg'

// Format from toDateString used to convert dates in/out bulma-calendar
export const DATE_STRING_FORMAT = 'ddd MMM DD YYYY'

export const FilterBar = ({
  onChange = (dates: { start: number; end: number }, interval: INTERVALS) => {},
  commissionDate,
  timezone,
  siteKey,
}) => {
  const [interval, setIvl] = useState<any>(INTERVALS.DAY)
  const [toDisabled, setToDisabled] = useState(true)
  const [fromDisabled, setFromDisabled] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  // Using moment objects does not trigger useEffect
  const [dates, setDates] = useState({
    start: 0,
    end: 0,
  })
  const isLifetime = interval === INTERVALS.LIFETIME
  const isCustom = interval === INTERVALS.CUSTOM
  let isCustomRange = false
  if (isCustom) {
    const start = moment.tz(dates.start, timezone)
    const end = moment.tz(dates.end, timezone)
    isCustomRange = !start.isSame(end, 'day')
  }
  const operationsUnitTime = isCustom && !isCustomRange ? 'days' : interval
  useEffect(() => {
    if (timezone) {
      onChange(dates, interval)
    }
  }, [dates, interval, timezone, onChange])

  useEffect(() => {
    if (
      ![INTERVALS.LIFETIME, INTERVALS.CUSTOM].includes(interval) &&
      siteKey &&
      timezone
    ) {
      setDates({
        start: moment().tz(timezone).startOf(interval).valueOf(),
        end: moment().tz(timezone).endOf(interval).valueOf(),
      })
    }
  }, [interval, siteKey, timezone])

  useEffect(() => {
    if (interval === INTERVALS.LIFETIME) {
      setDates({
        start: moment(commissionDate).tz(timezone).startOf('year').valueOf(),
        end: moment().tz(timezone).endOf('year').valueOf(),
      })
    }
  }, [interval, commissionDate])

  useEffect(() => {
    const commissionDateMoment = moment(commissionDate).clone()
    const startDateMoment = moment(dates.start).tz(timezone)
    const toDateMoment = moment(dates.end).tz(timezone)

    switch (interval) {
      case INTERVALS.DAY: {
        const maximumTo = moment().tz(timezone).endOf('day')
        setToDisabled(
          moment(toDateMoment)
            .add(1, interval as unitOfTime.Base)
            .isAfter(maximumTo),
        )
        const startDateCopy = startDateMoment.clone()
        const minimumDay = commissionDateMoment.clone().startOf('day')
        const checkDay = startDateCopy
          .subtract(1, interval as unitOfTime.Base)
          .isBefore(minimumDay)
        setFromDisabled(checkDay)
        break
      }
      case INTERVALS.WEEK: {
        const maximumTo = moment().tz(timezone).endOf('week')
        setToDisabled(
          moment(toDateMoment)
            .add(1, interval as unitOfTime.Base)
            .isAfter(maximumTo),
        )
        const startDateCopy = startDateMoment.clone()
        const minimumWeek = commissionDateMoment.clone().startOf('week')
        const checkWeek = startDateCopy
          .subtract(1, interval as unitOfTime.Base)
          .isBefore(minimumWeek)
        setFromDisabled(checkWeek)
        break
      }
      case INTERVALS.MONTH: {
        const maximumTo = moment().tz(timezone).endOf('month')
        setToDisabled(
          moment(toDateMoment)
            .add(1, interval as unitOfTime.Base)
            .isAfter(maximumTo),
        )
        const startDateCopy = startDateMoment.clone()
        const minimumMonth = commissionDateMoment.clone().startOf('month')
        const checkMonth = startDateCopy
          .subtract(1, interval as unitOfTime.Base)
          .isBefore(minimumMonth)
        setFromDisabled(checkMonth)
        break
      }
      case INTERVALS.YEAR: {
        const maximumTo = moment().tz(timezone).endOf('year')
        setToDisabled(
          moment(toDateMoment)
            .add(1, interval as unitOfTime.Base)
            .isAfter(maximumTo),
        )
        const startDateCopy = startDateMoment.clone()
        const minimumYear = commissionDateMoment.clone().startOf('year')
        const checkYear = startDateCopy
          .subtract(1, interval as unitOfTime.Base)
          .isBefore(minimumYear)
        setFromDisabled(checkYear)
        break
      }
      default: {
        break
      }
    }
  }, [dates.start, dates.end, interval, commissionDate])

  const onAddInterval = React.useCallback(() => {
    if (!toDisabled && ![INTERVALS.LIFETIME].includes(interval)) {
      setDates({
        start: moment(dates.start)
          .tz(timezone)
          .add(1, operationsUnitTime as unitOfTime.Base)
          .startOf(interval)
          .valueOf(),
        end: moment(dates.end)
          .tz(timezone)
          .add(1, operationsUnitTime as unitOfTime.Base)
          .endOf(interval)
          .valueOf(),
      })
    }
  }, [setDates, dates.start, dates.end, interval, toDisabled])

  const onSubtractInterval = React.useCallback(() => {
    if (!fromDisabled && ![INTERVALS.LIFETIME].includes(interval)) {
      const start = moment(dates.start)
        .tz(timezone)
        .subtract(1, operationsUnitTime as unitOfTime.Base)
        .startOf(interval)
        .valueOf()
      const end = moment(dates.end)
        .tz(timezone)
        .subtract(1, operationsUnitTime as unitOfTime.Base)
        .endOf(interval)
        .valueOf()
      setDates({
        start: start,
        end: end,
      })
    }
  }, [setDates, dates.start, dates.end, interval, fromDisabled])

  const onCustomDateSelect = React.useCallback(
    (date) => {
      const timezoneStartDate = moment.tz(
        date.startDate.toDateString(),
        DATE_STRING_FORMAT,
        timezone,
      )
      const timezoneEndDate = moment
        .tz(date.endDate.toDateString(), DATE_STRING_FORMAT, timezone)
        .endOf('day')

      setDates({
        start: timezoneStartDate.valueOf(),
        end: timezoneEndDate.valueOf(),
      })
      setShowCalendar(false)
      setIvl(INTERVALS.CUSTOM)
    },
    [setDates, setShowCalendar, timezone],
  )

  const dateLabel = useDateRangeLabel(
    dates.start,
    dates.end,
    interval,
    timezone,
  )

  const filterIntervalSelect = (intervalSelected, prevArrowDisabled) => {
    window.dataLayer.push({
      event: DataLayerEventName.INTERVAL_SELECT,
      eventData: { intervalSelected },
    })
    setIvl(intervalSelected)
    setFromDisabled(prevArrowDisabled)
  }
  return (
    <div className="filter-bar">
      <div
        className={clsx('filter is-flex', {
          selected: interval === INTERVALS.DAY,
        })}
        onClick={() => filterIntervalSelect(INTERVALS.DAY, false)}
      >
        <span>Day</span>
      </div>
      <div
        className={clsx('filter is-flex', {
          selected: interval === INTERVALS.WEEK,
        })}
        onClick={() => filterIntervalSelect(INTERVALS.WEEK, false)}
      >
        <span>Week</span>
      </div>
      <div
        className={clsx('filter is-flex', {
          selected: interval === INTERVALS.MONTH,
        })}
        onClick={() => filterIntervalSelect(INTERVALS.MONTH, false)}
      >
        <span>Month</span>
      </div>
      <div
        className={clsx('filter is-flex', {
          selected: interval === INTERVALS.YEAR,
        })}
        onClick={() => filterIntervalSelect(INTERVALS.YEAR, false)}
      >
        <span>Year</span>
      </div>
      <div
        className={clsx('filter is-flex', {
          selected: interval === INTERVALS.LIFETIME,
        })}
        onClick={() => filterIntervalSelect(INTERVALS.LIFETIME, true)}
      >
        <span>Lifetime</span>
      </div>
      <div
        className={clsx('filter is-flex', {
          selected: interval === INTERVALS.CUSTOM,
        })}
        onClick={() => setShowCalendar(true)}
      >
        <img src={calendarIcon} alt="calendar icon" />
        <span className="with-icon">Custom</span>
        {showCalendar && (
          <OutsideClickHandler onOutsideClick={() => setShowCalendar(false)}>
            <Calendar
              onChange={onCustomDateSelect}
              className="filter-calendar"
              options={{
                startDate: moment
                  .tz(dates.start, timezone)
                  .format(DATE_STRING_FORMAT),
                endDate: moment
                  .tz(dates.end, timezone)
                  .format(DATE_STRING_FORMAT),
                isRange: true,
                maxDate: moment.tz(timezone).toDate(),
                minDate: moment.tz(commissionDate, timezone).toDate(),
              }}
            />
          </OutsideClickHandler>
        )}
      </div>
      <div className="filter is-flex switcher">
        <div
          data-testid="arrow-prev"
          onClick={onSubtractInterval}
          className={clsx({
            disabled: fromDisabled || isLifetime || isCustomRange,
          })}
        >
          <img src={leftArrowIcon} alt="previous day" />
        </div>
        <span data-testid="date-label">{dateLabel}</span>
        <div
          data-testid="arrow-next"
          onClick={onAddInterval}
          className={clsx({
            disabled: toDisabled || isLifetime || isCustomRange,
          })}
        >
          <img src={rightArrowIcon} alt="next day" />
        </div>
      </div>
    </div>
  )
}
