import './style.scss'

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useSession } from '../../shared/hooks'
import { LayoutWithBackground } from '../../components/layout-with-background'
import { Modal } from '../../components/modal'
import { FirebaseEvents, fbTrackEvent } from '../../shared/firebaseAnalytics'
import paths from '../Router/paths'
import homeImg from './assets/clear-home.png'

export const PendingSystem = ({ modal }: { modal?: JSX.Element }) => {
  const { logout } = useSession()

  useEffect(() => {
    fbTrackEvent(FirebaseEvents.CONNECT_SYSTEM)
  }, [])

  return (
    <LayoutWithBackground title="" asset={homeImg} className="layout-styles">
      <div className="inner-container">
        <h2>Welcome</h2>
        <p className="mt-5">to the mySunpower</p>
        <p>monitoring application</p>
        <p className="mt-5">
          It looks like there is no system connected to your account.
        </p>
        <p className="mt-5">
          You can connect your system through the mySunPower
        </p>
        <p>mobile application.</p>

        <a
          href="https://us.sunpower.com/products/software/mysunpower"
          target="_blank"
          rel="noreferrer"
          className="button is-primary learn-more-button"
        >
          Learn more
        </a>

        <p className="mt-5 instructions">
          <b>If your solar system is not installed yet,</b>
          please return when the installation is completed.
        </p>
        <div className="links-container">
          <Link to={paths.CONTACT_US} className="inner-link">
            Need help?
          </Link>
          <button onClick={() => logout()} className="inner-link">
            Logout
          </button>
        </div>
      </div>
      <>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.PENDING_SYSTEM}>
            {modal}
          </Modal>
        )}
      </>
    </LayoutWithBackground>
  )
}
