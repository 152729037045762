import React from 'react'

export const MyAccountInfo = () => {
  return (
    <div className="content">
      <div className="mb-5">
        <p className="is-size-5-5">
          Your account information includes your name, email, and phone number.
          Use this email to sign into your account and it's also the email we'll
          use to get in touch with you!
        </p>
      </div>
    </div>
  )
}
