import { useFlags } from 'launchdarkly-react-client-sdk'
import qs from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import {
  FirebaseEvents,
  fbTrackEvent,
  initUserId,
} from './../../shared/firebaseAnalytics'
import { Loader } from '../../components/loader'
import {
  MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
  MY_SUNPOWER_MONITORING_ID_TOKEN,
  MY_SUNPOWER_PENDING_SYSTEM,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { UserContext } from '../../context/userContext'
import { useRemoveCookies } from '../../hooks/useRemoveCookies'
import { decodeJwtToken } from '../../shared/decodeToken'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import paths from '../Router/paths'

const SALES_REDIRECTION = 'deal-portal'
// export const COOKIES_OPTIONS = { path: '/monitor' } // todo: remove for ruby sign-in support
export const COOKIES_OPTIONS = { path: '/' } // make cookie to be accessible on all pages

export const Callback = ({ location, history }) => {
  const [redirectionActive, setRedirectionActive] = useState(false)
  const userContext = useContext(UserContext)
  const params = qs.parse(location.hash)
  const [cookies, setCookie, removeCookie] = useCookies([
    MY_SUNPOWER_MONITORING_ID_TOKEN,
    MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    MY_SUNPOWER_PENDING_SYSTEM,
  ])
  const removeCookies = useRemoveCookies(COOKIES_OPTIONS)
  const flags = useFlags() as LaunchDarklyFlags

  useEffect(() => {
    removeCookies()
    if (params.id_token && params.access_token) {
      const decodedId = decodeJwtToken(params.id_token.toString())
      const decodedToken = decodeJwtToken(params.access_token.toString())

      const { email, name, userEmail } = decodedId
      const { partyId, isAdmin, uid } = decodedToken
      const userSessionInfo = {
        email,
        name,
        partyId,
        isAdmin,
        userEmail,
        uid,
      }
      userContext?.loginUser(
        params.id_token,
        params.access_token,
        userSessionInfo,
        COOKIES_OPTIONS
      )
      removeCookie(USER_SELECTED_SITE_KEY)
    }
  }, [
    params.id_token,
    params.access_token,
  ])

  useEffect(() => {
    const session = cookies?.[USER_SESSION_INFO] ?? {}
    const pending_system = cookies?.[MY_SUNPOWER_PENDING_SYSTEM] === 'true'
    const PENDING_SYSTEM_FLAG = flags?.pendingSystemFlag ?? false
    const isAdmin = session?.isAdmin
    const redirectUserURL = session.isAdmin ? paths.MONITORING_ADMIN_LOGIN : paths.SITES_LIST
    const fetched = userContext?.user?.fetched
    const overriddenMilestone = userContext?.user?.data?.user?.overriddenMilestone
    const salesOktaLoginUrl = userContext?.user?.data?.user?.oktaLoginUrl

    if (!isAdmin) {
      if (fetched && overriddenMilestone === SALES_REDIRECTION) {
        window.location.href = salesOktaLoginUrl
      } else if (fetched && PENDING_SYSTEM_FLAG && pending_system) {
        history.push(paths.PENDING_SYSTEM)
      } else if (fetched) {
        history.push(redirectUserURL)
      }
    } else {
      history.push(redirectUserURL)
    }
  }, [cookies, history, userContext])

  return <Loader />
}
