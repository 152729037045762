import Highcharts from 'highcharts'
import moment from 'moment-timezone'

import { INTERVALS } from '../../shared/intervals'
import { toCommaNumber } from '../../shared/toCommaNumber'

export type IntervalSettings = {
  xAxisFormat: string
  xAxisStep?: number
  energyTooltipFormat: string
  powerTooltipFormat?: string
  xLabelsOffset?: number
  endDateOffset?: number
  disableMinX?: boolean
  disableMaxX?: boolean
}

export const getEnergyGraphOptions = (
  producedData,
  consumedData,
  maxY: number,
  intervalSettings: IntervalSettings,
  hasConsumption: boolean,
  startDate: number | null,
  endDate: number | null,
  interval = 'day',
) => {
  const onlyOneDataPoint = producedData.length === 1
  const dataSeries: Highcharts.SeriesOptionsType[] = [
    {
      name: 'PRODUCED',
      type: 'column',
      data: producedData,
      opacity: 1,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#0a9b3b'],
          [1, '#179E2B'],
        ],
      },
    },
  ]

  if (hasConsumption) {
    dataSeries.push({
      name: 'USED',
      type: 'column',
      data: consumedData,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#2B93CC'],
          [1, '#045886'],
        ],
      },
    })
  }

  if (endDate) {
    endDate = moment(endDate)
      .add(intervalSettings.endDateOffset, 'second')
      .valueOf()
  }

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      reflow: true,
      spacing: [10, 0, 40, 0],
      plotBackgroundColor: 'transparent',
    },
    legend: {
      enabled: true,
      floating: true,
      itemStyle: {
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#0076be',
      },
      itemHoverStyle: {
        cursor: 'pointer',
        color: '#F7921E',
      },
      symbolHeight: 16,
      verticalAlign: 'bottom',
      align: 'left',
      x: 35,
      y: 35,
    },
    plotOptions: {
      column: {
        pointPadding: 0.05,
        groupPadding: 0.1,
        borderWidth: 0,
      },
      series: {
        events: {
          legendItemClick: function () {
            // Prevents hiding all series
            const clickedSeries = this
            const series = clickedSeries.chart.series
            const visibleSeries = series.map(({ visible }) => visible)
            visibleSeries[clickedSeries.index] = !visibleSeries[
              clickedSeries.index
            ]
            const isSeriesVis = visibleSeries.some((vis) => vis)
            if (!isSeriesVis) return false
          },
        },
      },
    },
    xAxis: {
      tickWidth: 0,
      type: 'datetime',
      // for lifetime we set the tickInterval manually:  https://stackoverflow.com/questions/38076540/highcharts-tickinterval-with-datetime-values
      tickInterval:
        interval === INTERVALS.LIFETIME ? 365 * 24 * 60 * 60 * 1000 : undefined,
      labels: {
        formatter: function () {
          return moment(this.value).format(intervalSettings.xAxisFormat)
        },
        x: intervalSettings.xLabelsOffset ?? 0,
        style: {
          color: '#00000',
          fontWeight: 'bold',
        },
      },
      min: onlyOneDataPoint
        ? null
        : intervalSettings?.disableMinX
        ? null
        : startDate, //start point of the x-axis
      max: onlyOneDataPoint
        ? null
        : intervalSettings?.disableMaxX
        ? null
        : endDate, //end point of the x-axis
    },
    yAxis: {
      title: {
        style: {
          display: 'none',
        },
      },
      tickWidth: 0,
      gridLineDashStyle: 'Dash',
      gridLineColor: '#9cbccd',
      showLastLabel: true,
      labels: {
        enabled: true,
        formatter: function () {
          let divideBy = 1
          const absNum = Math.abs(this.value)
          const absMax = Math.abs(maxY)
          if (absMax < 1) {
            divideBy = 1000
          } else if (absNum > 0 && absNum < 100) {
            divideBy = 10
          }

          const wrapper =
            absNum >= absMax
              ? (content) =>
                  `<span style="font-weight: bold; color: #000000;">${content}</span>`
              : (content) => content

          return wrapper(
            `${Math.round((this.value as number) * divideBy) / divideBy} kWh`,
          )
        },
        align: 'right',
        x: -5,
        y: 0,
        zIndex: 2,
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      shadow: false,
      shared: true,
      formatter: function () {
        const tooltipTimelinePoints = this.points?.reduce(
          (timeLineValues: any, point) => {
            if (point) {
              const nextTimestamp = moment(point.x).clone().add(1, 'hour')
              return {
                currentTimeLine: this.x,
                nextTimeline: nextTimestamp,
              }
            }
          },
          {},
        )
        const tooltipTimeLinePartOne = moment(
          tooltipTimelinePoints.currentTimeLine,
        ).format(intervalSettings.energyTooltipFormat)
        const tooltipTimeLinePartTwo =
          tooltipTimelinePoints.nextTimeline !== '-'
            ? moment(tooltipTimelinePoints.nextTimeline).format('ha')
            : '--'
        const tooltipSeries = this.points?.reduce((tooltip, point) => {
          if (point) {
            tooltip +=
              '<div style="padding:1px 4px;color:#FFF;text-align:right;background-color:' +
              `${(point.color as any).stops?.[0]?.[1]}">` +
              `${toCommaNumber(point.y)} kWh` +
              '</div>'
          }
          return tooltip
        }, '')
        const tooltipDateTimeContent =
          interval === INTERVALS.DAY
            ? `<div style="padding:1px 4px;color:#FFF;background-color:#5e6367">
            ${tooltipTimeLinePartOne} - ${tooltipTimeLinePartTwo}</div>${tooltipSeries}`
            : `<div style="padding:1px 4px;color:#FFF;background-color:#5e6367">
            ${tooltipTimeLinePartOne}</div>${tooltipSeries}`
        return tooltipDateTimeContent
      },
    },
    title: {
      style: {
        display: 'none',
      },
    },
    series: dataSeries,
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  }

  return options
}
