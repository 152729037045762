export function getTimeFromTimeStamp(timeStamp) {
  return new Date(timeStamp).getTime()
}
const filterIncomingMessages = (messages) => {
  if (!Array.isArray(messages)) {
    return []
  }

  return messages.filter((msg) => msg)
}

export default filterIncomingMessages
