import './styles.scss'

import clsx from 'clsx'
import React, { useState } from 'react'

import moneyLogo from '../../assets/how-it-works/money-icon.svg'

type TabType = {
  id: number
  tabTitle: string
  infoTitle: string
  infoText?: string
  infoArray?: { text: string; style?: string }[]
  webImage: string
  mobileImage: string
}

type StepperProps = {
  title: string
  tabsInfo: TabType[]
  hidenLogo?: boolean
  subText?: string
}

export const Stepper = ({
  title,
  tabsInfo,
  hidenLogo = false,
  subText,
}: StepperProps) => {
  const [selectedTab, setSelectedTab] = useState<TabType>(tabsInfo[0])
  const handleSelectTab = (newValue: TabType) => {
    setSelectedTab(newValue)
  }

  return (
    <section className="how-it-works-tab-container">
      {!hidenLogo && (
        <img src={moneyLogo} alt="money logo" className="money-icon" />
      )}
      <h1 className="main-tittle">{title}</h1>
      {subText && <p className="sub-text">{subText}</p>}
      <section className="info-container">
        <section className="steps">
          {tabsInfo.map((element) => {
            return (
              <ButtonTab
                value={element}
                selectedTab={selectedTab}
                handleSelectTab={handleSelectTab}
                key={`step-tab-${element.id}`}
              />
            )
          })}
        </section>
        <InfoTab value={selectedTab} />
      </section>
      <section className="responsive-steps">
        {tabsInfo.map((currentTab) => {
          return <InfoTab value={currentTab} key={currentTab.id} />
        })}
      </section>
    </section>
  )
}

const ButtonTab = ({
  value,
  selectedTab,
  handleSelectTab,
}: {
  value: TabType
  selectedTab: TabType
  handleSelectTab: (TabType) => void
}) => {
  return (
    <button
      className={clsx(
        'step-button',
        selectedTab.id === value.id && 'active-tab',
      )}
      onClick={(_) => handleSelectTab(value)}
    >
      {`${value.id}. ${value.tabTitle}`}
    </button>
  )
}

const InfoTab = ({ value }: { value: TabType }) => {
  const { id, infoTitle, infoText, webImage, mobileImage, infoArray } = value
  return (
    <section className="mkt-info-card">
      <div className="mkt-info-card-number">{id}</div>
      <div className="mkt-info-card-text">
        <h2 className="mkt-info-card-tittle">{infoTitle}</h2>
        {infoText && <p className="mkt-info-paragraph">{infoText}</p>}
        {infoArray && infoArray.length > 0 && (
          <ul className="mkt-info-card-list">
            {infoArray.map((current, index) => {
              return (
                <li key={`mkt-tab-${index}`} className={current.style}>
                  {current.text}
                </li>
              )
            })}
          </ul>
        )}
        <img src={webImage} alt={infoTitle} className="mkt-info-card-img" />
        <img
          src={mobileImage}
          alt={infoTitle}
          className="mkt-info-card-img-mobile"
        />
      </div>
    </section>
  )
}
