import React, { createContext, useState } from 'react'

import {
  UseUtilityRatePlansReturnType,
  defaultvalue,
} from '../shared/hooks/useUtilityRatePlans'
import { TariffPlanUIStructureResponse } from '../shared/typings/site'

export const CustomTariffContext = createContext<any>(null)

type propsType = {
  children: JSX.Element
}
type UseCurrentPlanReturnType = {
  data: { currentSiteTariffDetails: UseUtilityRatePlansReturnType | null }
  loading: boolean
  called: boolean
  refetch?: Function
}

const CustomTariffContextProvider = ({ children }: propsType) => {
  const [utilityPlans, setUtilityPlans] =
    useState<UseUtilityRatePlansReturnType>(defaultvalue)
  const [utilityPlanDetail, setUtilityPlanDetail] =
    useState<UseCurrentPlanReturnType | null>(null)
  const [userCurrentPlan, setUserCurrentPlan] =
    useState<TariffPlanUIStructureResponse | null>(null)
  return (
    <CustomTariffContext.Provider
      value={{
        utilityPlans,
        setUtilityPlans,
        utilityPlanDetail,
        setUtilityPlanDetail,
        userCurrentPlan,
        setUserCurrentPlan,
      }}
    >
      {children}
    </CustomTariffContext.Provider>
  )
}

export default CustomTariffContextProvider
