import React, { useEffect, useContext } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { 
  MY_SUNPOWER_MONITORING_ID_TOKEN,
 } from '../../config/cookies'
 import { UserContext } from '../../context/userContext'

type PrivateRouteProps = RouteProps

export const PrivateRoute = ({
  component,
  ...rest
}: PrivateRouteProps) => {
  const [cookies] = useCookies([MY_SUNPOWER_MONITORING_ID_TOKEN])
  const mySunpowerMonitoringIdToken = cookies[MY_SUNPOWER_MONITORING_ID_TOKEN] ?? null
  const userContext = useContext(UserContext)

  useEffect(() => {
    if (!mySunpowerMonitoringIdToken && !userContext?.user?.logoutIdToken && !userContext?.user?.loggingOut) {
      window.location.href = process.env.REACT_APP_LOGOUT_URL || ""
    }
  }, [mySunpowerMonitoringIdToken, userContext?.user?.logoutIdToken, userContext?.user?.loggingOut])

  return (
    mySunpowerMonitoringIdToken ? <Route {...rest} component={component} /> : null
    )
}
