import './style.scss'

import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router-dom'

import infoIcon from '../widget-wrapper/assets/info-icon.svg'

export enum STATUS_LABEL_SIZES {
  SMALL = 'status-label-small',
  MEDIUM = 'status-label-medium',
  BIG = 'status-label-big',
}

type StatusLabelProps = {
  iconEnabled?: any
  iconDisabled?: any
  status?: boolean
  statusText: string
  label: string
  size?: STATUS_LABEL_SIZES
  centered?: boolean
  labelBold?: boolean
  statusTextBold?: boolean
  infoLink?: string
}

export const StatusLabel = ({
  iconEnabled,
  iconDisabled,
  status = true,
  statusText,
  label,
  size = STATUS_LABEL_SIZES.SMALL,
  centered = false,
  labelBold = false,
  statusTextBold = true,
  infoLink,
}: StatusLabelProps) => {
  const history = useHistory()

  const onInfoClick = React.useCallback(() => {
    if (infoLink) {
      history.push(infoLink)
    }
  }, [history, infoLink])

  return (
    <div
      className={clsx('status-label-wrapper', size, {
        'is-flex-centered': centered,
        spaced: size === STATUS_LABEL_SIZES.SMALL,
      })}
    >
      {(iconEnabled || iconDisabled) && (
        <div className="status-icon mr-5">
          <img src={status ? iconEnabled : iconDisabled} alt="status" />
        </div>
      )}
      <div className="status-label">
        <div
          className={clsx({
            'gray-text': size === STATUS_LABEL_SIZES.SMALL && !labelBold,
            'is-size-1': size === STATUS_LABEL_SIZES.BIG,
            'is-size-5': size === STATUS_LABEL_SIZES.MEDIUM,
            'light-text': size === STATUS_LABEL_SIZES.BIG,
            'has-text-weight-bold': labelBold,
            'is-uppercase': size !== STATUS_LABEL_SIZES.MEDIUM,
          })}
        >
          {label}
        </div>
        <div
          className={clsx('status-text', {
            'gray-text': size === STATUS_LABEL_SIZES.BIG,
            'is-size-5': size === STATUS_LABEL_SIZES.BIG,
            'has-text-weight-bold': statusTextBold,
            'is-uppercase': true,
          })}
        >
          {statusText}
          {infoLink && (
            <img
              className="status-label-icon ml-5"
              onClick={onInfoClick}
              src={infoIcon}
              alt="info"
            />
          )}
        </div>
      </div>
    </div>
  )
}
