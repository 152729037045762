import './styles.scss'

import React, { useEffect, useMemo } from 'react'
import Lottie from 'react-lottie'

import { Layout } from '../../../components/layout'
import { Loader } from '../../../components/loader'
import { useDynamicVpp } from '../../../shared/hooks/useDynamicVpp'
import { VPPEnrollment } from '../../../shared/typings/site'
import pidgeon_animation from '../assets/pidgeons'
import { ProgramCard } from '../vpp-components/program-card'

const pidgeonsOptions = {
  loop: true,
  autoplay: true,
  animationData: pidgeon_animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const DynamicProgramList = () => {
  const { fetchEnrollments, enrollments, loading } = useDynamicVpp()

  useEffect(() => {
    fetchEnrollments()
  }, [])

  const isEmpty = useMemo(() => {
    return enrollments?.length === 0
  }, [enrollments])

  return (
    <>
      <Layout title="SunPower VPP">
        <>
          <div className="vpp-list-container">
            {loading && <LoadingState />}
            {!loading && isEmpty && <EmptyPrograms />}
            {!loading && !isEmpty && <ProgramList enrollments={enrollments} />}
          </div>
          <div className="vpp-list-footer">
            <p>Have a question?</p>
            <a href="mailto:vpp@sunpower.com">vpp@sunpower.com</a>
          </div>
        </>
      </Layout>
    </>
  )
}

const EmptyPrograms = () => {
  return (
    <div className="vpp-list-empty-container">
      <Lottie options={pidgeonsOptions} />
      <h2 className="stay-touch-title">Let's stay in touch!</h2>
      <p className="stay-touch-paragraph">
        There are no available Virtual Power Plant (VPP) Programs around your
        location at this moment.
      </p>
      <p className="stay-touch-paragraph">
        Please email us at&nbsp;
        <a href="mailto:vpp@sunpower.com">vpp@sunpower.com</a> to receive the
        updates about VPP programs in your area.
      </p>
    </div>
  )
}

const ProgramList = ({ enrollments }: { enrollments: VPPEnrollment[] }) => {
  return (
    <>
      <p className="vpp-list-title">
        Select the program you'd like to participate in:
      </p>
      <div className="vpp-list-inner-container">
        {enrollments.map((currentEnrollment) => (
          <ProgramCard
            enrollment={currentEnrollment}
            key={currentEnrollment.enrollmentId}
          />
        ))}
      </div>
    </>
  )
}

const LoadingState = () => {
  return (
    <div className="vpp-list-loader">
      Loading programs
      <Loader />
    </div>
  )
}
