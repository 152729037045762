import React from 'react'

export const BillSavingsInfo = () => {
  return (
    <div className="content">
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          What can I learn from the Bill Savings widget?
        </h3>
        <p className="is-size-5-5">
          This is an estimate of the money you've saved from your investment in
          solar energy. Every kWh of solar energy you've generated means one
          less kWh of electricity you've had to buy from your utility.
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          Can I see my estimated bill savings different date ranges?
        </h3>
        <p className="is-size-5-5">
          You sure can. On the home page you can see your estimated savings for
          whatever time period you select with the date picker.
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          How is my bill savings calculated?
        </h3>
        <p className="is-size-5-5">
          Estimating bill savings from solar involves comparing your cost of
          electricity with and without solar. The goal is to determine your
          'avoided cost'—how much money you didn't spend on electricity from the
          grid because you used electricity from your solar system instead.
        </p>
        <p className="is-size-5-5">
          To determine your estimated bill savings, we first determine how much
          electricity your system generated during the time period in question
          (e.g., a week, a month, the lifetime of your system) and compare it to
          how much electricity you used during the same period.
        </p>
        <p className="is-size-5-5">
          We then multiply whichever quantity is smaller (electricity generated
          or electricity used) by the average cost per kWh within your state.
          The result is your estimate savings.
        </p>
      </div>
    </div>
  )
}
