import './styles.scss'

import moment from 'moment'
import React from 'react'

import { BOT, SYSTEM_MESSAGE } from '../../../../config/chat/config'
import { Direction, ItemDetails } from '../../../../shared/typings/chat'
import defaultAvatar from './assets/default-avatar.svg'

const CALENDAR_FORMAT = {
  lastDay: '[Yesterday]',
  sameDay: 'hh:mm a',
  lastWeek: '[Last] dddd',
  sameElse: 'dddd MMM D',
}

const hiddenAgents = [SYSTEM_MESSAGE, BOT]

export const ChatCard = ({
  item,
  index,
}: {
  item: ItemDetails
  index: number
}) => {
  if (item.type === 'EVENT') return null
  const isUser = item.transportDetails.direction === Direction.Incoming
  const dateFormatted = moment
    .unix(item?.transportDetails?.sentTime)
    .calendar(null, CALENDAR_FORMAT)
  return (
    <div className="chat-card-container">
      <div
        className={`messages-container ${
          !isUser && 'non-user-message-container'
        }`}
      >
        {isUser && <img src={defaultAvatar} alt="user-avatar" />}
        <div className="message-data-container">
          <div className={`user-card ${!isUser && 'non-user-card'}`}>
            {item?.content?.data}
          </div>
          <div className="message-data">
            {item?.displayName && (
              <div className="message-name">{`~ ${
                hiddenAgents.includes(item?.displayName)
                  ? ''
                  : item?.displayName
              }`}</div>
            )}
            <div className="message-date">{dateFormatted}</div>
          </div>
        </div>
        {!isUser && <img src={defaultAvatar} alt="user-avatar" />}
      </div>
    </div>
  )
}
