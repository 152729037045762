import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment-timezone'
import React, { useMemo, useState } from 'react'

import iconClose from '../../../components/menu/assets/icon-close.svg'
import { AlertData, PanelData } from '../../../components/panels-layout'
import { INTERVALS } from '../../../shared/intervals'
import { GRAPH_TYPES } from '../../../shared/sharedConstants'
import { LaunchDarklyFlags } from '../../../shared/typings/launchDarklyFlags'
import pvsWarn from './../assets/pvsWarn.svg'
import reporting from './../assets/reporting.svg'
import warnImg from './../assets/warn.svg'
import calendarImg from '../assets/calendar.svg'
import chartImg from '../assets/charts.svg'
import {
  PANEL_ALERTS_ID,
  getPanelDetailColor,
  getPanelStatus,
} from '../getPanelStatus'
import { PanelMessaging } from './panel-messaging'
import { RechartGraph } from './recharts-graphs'

export interface WeatherConditions {
  days: DayWeather[]
  timezone: string
  tzoffset: number
}
export interface DayWeather {
  conditions: string
  datetime: string
  datetimeEpoch: number
  sunset: string
  sunrise: string
  minTemperature: number
  maxTemperature: number
  hours: HourWeather[]
}

interface HourWeather {
  conditions: string
  datetime: string
  datetimeEpoch: number
  temperature: number
}

const getIcon = (
  alert: AlertData,
  DCE_ICE_ALERT_FLAG: boolean,
  ALERT_DEVICE_DOWN_FLAG: boolean,
) => {
  if (
    (alert?.alertType === PANEL_ALERTS_ID.ID && ALERT_DEVICE_DOWN_FLAG) ||
    (alert?.alertType === PANEL_ALERTS_ID.ICE && DCE_ICE_ALERT_FLAG)
  ) {
    return warnImg
  } else if (
    alert?.alertType === PANEL_ALERTS_ID.PANEL_TEMP_NO_COMMS ||
    alert?.alertType === PANEL_ALERTS_ID.PANEL_TEMP_ZERO_PROD
  ) {
    return pvsWarn
  }
  return reporting
}

export const PanelSliderDetail = ({
  handleClose,
  timezone,
  datesData = {
    start: moment().tz(timezone).startOf(INTERVALS.DAY).valueOf(),
    end: moment().tz(timezone).endOf(INTERVALS.DAY).valueOf(),
  },
  panel,
  weatherConditions,
  loading,
}: {
  handleClose: () => void
  datesData: {
    start: number
    end: number
  }
  timezone: string
  panel: PanelData
  weatherConditions: WeatherConditions
  loading: boolean
}) => {
  const flags = useFlags() as LaunchDarklyFlags

  const [graphType, setGraphType] = useState(GRAPH_TYPES.ENERGY)
  const { start } = datesData
  const { serialNumber, lastCommunicationTimestamp } = panel
  const status = useMemo(() => getPanelStatus(panel), [panel])

  const DCE_ICE_ALERT_FLAG = flags?.dceIceAlertFlag ?? false
  const ALERT_DEVICE_DOWN_FLAG = flags?.alertDeviceDownFlag ?? false

  const backgroundColor = useMemo(
    () =>
      getPanelDetailColor(panel, DCE_ICE_ALERT_FLAG, ALERT_DEVICE_DOWN_FLAG),
    [panel, DCE_ICE_ALERT_FLAG, ALERT_DEVICE_DOWN_FLAG],
  )
  const icon = useMemo(
    () => getIcon(status, DCE_ICE_ALERT_FLAG, ALERT_DEVICE_DOWN_FLAG),
    [status, DCE_ICE_ALERT_FLAG, ALERT_DEVICE_DOWN_FLAG],
  )
  const lastReportedDate = useMemo(
    () =>
      lastCommunicationTimestamp === null
        ? 'Not Available'
        : moment(lastCommunicationTimestamp).format('dddd M/D - hh:mma'),
    [lastCommunicationTimestamp],
  )
  const hourlyData = useMemo(() => panel?.hourlyData || [], [panel])

  const dailyEnergyProduction = useMemo(
    () => panel?.dailyEnergyProduction,
    [panel],
  )
  const sevenDayAvg = useMemo(() => panel?.sevenDayAverage, [panel])

  return (
    <aside className="slider">
      <div className="main-container">
        <div className="toolbar">
          <button
            className="closeSideNav"
            aria-label="close"
            onClick={handleClose}
          >
            <img src={iconClose} alt="Close sidebar" />
          </button>
        </div>
        <div className="info-container">
          <div className="date-label">
            <div className="day-week-label">{moment(start).format('dddd')}</div>
            {moment(start).format('l')}
          </div>
          <div
            className="status-container"
            style={{ backgroundColor: backgroundColor }}
          >
            <h4>{`SN# ${serialNumber}`}</h4>
            <img src={icon} alt="Warning panel" className="status-icon" />
          </div>
          <PanelMessaging status={status} timezone={timezone} />
          <div className="separator" />
          <div className="last-reporter-container">
            <div className="lastly-label">Last reported on:</div>
            <div>{lastReportedDate}</div>
          </div>
          <div className="separator" />
          <RechartGraph
            hourlyData={hourlyData}
            graphType={graphType}
            setGraphType={setGraphType}
            datesData={datesData}
            weatherConditions={weatherConditions}
            loading={loading}
          />
          <div className="cards-container">
            <div className="card">
              <img
                src={calendarImg}
                alt="calendar illustration"
                className="calendar-illustration"
              />
              <div className="day-label">DAY TOTAL</div>
              <div className="amount-label">
                {dailyEnergyProduction && !loading
                  ? `${dailyEnergyProduction?.toFixed(2)} kWh`
                  : '--'}
              </div>
            </div>
            <div className="card">
              <img
                src={chartImg}
                alt="chart illustration"
                className="chart-illustration"
              />
              <div className="day-label">7 DAY AVG</div>
              <div className="amount-label">
                {sevenDayAvg && !loading
                  ? `${sevenDayAvg?.toFixed(2)} kWh`
                  : '--'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}
