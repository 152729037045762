import './style.scss'

import React from 'react'

type TextInputProps = {
  value?: string
  inputName: string
  placeholder?: string
  handler?: any
  maxLength?: number
}
export const TextInput = ({
  value = '',
  placeholder = 'Placeholder',
  handler = () => {},
  inputName = 'label',
  maxLength = undefined,
}: TextInputProps) => {
  return (
    <input
      name={inputName}
      type="text"
      className="input is-medium"
      value={value}
      placeholder={placeholder}
      onChange={handler}
      autoComplete="true"
      maxLength={maxLength}
    />
  )
}
