import './style.scss'

import clsx from 'clsx'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { Link } from 'react-router-dom'

import { ALERTS_ID, useAlerts } from '../../hooks'
import paths from '../../pages/Router/paths'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import { MoreButton } from '../more-button'
import houseErrorImage from './assets/house-error.svg'
import houseImage from './assets/house.svg'
import { SiteInfoDetailsProps, SystemInfoDetail } from './systemInfoDetail'

export enum SYSTEM_STATUSES {
  CONNECTED = 'connected',
  NOT_REPORTING = 'not_reporting',
}

export type SystemInfoText = {
  title: string
  titleClasses: string
  description: string
}

type SystemInfoTexts = {
  [key in SYSTEM_STATUSES]: SystemInfoText
}

type SystemInfoProps = {
  siteKey: string | null
  siteDetails?: SiteInfoDetailsProps
}

export const SystemInfoTexts: SystemInfoTexts = {
  [SYSTEM_STATUSES.CONNECTED]: {
    title: 'System is Connected',
    titleClasses: 'is-green',
    description: 'Your system is online and reporting as expected',
  },
  [SYSTEM_STATUSES.NOT_REPORTING]: {
    title: 'System is not communicating to the network',
    titleClasses: 'is-canyon',
    description: '',
  },
}

export const SystemInfo = ({ siteKey, siteDetails }: SystemInfoProps) => {
  const flags = useFlags() as LaunchDarklyFlags
  const { alerts } = useAlerts(siteKey)

  const ICD_ALERT_FLAG = flags?.icdAlertFlag ?? false

  const status = React.useMemo(
    () =>
      ICD_ALERT_FLAG && alerts.some((a) => a.alertType === ALERTS_ID.ICD)
        ? SYSTEM_STATUSES.NOT_REPORTING
        : SYSTEM_STATUSES.CONNECTED,
    [alerts, ICD_ALERT_FLAG],
  )
  const systemInfoText = SystemInfoTexts[status]

  return (
    <div className="system-info">
      <div className="info-top">
        <div className="columns is-mobile is-vcentered">
          <div
            className={clsx(
              'info-label column is-size-3 is-two-fifths',
              systemInfoText.titleClasses,
            )}
          >
            {systemInfoText.title}
          </div>
          <div className="info-image column">
            <img
              src={
                status === SYSTEM_STATUSES.CONNECTED
                  ? houseImage
                  : houseErrorImage
              }
              alt="house"
            />
          </div>
        </div>
        <div className="info-description is-size-4 light-text mb-4">
          {status === SYSTEM_STATUSES.CONNECTED ? (
            systemInfoText.description
          ) : (
            <p className="mt-1">
              Check out the{' '}
              {
                <Link className="link-to-faq" to={paths.F_A_Q}>
                  FAQ
                </Link>
              }{' '}
              page for a troubleshooting guide
            </p>
          )}
        </div>
      </div>
      <MoreButton>
        <SystemInfoDetail {...siteDetails} />
      </MoreButton>
    </div>
  )
}
