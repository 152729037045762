import React, { createContext, useState } from 'react'

export const SupportCasesContext = createContext<any>(null)

type SupportCasesProviderPropsType = {
  children: JSX.Element
}

const SupportCasesProvider = ({ children }: SupportCasesProviderPropsType) => {
  const [oktaToken, setOktaToken] = useState<string | null>(null)
  const [oktaUser, setOktaUser] = useState<string | null>(null)
  const [supportCases, setSupportCases] = useState<any[]>([])
  const [fetchedCases, setFetchedCases] = useState<{
    oktaToken: null | string
    oktaUser: null | string
  }>({
    oktaToken: null,
    oktaUser: null,
  })

  return (
    <SupportCasesContext.Provider
      value={{
        oktaToken,
        setOktaToken,
        oktaUser,
        setOktaUser,
        supportCases,
        setSupportCases,
        fetchedCases,
        setFetchedCases,
      }}
    >
      {children}
    </SupportCasesContext.Provider>
  )
}

export default SupportCasesProvider
