import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { TFirebaseAlert } from '../../../shared/typings/firebaseAlert'
import { LaunchDarklyFlags } from '../../../shared/typings/launchDarklyFlags'
import paths from '../../Router/paths'
import gearIcon from './assets/gear-icon.svg'

type TAlertCustomContent = {
  fromPath?: string
}

export const AlertCustomContent = ({
  fromPath = paths.ROOT,
}: TAlertCustomContent) => {
  const flags = useFlags() as LaunchDarklyFlags
  const ModalData = flags?.customWebModal || '{}'
  const [modalData, setModalData] = useState<TFirebaseAlert>({})

  useEffect(() => {
    try {
      const dataObject = JSON.parse(ModalData)
      setModalData(dataObject)
    } catch (e) {
      setModalData({})
    }
  }, [ModalData])

  return (
    <div className="alert-modal-contents icd">
      <div className="container has-text-centered">
        <img src={gearIcon} alt="Alert Custom Icon" className="custom-icon" />

        <p className="alert-message has-text-weight-bold custom-modal-tittle">
          {modalData?.title}
        </p>
        <div
          className="custom-modal-body"
          dangerouslySetInnerHTML={{ __html: modalData?.body || '' }}
        ></div>
        <Link to={fromPath}>
          <button className="button is-primary custom-modal-button">
            Okay
          </button>
        </Link>
        <div className="custom-modal-footer">
          <span>For any questions please</span>
          <Link to={paths.CONTACT_US} className="custom-link">
            contact us
          </Link>
        </div>
      </div>
    </div>
  )
}
