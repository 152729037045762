import './style.scss'

import React from 'react'

type CardProps = {
  children?: React.ReactNode
  classname?: string
  onClick?: () => void
}

export const Card = ({ children, classname, onClick }: CardProps) => {
  return (
    <div
      onClick={onClick}
      className={`single-card ${classname ? classname : ''}`}
    >
      {children}
    </div>
  )
}
