import './style.scss'

import { get } from 'lodash/object'
import React, { useContext } from 'react'
import salesIcon from './assets/switch_header_sales_icon.svg'

import { UserContext } from '../../context/userContext'


const milestoneOptions = {
  dealPortal: 'deal-portal',
}

const SwitchMilestone = () => {
  const userContext = useContext(UserContext)
  const sfid = get(userContext, 'user.data.user.sfid')
  const salesUrl = get(userContext, 'user.data.user.oktaLoginUrl')

  type bannerConditionType = {
    conditionCheck: boolean
    milestoneToSwitchTo: string
    switchTo: string
    redirectPath: string
  }
  const showBannerConditions: Array<bannerConditionType> = [
    {
      conditionCheck: true,
      milestoneToSwitchTo: milestoneOptions.dealPortal,
      switchTo: 'view Sales View',
      redirectPath: salesUrl,
    },
  ]

  const handleClick = (sfid: string, milestoneToSwitch: string, redirectPath: string) =>
    userContext?.overriddeMilestone(sfid, milestoneToSwitch).then(() => {
      window.location.href = redirectPath
    })

  return (
    <>
      {showBannerConditions.map((currentCondition) => {
        const { conditionCheck, milestoneToSwitchTo, redirectPath, switchTo } =
          currentCondition
        if (conditionCheck) {
          return (
            <div key={switchTo} className="switch-header">
              <div />
              <div
                className="inner-text"
                onClick={() => handleClick(sfid, milestoneToSwitchTo, redirectPath)}
              >
                Please click here to <span className="target">{switchTo}</span>
              </div>
              <img src={salesIcon} width={16} height={19} alt="target source" />
            </div>
          )
        }
      })}
    </>
  )
}

export default SwitchMilestone
