import './style.scss'

import React from 'react'

import { INTERVALS } from '../../shared/intervals'
import { GRAPH_TYPES } from '../../shared/sharedConstants'
import { TEnergyMarkup } from '../graphs-legend/site-legends-new'
import { AGGREGATION_PERIOD } from './axisCalculations'
import { RechartsEnergyGraph } from './recharts-energy-graph'
import { RechartsPowerGraph } from './recharts-power-graph'
import { EnergyDataType, PowerDataType } from './types'

type TooltipsType = {
  tooltipConsumptionTotals: object[]
  tooltipGridTotals: object[]
  tooltipProductionTotals: object[]
  tooltipStorageTotals: object[]
}

type EnergyPowerGraphProps = {
  startDate: number
  endDate: number
  timezone: string
  interval: string
  energyData: EnergyDataType
  powerData: PowerDataType
  graphType?: GRAPH_TYPES
  siteType?: string
  hasConsumption?: boolean
  isLegacyPvs?: boolean
  windowWidth?: number | null
  tooltips?: TooltipsType | null
  newEnergyMarkUpOptions: TEnergyMarkup
}

const updateInterval = (interval) => {
  switch (interval) {
    case 'day': {
      return AGGREGATION_PERIOD.Today
    }
    case 'week': {
      return AGGREGATION_PERIOD.ThisWeek
    }
    case 'month': {
      return AGGREGATION_PERIOD.ThisMonth
    }
    case 'year': {
      return AGGREGATION_PERIOD.ThisYear
    }
    case 'lifetime': {
      return AGGREGATION_PERIOD.Lifetime
    }
    case 'custom': {
      return AGGREGATION_PERIOD.Custom
    }
    default: {
      return AGGREGATION_PERIOD.Today
    }
  }
}

export const RechartsEnergyPowerGraph = ({
  startDate,
  endDate,
  timezone,
  interval = AGGREGATION_PERIOD.Today,
  energyData,
  powerData,
  graphType = GRAPH_TYPES.ENERGY,
  siteType = '',
  hasConsumption = false,
  isLegacyPvs = false,
  windowWidth = null,
  tooltips,
  newEnergyMarkUpOptions,
}: EnergyPowerGraphProps) => {
  const updatedInterval = updateInterval(interval)

  const useTooltipTotals =
    tooltips &&
    (tooltips.tooltipConsumptionTotals.length > 0 ||
      tooltips.tooltipGridTotals.length > 0 ||
      tooltips.tooltipProductionTotals.length > 0 ||
      tooltips.tooltipStorageTotals.length > 0)

  return (
    <div className="chart-container">
      {graphType === GRAPH_TYPES.ENERGY || interval !== INTERVALS.DAY ? (
        <RechartsEnergyGraph
          data={energyData}
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}
          interval={updatedInterval}
          siteType={siteType}
          hasConsumption={hasConsumption}
          key={`${windowWidth}`}
          tooltips={useTooltipTotals ? tooltips : null}
          energyMarkUpOptions={newEnergyMarkUpOptions}
        />
      ) : (
        <RechartsPowerGraph
          data={powerData}
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}
          interval={updatedInterval}
          siteType={siteType}
          hasConsumption={hasConsumption}
          isLegacyPvs={isLegacyPvs}
          key={`${windowWidth}`}
          energyMarkUpOptions={newEnergyMarkUpOptions}
        />
      )}
    </div>
  )
}
