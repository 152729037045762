type CustomerCheckData = {
  agreement?: boolean
  credit?: boolean
  go_to_mysunpower?: boolean
  milestone?: string
  proposal?: boolean
  rhs?: boolean
}
type ohmConfig = {
  clientId: string
  authUrl: string
  redirectUri: string
  rewardsUrl: string
}

type helpCenter = {
  contactFlowId: string
  instanceId: string
  region: string
  apiGatewayEndpoint: string
  ccpUrl: string
  chatDurationInMinutes: number
}

type EnvVars = {
  google_tag_manager_id?: string
  customer_check?: CustomerCheckData
  okta_sales_login_url?: string
  ohm_config?: ohmConfig
  help_center?: helpCenter
}

let envVars: EnvVars = {}
export function setEnvVars(vars: EnvVars = {}) {
  envVars = vars
}
export function getEnvVars() {
  return envVars
}
