import { ApolloClient, InMemoryCache, from, split } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import paths from '../pages/Router/paths'
import { readCookie } from '../shared/readCookie'
import { alertSeenTimestampVar, wifiAlertSeenTimestampVar } from './cache'
import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
} from './cookies'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LaunchDarklyFlags } from '../shared/typings/launchDarklyFlags'

const subscriptionContext = {
  client: null as SubscriptionClient | null,
  siteKey: null,
  deviceSerialNumber: null,
}

export const RECONNECTION_ATTEMPTS = 20

export const getWebsocketClient = () => {
  return subscriptionContext.client
}

export const getSubscriptionParams = () => ({
  siteKey: subscriptionContext.siteKey,
  deviceSerialNumber: subscriptionContext.deviceSerialNumber,
})

export const setSubscriptionParams = (siteKey, deviceSerialNumber) => {
  subscriptionContext.siteKey = siteKey
  subscriptionContext.deviceSerialNumber = deviceSerialNumber
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookie if it exists
  let authToken = readCookie(MY_SUNPOWER_MONITORING_ACCESS_TOKEN)
  authToken =
    authToken ?? readCookie(MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN)
  const loginOverride = readCookie(LOGIN_OVERRIDE)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(loginOverride ? { 'x-override-login': loginOverride } : {}),
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  }
})

export function createApolloClient(flags: LaunchDarklyFlags) {
  const GQL_ROLL_OUT_FLAG = flags?.gqlRollOutFlag ?? false
  const gqlUri = GQL_ROLL_OUT_FLAG ? process.env.REACT_APP_EDP_API_GRAPHQL_URL_NEW : process.env.REACT_APP_EDP_API_GRAPHQL_URL
  const gqlLiveUri = process.env.REACT_APP_EDP_API_LIVEDATA_GRAPHQL_URL

  const batchLink = new BatchHttpLink({ uri: gqlUri })

  subscriptionContext.client = new SubscriptionClient(gqlLiveUri ?? '', {
    reconnect: true,
    reconnectionAttempts: RECONNECTION_ATTEMPTS,
    lazy: true,
    connectionParams: () => {
      // get the authentication token from cookie if it exists
      const authToken =
        readCookie(MY_SUNPOWER_MONITORING_ACCESS_TOKEN) ??
        readCookie(MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN)

      return {
        Authorization: authToken ? `Bearer ${authToken}` : '',
        'x-site-key': subscriptionContext.siteKey,
        'x-serial_number': subscriptionContext.deviceSerialNumber,
      }
    },
  })
  const errorLink = onError((params) => {
    const { operation } = params
    const { operationName } = operation
    if (['ChangeBatteryMode', 'UpdateParty'].includes(operationName)) {
      // we use window.location since we can't use history here cause ApolloProvider is not wrapped inside the Router context
      const basePath = ['UpdateParty'].includes(operationName)
        ? 'monitor/profile'
        : 'monitor'
      window.location.href = `/${basePath}${paths.API_ERROR}`
    }
  })
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    new WebSocketLink(subscriptionContext.client),
    authLink.concat(batchLink),
  )

  return new ApolloClient({
    link: from([errorLink, splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Party: {
          keyFields: ['partyId'],
        },
        Site: {
          keyFields: ['siteKey'],
        },
        Assignment: {
          keyFields: ['deviceSerialNumber'],
        },
        Device: {
          keyFields: ['dvcKey'],
        },
        Query: {
          fields: {
            alertsSeenTimestamp: {
              read() {
                return alertSeenTimestampVar()
              },
            },
            wifiAlertsSeenTimestamp: {
              read() {
                return wifiAlertSeenTimestampVar()
              },
            },
          },
        },
      },
    }),
  })
}
