import { useLazyQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import { USER_SELECTED_SITE_KEY } from './../../config/cookies'
import { CustomTariffContext } from '../../context/customTariffContext'
import { CurrentSiteTariffDetails } from '../typings/site'
import { FETCH_CURRENT_UTILITY_RATE_PLAN_DATA_QUERY } from './query'

interface CurrentPlanProps {
  siteKey: string
  skip?: boolean
}

type UseCurrentUtilityReturnType = {
  data: { currentSiteTariffDetails: CurrentSiteTariffDetails | null }
  loading: boolean
  called: boolean
  refetch?: Function
  error: any
}

export const defaultvalue: UseCurrentUtilityReturnType = {
  data: { currentSiteTariffDetails: null },
  loading: false,
  called: false,
  refetch: () => {},
  error: {},
}

export const useCurrentUtilityRatePlan = ({
  siteKey,
  skip,
}: CurrentPlanProps): UseCurrentUtilityReturnType => {
  const initValue = useContext(CustomTariffContext) ?? {
    userCurrentPlan: null,
    setUserCurrentPlan: null,
  }
  const { userCurrentPlan, setUserCurrentPlan } = initValue

  const [cookies] = useCookies([USER_SELECTED_SITE_KEY])
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]

  const [fetchCurrentUtilityPlan, { called, data, loading, refetch, error }] =
    useLazyQuery(FETCH_CURRENT_UTILITY_RATE_PLAN_DATA_QUERY, {
      fetchPolicy: 'network-only',
      variables: {
        siteKey,
      },
    })

  useEffect(() => {
    if (
      !skip &&
      !!selectedSiteKey &&
      userCurrentPlan?.siteKey !== selectedSiteKey
    ) {
      fetchCurrentUtilityPlan()
    }
  }, [fetchCurrentUtilityPlan, skip])

  useEffect(() => {
    if (data) {
      setUserCurrentPlan &&
        setUserCurrentPlan({
          data: data,
          loading: false,
          called,
          refetch,
          error: null,
        })
    }
  }, [called, data, refetch, setUserCurrentPlan])

  return userCurrentPlan
}
