import moment from 'moment-timezone'
import React from 'react'

export type SiteInfoDetailsProps = {
  systemTime?: Date
  sunrise?: Date
  sunset?: Date
  nickname?: string
  street?: string
  city?: string
  zip?: string
}

export const SystemInfoDetail = ({
  systemTime,
  sunrise,
  sunset,
  nickname = '',
  street = '',
  city = '',
  zip = '',
}: SiteInfoDetailsProps) => {
  return (
    <div className="system-info-detail">
      <div className="mb-4">
        <div className="level">
          <div className="level-item level-left left-col">System Time:</div>
          <div className="level-item level-left right-col">
            {systemTime ? moment(systemTime).format('hh:mm A') : 'Unavailable'}
          </div>
        </div>
        <div className="level">
          <div className="level-item level-left left-col">Sunrise:</div>
          <div className="level-item level-left right-col">
            {sunrise ? moment(sunrise).format('hh:mm A') : 'Unavailable'}
          </div>
        </div>
        <div className="level">
          <div className="level-item level-left left-col">Sunset:</div>
          <div className="level-item level-left right-col">
            {sunset ? moment(sunset).format('hh:mm A') : 'Unavailable'}
          </div>
        </div>
        <div className="level">
          <div className="level-item level-left left-col">Nickname:</div>
          <div className="level-item level-left right-col">{nickname}</div>
        </div>
        <div className="level">
          <div className="level-item level-left left-col">Street:</div>
          <div className="level-item level-left right-col">{street}</div>
        </div>
        <div className="level">
          <div className="level-item level-left left-col">City/State:</div>
          <div className="level-item level-left right-col">{city}</div>
        </div>
        <div className="level">
          <div className="level-item level-left left-col">Zip Code:</div>
          <div className="level-item level-left right-col">{zip}</div>
        </div>
      </div>
    </div>
  )
}
