import { useMemo } from 'react'
import { useCookies } from 'react-cookie'

import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import { usePartyData } from './usePartyData'

export const useCurrentSiteData = () => {
  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  ])
  const session = cookies[USER_SESSION_INFO] ?? {}
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )
  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )

  const { data, loading } = usePartyData(currentPartyId, selectedSiteKey)
  const site = useMemo(
    () =>
      (data?.party?.sites ?? []).find(
        (site) => site.siteKey === selectedSiteKey,
      ),
    [data, selectedSiteKey],
  )

  return { site, loading, partyId: currentPartyId }
}
