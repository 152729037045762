import './style.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'

import { getDateFromISOFormat } from '../../helpers/getDateFromISOFormat'
import {
  HOURS_IN_A_DAY,
  MINUTES_IN_AN_HOUR,
  SITE_TYPES,
} from '../../shared/sharedConstants'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import { AGGREGATION_PERIOD } from '../recharts-production-consumption-graphs/axisCalculations'
import blueArrow from './assets/blue-arrow.svg'
import greenArrow from './assets/green-arrow.svg'
import gridIcon from './assets/grid-icon.svg'
import homeUsage from './assets/home-usage-icon.svg'
import solarProduced from './assets/solar-produced-icon.svg'
import storageIcon from './assets/storage-icon.svg'

const ICONS_MAP = {
  grid: gridIcon,
  storage: storageIcon,
  production: solarProduced,
  consumption: homeUsage,
}
export const formatHour = (idx: number): string =>
  idx === 0 || idx === 24
    ? '12 am'
    : idx === 12
    ? '12 pm'
    : idx < 12
    ? `${idx} am`
    : `${idx - 12} pm`
const formatMinutes = (minutes: number) => {
  if (minutes === 0) {
    return '00'
  }
  if (minutes < 10) {
    return `0${minutes}`
  }
  return minutes
}
export const tooltipDateForPowerData = (hoverIndex, dataPointNum) => {
  const decimalHour = (hoverIndex / dataPointNum) * HOURS_IN_A_DAY
  const hourWithMeridian = formatHour(Math.floor(decimalHour))
  const hour = hourWithMeridian.slice(0, -3)
  const minutes = Math.round((decimalHour % 1) * MINUTES_IN_AN_HOUR)
  const meridian = hourWithMeridian.slice(-2)?.toLowerCase()
  return `${hour}:${formatMinutes(minutes)} ${meridian}`
}
export const TOOLTIP_DATE_FORMAT = {
  [AGGREGATION_PERIOD.Today]: 'dddd, M/DD - h:00a',
  [AGGREGATION_PERIOD.ThisMonth]: 'MMM DD, YYYY',
  [AGGREGATION_PERIOD.ThisYear]: 'MMM YYYY',
  [AGGREGATION_PERIOD.Lifetime]: 'YYYY',
  [AGGREGATION_PERIOD.Custom]: 'MMM DD, YYYY',
}
export const getCorrectIntervalForDaysDifference = (daysDiff) => {
  if (daysDiff === 0) {
    return AGGREGATION_PERIOD.Today
  } else if (daysDiff <= 31) {
    return AGGREGATION_PERIOD.ThisMonth
  } else if (daysDiff >= 32 && daysDiff <= 365) {
    return AGGREGATION_PERIOD.ThisYear
  } else if (daysDiff >= 366) {
    return AGGREGATION_PERIOD.Lifetime
  } else {
    return AGGREGATION_PERIOD.Today
  }
}
export const Tooltip = (props) => {
  const {
    payload = [],
    daysDifference = 0,
    isPower = false,
    label,
    totalItems,
    fillConsumption = false,
    siteType,
  } = props

  const flags = useFlags() as LaunchDarklyFlags

  const CUSTOM_DATES_NV_FLAG = flags.customDatesNvFlag ?? false

  const interval = getCorrectIntervalForDaysDifference(daysDifference)

  const getTooltipItemTitle = (payloadItem) => {
    const { dataKey, value } = payloadItem

    const updatedValue =
      value !== null && value !== 0.0 ? value.toFixed(2).toString() : 'N/A' //remove trailing zeros with toString
    const prefix = value < 0.0 ? 'Exported to' : 'Imported from'
    const storagePostfix = value < 0.0 ? 'Charged' : 'Discharged'
    const gridArrowDirection = value < 0.0 ? 'right' : 'left'
    const storageArrowDirection = value < 0.0 ? 'right' : 'left'

    const gridArrowMarkup = (
      <img
        src={blueArrow}
        className={`arrow ${gridArrowDirection}`}
        alt={`${prefix} grid`}
      />
    )
    const storageArrowMarkup = (
      <img
        src={greenArrow}
        className={`arrow ${storageArrowDirection}`}
        alt={`Storage ${storagePostfix}`}
      />
    )
    const fillerSpaceForAlignment = <div className="arrow" />
    const formattedValue =
      typeof updatedValue === 'number' ? Math.abs(updatedValue) : updatedValue

    const unit = isPower ? 'kW' : 'kWh'
    switch (dataKey) {
      case 'grid': {
        return {
          title: `${prefix} Grid`,
          value:
            formattedValue === 'N/A'
              ? `${formattedValue}`
              : `${updatedValue} ${unit}`,
          arrow: gridArrowMarkup,
        }
      }
      case 'storage': {
        return {
          title: `Storage ${storagePostfix}`,
          value:
            formattedValue === 'N/A'
              ? `${formattedValue}`
              : `${updatedValue} ${unit}`,
          arrow: storageArrowMarkup,
        }
      }
      case 'production': {
        return {
          title: 'Solar Produced',
          value:
            formattedValue === 'N/A'
              ? `${formattedValue}`
              : `${updatedValue} ${unit}`,
          arrow: fillerSpaceForAlignment,
        }
      }
      case 'consumption': {
        return {
          title: 'Home Usage',
          value:
            formattedValue === 'N/A'
              ? `${formattedValue}`
              : `${updatedValue} ${unit}`,
          arrow: fillerSpaceForAlignment,
        }
      }
      default: {
        return {
          title: 'Error parsing',
          value: `${formattedValue} ${unit}`,
          arrow: fillerSpaceForAlignment,
        }
      }
    }
  }
  const generateToolTipMarkUp = (item, index) => {
    const { dataKey } = item
    const { title, value, arrow = null } = getTooltipItemTitle(item)
    return (
      <div key={`tooltip-item${index}`} className="tooltip-item">
        {arrow}
        <img src={ICONS_MAP[dataKey]} alt={dataKey} />
        <h4 className="title">{title}</h4>
        <h4 className="value">{value}</h4>
      </div>
    )
  }

  const hasUsage = payload.some((item) => item.dataKey === 'consumption')
  const tooltipItemsMarkup = payload ? payload.map(generateToolTipMarkUp) : null

  if (!hasUsage && fillConsumption) {
    const consumptionInfo = generateToolTipMarkUp(
      {
        dataKey: 'consumption',
        value: 0,
      },
      payload.length,
    )

    if (siteType !== SITE_TYPES.PROD_ONLY) {
      if (isPower) {
        tooltipItemsMarkup.splice(2, 0, consumptionInfo)
      } else {
        tooltipItemsMarkup.splice(1, 0, consumptionInfo)
      }
    }
  }

  const selectedTimestampDate =
    payload && payload.length > 0 ? payload[0].payload.originalTimestamp : null

  const selectedYear =
    payload && payload.length > 0
      ? moment(payload[0].payload?.originalTimestamp).format('YYYY')
      : null

  const customOverOneMonth =
    payload && payload.length > 0
      ? getDateFromISOFormat(payload[0].payload?.originalTimestamp)
      : null

  const customSelectedDateMonth = moment(customOverOneMonth?.month, 'M').format(
    'MMM',
  )

  const customSelectedDateYear = moment(customOverOneMonth?.year, 'Y').format(
    'YYYY',
  )

  const customFormattedDate =
    customSelectedDateMonth + ' ' + customSelectedDateYear

  const originalTimestamp = selectedTimestampDate
    ? interval === AGGREGATION_PERIOD.Lifetime && daysDifference < 364
      ? selectedYear
      : interval === AGGREGATION_PERIOD.Lifetime && daysDifference >= 364
      ? customSelectedDateYear
      : interval === AGGREGATION_PERIOD.ThisYear
      ? CUSTOM_DATES_NV_FLAG
        ? customFormattedDate
        : moment(selectedTimestampDate).valueOf()
      : moment(selectedTimestampDate).valueOf()
    : moment().valueOf()

  const nextHour =
    interval === AGGREGATION_PERIOD.Today
      ? moment(originalTimestamp).clone().add(1, 'hour').format('h:00a')
      : null
  const valueOfOgTimestamp = moment(originalTimestamp).format(
    TOOLTIP_DATE_FORMAT[interval],
  )

  const updatedTimestamp = valueOfOgTimestamp.split('-')

  const powerTimestamp = tooltipDateForPowerData(label, totalItems)
  const headerDayDateTimestampMarkup =
    interval !== AGGREGATION_PERIOD.Lifetime &&
    interval !== AGGREGATION_PERIOD.ThisYear ? (
      <span>{updatedTimestamp[0]}</span>
    ) : (
      ''
    )

  const headerTimeDateMarkup =
    interval === AGGREGATION_PERIOD.Lifetime ||
    interval === AGGREGATION_PERIOD.ThisYear ? (
      originalTimestamp
    ) : isPower ? (
      <b>{powerTimestamp}</b>
    ) : interval === AGGREGATION_PERIOD.Today ? (
      <b>
        {updatedTimestamp[1]} - {nextHour}
      </b>
    ) : null

  const tooltipMarkup = useMemo(
    () => (
      <section className="tooltip-container">
        <header>
          {headerDayDateTimestampMarkup}
          {headerTimeDateMarkup}
        </header>
        <article className="tooltip-items-container">
          {tooltipItemsMarkup}
        </article>
      </section>
    ),
    [tooltipItemsMarkup, headerTimeDateMarkup, headerDayDateTimestampMarkup],
  )
  return payload && payload.length > 0 ? tooltipMarkup : null
}
