import { useLDClient } from 'launchdarkly-react-client-sdk'
import { get } from 'lodash/object'
import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../config/cookies'
import paths from '../pages/Router/paths'
import { getLaunchDarklyContext } from '../shared/compileSessionInformation'
import { usePartyData } from '../shared/hooks'

type LaunchDarklyProps = {
  children: React.ReactNode
}

// This instance should be inside the ApolloProvider and insite the LaunchDarklyInicialization in order to work properly
export const LauncDarklyInstance = ({ children }: LaunchDarklyProps) => {
  const ldClient = useLDClient()
  //const clientUser = ldClient?.getUser()
  const clientUser = {}
  const history = useHistory()
  const pathName = history?.location?.pathname ?? ''

  const [cookies] = useCookies([
    LOGIN_OVERRIDE,
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
    USER_SELECTED_SITE_KEY,
    USER_SESSION_INFO,
  ])
  const session = cookies[USER_SESSION_INFO] ?? {}
  const { isAdmin = false } = session
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )

  const [isReady, setReady] = useState<boolean>(false)
  const siteNoSelectedYet = useMemo(
    () => 
      pathName === paths.MONITORING_ADMIN_LOGIN ||
      pathName === paths.SITES_LIST ||
      pathName === paths.CALLBACK ||
      pathName === paths.KIOSK_CALLBACK ||
      pathName === paths.LOGIN,
    [pathName],
  )

  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )

  const { data, loading: partyDataLoading } = usePartyData(
    siteNoSelectedYet ? '' : currentPartyId,
  )

  const selectedSite = useMemo(
    () => data?.party?.sites?.find((site) => site.siteKey === selectedSiteKey),
    [data, selectedSiteKey],
  )

  useEffect(() => {
    const ldNotIdentified = get(clientUser, 'anonymous', false)

    if (
      !siteNoSelectedYet &&
      (ldNotIdentified || clientUser?.custom?.state === '') &&
      (selectedSite || selectedSiteKey)
    ) {
      const launchDarklyContext = getLaunchDarklyContext(
        selectedSite || { siteKey: selectedSiteKey },
        session,
      )
      ldClient?.identify(launchDarklyContext, undefined, () => {
        setReady(true)
      })
    }
  }, [
    pathName,
    partyDataLoading,
    data,
    ldClient,
    session,
    siteNoSelectedYet,
    selectedSite,
    selectedSiteKey,
    clientUser,
  ])

  return isReady || isAdmin || siteNoSelectedYet || true ? <>{children}</> : <></>
}
