declare global {
  interface Window {
    dataLayer: DataLayerObject[]
  }
}

interface UserData {
  partyId?: string
  siteId?: string
  isAdmin?: boolean
  hasLivedata?: boolean
  isKiosk?: boolean
}

interface PageData {
  path: string
}

export enum DataLayerEventName {
  INTERVAL_SELECT = 'intervalSelect',
  PAGE_VIEW = 'page_view',
  SITE_SELECT = 'siteSelect',
  FEEDBACK_SUBMIT_ERROR = 'feedbackSubmitError',
  POWER_DATA_ERROR = 'powerDataError',
  ENERGY_DATA_ERROR = 'energyDataError',
  LIVE_DATA_ERROR = 'liveDataError',
  REFERRALS_HOW_IT_WORKS = 'referralsHowItWorks',
  REFERRALS_REFER_SOMEONE = 'referralsReferSomeone',
  EDIT_PROFILE_SUCCESS_WEB = 'editProfileSuccessWeb',
  EDIT_PROFILE_FAILURE_WEB = 'editProfileFailureWeb',
}

export interface DataLayerObject {
  event?: DataLayerEventName
  userData?: UserData
  eventData?: any
  pageData?: PageData
}
