import './payments.scss'

import { useQuery } from '@apollo/client'
import * as React from 'react'
import { useCookies } from 'react-cookie'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'

import {
  HOME_ASSETS,
  LayoutWithBackground,
} from '../../components/layout-with-background'
import { Loader } from '../../components/loader'
import { Modal } from '../../components/modal'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import paths from '../Router/paths'
import { FETCH_SITE_INFO } from './query'

export enum FinanceStatus {
  LEASE_WITH_OUTSTANDING_BALANCE = 'leaseWithOutstandingBalance',
  LOAN_WITH_OUTSTANDING_BALANCE = 'loanWithOutstandingBalance',
  NO_BALANCE = 'noBalance',
}

export const PaymentsPage = ({ modal }: { modal?: JSX.Element }) => {
  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])

  const { data, loading } = useQuery(FETCH_SITE_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      siteKey: cookies[USER_SELECTED_SITE_KEY],
    },
  })
  const financeStatus = data?.site?.financeStatus

  const flags = useFlags() as LaunchDarklyFlags
  const SPF_EXTERNAL_SERVICING_LINK_FLAG = flags.spfExternalServicingLinkFlag ?? false

  const view_outstanding_lease = (
    <LayoutWithBackground
      className="payments"
      title="Payments"
      asset={HOME_ASSETS.DOLLAR}
    >
      <div className="payments-content">
        <h3>Go to the payment site to make a payment.</h3>
        <p className="payments-text">
          Lease and Power Purchase Agreement (PPA) Customers
        </p>
        <a
          href="https://sunpowerbillpay.osgview.com/"
          rel="noreferrer"
          target="_blank"
          className="button payments-button is-primary"
        >
          Make a payment
        </a>
        <a
          href="https://us.sunpower.com/solar-resources/search?text=%22Billing%20Account%20Help%22"
          className="payments-helpText"
          rel="noreferrer"
          target="_blank"
        >
          Need Help?
        </a>
      </div>
      <>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.PAYMENTS}>
            {modal}
          </Modal>
        )}
      </>
    </LayoutWithBackground>
  )

  const view_outstanding_loan = (
    <LayoutWithBackground
      className="payments"
      title="Payments"
      asset={HOME_ASSETS.DOLLAR}
    >
      <div className="payments-content">
        <h3>Go to the website to make a payment, view your balance, or manage auto pay.</h3>
        <a
          href={ SPF_EXTERNAL_SERVICING_LINK_FLAG ? "https://sunpower.youronlineaccount.com/pub/ssoamup.aspx" : "https://sunpower.youronlineaccount.com" }
          rel="noreferrer"
          target="_blank"
          className="button payments-button is-primary"
        >
          Make a payment
        </a>
        <a
          href="https://access.sunpower.com/sunpower-financials-faq"
          className="payments-helpText"
          rel="noreferrer"
          target="_blank"
        >
          Need Help?
        </a>
      </div>
      <>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.PAYMENTS}>
            {modal}
          </Modal>
        )}
      </>
    </LayoutWithBackground>
  )

  const view_no_outstanding_payment = (
    <LayoutWithBackground
      className="payments"
      title="Payments"
      asset={HOME_ASSETS.DOLLAR}
    >
      <div className="payments-content">
        <h3>If you have an outstanding loan, please go to your loan provider's website.</h3>
        <p className="payments-text">
          No payment due to SunPower.
        </p>
        <a
          href="https://us.sunpower.com/solar-resources/search?text=%22Billing%20Account%20Help%22"
          className="payments-helpText"
          rel="noreferrer"
          target="_blank"
        >
          Need Help?
        </a>
      </div>
      <>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.PAYMENTS}>
            {modal}
          </Modal>
        )}
      </>
    </LayoutWithBackground>
  )

  const view_loading = (
    <LayoutWithBackground
      className="payments"
      title="Payments"
      asset={HOME_ASSETS.DOLLAR}
    >
      <div className="payments-content">
        <Loader></Loader>
      </div>
      <>
        {modal && (
          <Modal hideTopBar={true} title={''} fromPath={paths.PAYMENTS}>
            {modal}
          </Modal>
        )}
      </>
    </LayoutWithBackground>
  )

  if (loading) {
    return view_loading
  }

  switch (financeStatus) {
    case FinanceStatus.LOAN_WITH_OUTSTANDING_BALANCE:
      return view_outstanding_loan
    case FinanceStatus.LEASE_WITH_OUTSTANDING_BALANCE:
      return view_outstanding_lease
    default:
      return view_no_outstanding_payment
  }
}
