import React, { createContext, useState } from 'react'

import { VPPEnrollment } from '../shared/typings/site'

export const VppContext = createContext<any>(null)

type VppProviderPropsType = {
  children: JSX.Element
}

type UseVPPDataReturnType = {
  data: { vppEnrollment: VPPEnrollment | null }
  loading: boolean
  called: boolean
  refetch?: Function
}

const initVppValue = {
  data: { vppEnrollment: null },
  loading: true,
  called: false,
  siteKey: null,
  eligiblePrograms: [],
}

const VppContextProvider = ({ children }: VppProviderPropsType) => {
  const [vppData, setVppData] = useState<UseVPPDataReturnType>(initVppValue)

  return (
    <VppContext.Provider
      value={{
        vppData,
        setVppData,
      }}
    >
      {children}
    </VppContext.Provider>
  )
}

export default VppContextProvider
