import { gql } from '@apollo/client'

export const COST_SAVINGS_COMMAND = gql`
  mutation ChangeBatteryMode($params: NewBatteryModeInput!) {
    changeBatteryMode(params: $params) {
      siteKey
      label
      value
    }
  }
`

export const SET_TARIFF_FOR_SITE = gql`
  mutation SetTariffForSite($masterTariffId: String!, $siteId: String!) {
    setTariffForSite(masterTariffId: $masterTariffId, siteId: $siteId) {
      label
      value
    }
  }
`
