import './style.scss'
import '../../styles/setupMode.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/layout'
import { Loader } from '../../components/loader'
import { Modal } from '../../components/modal'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../config/cookies'
import {
  useCurrentSiteData,
  useCurrentUtilityRatePlan,
  useUtilityRatePlans,
} from '../../shared/hooks'
import { SITE_TYPES } from '../../shared/sharedConstants'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import paths from '../Router/paths'
import { CostsSavings } from './CostSavingsDetail'
import { CostSavingSlider } from './CostSavingsSlider'

enum SCREEN_MODES {
  COST_SAVINGS = 'COST_SAVINGS',
  SLIDER = 'SLIDER',
}

export const CostSavingsModePage = ({
  modalTitle,
  modal,
  hideTopBar,
}: {
  modalTitle?: string
  modal?: JSX.Element
  hideTopBar?: boolean
}) => {
  const flags = useFlags() as LaunchDarklyFlags

  const CUSTOM_TARIFF_FLAG = flags.customTariffFlag ?? false
  const history = useHistory()
  const { fromUtilityRateSchedules } = history?.location?.state
    ? (history.location.state as any)
    : false
  const [sliderMode, setSliderMode] = useState<SCREEN_MODES>(
    fromUtilityRateSchedules ? SCREEN_MODES.SLIDER : SCREEN_MODES.COST_SAVINGS,
  )

  const { site, loading: loadingSite, partyId } = useCurrentSiteData()

  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])

  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]

  const utilityPlan = useCurrentUtilityRatePlan({
    siteKey: selectedSiteKey,
    skip: !CUSTOM_TARIFF_FLAG,
  })

  const utilityPlans = useUtilityRatePlans({
    siteKey: selectedSiteKey,
    skip: !CUSTOM_TARIFF_FLAG,
  })

  const { data: currentPlanData, loading: currentPlanLoading } =
    utilityPlan || {}

  const { currentSiteTariffDetails } = currentPlanData || {}

  const onCancelSavingsClick = useCallback(() => {
    history.push(paths.ROOT)
  }, [history])

  const onSetCostSavingsClick = useCallback(() => {
    setSliderMode(SCREEN_MODES.SLIDER)
  }, [setSliderMode])

  const handleOnClickUtility = useCallback(() => {
    history.push(paths.UTILITY_RATE_PLAN_SCHEDULES)
  }, [history])

  const notStorageSite = site?.siteType !== SITE_TYPES.STORAGE
  useEffect(() => {
    if (!loadingSite && site && notStorageSite) {
      history.push(paths.ROOT)
    }
  }, [site, loadingSite, notStorageSite, history])

  const markup =
    loadingSite || currentPlanLoading ? (
      <Loader />
    ) : sliderMode === SCREEN_MODES.SLIDER ? (
      <CostSavingSlider
        onCancel={onCancelSavingsClick}
        onClickUtilityRatePlan={handleOnClickUtility}
        selectedSiteKey={selectedSiteKey}
        partyId={partyId}
        history={history}
        currentPlan={utilityPlans.utilityPlanDetail ?? currentSiteTariffDetails}
        showCustomTariff={CUSTOM_TARIFF_FLAG}
      />
    ) : (
      <CostsSavings
        onCancel={onCancelSavingsClick}
        onSetCost={onSetCostSavingsClick}
      />
    )
  return (
    <Layout title="Cost Savings Mode">
      <section className="cost-savings-mode-page setup-mode-page">
        {markup}
        {modal && (
          <Modal
            title={modalTitle}
            hideTopBar={hideTopBar}
            fromPath={paths.COST_SAVINGS_MODE}
          >
            {modal}
          </Modal>
        )}
      </section>
    </Layout>
  )
}
