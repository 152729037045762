import React from 'react'

import { formatCommaNumber } from '../../shared/valueFormatters'
import { STATUS_LABEL_SIZES, StatusLabel } from '../status-label'
import carIcon from './assets/car.svg'
import gasIcon from './assets/gas.svg'
import treeIcon from './assets/tree.svg'

export const EnvironmentalSavingsDetail = ({ miles, gallons, trees }) => {
  return (
    <div className="environmental-savings-detail">
      <div className="level">
        <div className="level-item level-right">
          <img src={carIcon} alt="car" />
        </div>
        <div className="level-item level-left	">
          <StatusLabel
            size={STATUS_LABEL_SIZES.MEDIUM}
            statusText="Not driven"
            statusTextBold={false}
            labelBold={true}
            label={`${formatCommaNumber(miles?.value)} ${miles?.unit}`}
          />
        </div>
      </div>
      <div className="level">
        <div className="level-item level-right">
          <img src={gasIcon} alt="gas" />
        </div>
        <div className="level-item level-left	">
          <StatusLabel
            size={STATUS_LABEL_SIZES.MEDIUM}
            statusText="Gasoline Not used"
            statusTextBold={false}
            labelBold={true}
            label={`${formatCommaNumber(gallons?.value)} Gallons`}
          />
        </div>
      </div>
      <div className="level">
        <div className="level-item level-right">
          <img src={treeIcon} alt="tree" />
        </div>
        <div className="level-item level-left	">
          <StatusLabel
            size={STATUS_LABEL_SIZES.MEDIUM}
            statusText="Full grown"
            statusTextBold={false}
            labelBold={true}
            label={`${formatCommaNumber(trees?.value)} Trees`}
          />
        </div>
      </div>
    </div>
  )
}
