import './styles.scss'

import React, { useCallback, useEffect } from 'react'

import { Layout } from '../../../components/layout'
import { Loader } from '../../../components/loader'
import { useDynamicVpp } from '../../../shared/hooks/useDynamicVpp'
import pvsIl from '../assets/pvs.png'
import { Document } from './Document'

export const EnrollmentDocuments = () => {
  const {
    programSteps,
    fetchProgramSteps,
    selectedEnrollment,
    fetchDocusignURL,
    docusignURL,
    loadingProgram,
    loadingDocusignURL,
  } = useDynamicVpp()

  useEffect(() => {
    if (selectedEnrollment?.enrollmentId) {
      fetchProgramSteps()
    }
  }, [selectedEnrollment?.enrollmentId])

  useEffect(() => {
    if (programSteps.length > 0) {
      fetchDocusignURL()
    }
  }, [docusignURL, programSteps])

  const handleOnPressSignature = useCallback(() => {
    if (docusignURL && docusignURL.webviewUrl) {
      window.open(docusignURL.webviewUrl, '_blank', 'noreferrer')
    }
  }, [docusignURL])

  return (
    <Layout
      title="SunPower VPP"
      sectionClassName="section-enroll"
      containerClassName="container-enroll"
    >
      <>
        <div className="vpp-dashboard-top-section" />
        <div className="vpp-documents-container">
          <div className="documents-content">
            {loadingProgram ? (
              <LoadingState />
            ) : (
              <>
                <img src={pvsIl} alt="pvs il" className="pvs-il" />
                <div className="document-title">Enrollment Documents</div>
                {programSteps.map((step, index) => {
                  return (
                    <Document
                      key={`${index}-${step.fieldName}`}
                      index={index}
                      step={step as any}
                      onPressSignature={handleOnPressSignature}
                      isFetchingSignatureURL={loadingDocusignURL}
                    />
                  )
                })}
              </>
            )}
          </div>
        </div>
      </>
    </Layout>
  )
}

const LoadingState = () => {
  return (
    <div>
      <strong>Loading program documents</strong>
      <Loader />
    </div>
  )
}
