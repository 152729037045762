import './style.scss'

import clsx from 'clsx'
import React, { useMemo } from 'react'
import { useCookies } from 'react-cookie'
import Lottie from 'react-lottie'

import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../../../../config/cookies'
import {
  useCurrentPowerLevels,
  usePartyData,
} from '../../../../../shared/hooks'
import { getEventState } from '../../../../../shared/timeUtils'
import {
  EVENT_STATUS,
  VPPDeviceCurrentEvent,
  VPPEnrollment,
} from '../../../../../shared/typings/site'
import { eventsDateFormat } from '../../../../../shared/valueFormatters'
import currentStateIcon from '../assets/current-state-icon.svg'
import dischargin_animation from '../assets/dischargin_animation'
import editIcon from '../assets/edit-icon.svg'
import headerIcon from '../assets/header-icon.svg'

type EventStateProps = {
  enrollment?: VPPEnrollment | null
  handleOptOut: () => void
  onClickEdit: () => void
  siteKey: string
}

const discharginAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: dischargin_animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const EventState = ({
  enrollment,
  handleOptOut,
  onClickEdit,
  siteKey,
}: EventStateProps) => {
  const currentEvent = enrollment?.enrollmentDevice?.currentEvent
  const eventState = getEventState(
    currentEvent || ({} as VPPDeviceCurrentEvent),
  )

  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
    MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  ])
  const session = cookies[USER_SESSION_INFO] ?? {}
  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]
  const isKiosk = useMemo(
    () => !!cookies[MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN],
    [cookies],
  )
  const loginOverride = cookies[LOGIN_OVERRIDE]
  const currentPartyId = useMemo(
    () => (isKiosk ? null : loginOverride ?? session.partyId),
    [loginOverride, session.partyId, isKiosk],
  )

  const { data } = usePartyData(currentPartyId, siteKey)

  const selectedSite = useMemo(
    () =>
      (data?.party?.sites ?? []).find(
        (site) => site.siteKey === selectedSiteKey,
      ),
    [data, selectedSiteKey],
  )

  const hasLiveData = selectedSite?.hasLivedata ?? false

  const assignment = selectedSite?.assignments?.find(
    ({ assignmentEffectiveTimestamp }) => assignmentEffectiveTimestamp != null,
  )
  const deviceSerialNumber = assignment?.deviceSerialNumber ?? ''

  const [currentLevel] = useCurrentPowerLevels(
    siteKey,
    hasLiveData,
    deviceSerialNumber,
  )

  return (
    <div className="event-state card no-padding ">
      <EventHeader
        onGoingEvent={eventState === EVENT_STATUS.EVENT_IN_PROGRESS}
      />
      {eventState === EVENT_STATUS.EVENT_NOT_SCHEDULED ? (
        <NoEventsState />
      ) : eventState === EVENT_STATUS.EVENT_IN_PROGRESS ? (
        <OnGoingEvent
          event={currentEvent}
          handleOptOut={handleOptOut}
          onClickEdit={onClickEdit}
          currentLevel={currentLevel.storage}
        />
      ) : (
        <IncomingEvent
          event={currentEvent}
          handleOptOut={handleOptOut}
          onClickEdit={onClickEdit}
          currentLevel={currentLevel.storage}
        />
      )}
    </div>
  )
}

export const EventHeader = ({ onGoingEvent }: { onGoingEvent: boolean }) => {
  return (
    <div className="event-state-header">
      <img src={headerIcon} alt="calendar icon" />
      {onGoingEvent
        ? 'Discharge Event in Progress'
        : 'Next Scheduled Discharge Event'}
    </div>
  )
}

export const OnGoingEvent = ({
  event,
  handleOptOut,
  onClickEdit,
  currentLevel,
}: {
  event?: VPPDeviceCurrentEvent
  handleOptOut?: () => void
  onClickEdit?: () => void
  currentLevel: number
}) => {
  const optedIn = event?.optIn?.toUpperCase() === 'TRUE'
  const savedReserves = event?.reserveLevel

  return (
    <div className="state-content ongoing-event">
      <div className="info-wrapp">
        <DateSection
          eventDatetime={event?.eventDatetime}
          eventLength={event?.eventLength}
          text={'Battery is currently discharging...'}
        >
          <div className="charged-info mobil">
            {optedIn && (
              <Lottie
                options={discharginAnimationOptions}
                height={176}
                width={180}
              />
            )}
            {!optedIn && (
              <img
                src={currentStateIcon}
                alt="discharging disabled"
                className="disabled-image"
              />
            )}
            <div className={clsx('info-discharged', { disabled: !optedIn })}>
              <p>Current Power</p>
              <b>{`${currentLevel || 0} kW`}</b>
            </div>
          </div>
        </DateSection>
        <InfoRow
          savedReserves={savedReserves}
          optedIn={optedIn}
          handleOptOut={handleOptOut}
          onClickEdit={onClickEdit}
        />
      </div>
      <div className="charged-info right">
        {optedIn && (
          <Lottie
            options={discharginAnimationOptions}
            height={176}
            width={180}
          />
        )}
        {!optedIn && (
          <img
            src={currentStateIcon}
            alt="discharging disabled"
            className="disabled-image"
          />
        )}
        <div className={clsx('info-discharged', { disabled: !optedIn })}>
          <p>Current Power</p>
          <b>{`${Math.abs(currentLevel)} kW`}</b>
        </div>
      </div>
    </div>
  )
}

export const IncomingEvent = ({
  event,
  handleOptOut,
  onClickEdit,
  currentLevel,
}: {
  event?: VPPDeviceCurrentEvent
  handleOptOut?: () => void
  onClickEdit?: () => void
  currentLevel: number
}) => {
  const optedIn = event?.optIn?.toUpperCase() === 'TRUE'
  const savedReserves = event?.reserveLevel

  return (
    <div className="state-content incoming-event">
      <DateSection
        eventDatetime={event?.eventDatetime}
        eventLength={event?.eventLength}
        text={'Battery will start charging several hours before the event'}
      >
        <div className="date-info-graph">
          <img src={currentStateIcon} alt="current charge graph" />
          <div className="info-discharged">
            <p>Current Power</p>
            <b>{`${Math.abs(currentLevel)} kW`}</b>
          </div>
        </div>
      </DateSection>
      <InfoRow
        savedReserves={savedReserves}
        optedIn={optedIn}
        handleOptOut={handleOptOut}
        onClickEdit={onClickEdit}
      />
    </div>
  )
}

const DateSection = ({
  eventDatetime = '',
  eventLength = 0,
  text,
  children,
}: {
  eventDatetime?: string
  eventLength?: number
  text: string
  children?: React.ReactNode
}) => {
  return (
    <div className="date-info">
      <b>{eventsDateFormat(eventDatetime, eventLength)}</b>
      <p>{text}</p>
      {children}
    </div>
  )
}

const InfoRow = ({
  savedReserves,
  onClickEdit,
  optedIn,
  handleOptOut,
}: {
  savedReserves?: number
  onClickEdit?: () => void
  optedIn?: boolean
  handleOptOut?: () => void
}) => {
  const optLabelClass = optedIn ? 'opt-in-label' : 'opt-out-label'
  return (
    <div className="row">
      <div className={clsx('reserve-info', { disabled: !optedIn })}>
        <p>Saved Reserves:</p>
        <b>{`${savedReserves ? (savedReserves * 100).toFixed(0) : 0}`}%</b>
        <p className="edit-label">
          Edit
          <button
            className="edit-button"
            onClick={onClickEdit}
            disabled={!optedIn}
          >
            <img src={editIcon} alt="edit saved reserves" />
          </button>
        </p>
      </div>
      <div className="opt-in-info">
        <p>{`You ${optedIn ? 'are' : 'have'}:`}</p>
        <b className={optLabelClass}>
          {`${optedIn ? 'Opted-In' : 'Opted-Out'}`}
        </b>
        {optedIn && (
          <button className="opt-out-button" onClick={handleOptOut}>
            Opt-out?
          </button>
        )}
      </div>
    </div>
  )
}

export const NoEventsState = () => (
  <div className="state-content">
    <p className="empty-state">No scheduled discharge events at this point</p>
  </div>
)
