import './styles.scss'

import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useHistory } from 'react-router-dom'

import { Card } from '../../../components/card'
import { Layout } from '../../../components/layout'
import { Loader } from '../../../components/loader'
import { Modal } from '../../../components/modal'
import {
  LOGIN_OVERRIDE,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from '../../../config/cookies'
import { useDynamicVpp } from '../../../shared/hooks/useDynamicVpp'
import { getEventState, getSeasonfromProgram } from '../../../shared/timeUtils'
import { LaunchDarklyFlags } from '../../../shared/typings/launchDarklyFlags'
import { VPPDeviceCurrentEvent } from '../../../shared/typings/site'
import paths from '../../Router/paths'
import infoIcon from '../vpp-components/dashboard-header/assets/info-icon.svg'
import { EventStatus } from '../vpp-components/event-status'
import { SeasonOverview } from '../vpp-components/season-overview'
import { SettingsSlider } from '../vpp-components/settings-slider'

export const VPPDashboard = ({ modal }: { modal?: JSX.Element }) => {
  const flags = useFlags() as LaunchDarklyFlags
  const SEASON_OVERVIEW_FLAG = flags?.seasonOverviewFlag ?? false

  const [cookies] = useCookies([
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])

  const selectedSiteKey = cookies[USER_SELECTED_SITE_KEY]

  const history = useHistory()

  const { selectedEnrollment, loading } = useDynamicVpp()

  const [eventStatus, setEventStatus] = useState('')

  if (selectedEnrollment && !eventStatus) {
    const event = selectedEnrollment?.enrollmentDevice?.currentEvent

    if (event) {
      const eventState = getEventState(event || ({} as VPPDeviceCurrentEvent))
      setEventStatus(eventState)
    }
  }

  const handleOptOut = useCallback(() => {
    history.push(`${paths.VPP_DASHBOARD}${paths.ALERT_OPTED_OUT}`)
  }, [history])

  const handleOnClickEdit = useCallback(() => {
    history.push(paths.RESERVE_DYNAMIC_VPP)
  }, [history])

  const handleOnPressViewDetails = useCallback(
    () => history.push(paths.MARKETING_VPP_DYNAMIC),
    [history],
  )

  const [openSettingsSlider, setOpenSettingsSlider] = useState<boolean>(false)

  const handleOpenSettingsSlider = () =>
    setOpenSettingsSlider((prevState) => !prevState)

  const vPPProgram = useMemo(() => selectedEnrollment?.program || {}, [
    selectedEnrollment,
  ])

  const vPPEnrollmentDevice = useMemo(
    () => selectedEnrollment?.enrollmentDevice || null,
    [selectedEnrollment],
  )
  const currentEvent = useMemo(() => vPPEnrollmentDevice?.currentEvent || {}, [
    vPPEnrollmentDevice,
  ])
  const programName = vPPProgram ? vPPProgram.programName : ''

  const seasons = getSeasonfromProgram(vPPProgram)

  const showDocumentsCard = useMemo(() => {
    return !!selectedEnrollment && !selectedEnrollment?.isOhmConnect
  }, [selectedEnrollment])

  const handleOnClickDocuments = useCallback(
    () => history.push(paths.ENROLLMENT_DOCUMENTS),
    [history],
  )
  return (
    <Layout
      title="SunPower VPP"
      sectionClassName="section-enroll"
      containerClassName="container-enroll"
    >
      <>
        <div className="vpp-dashboard">
          <div className="vpp-dashboard-top-section top-section-green-background">
            {loading && <LoadingState />}
            {!loading && eventStatus && (
              <>
                <Link to={{ pathname: paths.MARKETING_VPP_DYNAMIC }}>
                  <img src={infoIcon} alt="info" className="info-button-vpp" />
                </Link>
                <div className="vpp-dashboard-content-card">
                  <div>
                    <EventStatus
                      enrollment={selectedEnrollment}
                      selectedSiteKey={selectedSiteKey}
                      onClickOptOut={handleOptOut}
                      onClickEdit={handleOnClickEdit}
                      handleOnPressViewDetails={handleOnPressViewDetails}
                      onClickOpenSettingsSlider={handleOpenSettingsSlider}
                      seasons={seasons}
                      // @ts-ignore
                      programSettingsTitle={programName}
                    />
                  </div>
                  {SEASON_OVERVIEW_FLAG && <SeasonOverview />}
                  {showDocumentsCard && (
                    <Card
                      classname="view-documents"
                      onClick={handleOnClickDocuments}
                    >
                      <div>View Enrollment Documents</div>
                    </Card>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="vpp-dashboard-bottom-section"></div>
          {openSettingsSlider && (
            <SettingsSlider
              vppDeviceData={selectedEnrollment?.enrollmentDevice || null}
              handleClose={handleOpenSettingsSlider}
              title="DYNAMIC VPP SETTINGS"
            />
          )}
        </div>
        {modal && (
          <Modal
            hideTopBar
            title=""
            fromPath={paths.VPP_DASHBOARD}
            noStylesModal={true}
            modalProps={{
              deviceUuid: vPPEnrollmentDevice?.deviceUuid,
              siteKey: selectedSiteKey,
              currentEvent: currentEvent,
            }}
          >
            {modal}
          </Modal>
        )}
      </>
    </Layout>
  )
}

const LoadingState = () => {
  return (
    <div className="vpp-dashboard-loader">
      <p className="vpp-dashboard-loader-info">Loading Virtual Power Plant</p>
      <div>
        <Loader />
      </div>
    </div>
  )
}
