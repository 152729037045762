import clsx from 'clsx'
import React from 'react'

import appleStoreBadge1x from './assets/apple-store-badge1x.png'
import appleStoreBadge2x from './assets/apple-store-badge2x.png'

export const AppleStoreBadge = ({
  imageHeight,
  className,
  href = 'https://apps.apple.com/us/app/id1504718150',
}: {
  imageHeight: string
  className?: string
  href?: string
}) => {
  return (
    <a
      className={clsx('is-size-5', className)}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={appleStoreBadge1x}
        srcSet={appleStoreBadge1x + ' 1x,' + appleStoreBadge2x + ' 2x'}
        height={imageHeight}
        alt="Download on the App Store"
      />
    </a>
  )
}
