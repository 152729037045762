import { gql, useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'

type AlertsAccumulator = {
  icds: Alert[]
  ids: Alert[]
  ices: Alert[]
  wifis: Alert[]
}

type Device = {
  prodMdlNm: string
  lastRcvdEps: number
  meterType: MeterType | null
}

type MeterType = {
  type: METER_TYPES
  level: string
}

enum METER_TYPES {
  UNKNOWN_TYPE = 'UNKNOWN_TYPE',
  NOT_USED = 'NOT_USED',
  STORAGE_METER = 'STORAGE_METER',
  NET_CONSUMPTION_LOADSIDE = 'NET_CONSUMPTION_LOADSIDE',
  GROSS_CONSUMPTION_LINESIDE = 'GROSS_CONSUMPTION_LINESIDE',
  GROSS_PRODUCTION = 'GROSS_PRODUCTION',
}

const ALERTS_POLL_INTERVAL = 1000 * 60 * 5 /* 5 minutes */

const ACTIVE_METER_THRESHOLD = 14400

// Regular Expression for production meters
const PROD_METER_REGEX = /^PVS\d.+p$/i
// Regular Expression for ACPV Inverters
const AC_INVERTER_MODEL_REGEX =
  /^AC(_|[A-z])Module_Type.[A-z]$|^ACPV-Gen3$|^SBT-MI-.+|^mi-model-.+/i

export const FETCH_ALERTS_QUERY = gql`
  query FetchAlerts($siteKey: String!) {
    site(siteKey: $siteKey) {
      siteKey
      alerts {
        alertType
        alertStatus
        eventTimestamp
      }
      assignments(assignmentType: COMMISSION) {
        deviceSerialNumber
        devices {
          dvcKey
          prodMdlNm
          lastRcvdEps
          meterType {
            type
            level
          }
        }
      }
    }
  }
`
export const FETCH_SITE_ASSIGNMENT = gql`
  query FetchSiteAssignment($siteKey: String!) {
    site(siteKey: $siteKey) {
      hasWifi
      siteKey
      assignments(assignmentType: COMMISSION) {
        assignmentType
        deviceSerialNumber
        assignmentEffectiveTimestamp
        devices(deviceType: "logger", deviceStatus: true) {
          dvcKey
          dvcTy
          prodMdlNm
          lastRcvdEps
          deviceStatus {
            pvStatus
            essStatus
            netIntfRptCtnt {
              currNetIntfEnum
            }
          }
        }
      }
    }
  }
`
export const prioritizeAlerts = (alerts: Alert[] = []) => {
  const [ICD, ICE, ID, WIFI] = Object.keys(ALERTS_ID)
  const accumulator: AlertsAccumulator = {
    icds: [],
    ids: [],
    ices: [],
    wifis: [],
  }
  const { icds, ids, ices, wifis } = alerts.reduce((acc, a, idx) => {
    const alert = {
      ...a,
      eventTimestamp: moment(a.eventTimestamp).valueOf(),
    }
    if (alert.alertType === ALERTS_ID.ICD) {
      alert.priority = 1
      alert.type = ICD
      acc.icds.push(alert)
    } else if (ID_ALERTS.includes(alert.alertType)) {
      alert.priority = 2
      alert.type = ID
      acc.ids.push(alert)
    } else if (ICE_ALERTS.includes(alert.alertType)) {
      alert.priority = 3
      alert.type = ICE
      acc.ices.push(alert)
    } else if (alert.alertType === ALERTS_ID.WIFI) {
      alert.priority = 4
      alert.type = WIFI
      acc.wifis.push(alert)
    }
    return acc
  }, accumulator)

  return [...icds, ...ids, ...ices, ...wifis]
}

export const filterAlerts = (alerts: Alert[] = [], devices: Device[] = []) => {
  const hasICE = alerts.some((a) => a.alertType === ALERTS_ID.ICE)
  if (!hasICE) {
    return alerts
  }
  const epochSecondNow = Math.round(moment().valueOf() / 1000)
  const prodMeters = devices.filter(
    (d) => d && PROD_METER_REGEX.test(d.prodMdlNm),
  )
  const hasWorkingProdMeter = prodMeters.some(
    (meter) =>
      meter.meterType &&
      meter.meterType.type === METER_TYPES.GROSS_PRODUCTION &&
      meter.lastRcvdEps &&
      epochSecondNow - meter.lastRcvdEps < ACTIVE_METER_THRESHOLD,
  )

  // Remove ICE alerts if production meter installed
  if (hasWorkingProdMeter) {
    return alerts.filter((a) => a.alertType !== ALERTS_ID.ICE)
  }

  const acInverters = devices.filter(
    (d) => d && AC_INVERTER_MODEL_REGEX.test(d.prodMdlNm),
  )
  const icesCount = alerts.filter((a) => a.alertType === ALERTS_ID.ICE).length

  let miDownPercentage = 0

  if (acInverters.length)
    miDownPercentage = Math.round((icesCount / acInverters.length) * 100)

  // If less than 20 percent of ac panels have ICE alerts
  if (miDownPercentage < 20) {
    return alerts.filter((a) => a.alertType !== ALERTS_ID.ICE)
  }

  return alerts
}
export enum DEVICE_NET_MODE {
  WIFI = 'STA0',
  CELL = 'CELL',
  UNKNOWN = 'UNKNOWN',
}
export const ALERTS_ID = {
  ICD: 'InternetCommunicationDownDataLogger',
  ICE: 'CommunicationOutageInverterICE',
  ID: 'SystemDownInverter',
  WIFI: 'WifiConnectionError',
  ICE_METER: 'CommunicationOutageMeterICE',
  ID_POWER_METER: 'SystemDownPowerMeter',
  ID_CONSUMPTION_METER: 'SystemDownConsumptionMeter',
}

export const ICE_ALERTS = [ALERTS_ID.ICE, ALERTS_ID.ICE_METER]

export const ID_ALERTS = [
  ALERTS_ID.ID,
  ALERTS_ID.ID_POWER_METER,
  ALERTS_ID.ID_CONSUMPTION_METER,
]

export type Alert = {
  alertType: string
  priority: number
  type: string
  eventTimestamp: number
  alertStatus: string
  seenTimestamp?: number
}

export function useAlerts(siteKey?: string | null) {
  const [alerts, setAlerts] = useState<Alert[]>([])
  const [fetchAlerts, { called, data, loading, startPolling: alertsPolling }] =
    useLazyQuery(FETCH_ALERTS_QUERY, {
      pollInterval: ALERTS_POLL_INTERVAL,
      fetchPolicy: 'network-only',
    })
  const [
    fetchSiteAssignmentInfo,
    {
      called: siteAssignmentCalled,
      data: siteAssignmentInfoData,
      loading: siteAssignmentInfoLoading,
      startPolling: siteAssignmentPolling,
    },
  ] = useLazyQuery(FETCH_SITE_ASSIGNMENT, {
    fetchPolicy: 'network-only',
  })
  const filter = useCallback(
    (alerts, devices) => filterAlerts(alerts, devices),
    [],
  )
  const prioritize = useCallback((alerts) => prioritizeAlerts(alerts), [])

  useEffect(() => {
    if (siteKey) {
      fetchAlerts({
        variables: {
          siteKey,
        },
      })
      alertsPolling?.(ALERTS_POLL_INTERVAL)
      fetchSiteAssignmentInfo({
        variables: { siteKey },
      })
      siteAssignmentPolling?.(ALERTS_POLL_INTERVAL)
    }
  }, [
    siteKey,
    fetchAlerts,
    fetchSiteAssignmentInfo,
    alertsPolling,
    siteAssignmentPolling,
  ])

  useEffect(() => {
    if (called && data && siteAssignmentCalled && siteAssignmentInfoData) {
      const { alerts: responseAlerts, assignments } = data.site
      const devices = assignments.map((a) => a.devices).flat()
      const siteAssignmentDevice = siteAssignmentInfoData?.site?.assignments
        .map((a) => a.devices)
        .flat()
      const hasWifi = siteAssignmentInfoData?.site?.hasWifi ?? false
      const hasDeviceStatus = siteAssignmentInfoData?.site?.assignments.find(
        (assignment) =>
          assignment.devices?.some((device) => !!device?.deviceStatus),
      )
      const network =
        hasWifi && hasDeviceStatus
          ? hasDeviceStatus.devices[0].deviceStatus.netIntfRptCtnt
              ?.currNetIntfEnum
          : null

      const hasWifiError =
        hasWifi && hasDeviceStatus && network
          ? network === DEVICE_NET_MODE.UNKNOWN ||
            network === DEVICE_NET_MODE.CELL
          : false
      const allDevices = siteAssignmentDevice.length
        ? [...devices, ...siteAssignmentDevice]
        : devices
      const wifiErrorAlert = {
        alertType: 'WifiConnectionError',
        type: 'wifi',
        priority: 0,
        alertStatus: 'Open',
        eventTimestamp: moment().valueOf(),
      }
      const allAlerts = hasWifiError
        ? [...responseAlerts, wifiErrorAlert]
        : responseAlerts
      setAlerts(filter(prioritize(allAlerts), allDevices))
    }
  }, [
    called,
    data,
    siteAssignmentCalled,
    siteAssignmentInfoData,
    filter,
    prioritize,
  ])
  return { alerts, loading: loading && siteAssignmentInfoLoading }
}
