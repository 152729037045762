import './style.scss'

import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'

import {
  alertSeenTimestampVar,
  customAlertSeenTimestampVar,
  wifiAlertSeenTimestampVar,
} from '../../config/cache'
import { USER_SESSION_INFO } from '../../config/cookies'
import {
  ALERTS_ID,
  Alert,
  ICE_ALERTS,
  ID_ALERTS,
  useAlerts,
} from '../../hooks/useAlerts'
import paths from '../../pages/Router/paths'
import { setSiteData } from '../../shared/firebaseAnalytics'
import { TFirebaseAlert } from '../../shared/typings/firebaseAlert'
import { LaunchDarklyFlags } from '../../shared/typings/launchDarklyFlags'
import alertIcon from './assets/alert-icon.svg'
import alertSingleIcon from './assets/alert-single-icon.svg'
import closeIcon from './assets/close-icon.svg'

const setToPathForLink = (location, toPath = '') => {
  return location.pathname !== '/' ? `${location.pathname}-${toPath}` : toPath
}
const setToPathForWifi = (
  location,
  toProfilePath = paths.PROFILE_SETTINGS_WIFI,
  toRootPath = paths.ROOT_SETTINGS_WIFI,
) => {
  return location.pathname !== '/' ? `${toProfilePath}` : toRootPath
}

type BannerProps = {
  siteKey: string | null
  className?: string
  siteList?: any[] | null
}

export const Banner = ({ siteKey, className = '', siteList }: BannerProps) => {
  const [cookies] = useCookies([USER_SESSION_INFO])
  const session = cookies[USER_SESSION_INFO] ?? {}
  const { alerts } = useAlerts(siteKey)
  const [activeAlerts, setActiveAlerts] = useState<Alert[]>([])
  const [customAlert, setCustomAlert] = useState<TFirebaseAlert>()
  const alertSeenTimestamp = useReactiveVar(alertSeenTimestampVar)
  const wifiAlertSeenTimestamp = useReactiveVar(wifiAlertSeenTimestampVar)
  const customAlertSeenTimestamp = useReactiveVar(customAlertSeenTimestampVar)
  const flags = useFlags() as LaunchDarklyFlags

  React.useEffect(() => {
    const openAlerts = alerts.filter((a) => a.alertStatus === 'Open')
    const unSeenAlerts = alertSeenTimestamp
      ? openAlerts.filter((a) => a.eventTimestamp > alertSeenTimestamp)
      : openAlerts

    const updatedAlerts = wifiAlertSeenTimestamp
      ? unSeenAlerts.filter(
          (a) =>
            a.type === ALERTS_ID.WIFI &&
            a.eventTimestamp > wifiAlertSeenTimestamp,
        )
      : unSeenAlerts
    setActiveAlerts((prevAlerts) =>
      updatedAlerts.reduce((acc, alert) => {
        const existAlert = acc.some(
          (a) =>
            a.type === alert.type && a.eventTimestamp === alert.eventTimestamp,
        )
        if (!existAlert) {
          return [...acc, alert]
        }
        return acc
      }, prevAlerts),
    )
  }, [alerts, alertSeenTimestamp])

  const ALERT_DEVICE_DOWN_FLAG = flags?.alertDeviceDownFlag ?? false
  const DCE_ICE_ALERT_FLAG = flags?.dceIceAlertFlag ?? false
  const ICD_ALERT_FLAG = flags?.icdAlertFlag ?? false

  useEffect(() => {
    if (
      alerts &&
      siteList?.length &&
      siteList?.length > 0 &&
      session &&
      siteKey
    ) {
      const selectedSite = siteList.filter(
        (currentSite) => currentSite.siteKey === siteKey,
      )
      setSiteData(session, selectedSite ? selectedSite[0] : {}, alerts)
    }
  }, [alerts])

  React.useEffect(() => {
    setActiveAlerts([])
  }, [siteKey])

  useEffect(() => {
    initializeCustomAlert()
  }, [flags?.customWebModal])

  const markAlertSeen = React.useCallback(() => {
    const timestamp = moment().valueOf()
    setActiveAlerts((prevAlerts) =>
      prevAlerts.map((a) => {
        a.seenTimestamp = timestamp
        return a
      }),
    )
    alertSeenTimestampVar(moment().valueOf())
    wifiAlertSeenTimestampVar(moment().valueOf())
  }, [])

  const initializeCustomAlert = () => {
    const alertFlag = flags?.customWebModal || '{}'

    try {
      const flagOptions: TFirebaseAlert = JSON.parse(alertFlag)
      setCustomAlert(flagOptions)
    } catch (error) {
      return
    }
  }

  const customAlertIsActive = useMemo(() => {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss'
    const currentHour = moment().utc()

    let notSeen = true
    if (customAlert?.to && customAlertSeenTimestamp) {
      notSeen = moment(customAlert.to).isAfter(moment(customAlertSeenTimestamp))
    }
    return (
      customAlert?.to &&
      customAlert?.from &&
      currentHour.isSameOrAfter(moment.utc(customAlert.from, dateFormat)) &&
      currentHour.isSameOrBefore(moment.utc(customAlert.to, dateFormat)) &&
      notSeen
    )
  }, [customAlert, customAlertSeenTimestamp])

  const markCustomAlertSeen = () => {
    customAlertSeenTimestampVar(customAlert?.to || '')
  }

  const { ICDAlert, ICEAlert, IDAlert, WIFIAlert } = useMemo(
    () => ({
      ICDAlert:
        ICD_ALERT_FLAG &&
        activeAlerts.find(
          (a) => a.alertType === ALERTS_ID.ICD && !a.seenTimestamp,
        ),
      ICEAlert:
        DCE_ICE_ALERT_FLAG &&
        activeAlerts.find(
          (a) => ICE_ALERTS.includes(a.alertType) && !a.seenTimestamp,
        ),
      IDAlert:
        ALERT_DEVICE_DOWN_FLAG &&
        activeAlerts.find(
          (a) => ID_ALERTS.includes(a.alertType) && !a.seenTimestamp,
        ),
      WIFIAlert: activeAlerts.find(
        (a) => a.alertType === ALERTS_ID.WIFI && !a.seenTimestamp,
      ),
    }),
    [ICD_ALERT_FLAG, activeAlerts, DCE_ICE_ALERT_FLAG, ALERT_DEVICE_DOWN_FLAG],
  )

  const hasAlerts = useMemo(
    () =>
      !!ICDAlert ||
      !!ICEAlert ||
      !!IDAlert ||
      !!WIFIAlert ||
      !!customAlertIsActive,
    [ICDAlert, ICEAlert, IDAlert, WIFIAlert, customAlertIsActive],
  )
  const showICDAlert = useMemo(() => !!ICDAlert, [ICDAlert])

  const showIDAlert = useMemo(() => !ICDAlert && !!IDAlert, [ICDAlert, IDAlert])
  const showICEAlert = useMemo(
    () => !ICDAlert && !IDAlert && !!ICEAlert,
    [ICDAlert, IDAlert, ICEAlert],
  )
  const showWifiAlert = useMemo(
    () => !ICDAlert && !IDAlert && !ICEAlert && !!WIFIAlert,
    [ICDAlert, IDAlert, ICEAlert, WIFIAlert],
  )

  const doubleAlert = useMemo(
    () =>
      (!!ICDAlert || !!ICEAlert || !!IDAlert || !!WIFIAlert) &&
      !!customAlertIsActive,
    [ICDAlert, ICEAlert, IDAlert, WIFIAlert, customAlertIsActive],
  )
  return hasAlerts ? (
    <>
      <div
        data-testid="alerts-container"
        className={clsx('banner-container', className)}
      >
        {customAlertIsActive && (
          <div className="alert is-flex custom-alert">
            <img src={alertSingleIcon} alt="alert" className="alert-icon" />
            <span data-testid="custom-alert-description">
              {customAlert?.bannerText}
            </span>
            <Link
              to={(location) => {
                return setToPathForLink(location, paths.ALERT_CUSTOM_MODAL)
              }}
              className="learn-more-modal"
            >
              Learn more
            </Link>
            <img
              src={closeIcon}
              alt="close"
              onClick={markCustomAlertSeen}
              className="close-icn"
            />
          </div>
        )}
        {showICDAlert && (
          <div className="alert is-flex">
            <img src={alertIcon} alt="alert" />
            <span data-testid="icd-alert-description">
              We’ve lost communication with your system.
            </span>
            <Link
              to={(location) => {
                return setToPathForLink(location, paths.ALERT_ICD_MODAL)
              }}
              className="learn-more-modal"
            >
              Learn more
            </Link>
            <img
              src={closeIcon}
              alt="close"
              onClick={markAlertSeen}
              className="close-icn"
            />
          </div>
        )}
        {showIDAlert && (
          <div className="alert is-flex">
            <img src={alertIcon} alt="alert" />
            <span data-testid="id-alert-description">
              One or more devices are not working properly.
            </span>
            <Link
              to={(location) => {
                return setToPathForLink(location, paths.ALERT_ID_MODAL)
              }}
              className="learn-more-modal"
            >
              Learn more
            </Link>
            <img
              src={closeIcon}
              alt="close"
              onClick={markAlertSeen}
              className="close-icn"
            />
          </div>
        )}
        {showICEAlert && (
          <div className="alert is-flex">
            <img src={alertIcon} alt="alert" />
            <span data-testid="ice-alert-description">
              One or more devices are not communicating properly.
            </span>
            <Link
              to={(location) => {
                return setToPathForLink(location, paths.ALERT_ICE_MODAL)
              }}
              className="learn-more-modal"
            >
              Learn more
            </Link>
            <img
              src={closeIcon}
              alt="close"
              onClick={markAlertSeen}
              className="close-icn"
            />
          </div>
        )}
        {showWifiAlert && (
          <div className="alert is-flex">
            <img src={alertIcon} alt="alert" />
            <span data-testid="wifi-alert-description">
              The system is not communicating through Wifi.
            </span>
            <Link
              to={(location) => {
                return setToPathForWifi(location)
              }}
              className="learn-more-modal"
            >
              Set up your Wifi connection.
            </Link>
            <img
              src={closeIcon}
              alt="close"
              onClick={markAlertSeen}
              className="close-icn"
            />
          </div>
        )}
      </div>
      {doubleAlert ? (
        <div className="double-spacing" />
      ) : (
        <div className="single-spacing" />
      )}
    </>
  ) : null
}
