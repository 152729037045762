import { useContext } from 'react'
import { useCookies } from 'react-cookie'

import {
  LOGIN_OVERRIDE,
  MY_SUNPOWER_CUSTOMER_CHECK,
  MY_SUNPOWER_ID_TOKEN,
  MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
  MY_SUNPOWER_MONITORING_ID_TOKEN,
  MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN,
  MY_SUNPOWER_USER_CONTEXT,
  USER_SELECTED_SITE_KEY,
  USER_SESSION_INFO,
} from './../../config/cookies'
import { UserContext } from '../../context/userContext'

export const useSession = () => {
  const userContext = useContext(UserContext)
  const [cookies, _, removeCookie] = useCookies([
    MY_SUNPOWER_MONITORING_ID_TOKEN,
    MY_SUNPOWER_MONITORING_ACCESS_TOKEN,
    USER_SESSION_INFO,
    USER_SELECTED_SITE_KEY,
    LOGIN_OVERRIDE,
  ])

  const logout = (isKiosk = false) => {
    removeCookie(MY_SUNPOWER_MONITORING_ID_TOKEN)
    removeCookie(MY_SUNPOWER_MONITORING_ACCESS_TOKEN)
    removeCookie(MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN)
    removeCookie(MY_SUNPOWER_USER_CONTEXT)
    removeCookie(USER_SESSION_INFO)
    removeCookie(USER_SELECTED_SITE_KEY)
    removeCookie(LOGIN_OVERRIDE)
    userContext?.setUser({
      ...userContext.user,
      logoutIdToken: cookies[MY_SUNPOWER_MONITORING_ID_TOKEN],
      loggingOut: true
    })
  }

  return { logout: logout }
}
