import './style.scss'

import React from 'react'

import { useSavings } from '../../shared/hooks'
import { INTERVALS } from '../../shared/intervals'
import { SITE_TYPES } from '../../shared/sharedConstants'
import { MoreButton } from '../more-button'
import { Tabs } from '../tabs'
import { BillSavings } from './billSavings'
import { EnvironmentalSavings } from './environmentalSavings'
import { EnvironmentalSavingsDetail } from './environmentalSavingsDetail'

export const Savings = ({
  siteKey,
  startDate,
  endDate,
  interval,
  timezone,
  siteType,
}: {
  siteKey: string
  startDate: number
  endDate: number
  interval: INTERVALS
  timezone?: string | null
  siteType?: string
}) => {
  const { savings } = useSavings(
    siteKey,
    startDate,
    endDate,
    interval,
    timezone,
  )
  const { billSavings, environmentalSavings } = savings

  const { carmiles, gasoline, trees, carbondioxide } = environmentalSavings

  const billSavingsTabObject = {
    label: 'Bill savings',
    className: 'bill-savings-tab',
    component: () => <BillSavings total={billSavings.value} />,
  }
  const environmentalSavingsTabObject = {
    label: 'Environmental',
    className: 'environmental-tab',
    component: () => (
      <React.Fragment>
        <EnvironmentalSavings carbondioxide={carbondioxide} />
        <MoreButton>
          <EnvironmentalSavingsDetail
            miles={carmiles}
            gallons={gasoline}
            trees={trees}
          />
        </MoreButton>
      </React.Fragment>
    ),
  }
  const savingsActiveTabs =
    siteType !== SITE_TYPES.PROD_ONLY
      ? [billSavingsTabObject, environmentalSavingsTabObject]
      : [environmentalSavingsTabObject]
  return (
    <div className="savings">
      <Tabs className="savings-tabs" tabs={savingsActiveTabs} />
    </div>
  )
}
