export const MY_SUNPOWER_MONITORING_ACCESS_TOKEN =
  'my_sunpower_monitoring_access_token'
export const MY_SUNPOWER_MONITORING_VENDOR_ACCESS_TOKEN =
  'my_sunpower_monitoring_vendor_access_token'
export const MY_SUNPOWER_MONITORING_ID_TOKEN = 'my_sunpower_monitoring_id_token'
export const LOGIN_OVERRIDE = 'login_override'
export const USER_SESSION_INFO = 'user_session_info'
export const USER_SELECTED_SITE_KEY = 'user_selected_site_key'
export const MY_SUNPOWER_ID_TOKEN = 'my_sunpower_id_token'
export const MY_SUNPOWER_CUSTOMER_CHECK = 'my_sunpower_customer_check'
export const MY_SUNPOWER_PENDING_SYSTEM = 'my_sunpower_pending_system'
export const MY_SUNPOWER_USER_CONTEXT = 'my_sunpower_user_context'
export const OKTA_SALES_LOGIN_URL = 'okta_sales_login_url'