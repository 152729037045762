import { get } from 'lodash/object'

import { ALERTS_ID } from '../hooks/useAlerts'
import { CONNECTION_STATUSES } from './connectionStatuses'

const PROD_METER_REGEX = /^PVS\d.+p$/i
const PVS_5_REGEX = /PVS5/i
const PVS_6_REGEX = /PVS6/i
const DATALOGGER_DEVICE_TYPE = ['datalogger', 'logger']
const NON_LEGACY_COMM_PROTOCOL = ['MQTT']

export const getUserData = (user) => {
  return {
    partyID: user.partyId,
  }
}

export const getFirmwareVersion = (loggerFirmwareVersion: string) => {
  const clearSection = loggerFirmwareVersion.split(' ').shift()
  const fwValue = clearSection?.split('.').pop() || '0'
  return +fwValue
}

export const getSiteData = (site) => {
  const assignments = get(site, 'assignments', [])
  const devices: any = []
  let communicationStatus: string | null = null

  const state = get(site, 'state', '')

  assignments?.map((currentAssignment) => {
    const asignADevices = get(currentAssignment, 'devices', [])
    asignADevices?.map((current) => devices.push(current))
  })

  devices.map(
    (currentDevice) =>
      (communicationStatus =
        currentDevice?.deviceStatus?.netIntfRptCtnt?.currNetIntfEnum),
  )

  const communicationMode = getNetworkFormat(communicationStatus)

  const hasPVS5 = devices.filter((currentDevice) =>
    PVS_5_REGEX.test(currentDevice.dvcKey),
  )

  const hasPVS6 = devices.filter((currentDevice) =>
    PVS_6_REGEX.test(currentDevice.dvcKey),
  )

  const productionMeter = devices.filter((currentDevice) =>
    PROD_METER_REGEX.test(currentDevice.prodMdlNm),
  )

  const isLeggacy = assignments?.map((cAssignment) => {
    const asignLegacy = DATALOGGER_DEVICE_TYPE.includes(
      cAssignment?.deviceType?.toLowerCase(),
    )
    const devicesLegacy = cAssignment?.devices?.filter((cDevice) => {
      NON_LEGACY_COMM_PROTOCOL.includes(cDevice.comProto)
    })
    return asignLegacy && devicesLegacy?.length === 0
  })

  let loggerFirmwareVersion = ''
  let loggerSoftwareVersion = ''

  const mainAssignment = assignments.length > 0 ? assignments[0] : null
  if (mainAssignment?.devices?.length > 0) {
    loggerFirmwareVersion = mainAssignment?.devices[0].fwVerNo ?? ''
    loggerSoftwareVersion = mainAssignment?.devices[0].sfwVerNo
  }

  const firmwareVersionBuild = getFirmwareVersion(loggerFirmwareVersion)

  return {
    has_PVS6: hasPVS6?.length > 0,
    has_PVS5: hasPVS5?.length > 0,
    active_production_meter: productionMeter > 0,
    loggerFirmwareVersion,
    loggerSoftwareVersion,
    firmwareVersionBuild,
    site_type: site?.siteType || '',
    has_legacy_pvs: isLeggacy.length > 0,
    site_ID: site.siteKey,
    communication_mode: communicationMode,
    finance_type: site?.financeType || '',
    state,
  }
}

const getNetworkFormat = (communicationStatus) => {
  let networkFormat = ''
  let valuesOnNetwork = Object.values(CONNECTION_STATUSES)
  let indexOfNetwork = Object.values(CONNECTION_STATUSES).indexOf(
    (communicationStatus as unknown) as CONNECTION_STATUSES,
  )

  if (
    communicationStatus &&
    valuesOnNetwork.includes(
      (communicationStatus as unknown) as CONNECTION_STATUSES,
    )
  ) {
    networkFormat = Object.keys(CONNECTION_STATUSES)[indexOfNetwork]
  }

  return networkFormat
}

export const getAlertsData = (
  alerts,
): {
  has_ICD_alert: boolean
  has_DCE_alert: boolean
  has_DD_alert: boolean
} => {
  if (alerts) {
    const icdCollection = alerts.filter((currentAlert) => {
      return currentAlert.alertType === ALERTS_ID.ICD
    })

    const dceCollection = alerts.filter((currentAlert) => {
      return currentAlert.alertType === ALERTS_ID.ICE
    })

    const ddCollection = alerts.filter((currentAlert) => {
      return currentAlert.alertType === ALERTS_ID.ID
    })

    return {
      has_ICD_alert: icdCollection.length > 0,
      has_DCE_alert: dceCollection.length > 0,
      has_DD_alert: ddCollection.length > 0,
    }
  }
  return {
    has_ICD_alert: false,
    has_DCE_alert: false,
    has_DD_alert: false,
  }
}

export const getLaunchDarklyContext = (selectedSite, user) => {
  return {
    key: selectedSite.siteKey,
    custom: {
      ...getUserData(user),
      ...getSiteData(selectedSite),
    },
  }
}
