import { unixToMomentOffset } from './unixToMomentOffset'

export const extractSiteDetails = (site) => {
  const {
    siteName,
    postalCode,
    city,
    address1,
    state,
    timezone,
    currentWeather,
    systemTime = new Date().valueOf() / 1000, //set as a default but pass string in test
  } = site
  const { sunrise, sunset } = currentWeather
  const convertToMoment = unixToMomentOffset(timezone)
  return {
    systemTime: convertToMoment(systemTime),
    sunrise: convertToMoment(sunrise),
    sunset: convertToMoment(sunset),
    nickname: siteName,
    street: address1,
    zip: postalCode,
    city: `${city}, ${state}`,
  }
}
