import React from 'react'

export const SystemInfo = () => {
  return (
    <div className="content">
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          What can I learn from the System Information widget?
        </h3>
        <p className="is-size-5-5">
          You'll see the current state of your solar system—either reporting or
          not reporting. If your system is not reporting, check for an alert at
          the top of your screen for details about the issue and make sure your
          internet connection is working.
        </p>
        <p className="is-size-5-5">
          Click the more arrow at the bottom to expand the widget and see
          additional system information. Click the less arrow at the bottom if
          you do not wish to have this information displayed.
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          How can I update my system information?
        </h3>
        <p className="is-size-5-5">
          To change your site nickname, click the gear in the header and create
          or update it. Naming your site won't change your account first and
          last name, it's only a nickname for the site.
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          What can I learn from the Last Data time?
        </h3>
        <p className="is-size-5-5">
          This widget displays the approximate time we last received data from
          your solar system. We should receive this data every 5-15 minutes so
          the last data time will usually be a few minutes behind local time. We
          update your graphs based on the last data we received so your graphs
          will usually be a few minutes behind local time as well.
        </p>
      </div>
      <div className="mb-5">
        <p className="is-size-5-5">
          <span className="has-text-weight-bold">Please note:</span> Your last
          data time may sometimes be more than 15 minutes behind local time due
          to a lag in the reporting. Please wait to see if reporting resumes. If
          there is an issue, this widget will display "System Not Reporting" and
          an alert will appear at the top of your screen with troubleshooting
          instructions.
        </p>
      </div>
      <div className="mb-5">
        <h3 className="is-size-4 has-text-weight-semibold">
          I see red LEDs on the device that sends my solar data, my PV
          Supervisor. What do they mean?
        </h3>
        <p className="is-size-5-5">
          We included a document in your Document Library that explain the LEDs,
          their status, indications, and basic troubleshooting. Refer to{' '}
          <a href="/#/support/documentlibrary">PV Supervisor LED Indicators</a>{' '}
          for an explanation of the LEDs and when to contact Support.
        </p>
      </div>
    </div>
  )
}
