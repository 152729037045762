import React from 'react'

import { WeatherIconProps } from './cloudy'

export const Thunderstorm = ({ x = 0, y = 0 }: WeatherIconProps) => (
  <svg
    width="27"
    height="27"
    viewBox={`${x} ${y} 27 27`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="27" height="27" />
    <path
      d="M17.4743 18.1581C17.5617 17.8961 17.4201 17.613 17.1581 17.5257C16.8961 17.4383 16.613 17.5799 16.5257 17.8419L17.4743 18.1581ZM14.5257 23.8419C14.4383 24.1039 14.5799 24.387 14.8419 24.4743C15.1039 24.5617 15.387 24.4201 15.4743 24.1581L14.5257 23.8419ZM16.5257 17.8419L14.5257 23.8419L15.4743 24.1581L17.4743 18.1581L16.5257 17.8419Z"
      fill="#6EC6E9"
    />
    <path
      d="M19.9682 18.1756C20.0651 17.917 19.9341 17.6288 19.6756 17.5318C19.417 17.4349 19.1288 17.5659 19.0318 17.8244L19.9682 18.1756ZM17.5318 21.8244C17.4349 22.083 17.5659 22.3712 17.8244 22.4682C18.083 22.5651 18.3712 22.4341 18.4682 22.1756L17.5318 21.8244ZM19.0318 17.8244L17.5318 21.8244L18.4682 22.1756L19.9682 18.1756L19.0318 17.8244Z"
      fill="#6EC6E9"
    />
    <path
      d="M8.12757 12.0311L8.12758 12.031C8.4261 9.64797 10.4631 7.85072 12.8653 7.85072C14.789 7.85072 16.517 8.99557 17.2685 10.7671C17.3645 10.9941 17.6176 11.1112 17.8543 11.0333C18.224 10.912 18.5564 10.8536 18.8711 10.8536C20.6784 10.8536 22.1493 12.3246 22.1493 14.1319C22.1493 14.736 22.108 15.2978 21.9901 15.7799C21.8722 16.2621 21.6795 16.6576 21.382 16.9375L21.3726 16.9462L21.3658 16.957C21.2582 17.128 21.2039 17.2708 21.2087 17.3886C21.2111 17.4503 21.2299 17.5057 21.2655 17.5514C21.3005 17.5963 21.3469 17.6256 21.3953 17.6444C21.4891 17.6807 21.6039 17.6825 21.7096 17.6717C21.8178 17.6606 21.929 17.6348 22.0226 17.6056L22.0466 17.5981L22.0642 17.5802C22.8274 16.8034 23.1 15.5337 23.1 14.1319C23.1 11.7997 21.2032 9.90289 18.8711 9.90289C18.5763 9.90289 18.2772 9.93982 17.9651 10.0137C16.9881 8.1097 15.0302 6.9 12.8653 6.9C10.0904 6.9 7.7225 8.89922 7.23155 11.6002C5.83385 12.0781 4.88267 13.3848 4.88267 14.8826C4.88267 16.8004 6.44315 18.3609 8.36092 18.3609C8.62335 18.3609 8.83628 18.1479 8.83628 17.8855C8.83628 17.6231 8.62335 17.4101 8.36092 17.4101C6.96725 17.4101 5.83339 16.2763 5.83339 14.8826C5.83339 13.7167 6.62799 12.7094 7.76721 12.4334C7.95948 12.3876 8.10287 12.2268 8.12757 12.0311Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      strokeWidth="0.2"
    />
    <path
      d="M12.3624 17.1552L13.1141 12.4523L13.15 12.4302C14.3586 11.6888 15.9437 12.1762 16.5269 13.4686L13.9762 20.1152L11.7639 19.2723L9.45794 25.4105L10.3854 16.4358L12.3624 17.1552Z"
      fill="#FFC72C"
    />
  </svg>
)
